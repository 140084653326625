import { ABVariant } from "server-sdk/src/types";

let _saveExpGroup: (variant: ABVariant) => void;

export const setExperimentSaveFn = (fn: (variant: ABVariant) => void) => {
  _saveExpGroup = fn;
};

let _getExpGroup: () => ABVariant | undefined;

export const setExperimentGetFn = (fn: () => ABVariant | undefined) => {
  _getExpGroup = fn;
}

export const getExperimentGroup = () => {
  return _getExpGroup && _getExpGroup();
}

export const setExperimentGroup = (variant: ABVariant) => {
  _saveExpGroup && _saveExpGroup(variant);
}
