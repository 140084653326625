import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import { Card } from 'src/base';
import { theme } from 'ui-sdk/src/theme';
import { MarbleButton } from '../inputs';
import { MarbleText } from '../texts/Text';

interface PaperListProps {
  items: ReactNode[];
}

interface CardItemProps {
  title: string;
  captions: string[];
}

export const CardList = ({ items }: PaperListProps): JSX.Element => (
  <div>
    {items?.map((item) => (
      <div
        style={{
          paddingBlock: theme.spacing(2),
        }}
      >
        <Card>{item}</Card>
      </div>
    ))}
  </div>
);

export const CardItem = ({ title, captions }: CardItemProps) => (
  <div
    style={{
      padding: theme.spacing(2),
      margin: theme.spacing(2),
    }}
  >
    <Grid container justifyContent="space-between">
      <Grid item md={6} xs={12}>
        <MarbleText sx={{ marginBottom: 0 }}>{title}</MarbleText>
      </Grid>
      <Grid item md={6} xs={12}>
        <Grid container justifyContent="space-between" direction="row">
          {captions?.map((caption) => (
            <MarbleText size="s" sx={{ marginBottom: 0 }}>
              {caption}
            </MarbleText>
          ))}
        </Grid>
      </Grid>
    </Grid>
  </div>
);

interface CardItemWithButtonProps {
  title: string;
  caption: string;
  buttonTitle: string;
  onClick: any;
}

export const CardItemWithButton = ({
  title,
  caption,
  buttonTitle,
  onClick,
}: CardItemWithButtonProps) => (
  <div
    style={{
      padding: theme.spacing(2),
      margin: theme.spacing(2),
    }}
  >
    <Grid container justifyContent="space-between">
      <Grid item md={8} xs={12}>
        <MarbleText>{title}</MarbleText>
      </Grid>
      <Grid item md={2} xs={12}>
        <MarbleText>{caption}</MarbleText>
      </Grid>
      <Grid item md={2} xs={12}>
        <MarbleButton title={buttonTitle} onClick={onClick} />
      </Grid>
    </Grid>
  </div>
);
