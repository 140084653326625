import axios from 'axios';

export const getSuggestions = async (address_line_1) => {
  const response = await lobApi.post('us_autocompletions', {
    address_prefix: address_line_1,
  });
  return response?.data?.suggestions;
};

const lobApi = axios.create({
  baseURL: 'https://api.lob.com/v1/',
});

// Auth for lob only uses the token in the 'username' field.
// To skip the password, the documentation says to add a semicolon ":" after the key
// https://docs.lob.com/#tag/Authentication
const constructAuthToken = (key) => Buffer.from(`${key}:`).toString('base64');

lobApi.interceptors.request.use((config) => {
  config.headers = {
    Authorization: `Basic ${constructAuthToken(process.env.LOB_API_KEY)}`,
  };
  return config;
});

export default lobApi;
