import { Application, LeaseAddendumInfo, User } from "../types";
import { DocTemplate, DocusignType } from "./templates";

export interface EnvelopeData {
  id?: string
  type?: DocusignType;
  lease_addendum_info_id?: number;
  email?: string
  application_id?: number;
  doc?: DocTemplate
  data?: Envelope;
  error?: string;
  guid?: string
};

export interface Envelope {
  envelope_id?: string;
  envelope_status?: DocusignStatus;
  application_id?: number;
  users?: User[];
  signers?: UserEnvelope[];
  application?:Application;
  leaseAddendumInfo?: LeaseAddendumInfo;
  enable_redo?: boolean;
}

export interface UserEnvelope{
  docusign_envelope_id?: number;
  user_id?: number;
  docusign_user_id?: string;
  email?: string;
  envelope_recipient_id?: string;
  envelope_signed_status?: DocusignStatus
}

export enum DocusignStatus {
  notsent = 0,
  sent = 1,
  delivered = 2,
  completed = 3
}

export interface DocusignSigner {
  email?: string;
  name?: string;
  priority?: number;
  signAnchorString?: string;
  user_id?:number
}
export interface DocusignInfo {
  accessToken?: string;
  apiAccountId?: string;
  basePath?: string;
}

export interface CreateEnvelopeArgs {
  info: DocusignInfo;
  envelopeTitle: string;
  signDate: number;
  signers: DocusignSigner[];
}