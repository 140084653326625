let getAnalytics = () => undefined;

export const initAnalytics = (analyticsFn: () => any) => {
  getAnalytics = analyticsFn;
}

const enabled = () =>
  process.env.NODE_ENV === 'production' && getAnalytics();

type OwnerFunnel =
  | 'Visit'
  | 'Sign Up'
  | 'Click Tier'
  | 'Owner Account Creation'
  | 'Create Account'
  | 'Login'
  | 'Add Property'
  | 'ID Verification Started'
  | 'ID Verification Complete'
  | 'ID Verification Cancelled'
  | 'ID Verification Errored'
  | 'Message Sent'
  | 'Referral Link Copied'
  | 'Call Booked'
  | 'Rental Services Shop'
  | 'Multi Page Form Submission Attempt'
  | 'Multi Page Form Submission Complete'
  | 'Button Click';

type RenterFunnel =
  | 'Update Availability'
  | 'Add Availability'
  | 'Remove Availability'
  | 'Preview Availability Link'
  | 'Copy Availability Link'
  | 'Copy Renter Referral Link'
  | 'Renter Availability View'
  | 'Renter Availability Booking'
  | 'Suggested Rentals Clicked';

export const track = (event: OwnerFunnel | RenterFunnel, props?: any) => {
  if (enabled()) {
    // @ts-ignore
    getAnalytics().track(event, props);
  }
};

export const alias = (userId: number) => {
  if (enabled()) {
    // @ts-ignore
    getAnalytics().identify(userId.toString());
  }
};

export const identify = (userId: number) => {
  if (enabled()) {
    // @ts-ignore
    getAnalytics().identify(userId.toString());
  }
};

let getGtag = () => undefined;

export const initGtag = (gtagFn: () => any) => {
  getGtag = gtagFn;
}

const gtagEnabled = () =>
  process.env.NODE_ENV === 'production' && getGtag();

export const sendConversion = () => {
  // @ts-ignore
  gtagEnabled() && getGtag()('event', 'conversion', {
    send_to: 'AW-874863650/zbovCJu03toBEKK4laED',
  });
  try {
    track('Owner Account Creation');
  } catch (e) {
    console.error('Failed to track owner account creation to segment');
  }
}
