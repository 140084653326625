import { useEffect, useState } from "react";
import { validateBoolean, validateNumber, validateObject } from "../util/validate";
import { useApiDispatch, useApiStore } from "./global";
import { LeaseAddendumChanges, LeaseAddendumInfo, LeaseAddendumType, RentalAgreement } from "server-sdk/src/types";
import { cents, usd, useMultiPageForm } from "./useForm";

export const useTenantLeaseExtensionForm = (
  onFinish: () => void,
) => {
  const apiDispatch = useApiDispatch();

  const [rentalAgreement, setRentalAgreement] = useState<
    RentalAgreement | undefined
  >(undefined);

  const [started, startFlow] = useApiStore(api => api.flows.start);

  useEffect(() => {
    apiDispatch(async (call, api) => {
      const res = await call(api.property.getRenterRentalAgreement);
      console.log(res);
      if (res) {
        setRentalAgreement(res);
        return [true];
      }

      return [false, "Something went wrong"];
    });
  }, []);

  const pages = useMultiPageForm({
    choice: {
      initial: {
        choice: undefined,
      },
      validationSchema: validateObject({
        choice: validateBoolean().required(),
      }),
      label: 'Lease Extension',
      description: 'Would you like to request to extend your current lease?',
      steps: (values) => [
       {
          name: 'choice',
          label: 'Choice',
          required: true,
          type: 'choice',
          exclusive: true,
          options: [
            {
              label: 'Request Extension',
              caption: 'This will notify the landlord for an extension request on the current lease.',
              value: true
            },
            {
              label: 'No Renewal',
              caption: 'No extension will be requested for the current lease',
              value: false,
            },
          ],
        },
      ],
      submit: ({ values, done }) => {
        if(!values.choice.choice){
          done();
          onFinish();
          return;
        }
        done();
      },
    },
    extension_length: {
      label: "New End Lease",
      description: "How many months do you want to extend the lease for?",
      initial: {
        extension_months: 12,
      },
      validationSchema: validateObject({
        extension_months: validateNumber().required("Months is required"),
      }),
      disabled: (values) => (!values.choice.choice),
      steps: (values) => [
        {
          name: "extension_months",
          label: "Months",
          placeholder: "Months",
          required: true,
          type: "number",
        },
      ],
      submit: ({ values, done }) => {
        if(!values.choice.choice){
          done();
          onFinish();
          return;
        }
        apiDispatch(async (call, api) => {
          const res = await call(api.leasing_addendum.createInfo, {
            rental_agreement_id: rentalAgreement?.id,
            addendum_type: LeaseAddendumType.LeaseExtension,
            change_fields: {
              effective_date: rentalAgreement?.end_lease,
              requested_extension_months: values.extension_length.extension_months
            },
            reason: "Tenant Extension request"
          });

          if (!res) {
            return [false, "Something went wrong"];
          }

          startFlow({
            params: {
              type: "leasing-addendum",
              args: {
                propertyId: rentalAgreement?.property_id ?? 0,
                leaseAddendumInfoId: parseInt(res.id ? res.id.toString() : "0"),
              },
            }
          });

          done();
          onFinish();

          return [true, "Successfully submitted lease extension request"];
        });
      },
    },
  });

  return pages;
};

export const useLandlordLeaseExtensionForm = (
  leaseAddendumInfo: LeaseAddendumInfo,
  flowActivityId: number,
  onFinish: (declined: boolean) => void
) => {
  const apiDispatch = useApiDispatch();

  const acceptLeasingExtensionFlow = (
    change_fields: LeaseAddendumChanges,
    done: () => void
  ) => {
    apiDispatch(async (call, api) => {
      const res = await call(api.leasing_addendum.updateInfo, {
        id: leaseAddendumInfo.id,
        change_fields,
      });

      if (!res) {
        return [false, "Something went wrong"];
      }

      // complete flow
      call(api.flows.complete, {
        id: flowActivityId,
        metadata: {
          extend_lease: true,
        },
      });

      onFinish(false);
      done();
      return [true];
    });
  };

  const declineLeasingExtensionFlow = (
    change_fields: LeaseAddendumChanges,
    done: () => void
  ) => {
    apiDispatch(async (call, api) => {
      const res = await call(api.leasing_addendum.updateInfo, {
        id: leaseAddendumInfo.id,
        change_fields,
        addendum_type: LeaseAddendumType.NonLeaseRenewal
      });

      if (!res) {
        return [false, "Something went wrong"];
      }

      // complete flow
      const actionRes = await call(api.flows.complete, {
        id: flowActivityId,
        metadata: {
          extend_lease: true,
        },
      });
      
      if(!actionRes || actionRes?.error){
        return [false, (actionRes && actionRes?.error) ? actionRes.error : "Something went wrong"]
      }

      onFinish(false);
      done();
      return [true];
    });
  };

  const pages = useMultiPageForm({
    choice: {
      initial: {
        choice: undefined,
      },
      validationSchema: validateObject({
        choice: validateBoolean().required(),
      }),
      label: "Lease Extension",
      description:
        "Would you like to extend the current lease for this property?",
      steps: (values) => [
        {
          name: "choice",
          label: "Choice",
          required: true,
          type: "choice",
          exclusive: true,
          options: [
            {
              label: "Extend Lease",
              caption:
                "This will allow you to extend the current lease with a new rent and end lease date",
              value: true,
            },
            {
              label: "No Renewal",
              caption: "No extension will be placed for the current lease",
              value: false,
            },
          ],
        },
      ],
      submit: ({ values, done }) => {
        if (!values.choice.choice) {
          declineLeasingExtensionFlow(
            { extend_lease: values.choice.choice },
            done
          );
          return;
        }
        apiDispatch(async (call, api) => {
          const res = await call(api.leasing_addendum.updateInfo, {
            id: leaseAddendumInfo.id,
            change_fields: {
              extend_lease: values.choice.choice,
            },
          });

          if (res) {
            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
    rent: {
      label: "New Rent Price",
      description:
        "Enter in the new rent price you want for the duration of the extended lease.",
      initial: {
        listing_price: usd(
          leaseAddendumInfo.rental_agreement?.base_rent_amount
        ),
      },
      validationSchema: validateObject({
        listing_price: validateNumber().required("Listing price is required"),
      }),
      disabled: (values) => (!values.choice.choice),
      steps: (values) => [
        {
          name: "listing_price",
          label: "Listing Price",
          placeholder: "Rent",
          required: true,
          type: "usd",
        },
      ],
      resources: (values) => {
        const property : any = leaseAddendumInfo.rental_agreement?.property
        const state = property?.state
        const limits = {
          CA: "Limited to 5% plus consumer price index (CPI) annually, up to a maximum of 10%.",
          NY: "Determined by the DHCR",
          OR: "Limited to 7% plus consumer price index (CPI) annually.",
          WA: "Should not increase more than 6.2%",
        }
        const res : any[] = state in limits ? [
          {
            name: "listing_price",
            title: "Rent Increase Limit",
            description:
              // @ts-ignore
              `${limits[state]}`,
          },
        ] : [];
        return res
      },
      submit: ({ values, done }) => {
        console.log("values", values);
        apiDispatch(async (call, api) => {
          const res = await call(api.leasing_addendum.updateInfo, {
            id: leaseAddendumInfo.id,
            change_fields: {
              new_rent: values.rent.listing_price
                ? cents(values.rent.listing_price)
                : undefined,
            },
          });

          if (res) {
            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
    extension_months: {
      label: "New End Lease",
      description: "How many months do you want to extend the lease for?",
      initial: {
        extension_months: 12,
      },
      validationSchema: validateObject({
        extension_months: validateNumber().required("Months is required"),
      }),
      disabled: (values) => (!values.choice.choice),
      steps: (values) => {
        const steps : any[] = []
        if(!!leaseAddendumInfo.change_fields?.requested_extension_months){
          steps.push({
            name: "placeholder",
            label: `Tenant's desired extension length: ${leaseAddendumInfo.change_fields.requested_extension_months} months`,
            required: false,
            type: "note",
          });
        }
        steps.push(
        {
          name: "extension_months",
          label: "Months",
          placeholder: "Months",
          required: true,
          type: "number",
        });
        return steps
    },
      submit: ({ values, done }) => {
        console.log("values", values);
        acceptLeasingExtensionFlow(
          {
            extension_months: values.extension_months.extension_months,
          },
          done
        );
      },
    },
  });

  return pages;
};