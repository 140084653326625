export type ABVariant = "control" | "b" | "c" | "d" | "e";

type ABTest = {
  distribution: number,
  routes: ABRoute[],
}

type ABRoute = string;

export const AB_COOKIE = 'experiment_v2';
export const AB_HEADER = 'mrb-ab-test';

export const TESTS: { [K in ABVariant]: ABTest } = {
  "control": {
    distribution: 100,
    routes: [],
  },
  "b": {
    distribution: 0,
    routes: [
      '/',
      '/owner/portfolio/property/:propertyId',
    ],
  },
  "c": {
    distribution: 0,
    routes: [
      '/',
      '/pricing',
      '/how-it-works',
    ],
  },
  "d": {
    distribution: 0,
    routes: [
      '/',
      '/pricing',
    ],
  },
  "e": {
    distribution: 0,
    routes: [
      '/',
      '/pricing',
    ],
  },
};

if (Object.values(TESTS).map(v => v.distribution).reduce((a, b) => a + b, 0) != 100) throw new Error('Incorrect distribution of ab tests!')
