import React from 'react';
import { Grow as MuiGrow } from '@mui/material';
import { CssStyleProps } from 'src/base/props/CssStyleProps';

interface FadeProps {
  on: boolean;
}

export const Grow: React.FC<FadeProps & CssStyleProps> = ({
  children,
  style,
  on,
}) => (
  <MuiGrow in={on} style={style}>
    <div>{on && children}</div>
  </MuiGrow>
);
