import React, { CSSProperties } from 'react';
import { CssStyleProps } from 'src/base/props/CssStyleProps';
import { BoldableProps, TextProps } from 'src/base/texts/props';
import { Text } from 'src/base/texts/Text';

export const P: React.FC<CssStyleProps & TextProps & BoldableProps> = ({
  align,
  bold,
  children,
  style,
}) => {
  let finalStyle: CSSProperties = style;
  if (bold) {
    finalStyle = {
      ...finalStyle,
      fontWeight: 500,
    };
  }
  return (
    <Text variant="body1" style={finalStyle} align={align} bold>
      {children}
    </Text>
  );
};
