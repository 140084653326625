import { CacheProvider, EmotionCache } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import Head from 'next/head';
import { Router, useRouter } from 'next/router';
import React from 'react';
import { Provider } from 'react-redux';
import withMarketingLayout from 'src/components/layout/MarketingLayout';
import withOwnerLayout from 'src/components/layout/OwnerLayout';
import withRenterLayout from 'src/components/layout/RenterLayout';
import createEmotionCache from 'src/deps/emotion';
import store from 'ui-sdk/src/store';
import { MarbleThemeProvider } from 'src/styles/theme';
import { AppPropsWithLayout } from 'src/utils/types';
import '../styles/global.css';
import { initExperiment } from 'src/utils/useCookie';
import { setupRouter } from 'ui-sdk/src/router';
import { setupPersona } from 'ui-sdk/src/persona';
import { personaVerification } from 'src/services/persona';
import { setupStripe } from 'ui-sdk/src/hooks/stripe';
import { loadStripe } from '@stripe/stripe-js';
import { setupGuidGenerator } from 'ui-sdk/src/util/guid';
import { v4 } from 'uuid';
import { setupLoginLogout } from 'ui-sdk/src/call';
import { updateToken } from 'ui-sdk/src/ducks/identity';
import { initAnalytics, initGtag } from 'ui-sdk/src/util/track';
import { initTheme } from 'ui-sdk/src/theme';

// eslint-disable-next-line block-scoped-var
if (typeof globalThis === 'undefined') {
  // eslint-disable-next-line vars-on-top, no-var, no-new-func
  var globalThis = Function('return this')();
}

Router.events.on('routeChangeComplete', (url) => {
  // @ts-ignore
  window.analytics.page(url);
});

initExperiment();
setupRouter(useRouter);
setupPersona(personaVerification);
setupStripe(loadStripe, process.env.STRIPE_API_KEY);
setupGuidGenerator(v4);
setupLoginLogout(
  () => store.getState().identity.token,
  () => {
    store.dispatch(updateToken(null));
  },
);

// @ts-ignore
initGtag(() => gtag);
// @ts-ignore
initAnalytics(() => window.analytics);

initTheme({
  usePx: true,
  multiplier: 5,
});

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface EmtionAppPropsWithLayout extends AppPropsWithLayout {
  emotionCache?: EmotionCache;
}

function MyApp({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
  router,
}: EmtionAppPropsWithLayout) {
  const path = router.pathname;

  const WrappedComp = Component.getLayout ?
    Component :
    (path.startsWith('/owner') && withOwnerLayout(Component)) ||
      (path.startsWith('/renter') && withRenterLayout(Component)) ||
      withMarketingLayout(Component);

  const getLayout = WrappedComp.getLayout || ((page) => page);

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      {/* <ThemeProvider theme={theme}> */}
      <MarbleThemeProvider>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Provider store={store}>
          {getLayout(<WrappedComp {...pageProps} />)}
        </Provider>
        {/* </ThemeProvider> */}
      </MarbleThemeProvider>
    </CacheProvider>
  );
}

export default MyApp;
