export const toMonthDayYear = (time: number) => {
  const date = new Date(time);
  const st = date.toLocaleDateString("en-US");
  return st;
};

// date picker has time zone by default
// must convert to utc before sending to server
export const addTimeZoneOffsetForServer = (date: Date) => {
  const utcOffset = date.getTimezoneOffset();
  date.setMinutes(date.getMinutes() - utcOffset);
  return date;
};

// value is in utc time.
// must offset by local time zone to display correctly in the ui
export const revertTimeZoneOffsetForUI = (gmtTimeInMilliseconds: number) => {
  const date = new Date();
  const offsetInMinutes = date.getTimezoneOffset();

  const localTime = gmtTimeInMilliseconds + offsetInMinutes * 60 * 1000;
  return localTime;
};