import * as yup from 'yup';
import { call } from '../call';
import { api } from 'server-sdk/src/api';
import { useGlobal } from '../hooks/global';

const PHONE_NUMBER_REGEX =  /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
export const validateObject = yup.object;
export const validateString = yup.string;
export const validateNumber = yup.number;
export const validateDate = yup.date;
export const validateBoolean = yup.boolean;
export const validateArray = yup.array;

export const validatePhoneSync = (value : any) => {
  yup.string().matches(PHONE_NUMBER_REGEX, 'Phone number is not valid').validateSync(value)
}

export const validateUserExists = async (value, isEmailIdentity) => {
  if (!value) {
    // if it is empty then dont try to validate, it will fail from previous validation
    return true;
  }
  try {
    // validation logic
    const identity = isEmailIdentity ? { email: value } : { phone: value };
    const checkUser = await call(api.users.auth.validate, identity);
    return !checkUser?.exists;
  } catch (_) {
    const { error } = useGlobal();
    error(`Failed to validate identity`);
  }
};
