import Head from 'next/head';
import React from 'react';

interface TitleDescriptionHeadProps {
  title: string;
  description: string;
}

export default ({ description, title }: TitleDescriptionHeadProps) => (
  <Head>
    <title>Marble - Virtual Rental Management</title>
    <meta property="og:title" content={title} key="og:title" />
    <meta
      property="og:description"
      content={description}
      key="og:description"
    />
    <meta property="og:type" content="website" />
    <meta property="description" content={description} />
  </Head>
);
