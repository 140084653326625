import { Radio, styled } from '@mui/material';
import { theme } from 'ui-sdk/src/theme';

const MarbleRadio = styled(Radio)(() => ({
  '&': {
    color: theme.colors.gray[3],
  },
  '&.Mui-checked': {
    color: theme.colors.blue.main,
  },
}));

export default MarbleRadio;
