import { Box, LinearProgress } from '@mui/material';
import React from 'react';
import { CssStyleProps } from 'src/base/props/CssStyleProps';
import { P } from 'src/base/texts/P';

interface ProgressProps {
  current: number;
  max: number;
}

export const Progress: React.FC<ProgressProps & CssStyleProps> = ({
  current,
  style,
  max,
}) => {
  const progress = (current / max) * 100;
  return (
    <div style={style}>
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
        <Box minWidth={35}>
          <P
            style={{
              opacity: 0.5,
              fontSize: '75%',
            }}
          >
            {`${Math.round(progress)}%`}
          </P>
        </Box>
      </Box>
    </div>
  );
};
