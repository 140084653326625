import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { call } from '../call';
import { api } from 'server-sdk/src/api';
import {
  Address,
  LaundryType,
  LeasingStep,
  ListingStatus,
  Property,
  PropertySubscription,
  UtilityAssignment,
  UserIdVerificationPlatform,
  UserType,
  ABVariant,
} from 'server-sdk/src/types';
import {
  validateBoolean,
  validateNumber,
  validateObject,
  validateString,
} from '../util/validate';
import { useForm, useReduxState, useApiDispatch, useGlobal, useUser } from '.';
import {
  getMyProperties,
  getTours,
  refreshApplications,
  refreshPortfolio,
} from '../ducks/portfolio';
import {
  updateProperty,
} from '../thunks/portfolio';
import { useRouter } from '../router';
import { usePersona } from '../persona';
import { CrossPlatformStripe, useStripe } from './stripe';
// import { loadStripe, Stripe } from '@stripe/stripe-js';

export const useActivate = () => {
  const [verified, setVerified] = useState(false);
  const apiDispatch = useApiDispatch();
  const dispatch = useDispatch();
  const { refreshMe } = useUser();
  const portfolio = useReduxState((state) => state.portfolio);
  const verify = usePersona();
  const [stripe, setStripe] = useState<CrossPlatformStripe>();

  useEffect(() => {
    dispatch(async () => {
      if (!stripe) {
        const loaded = await useStripe();
        setStripe(loaded);
      }
    });
  }, []);

  const verifyStripeIdentity = async (propertyId? : number) => {
    if (!stripe) {
      console.log('Verification no stripe');
      return false;
    }

    let finalVerified = verified;
    if (!finalVerified) {
      const realTime = await call(api.identity.stripe.fetch);
      finalVerified = realTime?.idVerified;
    }

    if (!finalVerified) {
      // Call your backend to create the VerificationSession.
      const response = await call(api.identity.stripe.verify, {
        propertyId,
      });

      if (!response) {
        console.log("Stripe identity verify failed with undefined response. Property Id : ", propertyId)
        return false;
      }
      // Show the verification modal.
      const { error } = await stripe.verifyIdentity(
        response.stripeClientSecret,
      );

      await refreshMe();

      if (error) {
        return false;
      }
    }

    return true;
  };

  const verifyPersona = async () => {
    let finalVerified = verified;
    if (!finalVerified) {
      const realTime = await call(api.identity.verification.fetch);
      finalVerified = realTime?.idVerified;
    }

    if (!finalVerified) {
      const res = await verify();
      if (!res) {
        setVerified(false);
        console.log('failed to verify id');
        return false;
      }
      refreshMe();
      setVerified(true);
    }

    return true;
  };

  const activateWithIdCheck = (
    propertyId: number,
    status: ListingStatus,
    idVerificationPlatform,
  ) => {
    const p = portfolio.properties.find((prop) => prop.id === propertyId);

    // eslint-disable-next-line no-shadow
    apiDispatch(async (call, api) => {
      if (!p) {
        return [false, 'Could not find this property under your account'];
      }

      if (
        idVerificationPlatform &&
        idVerificationPlatform === UserIdVerificationPlatform.Persona
      ) {
        const verified = await verifyPersona();

        if (!verified) {
          return [
            false,
            'Failed to verify identity. Please refresh the page and try again.',
          ];
        }

        const updatedp = await call(api.property.update, {
          id: propertyId,
          listing_status: status,
        });
        if (!updatedp) {
          return [false, 'Failed to modify property status. Please try again.'];
        }
        if (updatedp && updatedp.error) {
          return [false, updatedp.error];
        }

        dispatch(getMyProperties());

        return [true, 'Property is now activated!'];
      }
      const verified = await verifyStripeIdentity(propertyId);
      if (!verified) {
        return [
          false,
          'Failed to verify identity. Please refresh the page and try again.',
        ];
      }

      return [true, 'Verifying identity! This may take up to 5 minutes.'];
    });
  };

  const activate = (propertyId: number, status: ListingStatus) => {
    const p = portfolio.properties.find((prop) => prop.id === propertyId);

    // eslint-disable-next-line no-shadow
    apiDispatch(async (call, api) => {
      if (!p) {
        return [false, 'Could not find this property under your account'];
      }

      const updatedp = await call(api.property.update, {
        id: propertyId,
        listing_status: status,
      });
      if (!updatedp) {
        return [false, 'Failed to modify property status. Please try again.'];
      }
      if (updatedp && updatedp.error) {
        return [false, updatedp.error];
      }

      dispatch(getMyProperties());

      return [true, 'Property is now activated!'];
    });
  };

  const validate = (onFinish?: (v: boolean) => void) => {
    // eslint-disable-next-line no-shadow
    apiDispatch(async (call, api) => {
      let finalVerified = verified;
      const validationStatus = await call(api.identity.verification.fetch);
      if (!finalVerified) {
        finalVerified = validationStatus?.idVerified;
      }

      if (!finalVerified) {
        let res;
        if (!validationStatus || validationStatus.idVerificationPlatform === UserIdVerificationPlatform.Stripe) {
          res = await verifyStripeIdentity();
        }
        if (validationStatus.idVerificationPlatform === UserIdVerificationPlatform.Persona) {
          res = await verifyPersona();
        }
        if (!res) {
          setVerified(false);
          console.log('failed to verify id');
          return [
            false,
            'Failed to verify identity. Please refresh the page and try again.',
          ];
        }
        refreshMe();
        setVerified(true);
        if (onFinish) {
          onFinish(true)
        }
      }

      return [true];
    });
  };

  return {
    activate,
    activateWithIdCheck,
    verified,
    verify,
    validate,
    verifyStripeIdentity,
  };
};

export const useAddressForm = (address: Address) =>
  useForm<Address>({
    initial: address,
    validationSchema: validateObject({
      addr1: validateString().min(1, 'Enter a valid address'),
      city: validateString().min(1, 'Enter a valid city'),
      state: validateString().min(2, 'Enter valid state'),
      zip: validateString().matches(
        /(^\d{5}$)|(^\d{5})/,
        'Enter valid 5 digit zip code',
      ),
    }),
  });

export const usePropertyForm = (property: Property, field: string | undefined) => {
  const dispatch = useDispatch();
  const lock = useReduxState((state) =>
    state.portfolio.locks.find((l) => l.propertyId === property?.id),
  );
  const global = useGlobal();
  return useForm<Property>({
    initial: property,
    validationSchema: validateObject({
      prop_type: validateNumber(),
      bedrooms: validateNumber(),
      bathrooms: validateNumber(),
      sq_footage: validateNumber().nullable(true),
      address: validateObject({
        addr1: validateString().min(1, 'Enter a valid address'),
        city: validateString().min(1, 'Enter a valid city'),
        state: validateString().min(2, 'Enter valid state'),
        zip: validateString().matches(
          /(^\d{5}$)|(^\d{5}-\d{4}$)/,
          'Enter valid 5 digit zip code',
        ),
      }),
      allow_dogs: validateBoolean(),
      allow_cats: validateBoolean(),
      dog_deposit: validateNumber(),
      dog_fee: validateNumber(),
      cat_deposit: validateNumber(),
      cat_fee: validateNumber(),

      description: validateString(),
      access_instructions: validateString().nullable(true),
      listing_price: validateNumber(),
      security_deposit: validateNumber(),
      listing_status: validateNumber()
        .oneOf([
          ListingStatus.Off,
          ListingStatus.Waitlist,
          ListingStatus.Touring,
          ListingStatus.ManualTouring,
        ])
        .test(
          'validateEnablingTours',
          'Please install a lock before enabling tours',
          (value, formikVariables) => {
            if (
              !lock &&
              value === ListingStatus.Touring &&
              !formikVariables?.parent?.direct_tour
            ) {
              global.error(`Please install a lock before enabling tours`);
              return false;
            }
            return true;
          },
        ),
      laundry_type: validateNumber().oneOf([
        null,
        undefined,
        LaundryType.None,
        LaundryType.InUnit,
        LaundryType.Shared,
      ]),
      garage_parking: validateBoolean(),
      offstreet_parking: validateBoolean(),

      appliances: validateObject(),
      late_fees: validateObject(),

      parking_type: validateString().oneOf([
        'carport',
        'garage attached',
        'off street',
        'on street',
        'none',
      ]),
      parking_count: validateNumber(),
      parking_fee: validateNumber(),

      water_utilities: validateNumber().oneOf([
        null,
        undefined,
        UtilityAssignment.NA,
        UtilityAssignment.Owner,
        UtilityAssignment.Renter,
      ]),
      electric_utilities: validateNumber().oneOf([
        null,
        undefined,
        UtilityAssignment.NA,
        UtilityAssignment.Owner,
        UtilityAssignment.Renter,
      ]),
      gas_utilities: validateNumber().oneOf([
        null,
        undefined,
        UtilityAssignment.NA,
        UtilityAssignment.Owner,
        UtilityAssignment.Renter,
      ]),
      garbage_sewage_utilities: validateNumber().oneOf([
        null,
        undefined,
        UtilityAssignment.NA,
        UtilityAssignment.Owner,
        UtilityAssignment.Renter,
      ]),
    }),
    submit: (values) => {
      console.log(values);
      console.log(field);

      const convertToInt = (value: number | undefined) => parseInt((value ?? 0).toString()) * 100;

      switch (field) {
        case "type":
          dispatch(
            updateProperty({
              id: property.id,
              prop_type: values.prop_type,
              bedrooms: values.bedrooms,
              bathrooms: values.bathrooms,
              sq_footage: values.sq_footage
            }),
          );
          break;
        case "price":
          dispatch(
            updateProperty({
              id: property.id,
              listing_price: convertToInt(values?.listing_price),
              security_deposit: convertToInt(values?.security_deposit),
            }),
          );
          break;
        case "late_fees":
          dispatch(
            updateProperty({
              id: property.id,
              late_fees: values.late_fees
            }),
          );
          break;
        case "pet_policy":
          dispatch(
            updateProperty({
              id: property.id,
              allow_cats: values.allow_cats,
              allow_dogs: values.allow_dogs,
              dog_deposit: convertToInt(values?.dog_deposit),
              dog_fee: convertToInt(values?.dog_fee),
              cat_deposit: convertToInt(values?.cat_deposit),
              cat_fee: convertToInt(values?.cat_fee),
            }),
          );
          break;
        case "description":
          dispatch(
            updateProperty({
              id: property.id,
              description: values.description
            })
          );
          break;
        case "amenities":
          dispatch(
            updateProperty({
              id: property.id,
              laundry_type: values.laundry_type,
              parking_type: values.parking_type,
              parking_count: values.parking_count,
              parking_fee: convertToInt(values?.parking_fee),
            }),
          );
          break;
        case "appliances":
          dispatch(
            updateProperty({
              id: property.id,
              appliances: values.appliances
            }),
          );
          break;
        case "utilities":
          dispatch(
            updateProperty({
              id: property.id,
              gas_utilities: values.gas_utilities,
              water_utilities: values.water_utilities,
              electric_utilities: values.electric_utilities,
              garbage_sewage_utilities: values.garbage_sewage_utilities
            }),
          );
          break;
        case "access_info":
          dispatch(
            updateProperty({
              id: property.id,
              access_instructions: values.access_instructions
            }),
          );
          break;
        default:
          dispatch(
            updateProperty({
              ...values,
              id: property.id,
              listing_price: convertToInt(values?.listing_price),
              security_deposit: convertToInt(values?.security_deposit),
              dog_deposit: convertToInt(values?.dog_deposit),
              dog_fee: convertToInt(values?.dog_fee),
              cat_deposit: convertToInt(values?.cat_deposit),
              cat_fee: convertToInt(values?.cat_fee),
              parking_fee: convertToInt(values?.parking_fee),
            }),
          );
          break;
      }
      
    },
  });
};

export const usePropertySubscription = (propertyId: number) => {
  const { property } = useProperty(propertyId);

  const active = useMemo(() => {
    const sub = property?.subscription;
    return (
      sub === PropertySubscription.Subscribed ||
      sub === PropertySubscription.Legacy
    );
  }, [property?.subscription]);

  return {
    active,
  };
};

interface TodoItem {
  title: string;
  description: string;
  actionText?: string;
  action?: () => void;

  complete?: boolean;
  warningText?: string;
  learn?: string;
  pending?: boolean;
  verified?: boolean;
}

interface TodoSection {
  title: string;
  items: TodoItem[];
}

export const usePropertyTodo = (propertyId: number, setModal?: any, experiment?: ABVariant) => {
  const { portfolio } = useReduxState((state) => state);
  const { property } = useProperty(propertyId);
  const lock = portfolio.locks?.filter((l) => l.propertyId === propertyId)[0];
  const { active } = usePropertySubscription(propertyId);
  const { me } = useUser();
  const { success } = useGlobal();
  const { refreshMe } = useUser();
  const [propertyListed, setPropertyListed] = useState(undefined);
  const dispatch = useDispatch();

  const baseUrl = `/owner/portfolio/property/${propertyId}`;

  const rented = property?.rented;

  const router = useRouter();
  const act = useActivate();

  let todos: TodoSection[] = [];

  useEffect(() => {
    if (me?.authToken && me.type === UserType.Owner) {
      refreshMe();
      const interval = setInterval(() => {
        refreshMe();
        dispatch(getMyProperties());
        setPropertyListed(property?.listing_status !== ListingStatus.Off);
      }, 5000);

      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  if (property?.existing_renter) {
    todos = [
      {
        title: 'Prepare Your Property for Listing and Rental!',
        items: [
          {
            title: 'Tell us about your property',
            description:
              'Describe your property to inform current tenants and interested renters',
            learn: 'https://capture.navattic.com/ckzegegmz3089609l9seiau1vg',
            actionText: 'Edit Property',
            action: () => {
              router.push(`${baseUrl}/settings`);
            },
            complete: true,
          },
          {
            title: 'Activate Marble Premium',
            description:
              'Subscribe your property to activate our full virtual management services.',
            learn: 'https://capture.navattic.com/ckzegegmz3089609l9seiau1vg',
            action: () => {
              router.push(`${baseUrl}/subscribe`);
            },
            actionText: 'Subscribe',
            complete: Boolean(
              property?.subscription === PropertySubscription.Subscribed,
            ),
          },
        ],
      },
      // {
      //   title: 'Edit your existing tenant info and lease',
      //   items: [
      //     {
      //       title: 'Edit Tenant Info',
      //       description: '',
      //       learn: 'https://capture.navattic.com/ckzegegmz3089609l9seiau1vg',
      //       actionText: 'Edit Tenant',
      //       action: () => {
      //         router.push(`${baseUrl}/listing#existing_tenant`);
      //       },
      //       complete: true,
      //     },
      //     {
      //       title: 'Edit Lease Info',
      //       description: '',
      //       learn: 'https://capture.navattic.com/ckzegegmz3089609l9seiau1vg',
      //       actionText: 'Edit Lease',
      //       action: () => {
      //         router.push(`${baseUrl}/listing#existing_tenant`);
      //       },
      //       complete: true,
      //     },
      //   ],
      // },
    ];
  } else {
    todos = [
      {
        title: 'Complete the following to prepare your property’s listing',
        items: [
          {
            title: me?.idVerificationFailed ?
              'Activate Listing - Failed Identity Check' :
              me?.idVerificationPending ?
                'Activate Listing - Pending Identity Check' :
                'Activate Listing',
            description:
              'Advertise across all our integrated Ad Networks including Zillow, Trulia, Hotpads, and many more.',
            learn: 'https://capture.navattic.com/ckzegegmz3089609l9seiau1vg',
            action: async () => {
              if (active) {
                if (me?.idVerificationPending) {
                  success(
                    'We are currently processing your identity. This may take up to 5 minutes',
                  );
                } else if (me?.idVerified || (experiment === 'e' && ((me?.trial_days_remaining && me.trial_days_remaining > 0) || me?.subscribed))) {
                  act.activate(
                    propertyId,
                    lock ? ListingStatus.Touring : ListingStatus.Waitlist,
                  );
                } else {
                  act.activateWithIdCheck(
                    propertyId,
                    lock ? ListingStatus.Touring : ListingStatus.Waitlist,
                    me?.idVerificationPlatform,
                  );
                }
              } else {
                setModal(true);
              }
            },
            actionText: !me?.idVerified && !me?.idVerificationPending ?
              'Verify Identity' :
              me?.idVerificationPending ?
                'Pending Id Check' :
                'Activate',
            complete: Boolean(property?.listing_status !== ListingStatus.Off),
            pending: me?.idVerificationPending,
            verified: me?.idVerified,
          },
          {
            title: 'Tell us about your property',
            description:
              'Describe your property to inform current tenants and interested renters',
            learn: 'https://capture.navattic.com/ckzegegmz3089609l9seiau1vg',
            actionText: 'Edit Property',
            action: () => {
              router.push(`${baseUrl}/settings`);
            },
            complete: true,
          },
          {
            title: 'Activate Marble Premium',
            description:
              'Subscribe your property to activate our full virtual management services.',
            learn: 'https://capture.navattic.com/ckzegegmz3089609l9seiau1vg',
            action: () => {
              router.push(`${baseUrl}/subscribe`);
            },
            actionText: 'Subscribe',
            complete: Boolean(
              property?.subscription === PropertySubscription.Subscribed,
            ),
          },
        ],
      },
    ];

    // todos.push({
    //   title: 'Enable Self-Touring with a smart lock!',
    //   items: [
    //     {
    //       title: 'Order Smart Lock',
    //       description:
    //         'Marble has an integration with smart locks to place tenants twice as fast as industry standards. Order your locks to get started with touring.',
    //       learn: 'https://capture.navattic.com/ckzegegmz3089609l9seiau1vg',
    //       actionText: 'Order Lock',
    //       action: () => {
    //         router.push(`/owner/order-lock/${propertyId}/start`);
    //       },
    //       complete: property?.lock_ordered || Boolean(lock),
    //     },
    //     {
    //       title: 'Setup Smart Lock',
    //       description:
    //         'Marble has an integration with smart locks to place tenants twice as fast as industry standards. Add your locks to get started with touring.',
    //       learn: 'https://capture.navattic.com/ckzegegmz3089609l9seiau1vg',
    //       actionText: 'Add Lock',
    //       action: () => {
    //         router.push(`${baseUrl}/property-access`);
    //       },
    //       complete: Boolean(lock),
    //     },
    //   ],
    // });
  }

  return {
    incomplete:
      !rented && Boolean(todos.find((t) => t.items.find((i) => !i.complete))),
    sections: todos,
  };
};

export const useProperty = (propertyId: number) => {
  const dispatch = useDispatch();

  const portfolio = useReduxState((state) => state.portfolio);
  const property = useMemo(
    () => portfolio.properties?.filter((p) => p.id === propertyId)[0],
    [propertyId, portfolio.properties],
  );

  const { success, error } = useGlobal();

  const update = useCallback(
    (p: Property) => {
      dispatch(async () => {
        try {
          const updated = await call(api.property.update, p);
          if (updated && !updated.error) {
            dispatch(refreshPortfolio());
            success('Property has been updated!');
          } else if (!updated) {
            error("Property wasn't updated. Please try again later.");
          } else if (updated.error) {
            error(`Property wasn't updated. ${updated.error}`);
          }
        } catch (e) {
          error('Something went wrong. Please try again later.');
        }
      });
    },
    [property],
  );

  return {
    property,
    update,
  };
};

export const usePortfolioRefresher = () => {
  const dispatch = useDispatch();
  const tours = () => {
    dispatch(getTours());
  };
  const properties = () => {
    dispatch(getMyProperties());
  };
  const applications = () => {
    dispatch(refreshApplications());
  }

  return {
    tours,
    properties,
    applications
  };
};

export const useLeasingData = (propertyId: number) => {
  const dispatch = useApiDispatch();
  const [step, setStep] = useState<LeasingStep>(LeasingStep.NotStarted);

  const refresh = () => {
    // eslint-disable-next-line no-shadow
    dispatch(async (call, api) => {
      const l = await call(api.property.leasing, {
        propertyId,
      });
      console.log('leasing data', l);

      if (l) {
        setStep(l.step);
        return [true];
      }

      return [false];
    });
  };

  return {
    refresh,
    step,
  };
};
