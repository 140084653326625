import { Grid, Autocomplete } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { States_With_NA } from 'server-sdk/constants';
import { UserAddress } from 'server-sdk/types';
import { MarbleDropdown } from 'src/base';
import { getSuggestions } from 'src/services/lob';
import { useDispatch } from 'react-redux';
import { MarbleTextInput } from './MarbleTextInput';

interface AutoCompleteAddressFormProp {
  address: UserAddress;
  handleChange: any;
  required: boolean;
}

interface Option {
  label: string;
  address: UserAddress;
}

export const AutoCompleteAddressForm: React.FC<AutoCompleteAddressFormProp> = ({
  address,
  handleChange,
  required,
}) => {
  const dispatch = useDispatch();
  const [suggestions, setSuggestions] = useState<Option[]>(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(async () => {
      if (address?.address_line_1 && !loading) {
        setLoading(true);
        const suggestions = await getSuggestions(address.address_line_1);
        const conv = suggestions?.map((s) => ({
          label: `${s.primary_line}, ${s.city}, ${s.state} ${s.zip_code}`,
          address: {
            address_line_1: s.primary_line,
            city: s.city,
            state: s.state,
            zipcode: s.zip_code,
          },
        }));

        setSuggestions(conv);
        setLoading(false);
      }
    });
  }, [address]);

  return (
    <Grid container spacing={1} p={0}>
      <Grid item xs={12}>
        <Autocomplete
          freeSolo
          value={address?.address_line_1 ?? ''}
          disablePortal
          id="chat-suggestions"
          sx={{ width: '100%' }}
          options={suggestions ?? []}
          renderInput={(params) => (
            <MarbleTextInput
              params={params}
              inputProps={{
                ...params.inputProps,
                maxLength: 50,
              }}
              placeholder="Address Line 1"
              required={required}
            />
          )}
          onChange={(_, value) => {
            const option = value as Option;
            if (option?.address) {
              handleChange(option.address);
            }
          }}
          onInputChange={(event: any, newValue: string | null) => {
            if (event?.type === 'change') {
              handleChange({
                ...address,
                address_line_1: newValue,
              });
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <MarbleTextInput
          placeholder="Unit Number (optional)"
          value={address?.address_line_2 ?? ''}
          inputProps={{ maxLength: 50 }}
          onChange={(e) => {
            const event = e as ChangeEvent<any>;
            handleChange({
              ...address,
              address_line_2: event.target.value,
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <MarbleTextInput
          placeholder="City"
          value={address?.city ?? ''}
          onChange={(e) => {
            const event = e as ChangeEvent<any>;
            handleChange({
              ...address,
              city: event.target.value,
            });
          }}
          required={required}
        />
      </Grid>
      <Grid item xs={12}>
        <MarbleDropdown
          options={States_With_NA.map((state) => ({
            value: state,
            label: state,
          }))}
          value={address?.state ?? ''}
          placeholder="State"
          required
          onChange={(e) => {
            const event = e as ChangeEvent<any>;
            handleChange({
              ...address,
              state: event.target.value,
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <MarbleTextInput
          placeholder="Zip Code"
          value={address?.zipcode ?? ''}
          onChange={(e) => {
            const event = e as ChangeEvent<any>;
            handleChange({
              ...address,
              zipcode: event.target.value,
            });
          }}
          required={required}
        />
      </Grid>
    </Grid>
  );
};
