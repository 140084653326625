import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import React from 'react';
import { theme } from 'ui-sdk/src/theme';

interface BaseContainerProps {
  style?: React.CSSProperties;
  wide?: boolean;
  padding?: number;
  width?: number;
}

export const BaseContainer: React.FC<BaseContainerProps> = ({
  children,
  wide,
  padding,
  width,
}) => {
  const pad = padding ?? 50;
  return (
    <Collapse in timeout={500}>
      <div
        style={
          {
            // ...style,
          }
        }
      >
        <Container
          maxWidth={wide ? 'xl' : 'lg'}
          style={{
            paddingTop: pad,
            paddingBottom: pad,
            paddingInline: theme.spacing(2),
            margin: 'auto',
          }}
          {...(!wide && {
            style: {
              paddingTop: pad,
              paddingBottom: pad,
              margin: 'auto',
              maxWidth: width ?? 900,
            },
          })}
        >
          {children}
        </Container>
      </div>
    </Collapse>
  );
};
