import * as MUIIcons from '@mui/icons-material';
import Description from '@mui/icons-material/Description';
import {
  AppBar,
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputBaseComponentProps,
  LinearProgress,
  RadioGroup,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { arrayMoveImmutable } from 'array-move';
import { DateTime } from 'luxon';
import Image from 'next/image';
import { useRouter } from 'ui-sdk/src/router';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { CaretLeft, CaretRight } from 'react-bootstrap-icons';
import NumberFormat from 'react-number-format';
import { Charge, Form, UserType } from 'server-sdk/types';
import { FormBase } from 'server-sdk/api';
import { States_With_NA } from 'server-sdk/constants';
import {
  useApiDispatch,
  useFileUpload,
  useActivate,
  useUser,
} from 'ui-sdk/src/hooks';
import { getWordCount } from 'server-sdk/src/util';
import {
  CostBreakdown,
  RadioList,
  SortablePhotoGalleryRow,
  Button as BaseButton,
  MarbleDropdown,
  MarbleButton,
} from 'src/base';
import UppyUploader from 'src/base/inputs/UppyUploader';
import { Logo } from 'src/base/logos';
import { AlertCard, AlertCardType } from 'src/base/surfaces/AlertCard';
import { CardInput } from 'src/components/app/stripe';
import { isExternalLink, openExternalLink } from 'src/utils/url';
import { v4 as uuid } from 'uuid';
import { AutoCompleteAddressForm } from 'src/base/inputs/AutoCompleteAddress';
import { compress } from 'src/utils/compress';
import { MarbleNumberInput } from 'src/base/inputs/MarbleNumberInput';
import { theme } from 'ui-sdk/src/theme';
import { MarbleText } from 'src/base/texts/Text';
import { MarbleTextInput } from 'src/base/inputs/MarbleTextInput';
import MarbleRadio from 'src/base/inputs/MarbleRadio';
import { sendCreateSubscriptionConversion } from 'src/utils/google';

const useStyles = makeStyles(() => ({
  datePicker: {
    fontSize: '15px',
    fontWeight: '500',
    lineHeight: '150%',
    fontStyle: 'normal',
    padding: '0px',
    color: theme.colors.gray[1],
    background: theme.colors.gray[5],
    marginBottom: theme.spacing(1),
    border: 0,
  },
}));

export const Num: React.FC<InputBaseComponentProps> = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    // @ts-ignore
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        console.log('changing values', values);
        onChange({
          target: {
            // @ts-ignore
            name: props.name,
            value: values.value,
          },
        });
      }}
      value={props.value ?? ''}
      style={{
        outline: 'none',
        border: 0,
      }}
    />
  );
};

export function NumberInput(props) {
  return (
    <MarbleNumberInput
      required={props.required}
      value={props.value ?? ''}
      onChange={(e) => {
        props.onChange(e);
      }}
      placeholder={props.label}
      {...props}
    />
  );
}

export interface ServerFormProps {
  base: FormBase;
  id: any;
  step: string;
  home: string;
  onFinish: () => void;
  onExit?: () => void;
}

export const ServerForm: React.FC<ServerFormProps> = ({
  id,
  base,
  step,
  home,
  onExit,
  onFinish,
}) => {
  const [form, setForm] = useState<Form>(undefined);
  const [inMemory, setInMemory] = useState<any>({});
  const dispatch = useApiDispatch();
  const router = useRouter();
  const { me } = useUser();

  const loadFromMemory = (form, value) => {
    if (!value) {
      value = {};
    }
    if (form.input.saveToMemory) {
      value = inMemory[form.field];
    }

    if (form.input.subForms?.length) {
      for (const f of form.input.subForms) {
        value[f.field] = loadFromMemory(f, value[f.field]);
      }
    }

    return value;
  };

  const updateValues = (form, currentInMemoryValue, value) => {
    if (form.input.saveToMemory) {
      currentInMemoryValue[form.field] = value[form.field];
    }

    if (form.input.subForms?.length) {
      for (const f of form.input.subForms) {
        updateValues(f, currentInMemoryValue, value);
      }
    }
  };

  const shouldLoadFromMemory = (form) => {
    if (form.input?.saveToMemory) {
      return true;
    }

    if (form.input?.subForms?.length) {
      for (const subForm of form.input.subForms) {
        if (shouldLoadFromMemory(subForm)) {
          return true;
        }
      }
    }

    return false;
  };

  useEffect(() => {
    dispatch(async (call) => {
      if (!id || !step) {
        return [false];
      }

      const f = await call(base.get, {
        id,
        field: step,
      });

      if (!f) {
        return [false, 'Failed to load form.'];
      }

      if (f.error) {
        console.error('An error occured. ', f.error);
        return [false, f.error];
      }

      if (shouldLoadFromMemory(f)) {
        const cloned: any = {
          ...f.value,
        };
        loadFromMemory(f, cloned);

        setForm({
          ...f,
          value: cloned,
        });
      } else {
        setForm(f);
      }

      console.log('got form', step, f);
      if (f.field !== step) {
        console.log('navigating to correct page');
        router.push(`${home}/${id}/${f.field}`);
      }

      console.log('effect', f);

      return [true];
    });
  }, [id, base, step]);

  const { input: curr, value, error, helpUrl } = form ?? {};

  const { val, err } = useMemo(
    () => ({
      val: value,
      err: error,
    }),
    [error, value],
  );

  const handleHelp = () => {
    if (isExternalLink(helpUrl)) {
      openExternalLink(helpUrl);
    }
  };

  const handleSubmit = () => {
    dispatch(async (call) => {
      console.log('sending', step, val);
      const cloned = clone(inMemory);
      updateValues(form, cloned, val);
      setInMemory(cloned);

      const f = await call(base.update, {
        id,
        value: val,
        field: step,
      });
      console.log('received', f);

      if (f) {
        console.log(f.progress);
        if (f.progress === 100) {
          try {
            if (
              step === 'charge' &&
              f.input?.type === 'checkout' &&
              me?.type === UserType.Owner
            ) {
              console.log('tracking subscription');
              sendCreateSubscriptionConversion();
            }
          } catch (e) {
            console.error('Failed to send conversion', e);
          }
          onFinish();
        }

        setForm(f);
        if (f.field !== step) {
          router.push(`${home}/${id}/${f.field}`);
        }
        return [true];
      }

      return [false];
    });
  };

  const handleBack = () => {
    if (form.prev) {
      console.log('back', form);
      router.push(`${home}/${id}/${form.prev}`);
    }
  };

  const { processing, handleUpload, handleUpdate } = useFileUpload(compress);

  if (!curr) {
    return <Box />;
  }

  const handleChange = (v: any) => {
    setForm({
      ...form,
      value: v,
    });
  };

  return (
    <Box>
      <style global jsx>{`
        html,
        body,
        body > div:first-child,
        div#__next,
        div#__next > div {
          height: 100%;
        }
      `}</style>
      <AppBar
        position="absolute"
        color="transparent"
        elevation={0}
        sx={(theme) => ({
          // zIndex: 0,
          [theme.breakpoints.down('sm')]: {},
        })}
      >
        <Grid container>
          <Grid item xs={6} padding={2}>
            <Logo size={40} variant="outline-white" onlyIcon onClick={onExit} />
          </Grid>
          <Grid
            item
            xs={6}
            padding={2}
            margin={0}
            sx={(theme) => ({
              backgroundColor: 'white',
              justifyContent: 'right',
              display: 'flex',
              [theme.breakpoints.down('sm')]: {
                backgroundColor: 'transparent',
              },
            })}
          >
            <MarbleButton
              onClick={handleHelp}
              variant="nobg"
              title="Help"
              sx={(theme) => ({
                [theme.breakpoints.down('sm')]: {
                  backgroundColor: 'gray',
                  color: 'white',
                  borderRadius: 30,
                  fillOpacity: 0.4,
                  m: 0.5,
                },
              })}
            />
            {onExit && (
              <MarbleButton
                title="Save & exit"
                variant="nobg"
                onClick={onExit}
                sx={(theme) => ({
                  [theme.breakpoints.down('sm')]: {
                    backgroundColor: 'gray',
                    color: 'white',
                    borderRadius: 30,
                    fillOpacity: 0.4,
                    m: 0.5,
                  },
                })}
              />
            )}
          </Grid>
        </Grid>
      </AppBar>
      <Box
        sx={(theme) => ({
          [theme.breakpoints.up('sm')]: {
            position: 'absolute',
            right: '50%',
            width: '50%',
            height: '100%',
          },
        })}
      >
        <Box
          sx={(theme) => ({
            position: 'relative',
            width: '100%',
            height: '100%',
            [theme.breakpoints.down('sm')]: {
              pt: 20,
            },
          })}
        >
          <Box
            sx={{
              zIndex: 1,
              // background:
              //   'linear-gradient(to bottom, rgba(0,0,0,0.4) 0%, ' +
              //   'rgba(0,0,0,0.1) 70%, rgba(0,0,0,0) 100%)',
              // display: 'flex',
              height: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <MarbleText
                size="h1"
                style={{
                  color: 'white',
                  paddingLeft: 20,
                  textDecoration: 'underline',
                }}
              >
                {curr.title}
              </MarbleText>
            </Box>
          </Box>
          <Image
            // width="50%"
            // height="100%"
            layout="fill"
            objectFit="cover"
            src={curr?.asset ?? '/img/onboarding/front.jpg'}
            style={{
              filter: 'brightness(60%) blur(2px)',
              WebkitFilter: 'brightness(60%) blur(2px)',
              zIndex: -1,
              background:
                'linear-gradient(to bottom, rgba(0,0,0,0.4) 0%, ' +
                'rgba(0,0,0,0.1) 70%, rgba(0,0,0,0) 100%)',
            }}
          />
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          position: 'relative',
          [theme.breakpoints.up('sm')]: {
            position: 'absolute',
            left: '50%',
            width: '50%',
            height: '100%',
            overflowY: 'scroll',
            pb: 5,
          },
          [theme.breakpoints.down('sm')]: {
            pb: 20,
          },
        })}
      >
        <Box>
          <Box
            sx={(theme) => ({
              // position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              p: 3,
              [theme.breakpoints.up('sm')]: {
                pt: 6,
                pb: 10,
              },
              [theme.breakpoints.down('sm')]: {
                pt: 0,
                pb: 0,
              },
            })}
          >
            <Box
              sx={(theme) => ({
                pt: 0,
                backgroundColor: 'transparent',
                [theme.breakpoints.up('sm')]: {
                  minWidth: '65%',
                  minHeight: '75%',
                  pr: 5,
                  pl: 5,
                },
              })}
            >
              <Box paddingTop={10}>
                <MarbleText size="xl" style={{ display: 'inline' }}>
                  {curr.label}
                  <MarbleText
                    size="xl"
                    color="red.main"
                    style={{
                      display: 'inline',
                    }}
                  >
                    {curr.required ? ' *' : ''}
                  </MarbleText>
                </MarbleText>
              </Box>
              <Box paddingTop={6}>
                <MarbleText>{curr.caption}</MarbleText>
              </Box>
              {err && (
                <Box paddingTop={6}>
                  <MarbleText color="red.main">{err}</MarbleText>
                </Box>
              )}
              <Box paddingTop={6}>
                <FormInput
                  curr={curr}
                  val={val}
                  form={form}
                  handleChange={handleChange}
                  setForm={setForm}
                  id={id}
                  base={base}
                  processing={processing}
                  handleUpload={handleUpload}
                  handleUpdate={handleUpdate}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <Box
        sx={(theme) => ({
          position: 'sticky',
          bottom: 0,
          left: 0,
          right: 0,
          // paddingBottom: 4,
          [theme.breakpoints.up('sm')]: {
            // left: '50%',
          },
        })}
      > */}
      <AppBar
        position="fixed"
        sx={(theme) => ({
          top: 'auto',
          bottom: 0,
          pb: 3,
          backgroundColor: 'white',
          [theme.breakpoints.up('sm')]: {
            left: '50%',
            width: '50%',
          },
        })}
        elevation={0}
      >
        <Box paddingTop={1}>
          <LinearProgress value={form.progress} variant="determinate" />
        </Box>
        <Grid container paddingTop={3} justifyContent="space-between">
          <Grid item>
            <MarbleButton
              title="Back"
              icon={<CaretLeft />}
              sx={{
                display: !form.prev ? 'none' : undefined,
              }}
              onClick={handleBack}
            />
          </Grid>
          <Grid item>
            <MarbleButton
              title="Next"
              endIcon={<CaretRight />}
              variant="contained"
              onClick={handleSubmit}
            />
          </Grid>
        </Grid>
      </AppBar>
      {/* </Box> */}
    </Box>
  );
};

interface FormInputProps {
  curr: any;
  val: any;
  form: any;
  handleChange: any;
  setForm?: any;
  id?: any;
  base?: any;
  processing?;
  handleUpload?: any;
  handleUpdate?: any;
}

const FormInput = ({
  curr,
  val,
  form,
  handleChange,
  setForm,
  id,
  base,
  processing,
  handleUpload,
  handleUpdate,
}: FormInputProps): JSX.Element => {
  const router = useRouter();
  const act = useActivate();
  const classes = useStyles();

  switch (curr.type) {
    case 'choice': {
      const Icon = MUIIcons.HelpOutlineOutlined;
      return (
        <ToggleButtonGroup
          orientation="vertical"
          value={val}
          onChange={(e, v) => {
            handleChange(v);
          }}
          exclusive
        >
          {curr.choices.map((c) => (
            <ToggleButton value={c.value}>
              <Box width="100%">
                <Grid container>
                  <Grid item p={0}>
                    <MarbleText weight="bold" align="left">
                      {c.label}
                    </MarbleText>
                  </Grid>
                  {c.infoURL && (
                    <Grid item paddingLeft={1}>
                      <IconButton
                        style={{
                          display: 'inline-block',
                          minHeight: 0,
                          minWidth: 0,
                          padding: 0,
                        }}
                        size="small"
                        href={c.infoURL}
                        target="_blank"
                      >
                        <Icon fontSize="small" />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
                <MarbleText align="left">{c.caption}</MarbleText>
              </Box>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      );
    }
    case 'yes_no':
      return (
        <FormControl>
          <RadioGroup
            onChange={(e) => {
              if (e.target.value === 'yes') {
                handleChange(true);
              } else if (e.target.value === 'no') {
                handleChange(false);
              }
            }}
            value={val === false ? 'no' : val === true ? 'yes' : ''}
          >
            <FormControlLabel
              label="Yes"
              value="yes"
              control={<MarbleRadio />}
            />
            <FormControlLabel label="No" value="no" control={<MarbleRadio />} />
          </RadioGroup>
        </FormControl>
      );
    case 'number':
      return (
        <Box>
          <NumberInput
            required={curr.required}
            value={val}
            label={curr.label}
            onChange={(e) => {
              const v = curr.halfIncrement ?
                Math.round(parseFloat(e.target.value) * 2) / 2 :
                parseFloat(e.target.value);
              handleChange(v);
            }}
            fullWidth
            InputProps={{
              inputProps: {
                min: curr.min,
                max: curr.max,
                inputMode: 'decimal',
                pattern: '[0-9,.]*',
              },
            }}
            {...(curr.currency === 'usd' && {
              prefix: '$',
              thousandsGroupStyle: 'thousand',
              fixedDecimalScale: true,
              allowNegative: false,
              decimalScale: curr.decimals ?? 0,
            })}
            {...(curr.currency === 'percentage' && {
              suffix: '%',
              fixedDecimalScale: true,
              allowNegative: false,
              decimalScale: curr.decimals ?? 0,
              numberType: 'percentage',
            })}
          />
          {curr.currency === 'percentage' && (
            <Typography style={{ fontSize: 16 }}>
              {val && curr.base ?
                `Amount: $${Math.round(val * curr.base) / 100}` :
                ''}
            </Typography>
          )}
          {curr.inputCaption && (
            <Typography style={{ fontSize: 16 }}>
              {curr?.inputCaption}
            </Typography>
          )}
        </Box>
      );
    case 'text':
      return (
        <Box>
          <MarbleTextInput
            type={curr.password ? 'password' : 'text'}
            placeholder={curr.label}
            value={val ?? ''}
            onChange={(e) => {
              const event = e as ChangeEvent<any>;
              handleChange(event.currentTarget.value);
            }}
            required={curr.required}
          />
        </Box>
      );
    case 'email':
      return (
        <Box>
          <MarbleTextInput
            type="text"
            placeholder={curr.label}
            value={val ?? ''}
            onChange={(e) => {
              const event = e as ChangeEvent<any>;
              handleChange(event.currentTarget.value);
            }}
          />
        </Box>
      );
    case 'phone':
      return (
        <Box>
          <MarbleTextInput
            mask="phone"
            placeholder={curr.label}
            value={val ?? ''}
            onChange={(e) => {
              const event = e as ChangeEvent<any>;
              handleChange(event.target.value);
            }}
            required={curr.required}
          />
        </Box>
      );
    case 'upload_photos':
      return (
        <Box>
          <UppyUploader
            allowedFileTypes={curr.allowedFileTypes}
            handleUpload={async (uppyFiles) => {
              const uploads = await handleUpload(
                base,
                id,
                form.field,
                uppyFiles,
                (files) => {
                  console.log('files', uppyFiles);
                  setForm({
                    ...form,
                    value: form.value ?
                      form.value.concat(files) :
                      [].concat(files),
                  });
                },
              );
              return uploads;
            }}
          />
          {(val?.length ?? 0) > 0 && (
            <MarbleText style={{ marginTop: '1.5em' }} />
          )}
          {processing && <LinearProgress variant="indeterminate" />}
          {/* <SortableGallery
            items={val}
            removePhoto={()}
            onSortEnd={onSortEnd}
            axis="xy"
            pressDelay={150}
          /> */}
          <SortablePhotoGalleryRow
            photos={
              val ?
                val.map((p, i) => ({
                    id: i,
                    src: `${curr.base}/${p}`,
                  })) :
                []
            }
            swapPhotos={(oldIdx, newIdx) => {
              const updates: string[] = arrayMoveImmutable(val, oldIdx, newIdx);
              setForm({
                ...form,
                value: updates,
              });
              handleUpdate(base, id, form.field, updates, (updated) => {
                setForm({
                  ...form,
                  value: updated,
                });
              });
            }}
            removePhoto={(photoId) => {
              const updates = val.filter((p, i) => i !== photoId);
              setForm({
                ...form,
                value: updates,
              });
              handleUpdate(base, id, form.field, updates, (updated) => {
                setForm({
                  ...form,
                  value: updated,
                });
              });
            }}
          />
        </Box>
      );
    case 'upload_documents':
      return (
        <Box>
          <UppyUploader
            allowedFileTypes={curr.allowedFileTypes}
            handleUpload={async (uppyFiles) => {
              const uploads = await handleUpload(
                base,
                id,
                form.field,
                uppyFiles,
                (files) => {
                  handleChange(val.concat(files));
                },
              );
              return uploads;
            }}
          />
          {val?.length > 0 && <MarbleText style={{ marginTop: '1em' }} />}
          {processing && <LinearProgress variant="indeterminate" />}
          {val?.map((p) => (
            <Grid container justifyContent="center" p={2}>
              <Grid container item xs={0.6}>
                <Description />
              </Grid>
              <Grid
                container
                item
                md={4}
                xs={6}
                onClick={() => {
                  window.open(p.path, '_blank');
                }}
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                <MarbleText>{p?.name}</MarbleText>
              </Grid>
              <Grid container item xs={2}>
                <MarbleButton
                  title="X"
                  onClick={() => {
                    handleUpdate(base, id, form.field, [p], (updated) => {
                      handleChange(updated);
                    });
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </Box>
      );
    case 'dropdown':
      return (
        <MarbleDropdown
          options={curr.choices as { value: any; label: string }[]}
          value={val ?? undefined}
          placeholder={curr.caption}
          onChange={(e) => {
            const event = e as ChangeEvent<any>;
            handleChange(event.target.value);
          }}
          style={{
            minWidth: '100%',
          }}
        />
      );
    case 'radio':
      return (
        <RadioList
          options={curr.choices as { value: any; label: string }[]}
          value={val ?? undefined}
          onChange={(e) => {
            const event = e as ChangeEvent<any>;
            handleChange(event.target.value);
          }}
        />
      );
    case 'icons_choice':
      return (
        <ToggleButtonGroup
          orientation="vertical"
          value={val}
          exclusive={curr.exclusive}
        >
          <Grid container spacing={2} maxWidth="600px">
            {curr.choices.map((c) => {
              const iconName: string = c.webIcon ?? 'Error';
              const Icon = MUIIcons[iconName];

              return (
                <Grid item xs={6} sm={4}>
                  <ToggleButton
                    value={c.value}
                    selected={val.includes(c.value)}
                    onChange={(e, v) => {
                      if (val.includes(v)) {
                        const index = val.indexOf(v);
                        if (index > -1) {
                          val.splice(index, 1);
                        }
                      } else if (curr.exclusive) {
                        val = [v];
                      } else if (
                        !curr.maxChoices ||
                        (curr.maxChoices && val.length < curr.maxChoices)
                      ) {
                        val.push(v);
                      }
                      handleChange(val);
                    }}
                  >
                    <Grid container item xs={12}>
                      <Box
                        width="100px"
                        height="100px"
                        alignItems="center"
                        display="flex"
                        alignContent="center"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <Icon fontSize="large" />

                        <MarbleText weight="bold" align="center">
                          {c.label}
                        </MarbleText>
                      </Box>
                    </Grid>
                  </ToggleButton>
                </Grid>
              );
            })}
          </Grid>
        </ToggleButtonGroup>
      );
    case 'paragraph': {
      const valWordCount = getWordCount(val);
      return (
        <Box>
          <MarbleTextInput
            multiline
            rows={5}
            value={val ?? ''}
            onChange={(e) => {
              const event = e as ChangeEvent<any>;
              const currentValue = event.currentTarget.value;
              const currentValueWordCount = getWordCount(currentValue);
              if (curr?.max === undefined || currentValueWordCount < curr.max) {
                handleChange(currentValue);
              } else {
                // apparently need to get rid of the whitespaces
                handleChange(currentValue.trim());
              }
            }}
          />
          {valWordCount !== undefined &&
            valWordCount > 0 &&
            curr?.minWarning !== undefined &&
            curr?.max !== undefined && (
              <Box textAlign="right">
                <MarbleText
                  color={
                    valWordCount <= (curr?.min ?? 0) ?
                      'red.main' :
                      valWordCount <= curr.minWarning ?
                      'red.glass' :
                      'green.main'
                  }
                >
                  {valWordCount} words
                </MarbleText>
              </Box>
            )}
          {valWordCount !== undefined &&
            curr?.minWarning !== undefined &&
            valWordCount <= curr.minWarning &&
            valWordCount > (curr?.min ?? 0) && (
              <AlertCard
                title={`Add at least ${
                  curr?.minWarning ? curr.minWarning - valWordCount : 0
                } more words to ${curr.warningText}`}
                alertType={AlertCardType.Warning}
              />
            )}
        </Box>
      );
    }
    case 'date':
    case 'date_with_text':
      return (
        <Box>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DesktopDatePicker
              className={classes.datePicker}
              value={getDate(curr.type, val)}
              onChange={(e) => {
                if (e instanceof DateTime) {
                  const serverUTCTime = addTimeZoneOffsetForServer(e);
                  if (curr.type === 'date') {
                    handleChange(serverUTCTime.toMillis());
                  } else {
                    handleChange({
                      timestamp: serverUTCTime.toMillis(),
                    });
                  }
                } else if (curr.type === 'date') {
                  handleChange(e);
                } else {
                  handleChange({
                    timestamp: e,
                  });
                }
              }}
              minDate={
                curr?.minDate ? DateTime.fromMillis(curr.minDate) : undefined
              }
              maxDate={
                curr?.maxDate ? DateTime.fromMillis(curr.maxDate) : undefined
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: 'mm/dd/yyyy',
                  }}
                  sx={{
                    fontSize: '15px',
                    fontWeight: '500',
                    lineHeight: '150%',
                    fontStyle: 'normal',
                    padding: '0px',
                    color: theme.colors.gray[1],
                    background: theme.colors.gray[5],
                  }}
                />
              )}
              inputFormat="MM/dd/yyyy"
              label={curr.required ? `${curr.label} *` : curr.label}
            />
          </LocalizationProvider>
        </Box>
      );
    case 'info':
      return (
        <Box>
          {curr.label && (
            <MarbleText
              color={curr.important ? 'red.main' : 'black'}
              style={{ paddingTop: theme.spacing(2) }}
            >
              {curr.label}
            </MarbleText>
          )}
          <MarbleText
            style={{
              paddingTop: theme.spacing(2),
            }}
          >
            {curr.caption}
          </MarbleText>
          <br />
          {curr.buttonText && curr.link && (
            <BaseButton
              title={curr.buttonText}
              href={curr.link}
              target="_blank"
              color="secondary"
              style={{
                marginTop: 10,
                marginBottom: 10,
              }}
            />
          )}
        </Box>
      );
    case 'checkout':
      return (
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <CostBreakdown
              breakdown={curr.charge.breakdown.map((b) => ({
                item: b.name,
                price: b.value,
              }))}
              total={curr.charge.amount}
            />
          </Grid>
          <br />
          {curr.additionalInfo && (
            <Grid item xs={12}>
              <MarbleText>{curr.additionalInfo}</MarbleText>
            </Grid>
          )}
          <Grid item xs={12}>
            <CardInput
              paddingTop={10}
              setToken={(token) => {
                if (token) {
                  const charge: Charge = {
                    ...curr.charge,
                    token: token.id,
                    idempotencyKey: uuid(),
                  };
                  handleChange(charge);
                }
              }}
            />
          </Grid>
        </Grid>
      );
    case 'dynamic_collection':
      if (!val || !val?.length) {
        const newRow = clone(curr.newRow);
        val = [newRow];
        handleChange(val);
      }
      return (
        <Grid container item xs={12} spacing={2} p={0}>
          {val?.map((data, itemIndex) => (
            <Grid container item xs={12} spacing={2} p={0}>
              <Grid container item xs={12} spacing={2} p={0}>
                {curr.rowLabel && (
                  <Grid container item xs={12} p={0}>
                    <MarbleText size="xl" weight="bold">
                      {curr.rowLabel} {itemIndex + 1}
                    </MarbleText>
                  </Grid>
                )}
                {curr?.subForms.map((subItem) => {
                  const handleSetChildForm = (v: any) => {
                    const updated = val;
                    updated[itemIndex][subItem.field] = v;
                    handleChange(updated);
                  };
                  return (
                    <Grid container item xs={12} p={0}>
                      <Grid container item xs={12} p={0}>
                        <MarbleText>{subItem.input.label}</MarbleText>
                      </Grid>
                      <Grid container item xs={12} p={0}>
                        <MarbleText color="gray.1">
                          {subItem.input.caption}
                        </MarbleText>
                      </Grid>
                      <Grid container item xs={12} p={0}>
                        <FormInput
                          curr={subItem.input}
                          val={data[subItem.field]}
                          form={form}
                          handleChange={handleSetChildForm}
                          setForm={setForm}
                          id={id}
                          base={base}
                          processing={processing}
                          handleUpload={handleUpload}
                          handleUpdate={handleUpdate}
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item xs={12} textAlign="center">
                <MarbleButton
                  title="Remove"
                  onClick={() => {
                    val.splice(itemIndex, 1);
                    handleUpdate(base, id, form.field, [data], () => {
                      handleChange(val);
                    });
                  }}
                />
              </Grid>
            </Grid>
          ))}
          <Grid>
            <MarbleButton
              title="+ Add Another"
              onClick={() => {
                const newRow = clone(curr.newRow);
                const updated = val.concat(newRow);
                handleChange(updated);
              }}
            />
          </Grid>
        </Grid>
      );
    case 'collection':
      if (!val) {
        val = {};
        handleChange(val);
      }
      return (
        <Grid container spacing={2}>
          {curr?.subForms.map((subItem) => {
            const handleSetChildForm = (child: any) => {
              const cloned = clone(val);
              cloned[subItem.field] = child;
              handleChange(cloned);
            };

            return (
              <Grid item xs={12} spacing={2}>
                <FormInput
                  curr={subItem.input}
                  val={val[subItem.field]}
                  form={form}
                  handleChange={handleSetChildForm}
                  setForm={setForm}
                  id={id}
                  base={base}
                  processing={processing}
                  handleUpload={handleUpload}
                  handleUpdate={handleUpdate}
                />
              </Grid>
            );
          })}
        </Grid>
      );
    case 'message':
      return (
        <Grid
          container
          item
          xs={curr?.width ?? 12}
          p={0}
          sx={{ flexDirection: 'column', justifyContent: 'center' }}
        >
          <MarbleText
            style={{ fontSize: curr?.fontSize ?? 12, color: curr?.color }}
          >
            {val}
          </MarbleText>
        </Grid>
      );
    case 'checkbox':
      return (
        <Grid container>
          <Grid item xs={12} p={0}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={val}
                    onChange={(e) => {
                      val = e.target.checked;
                      handleChange(val);
                    }}
                  />
                }
                label={
                  <Typography style={{ fontSize: 16 }}>
                    {curr?.label}
                  </Typography>
                }
              />
            </FormGroup>
          </Grid>
        </Grid>
      );
    case 'address':
      return (
        <Grid container item xs={12} p={0} rowSpacing={1}>
          <Grid container spacing={1} p={0}>
            <Grid item xs={12}>
              <MarbleTextInput
                type="text"
                placeholder="Address Line 1"
                value={val?.address_line_1 ?? ''}
                onChange={(e) => {
                  const event = e as ChangeEvent<any>;
                  handleChange({
                    ...val,
                    address_line_1: event.target.value,
                  });
                }}
                required={curr.required}
              />
            </Grid>
            <Grid item xs={12}>
              <MarbleTextInput
                type="text"
                placeholder="Unit Number (optional)"
                value={val?.address_line_2 ?? ''}
                onChange={(e) => {
                  const event = e as ChangeEvent<any>;
                  handleChange({
                    ...val,
                    address_line_2: event.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <MarbleTextInput
                type="text"
                placeholder="City"
                value={val?.city ?? ''}
                onChange={(e) => {
                  const event = e as ChangeEvent<any>;
                  handleChange({
                    ...val,
                    city: event.target.value,
                  });
                }}
                required={curr.required}
              />
            </Grid>
            <Grid item xs={12}>
              <MarbleDropdown
                options={States_With_NA.map((state) => ({
                  value: state,
                  label: state,
                }))}
                value={val?.state ?? ''}
                placeholder="State"
                onChange={(e) => {
                  const event = e as ChangeEvent<any>;
                  handleChange({
                    ...val,
                    state: event.target.value,
                  });
                }}
                style={{
                  minWidth: '100%',
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <MarbleTextInput
                type="text"
                placeholder="Zip Code"
                value={val?.zipcode ?? ''}
                onChange={(e) => {
                  const event = e as ChangeEvent<any>;
                  handleChange({
                    ...val,
                    zipcode: event.target.value,
                  });
                }}
                required={curr.required}
              />
            </Grid>
          </Grid>
        </Grid>
      );
    case 'auto_complete_address':
      return (
        <Grid container item xs={12} p={0} rowSpacing={1}>
          <Grid item xs={12} p={0}>
            <AutoCompleteAddressForm
              address={val}
              handleChange={handleChange}
              required={curr.required}
            />
          </Grid>
        </Grid>
      );
    case 'extension':
      if (!val) {
        val = {};
      }
      return (
        <Grid container spacing={2}>
          {curr?.mainForms.map((subItem) => {
            const handleSetChildForm = (v: any) => {
              const updated = val;
              val[subItem.field] = v;

              handleChange(updated);
            };

            return (
              <Grid item xs={12}>
                <FormInput
                  curr={subItem.input}
                  val={val[subItem.field]}
                  form={form}
                  handleChange={handleSetChildForm}
                  setForm={setForm}
                  id={id}
                  base={base}
                  processing={processing}
                  handleUpload={handleUpload}
                  handleUpdate={handleUpdate}
                />
              </Grid>
            );
          })}
          {val[curr.triggerField] === curr.triggerValue &&
            curr?.subForms.map((subItem) => {
              const handleSetChildForm = (v: any) => {
                const updated = val;
                val[subItem.field] = v;

                handleChange(updated);
              };

              return (
                <Grid item xs={12}>
                  <FormInput
                    curr={subItem.input}
                    val={val[subItem.field]}
                    form={form}
                    handleChange={handleSetChildForm}
                    setForm={setForm}
                    id={id}
                    base={base}
                    processing={processing}
                    handleUpload={handleUpload}
                    handleUpdate={handleUpdate}
                  />
                </Grid>
              );
            })}
        </Grid>
      );
    case 'array':
      if (!val) {
        val = [];
        handleChange(val);
      }
      return (
        <Grid container>
          <Grid container>
            <MarbleText>{curr.label}</MarbleText>
          </Grid>
          {!val?.length && <MarbleText>{curr.emptyArrayMessage}</MarbleText>}
          {val?.map((item, index) => (
            <Grid container spacing={2}>
              {curr?.subForms.map((subForm) => {
                const handleSetChildForm = (v: any) => {
                  const updated = val;
                  item[subForm.field] = v;

                  handleChange(updated);
                };

                return (
                  <FormInput
                    curr={subForm.input}
                    val={item[subForm.field]}
                    form={form}
                    handleChange={handleSetChildForm}
                    setForm={setForm}
                    id={id}
                    base={base}
                    processing={processing}
                    handleUpload={handleUpload}
                    handleUpdate={handleUpdate}
                  />
                );
              })}
              {curr.deleteableRows && (
                <Grid container item xs={1}>
                  <MarbleButton
                    title="x"
                    onClick={() => {
                      const removeMe = val[index];
                      val.splice(index, 1);
                      handleUpdate(base, id, form.field, [removeMe], () => {
                        handleChange(val);
                      });
                    }}
                  />
                </Grid>
              )}
            </Grid>
          ))}
        </Grid>
      );
    case 'conditional':
      if (!val) {
        val = {};
      }
      return (
        <Grid container spacing={2}>
          {curr?.mainForms.map((subItem) => {
            const handleSetChildForm = (v: any) => {
              const updated = val;
              val[subItem.field] = v;
              console.log(val);
              handleChange(updated);
            };

            return (
              <Grid item xs={12}>
                <FormInput
                  curr={subItem.input}
                  val={val[subItem.field]}
                  form={form}
                  handleChange={handleSetChildForm}
                  setForm={setForm}
                  id={id}
                  base={base}
                  processing={processing}
                  handleUpload={handleUpload}
                  handleUpdate={handleUpdate}
                />
              </Grid>
            );
          })}
          {curr.triggerValues.some((v) => val[curr.triggerField] === v) &&
            [
              curr?.subForms[
                curr.triggerValues.findIndex(
                  (v) => val[curr.triggerField] === v,
                )
              ],
            ].map((subItem) => {
              if (!subItem) {
                return;
              }
              const handleSetChildForm = (v: any) => {
                const updated = val;
                val[subItem.field] = v;

                handleChange(updated);
              };

              return (
                <Grid item xs={12}>
                  <FormInput
                    curr={subItem.input}
                    val={val[subItem.field]}
                    form={form}
                    handleChange={handleSetChildForm}
                    setForm={setForm}
                    id={id}
                    base={base}
                    processing={processing}
                    handleUpload={handleUpload}
                    handleUpdate={handleUpdate}
                  />
                </Grid>
              );
            })}
        </Grid>
      );
    case 'data':
      return <Grid />;
    case 'persona':
      return (
        <MarbleButton
          title="Verify Identity"
          fullWidth
          variant="contained"
          onClick={() => {
            act.validate();
          }}
          disabled={curr.disable}
        />
      );
    case 'form_check':
      if (!val) {
        val = true;
        handleChange(val);
      }
      return <Grid />;
    case 'redirect':
      router.replace(val);
      return (
        <Grid container item xs={12} justifyContent="center">
          <CircularProgress />
        </Grid>
      );
    default:
      return <MarbleText>Unsupported type</MarbleText>;
  }
};

const getDate = (type: string, val) => {
  if (!val) {
    return null;
  }
  if (type === 'date') {
    return new Date(revertTimeZoneOffsetForUI(val));
  }
  return val.timestamp ?
    new Date(revertTimeZoneOffsetForUI(val.timestamp)) :
    null;
};

// date picker has time zone by default
// must convert to utc before sending to server
const addTimeZoneOffsetForServer = (e) => {
  const utcOffset = e.offset;
  const utcTime = e.plus(utcOffset * 60 * 1000);
  return utcTime;
};

// value is in utc time.
// must offset by local time zone to display correctly in the ui
export const revertTimeZoneOffsetForUI = (e) => {
  if (e instanceof DateTime) {
    const localTimeOffset = e.offset;
    const localTime = e.minus(localTimeOffset * 60 * 1000);
    return localTime;
  }
  return e;
};

const clone = (cloneMe) => {
  if (global?.structuredClone) {
    return structuredClone(cloneMe);
  }
  return JSON.parse(JSON.stringify(cloneMe));
};
