import {
  Address,
  AppliancesData,
  ItemType,
  LaundryType,
  ListingStatus,
  Property,
  PropertyKeyHandoff,
  PropertyType,
  Shipment,
  ShipmentStatus,
  User,
  UtilityAssignment,
  appliancesField,
  emptyAppliances,
  mobileAppliancesIcon,
  webAppliancesIcon,
} from "server-sdk/src/types";
import {
  DAY, States
} from 'server-sdk/src/constants';
import { useApiDispatch, useApiStore } from "./global";
import {
  validateArray,
  validateBoolean,
  validateNumber,
  validateObject,
  validateString,
} from "../util/validate";
import { cents, useMultiPageForm } from "./useForm";
import { api } from "server-sdk/src/api";
import { FlowAction, FlowDefinition } from "server-sdk/src/types/workflow";
import { useEffect, useState } from "react";
import { getShipmentChargeDetails } from "../util/shipment";
import { usePortfolioRefresher } from "./property";
import { DateTime } from "luxon";
import { getExperimentGroup } from "../util/experiment";

const buildAppliances = (value: AppliancesData | undefined) => {
  const current: string[] = [];
  if (value === undefined || value === null) {
    return [];
  }

  Object.keys(appliancesField).forEach((field: string) => {
    // @ts-ignore
    if (value[field]) {
      current.push(field);
    }
  });
  return current;
};

const sameAddress = (
  address1: Address | undefined,
  address2: Address | undefined
) => {
  return (
    address1?.addr1 === address2?.addr1 &&
    address1?.addr2 === address2?.addr2 &&
    address1?.city === address2?.city &&
    address1?.state === address2?.state &&
    address1?.zip === address2?.zip
  );
};

type AppliancesVal =
  | "heater"
  | "air_conditioner"
  | "washer"
  | "dryer"
  | "dishwasher"
  | "refrigerator"
  | "freezer"
  | "stove"
  | "oven"
  | "microwave"
  | "television"
  | "solar_panels";

export const utilitiesList = [
  null,
  undefined,
  UtilityAssignment.NA,
  UtilityAssignment.Owner,
  UtilityAssignment.Renter,
];

const convertAppliances = (value: AppliancesVal[] | string[]) => {
  const appliances = JSON.parse(JSON.stringify(emptyAppliances));
  if (!value) {
    return appliances;
  }
  value.forEach((field) => {
    appliances[field] = true;
  });
  return appliances;
};

export const usePropertyInfoForm = (
  property: Property,
  flowActivityId: number,
  onFinish: (type: string | undefined, actionId: number | undefined) => void,
  mobile: boolean,
  goToUrl: (url: string) => void
) => {
  const startOfToday = DateTime.now().startOf('day');
  const apiDispatch = useApiDispatch();
  const refresh = usePortfolioRefresher();
  const [me, refreshMe] = useApiStore((api) => api.users.v2.me);
  // const [defaultPaymentMethod, refreshDefaultPaymentMethod] = useApiStore((api) => api.payment.methods.default);
  // const [started, startFlow] = useApiStore(api => api.flows.start);
  
  useEffect(() => {
    refreshMe();
  }, []);

  // useEffect(() => {
  //   if (me?.idVerified) {
  //     pages.id_verification.form.setValue("verified", true)
  //   }

  // }, [me?.idVerified])

  const pages = useMultiPageForm({
    prop_type: {
      initial: {
        prop_type: property?.prop_type,
      },
      validationSchema: validateObject({
        prop_type: validateNumber()
          .typeError("Property Type is required")
          .required("Property Type is required"),
      }),
      label: "Type",
      description: "How would you describe your property?",
      steps: (values) => [
        {
          name: "prop_type",
          label: "Property Type",
          placeholder: "Type",
          required: true,
          type: "choice",
          exclusive: true,
          options: [
            {
              label: "House",
              caption:
                "A standalone single family home with no shared walls and a single entry door.",
              value: PropertyType.House,
            },
            {
              label: "Townhome",
              caption:
                "A connected unit that shares walls with other units, but still has its own door.",
              value: PropertyType.TownHome,
            },
            {
              label: "Condo",
              caption:
                "An apartment style unit in a building with many units. These might have shared common spaces and a common access door.",
              value: PropertyType.Condo,
            },
          ],
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          console.log("values for form", values);
          const res = await call(api.property.update, {
            id: property.id,
            ...values.prop_type,
          });

          if (res) {
            refresh.properties();
            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
    beds_baths: {
      initial: {
        bedrooms: property?.bedrooms,
        bathrooms: property?.bathrooms,
        sq_footage: property?.sq_footage,
      },
      label: "Beds/Baths/SqFt",
      description: "How many beds/baths and square footage?",
      validationSchema: validateObject({
        bedrooms: validateNumber().typeError("Num of bedrooms is required").required("Num of bedrooms is required"),
        bathrooms: validateNumber().typeError("Num of bathrooms is required").required("Num of bathrooms is required"),
        sq_footage: validateNumber().typeError("Square footage is required")
          .nullable(true)
          .required("Square footage is required"),
      }),
      steps: (values) => [
        {
          name: "bedrooms",
          label: "Number of Beds",
          placeholder: "Beds",
          required: true,
          type: "number",
        },
        {
          name: "bathrooms",
          label: "Number of Baths",
          placeholder: "Baths",
          required: true,
          type: "number",
        },
        {
          name: "sq_footage",
          label: "Square Footage of Property",
          placeholder: "Square Footage",
          required: true,
          type: "number",
        },
      ],
      submit: ({ values, done }) => {
        console.log("values", values);
        apiDispatch(async (call, api) => {
          const { bathrooms, bedrooms, sq_footage } = values.beds_baths;
          const res = await call(api.property.update, {
            id: property.id,
            ...values.beds_baths,
          });

          if (res) {
            refresh.properties();
            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
    laundry: {
      initial: {
        laundry_type: property?.laundry_type,
      },
      label: "Laundry",
      description: "What type of laundry is in the property?",
      validationSchema: validateObject({
        laundry_type: validateNumber()
          .typeError("Laundry type is required")
          .oneOf([
            null,
            undefined,
            LaundryType.None,
            LaundryType.InUnit,
            LaundryType.Shared,
          ])
          .required("Laundry type is required"),
      }), 
      steps: (values) => [
        {
          name: "laundry_type",
          label: "Laundry Type",
          placeholder: "Type",
          required: true,
          type: "choice",
          exclusive: true,
          options: [
            {
              label: "None",
              caption: "No laundry is included",
              value: LaundryType.None,
            },
            {
              caption: "Laundry is included in the property",
              label: "In-Unit",
              value: LaundryType.InUnit,
            },
            {
              caption: "Laundry is shared among other tenants",
              label: "Shared",
              value: LaundryType.Shared,
            },
          ],
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const res = await call(api.property.update, {
            id: property.id,
            ...values.laundry,
          });

          if (res) {
            done();
            refresh.properties();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
    parking: {
      initial: {
        parking_type: property?.parking_type,
        parking_count: property?.parking_count,
      },
      label: "Parking",
      description: "Parking Information",
      validationSchema: validateObject({
        parking_type: validateString()
          .typeError("Parking type is required")
          .oneOf([
            "carport",
            "garage attached",
            "off street",
            "on street",
            "none",
          ]),
        parking_count: validateNumber().when("parking_type", {
          is: "none",
          then: validateNumber(),
          otherwise: validateNumber().test(
            "check count",
            "Parking Count is required",
            (value) => {
              return !(value === undefined || value === null || value < 0);
            }
          ),
        }),
      }),
      steps: (values) => {
        const finalSteps: any[] = [
          {
            name: "parking_type",
            label: "Parking Type",
            placeholder: "Type",
            required: true,
            type: "choice",
            exclusive: true,
            options: [
              {
                label: "Garage attached",
                caption: "A garage that is directly attached to the property.",
                value: "garage attached",
              },
              {
                label: "Carport",
                caption: "A detached spot with a covering",
                value: "carport",
              },
              {
                label: "Off street",
                caption: "A designated parking spot that is NOT on the street.",
                value: "off street",
              },
              {
                label: "On street",
                caption:
                  "A parking spot that on the street (please only select if it is a reserved spot).",
                value: "on street",
              },
              {
                label: "None",
                caption: "No parking is reserved for your property",
                value: "none",
              },
            ],
          },
        ];

        if (
          values?.parking?.parking_type !== "none" &&
          values?.parking?.parking_type !== undefined
        ) {
          finalSteps.push({
            name: "parking_count",
            label: "Parking Count",
            placeholder: "Count",
            required: true,
            type: "number",
          });
        }
        return finalSteps;
      },
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const res = await call(api.property.update, {
            id: property.id,
            ...values.parking,
          });

          if (res) {
            refresh.properties();
            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
    parking_price: {
      label: 'Parking Pricing',
      description: 'Monthly Parking Fee (per spot)',
      initial: {
        parking_fee: property?.parking_fee,
      },
      validationSchema: validateObject({
        parking_fee: validateNumber(),
      }),
      disabled: (values) => !values.parking.parking_type || values.parking.parking_type === 'none',
      steps: (values) => [
        {
          name: "parking_fee",
          label: "Parking Fee",
          placeholder: "Fee",
          required: true,
          type: "usd",
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const res = await call(api.property.update, {
            id: property.id,
            parking_fee: values.parking_price.parking_fee ? cents(values.parking_price.parking_fee) : 0,
          });

          if (res) {
            refresh.properties();
            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
    rent: {
      label: 'Rent Price',
      description: 'Enter in your monthly rental pricing',
      initial: {
        listing_price: property?.listing_price,
      },
      validationSchema: validateObject({
        listing_price: validateNumber().required("Listing price is required"),
      }),
      resources: (values) => [
        {
          name: "listing_price",
          title: "How much should rent be set to?",
          description:
            "Visit our rent estimate page to see what rentals in your area are going for.",
          actionTitle: "Estimate your Rent",
          action: () => {
            goToUrl("https://www.rentmarble.com/rent-estimate/");
          },
        },
      ],
      steps: (values) => [
        {
          name: "listing_price",
          label: "Listing Price",
          placeholder: "Rent",
          required: true,
          type: "usd",
        },
      ],
      submit: ({ values, done }) => {
        console.log("values", values);
        apiDispatch(async (call, api) => {
          const res = await call(api.property.update, {
            id: property.id,
            listing_price: cents(values.rent.listing_price),
          });

          if (res) {
            refresh.properties();
            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
    deposit: {
      label: 'Deposit',
      description: 'What is the security deposit for this property?',
      initial: {
        security_deposit: property?.security_deposit,
      },
      validationSchema: validateObject({
        security_deposit: validateNumber().required("Security Deposit is required"),
      }),
      resources: (values) => [],
      steps: (values) => [
        {
          name: "security_deposit",
          label: "Security Deposit",
          placeholder: "Deposit",
          required: true,
          type: "usd",
        },
      ],
      submit: ({ values, done }) => {
        console.log("values", values);
        apiDispatch(async (call, api) => {
          const res = await call(api.property.update, {
            id: property.id,
            security_deposit: cents(values.deposit.security_deposit),
          });

          if (res) {
            refresh.properties();
            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
    pet_policy: {
      initial: {
        allow_dogs: property?.allow_dogs,
        allow_cats: property?.allow_cats,
      },
      label: "Pet Policy",
      description: "Pet Policy",
      validationSchema: validateObject({
        allow_dogs: validateBoolean().required("Dog policy is required"),
        allow_cats: validateBoolean().required("Cat policy is required"),
      }),
      steps: (values) => {
        return [
          {
            name: "allow_dogs",
            label: "Are Dogs Allowed?",
            placeholder: "Allow Dogs",
            required: true,
            type: "yes-no",
           
          },
          {
            name: "allow_cats",
            label: "Are Cats Allowed?",
            placeholder: "Allow Cats",
            required: true,
            type: "yes-no",
          },
        ];
      },
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const res = await call(api.property.update, {
            id: property.id,
            ...values.pet_policy,
          });

          if (res) {
            refresh.properties();
            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
    pet_fees: {
      label: 'Pet Fees',
      description: 'Pet Deposit and Fees (per pet)',
      disabled: (values) => !values.pet_policy.allow_cats && !values.pet_policy.allow_dogs,
      initial: {
        cat_deposit: property?.cat_deposit,
        cat_fee: property?.cat_fee,
        dog_deposit: property?.dog_deposit,
        dog_fee: property?.dog_fee,
      },
      validationSchema: validateObject({
        cat_deposit: validateNumber(),
        cat_fee: validateNumber(),
        dog_deposit: validateNumber(),
        dog_fee: validateNumber(),
      }),
      steps: (values) => {
        const finalSteps: any[] = [];
        if (values?.pet_policy?.allow_cats) {
          finalSteps.push({
            name: "cat_deposit",
            label: "Cat Deposit",
            placeholder: "Cat Deposit",
            required: true,
            type: "usd",
          });
          finalSteps.push({
            name: "cat_fee",
            label: "Cat Fee",
            placeholder: "Cat Fee",
            required: true,
            type: "usd",
          });
        }
        if (values?.pet_policy?.allow_dogs) {
          finalSteps.push({
            name: "dog_deposit",
            label: "Dog Deposit",
            placeholder: "Dog Deposit",
            required: true,
            type: "usd",
          });
          finalSteps.push({
            name: "dog_fee",
            label: "Dog Fee",
            placeholder: "Dog Fee",
            required: true,
            type: "usd",
          });
        }
        return finalSteps;
      },
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const res = await call(api.property.update, {
            id: property.id,
            cat_deposit: values.pet_fees.cat_deposit ? cents(values.pet_fees.cat_deposit) : 0,
            cat_fee: values.pet_fees.cat_fee ? cents(values.pet_fees.cat_fee) : 0,
            dog_deposit: values.pet_fees.dog_deposit ? cents(values.pet_fees.dog_deposit) : 0,
            dog_fee: values.pet_fees.dog_fee ? cents(values.pet_fees.dog_fee) : 0,
          });
          
          if (res) {
            refresh.properties();
            done();
            return [true];
          }
          

          return [false, "Something went wrong"];
        });
      },
    },
    appliances: {
      initial: {
        appliances: buildAppliances(property?.appliances),
      },
      label: "Appliances",
      description: "Select all Appliances in your Property.",
      validationSchema: validateObject({
        appliances: validateArray(),
      }),
      steps: (values) => [
        {
          name: "appliances",
          label: "Appliances",
          placeholder: "Appliances",
          required: true,
          type: "choice-icons",
          exclusive: false,
          options: Object.entries(appliancesField).map((value) => ({
            value: value[0].toString(),
            label: value[1].toString(),
            icon: mobile
              ? // @ts-ignore
                mobileAppliancesIcon[value[0]]
              : // @ts-ignore
                webAppliancesIcon[value[0]],
          })),
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const res = await call(api.property.update, {
            id: property.id,
            appliances: convertAppliances(values.appliances.appliances),
          });

          if (res) {
            refresh.properties();
            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
    utilities: {
      label: 'Utilities',
      description: 'Assign your utility fees',
      initial: {
        water_utilities: property?.water_utilities,
        electric_utilities: property?.electric_utilities,
        gas_utilities: property?.gas_utilities,
        garbage_sewage_utilities: property?.garbage_sewage_utilities,
      },
      validationSchema: validateObject({
        water_utilities: validateNumber().oneOf(utilitiesList).required("Water Utilities are required"),
        electric_utilities: validateNumber().oneOf(utilitiesList).required("Electric Utilities are required"),
        gas_utilities: validateNumber().oneOf(utilitiesList).required("Gas Utilities are required"),
        garbage_sewage_utilities: validateNumber().oneOf(utilitiesList).required("Garbage & Sewage Utilities are required"),
      }),
      steps: (values) => {
        const utilitiesChoices = [
          {
            label: "Tenant pays",
            value: UtilityAssignment.Renter,
          },
          {
            label: "Landlord pays",
            value: UtilityAssignment.Owner,
          },
          {
            label: "N/A",
            value: UtilityAssignment.NA,
          },
        ];
        return [
          {
            name: "water_utilities",
            label: "Water Utilities",
            placeholder: "Water Utilities",
            required: true,
            type: "dropdown",
            options: utilitiesChoices,
          },
          {
            name: "electric_utilities",
            label: "Electric Utilities",
            placeholder: "Electric Utilities",
            required: true,
            type: "dropdown",
            options: utilitiesChoices,
          },
          {
            name: "gas_utilities",
            label: "Gas Utilities",
            placeholder: "Gas Utilities",
            required: true,
            type: "dropdown",
            options: utilitiesChoices,
          },
          {
            name: "garbage_sewage_utilities",
            label: "Garbage & Sewage Utilities",
            placeholder: "Garbage & Sewage Utilities",
            required: true,
            type: "dropdown",
            options: utilitiesChoices,
          },
        ];
      },
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const res = await call(api.property.update, {
            id: property.id,
            ...values.utilities,
          });

          if (res) {
            refresh.properties();
            done();
            return [true];
          }
          
          return [false, "Something went wrong"];
        });
      },
    },
    photos: {
      initial: {
        photos: property?.photosDb
          ?.sort((p1, p2) => p1?.sort_key - p2?.sort_key)
          .map((p) => p.key),
      },
      label: "Photos",
      description: "Add at least 5 photos. (Drag to reorder photos)",
      validationSchema: validateObject({
        photos: validateArray()
          .min(5, "At least 5 photos are required.")
          .required("Photos are required"),
      }),
      steps: (values) => [
        {
          name: "photos",
          label: "Add Photos",
          required: true,
          type: "upload-photos",
          uploadFields: {
            allowedFileTypes: ["jpg", "jpeg", "png"],
            id: property?.id,
            field: "photos",
            base: api.forms.onboarding.owner,
          },
        },
      ],
      submit: ({ values, done }) => {
        refresh.properties();
        done();
      },
    },
    listing_description: {
      initial: {
        description: property?.description,
      },
      validationSchema: validateObject({
        description: validateString().required("A listing description is required"),
      }),
      label: 'Description',
      description: 'Describe Your Property',
      steps: (values) => [
        {
          name: "description",
          label: "Property Description",
          placeholder: "Description",
          required: true,
          type: "paragraph",
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const updated = await call(api.property.update, {
            id: property.id,
            ...values.listing_description,
          });

          if (!updated) {
            return [false, "Something went wrong"];
          }

          done();
          return [true];
        });
      },
    },
    move_in_date: {
      initial: {
        availability_date: property?.availability_date,
      },
      validationSchema: validateObject({
        availability_date: validateNumber().min(startOfToday.minus({ day: 1 }).toMillis(), "Leasing must begin not earlier than today").required("Please enter an availability date."),
      }),
      label: 'Move-In Date',
      description: 'The date the unit will be ready for move-in and ready for tours.',
      steps: (values) => [
        {
          name: "availability_date",
          label: "Move In Date",
          placeholder: "Move In Date",
          required: true,
          type: "date",
          minDate: startOfToday.toMillis(),
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const res = await call(api.property.update, {
            id: property.id,
            ...values.move_in_date,
          });

          if (res) {
            refresh.properties();
            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
      resources: (values) => {
        return [
          {
            name: "availability_date",
            title: "Listing and Advertisement Information",
            description:
              "Review information about Marble's listing and advertisement process",
            actionTitle: "Listing and Advertisement",
            action: () => {
              goToUrl("https://www.rentmarble.com/listing-advertisement");
            },
          },
        ];
      },
    },
    waitlist_preference: {
      initial: {
        waitlist_preference: undefined,
      },
      validationSchema: validateObject({
        waitlist_preference: validateBoolean().required(), 
      }),
      label: 'Waitlisting',
      description: "Do you want to waitlist your property prior to tours? Waitlisting can begin up to 30 days prior to the tour start date.",
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          if (values.waitlist_preference.waitlist_preference) {
            const updated = await call(api.property.update, {
              id: property.id,
              listing_status: ListingStatus.Waitlist,
            });
            if (!updated) {
              return [false, "Something went wrong"];
            }
          }
          refresh.properties();
          done();
          return [true];
        });
      },
      steps: (values) => {
        return [
          {
            name: "waitlist_preference",
            label: "Waitlist Choice",
            required: true,
            type: "choice",
            exclusive: true,
            options: [
              {
                label: "Yes",
                value: true,
                caption: 'Waitlist my property prior to tours.'
              },
              {
                label: "No",
                value: false,
                caption: 'Do NOT waitlist my property prior to tours.'
              },
            ]
          },
        ];
      },
      resources: (values) => {
        return [
          {
            name: "waitlist_preference",
            title: "Marble System Setup",
            description:
              "Learn about Marble's setup process involving a smart-lock system",
            actionTitle: "Marble System Setup",
            action: () => {
              goToUrl("https://www.rentmarble.com/marble-setup");
            },
          },
        ];
      },
    },
    setup_consent: {
      initial: {
        yesno: undefined,
      },
      validationSchema: validateObject({
        yesno: validateString()
          .oneOf(
              [
                'yes'
              ]
            )
          .required(),
      }),
      label: 'Smart Access Setup',
      description: 'Do you allow Marble to coordinate and install a smart access system?',
      steps: (values) => [
        {
          name: 'yesno',
          label: 'Approve Access',
          required: true,
          type: 'choice',
          exclusive: true,
          options: [
            {
              label: 'I Approve',
              caption: 'I agree to allow Marble\'s smart access system.',
              value: 'yes',
            }
          ]
        },
      ],
      submit: ({ values, done }) => {
        if (me?.experiment !== 'd') {
          apiDispatch(async (call, api) => {
            const res = await call(api.flows.complete, {
              id: flowActivityId,
            });

            if(!res){
              return [false, "Something went wrong"]
            }

            const action = res?.activity as FlowAction<FlowDefinition>;
            onFinish(action?.action_type, action?.id);

            done();
            return [true];
          });
        } else {
          done();
        }
      },
      resources: (values) => {
        return [
          {
            name: "yesno",
            title: "Listing and Advertisement Information",
            description:
              "Review information about Marble's listing and advertisement process",
            actionTitle: "Listing and Advertisement",
            action: () => {
              goToUrl("https://www.rentmarble.com/listing-advertisement");
            },
          },
        ];
      },
    },

    monthly_fee_consent: {
      initial: {
        yesno: undefined,
      },
      validationSchema: validateObject({
        yesno: validateString()
          .oneOf(
            [
              'yes',
            ],
            'You must approve the monthly fee policy.'
          )
          .required('You must approve the monthly fee policy.'),
      }),
      label: 'Monthly Fee Policy',
      description: 'Do you allow Marble to deduct a monthly fee of $65 from rent?',
      disabled: () => me?.experiment !== 'd',
      steps: () => [
        {
          name: 'yesno',
          required: true,
          label: 'Approve Monthly Fee',
          type: 'choice',
          exclusive: true,
          options: [
            {
              label: 'I Approve',
              caption: 'Marble will deduct the monthly fee when tenant\'s pay rent before dispersing payouts.',
              value: 'yes',
            },
          ]
        }
      ],
      submit: ({ values, done }) => {
        done();
      },
    },

    leasing_fee_consent: {
      initial: {
        yesno: undefined,
      },
      validationSchema: validateObject({
        yesno: validateString()
          .oneOf(
            [
              'yes',
            ],
            'You must approve the leasing fee policy.'
          )
          .required('You must approve the leasing fee policy.'),
      }),
      label: 'Leasing Fee Policy',
      description: 'Do you allow Marble to deduct a one-time leasing fee of $725 from the first month\'s rent (after tenant placement)?',
      disabled: () => me?.experiment !== 'd',
      steps: () => [
        {
          name: 'yesno',
          required: true,
          label: 'Approve Leasing Fee',
          type: 'choice',
          exclusive: true,
          options: [
            {
              label: 'I Approve',
              caption: 'Marble will deduct the leasing fee from the first month\'s rent after finding a tenant.',
              value: 'yes',
            },
          ]
        }
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          if (values.leasing_fee_consent.yesno === 'yes') {
            const updated = await call(api.property.update, {
              id: property.id,
              setup_deduct_consent: true,
            });
            if (!updated) {
              return [false, "Something went wrong"];
            }
            const updatedSub = await call(api.property.subscribeTransactional, {
              propertyId: property.id,
            });

            if (!updatedSub) {
              return [false, "Something went wrong"];
            }


            if (updatedSub.error) {
              return [false, updatedSub.error];
            }

            refresh.properties();

            const res = await call(api.flows.complete, {
              id: flowActivityId,
            });

            if (!res) {
              return [false, "Something went wrong"];
            }

            const action = res?.activity as FlowAction<FlowDefinition>;
            onFinish(action?.action_type, action?.id);

            done();
            return [true];
          }

          return [false];
        });
      },
    },

    // id_verification: {
    //   initial: {
    //     verified: me?.idVerified,
    //   },
    //   validationSchema: validateObject({
    //     verified: validateBoolean().required('ID Verification is required'),
    //   }),
    //   label: "ID Verification",
    //   description: "To ensure the safety of the Marble community, we require all owners to verify their identity.",
    //   steps: (values) => {
    //     return [
    //       {
    //         name: 'verified',
    //         label: 'ID Verification',
    //         required: true,
    //         type: 'id-check',
    //       },
    //     ];
    //   },
    //   submit: ({ values, done }) => {
    //     apiDispatch(async (call, api) => {
    //       const updated = await call(api.property.subscribeTransactional, {
    //         propertyId: property.id,
    //       });

    //       if (!updated) {
    //         return [false, "Something went wrong"];
    //       }

    //       if (updated.error) {
    //         return [false, updated.error];
    //       }

    //       const res = await call(api.flows.complete, {
    //         id: flowActivityId,
    //       });

    //       const action = res.activity as FlowAction<FlowDefinition>;
    //       onFinish(action?.action_type, action?.id);

    //       if (!res) {
    //         return [false, "Something went wrong"];
    //       }

    //       done();
    //       return [true];
    //     });
    //   },
    // }

    // payment: {
    //   label: 'Payment',
    //   description: 'Enter in your credit card to pay.',
    //   initial: {
    //     charge: {
    //       amount: 6500,
    //       token: undefined, 
    //       breakdown: [],
    //     },
    //   },
    //   validationSchema: validateObject({
    //     charge: validateObject(),
    //   }),
    //   steps: (values) => [
    //     {
    //       name: "charge",
    //       label:
    //         "Review your installation price and get your property onboarding started with Marble. Advertising for your property's waitlist will begin immediately. All purchases are refundable within 90 days.",
    //       placeholder: "Access Instructions",
    //       required: true,
    //       type: "checkout",
    //       charge: values?.payment.charge,
    //     }
    //   ],
    //   submit: ({ values, done }) => {
    //     apiDispatch(async (call, api) => {
    //       // if (values.waitlist_preference.waitlist_preference && (values?.tour_start?.tour_start_time ?? 0 - Date.now() <= 30 * DAY)) {
    //       //   const updated = await call(api.property.update, {
    //       //     id: property.id,
    //       //     listing_status: ListingStatus.Waitlist,
    //       //   });
    //       //   if (!updated) {
    //       //     return [false, "Something went wrong"];
    //       //   }
    //       // }

    //       if (values.payment.charge && (values.payment.charge.token || defaultPaymentMethod)) {
    //         const p = await call(api.property.subscribe, {
    //           propertyId: property.id,
    //           creationToken: values.payment.charge.token,
    //         });

    //         if (!p) {
    //           return [false, "Something went wrong"];
    //         }
    //       }

    //       refresh.properties();

    //       console.log("completing");
    //       const res = await call(api.flows.complete, {
    //         id: flowActivityId,
    //       });

    //       if (!res) {
    //         return [false, "Something went wrong"];
    //       }

    //       done();
    //       const action = res.activity as FlowAction<FlowDefinition>;
    //       onFinish(action?.action_type, action?.id);

    //       return [true];
    //     });
    //   },
    // },



  });

  return pages;
};

const TOUR_BUFFER = 4;

export const usePropertyActivateListingForm = (
  property: Property,
  flowActivityId: number,
  onFinish: (type: string | undefined, actionId: number | undefined) => void,
  goToUrl: (url: string) => void
) => {
  const apiDispatch = useApiDispatch();
  const startOfToday = DateTime.now().startOf('day');

  const [defaultPaymentMethod, refreshDefaultPaymentMethod] = useApiStore((api) => api.payment.methods.default);
  const [started, startFlow] = useApiStore(api => api.flows.start);
  
  const refresh = usePortfolioRefresher();

  useEffect(() => {
    refreshDefaultPaymentMethod({});
  }, []);

  const pages = useMultiPageForm({
    move_in_date: {
      initial: {
        availability_date: property?.availability_date,
      },
      validationSchema: validateObject({
        availability_date: validateNumber().min(startOfToday.minus({ day: 1 }).toMillis(), "Leasing must begin not earlier than today").required(),
      }),
      label: 'Vacancy Date',
      description: 'The date the unit will be vacant and ready for tours.',
      steps: (values) => [
        {
          name: "availability_date",
          label: "Move In Date",
          placeholder: "Move In Date",
          required: true,
          type: "date",
          minDate: startOfToday.toMillis(),
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const res = await call(api.property.update, {
            id: property.id,
            ...values.move_in_date,
          });

          if (res) {
            refresh.properties();
            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
      resources: (values) => {
        return [
          {
            name: "availability_date",
            title: "Listing and Advertisement Information",
            description:
              "Review information about Marble's listing and advertisement process",
            actionTitle: "Listing and Advertisement",
            action: () => {
              goToUrl("https://www.rentmarble.com/listing-advertisement");
            },
          },
        ];
      },
    },
    tour_start: {
      initial: {
        tour_start_time: property?.tour_start_time,
      },
      label: 'Tour Start Date',
      description: "When can tours begin? The Marble Maintenance team will coordinate installation of the smart lock touring system prior to this date.",
      validationSchema: validateObject({
        tour_start_time: validateNumber()
          .min(startOfToday.toMillis() + TOUR_BUFFER * DAY, `Tour setup needs at least ${TOUR_BUFFER} days`),
      }),
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const res = await call(api.property.update, {
            id: property.id,
            ...values.tour_start,
          });

          if (res) {
            refresh.properties();
            done();
            return [true];
          }
          return [false, "Something went wrong"];
        });
      },
      steps: (values) => {
        return [
          {
            name: "tour_start_time",
            label: "Pick Date",
            placeholder: "Date",
            required: true,
            type: "date",
            minDate: startOfToday.toMillis() + TOUR_BUFFER * DAY,
          }
        ];
      },
      resources: (values) => {
        return [
          {
            name: "tour_start_time",
            title: "Listing and Advertisement Information",
            description:
              "Review information about Marble's listing and advertisement process",
            actionTitle: "Listing and Advertisement",
            action: () => {
              goToUrl("https://www.rentmarble.com/listing-advertisement");
            },
          },
        ];
      },
    },
    key_handoff_choice: {
      initial: {
        handoff: undefined,
      },
      validationSchema: validateObject({
        handoff: validateString()
          .oneOf(
            [
              PropertyKeyHandoff.LockboxDropOff,
              PropertyKeyHandoff.Ship
            ],
            'Key handoff choice is required'
          )
          .required(),
      }),
      label: 'Key Handoff',
      description: 'How would you like to hand off keys to Marble?',
      steps: (values) => [
       {
          name: 'handoff',
          label: 'Pick Handoff Method',
          required: true,
          type: 'choice',
          exclusive: true,
          options: [
            {
              label: 'Drop Off in Lockbox',
              caption: 'I will drop off the keys at the property after Marble sets up a lockbox.',
              value: PropertyKeyHandoff.LockboxDropOff
            },
            {
              label: 'Ship keys to Marble offices (additional charges may apply).',
              caption: 'Send keys to Marble and we will coordinate installation of the lockbox.',
              value: PropertyKeyHandoff.Ship,
            },
          ],
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const res = await call(api.property.update, {
            id: property.id,
            key_handoff: values.key_handoff_choice.handoff,
          });

          if (!res) {
            return [false, "Something went wrong"];
          }

          done();
          refresh.properties();

          return [true];
        });
      },
      resources: (values) => {
        return [
          {
            name: "handoff",
            title: "Listing and Advertisement Information",
            description:
              "Review information about Marble's listing and advertisement process",
            actionTitle: "Listing and Advertisement",
            action: () => {
              goToUrl("https://www.rentmarble.com/listing-advertisement");
            },
          },
        ];
      },
    },
    call_consent: {
      initial: {
        call_consent: undefined,
      },
      label: 'Call Consent',
      description: 'Do you consent to receive calls from Marble for initial setup coordination?',
      validationSchema: validateObject({
        call_consent: validateString()
          .oneOf(
            [
              'yes',
            ],
            'Call consent is required to activate Marble services'
          )
          .required(),
      }),
      steps: (values) => [
        {
          name: 'call_consent',
          label: 'Approval Call Consent',
          required: true,
          type: 'choice',
          exclusive: true,
          options: [
            {
              label: 'I Consent',
              caption: 'I consent to receive calls with Marble\'s Maintenance team for coordinating key handoff and lock installation.',
              value: 'yes',
            }
          ]
        }
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          console.log("completing");
          const res = await call(api.flows.complete, {
            id: flowActivityId,
          });

          done();
          onFinish(undefined, undefined);

          return [true];
        });
      },
      resources: (values) => {
        return [
          {
            name: "call_consent",
            title: "Which touring option should you go with?",
            description:
              "Visit our locks and touring page to learn more on how touring your property is made simple!",
            actionTitle: "Locks and Touring",
            action: () => {
              goToUrl("http://rentmarble.com/locks-and-touring");
            },
          },
        ];
      },
    },

  });

  return pages;
};

export const usePropertySetupTouringForm = (
  property: Property,
  flowActivityId: number,
  onFinish: (type: string | undefined, actionId: number | undefined) => void,
  goToUrl: (url: string) => void
) => {
  const apiDispatch = useApiDispatch();
  const experiment = getExperimentGroup();

  const [shipment, setShipment] = useState<Shipment | undefined>(undefined);
  const [defaultPaymentMethod, refreshDefaultPaymentMethod] = useApiStore((api) => api.payment.methods.default);
  // const [started, startFlow] = useApiStore(api => api.flows.start);
  
  useEffect(() => {
    refreshDefaultPaymentMethod({});
  }, []);

  const setValues = (shipment: Shipment | undefined) => {
    pages.lock_type.form.values.item = shipment?.item;
    pages.handyman.form.values.handyman = shipment?.handyman;
    pages.address.form.values.address = shipment?.address;
    pages.access_instructions.form.values.access_instructions =
      shipment?.access_instructions;
  };

  useEffect(() => {
    apiDispatch(async (call, api) => {
      const res = await call(api.shipment.list);

      if (res) {
        const propShipment = res.find(
          (shipment) => shipment.property_id === property.id && shipment.status === ShipmentStatus.Started
        );
        setShipment(propShipment);
        setValues(propShipment);
        return [true];
      }

      return [false, "Something went wrong"];
    });
  }, []);

  const pages = useMultiPageForm({
    touring_type: {
      initial: {
        self_touring: true,
      },
      validationSchema: validateObject({
        self_touring: validateBoolean().required("Select a Touring Option"),
      }),
      disabled: (values) => {
        return true;
      },
      label: "Touring Option",
      steps: (values) => [
        {
          name: "self_touring",
          label: "Touring Option",
          placeholder: "Touring Option",
          required: true,
          type: "choice",
          exclusive: true,
          options: [
            {
              value: true,
              label: "Marble Touring",
              caption: "Self-guided tours handled by Marble with a smart lock.",
            },
            {
              value: false,
              label: "Manual Touring",
              caption: "Tours using a pre-existing smart/manual lock.",
            },
          ],
        },
      ],
      submit: ({ values, done }) => {
        if (values.touring_type.self_touring) {
          apiDispatch(async (call, api) => {
            const res = await call(api.shipment.findOrCreate, {
              property_id: property.id,
            });

            if (res) {
              if(res.error){
                return [false, res.error]
              }
              setShipment(res);
              setValues(res);
              done();
              return [true];
            }

            return [false, "Something went wrong"];
          });
        } else {
          done();
        }
      },
      resources: (values) => {
        return [
          {
            name: "self_touring",
            title: "Which touring option should you go with?",
            description:
              "Visit our locks and touring page to learn more on how touring your property is made simple!",
            actionTitle: "Locks and Touring",
            action: () => {
              goToUrl("http://localhost:8000/locks-and-touring");
            },
          },
        ];
      },
    },
    
    intro: {
      label: 'Lock Order Form',
      description: 'A smart lock is required for Marble\'s self-touring. Continue through this form to order a new lock and setup for property touring.',
      initial: {
      },
      validationSchema: validateObject({
      }),
      steps: (values) => [
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const res = await call(api.shipment.findOrCreate, {
            property_id: property.id,
          });

          if (res) {
            if(res.error){
              return [false, res.error]
            }
            setShipment(res);
            setValues(res);
            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
    lock_type: {
      label: 'Lock Type',
      description: 'Which lock do you want to order?',
      // disabled: (values) => !values.touring_type.self_touring,
      disabled: (values) => experiment === 'd',
      initial: {
        item: shipment?.item,
      },
      validationSchema: validateObject({
        item: validateNumber().typeError("A lock type is required")
          .oneOf(
            [
              ItemType.IglooDeadbolt2E,
              ItemType.IglooKeybox3E,
              ItemType.FridayUnoLock,
            ],
            "A lock type is required"
          )
          .required("A lock type is required"),
      }),
      steps: (values) => [
        {
          name: "item",
          label: "Lock Type",
          placeholder: "Lock Type",
          required: true,
          type: "choice",
          exclusive: true,
          options: [
            {
              label: "Igloo Deadbolt 2E ($199.00)",
              value: ItemType.IglooDeadbolt2E,
              caption:
                "A slim smart deadbolt that is accessible with PIN, mobile app, and physical keys for ease of use. Supports time-sensitive PIN codes and Bluetooth keys to gain access and ensure security.",
              infoURL: "https://www.iglooworks.co/en-US/hardware/deadbolt-2e",
            },
            // {
            //   label: "Friday Uno Lock ($199.00)",
            //   value: ItemType.FridayUnoLock,
            //   caption: "A retro-fit lock that is installed on the interior of your home's front door. It will allow use of the original key and access is secured and encrypted completely over Bluetooth.",
            //   infoURL: "https://fridayhome.net"
            // },
            {
              label: "Igloo Keybox 3E ($179.00)",
              value: ItemType.IglooKeybox3E,
              caption:
                "A robust digital keybox that can be attached to nearly any handle. Supports both permanent and one-time use PINs that are cryptographically generated.",
              infoURL: "https://www.iglooworks.co/en-US/hardware/keybox-3e",
            },
          ],
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const res = await call(api.shipment.update, {
            shipment: {
              id: shipment?.id,
              ...values.lock_type,
            },
          });
          console.log(res);

          if (res) {
            setShipment(res.shipment);
            setValues(res.shipment);
            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
    handyman: {
      label: 'Installation',
      description: 'How do you want to install the lock?',
      // disabled: (values) => !values.touring_type.self_touring,
      disabled: () => experiment === 'd',
      initial: {
        handyman: shipment?.handyman,
      },
      validationSchema: validateObject({
        handyman: validateBoolean().typeError("Select a Setup Option").required("Select a Setup Option"),
      }),
      steps: (values) => [
        {
          name: "handyman",
          label: "Setup Option",
          placeholder: "Setup Option",
          required: true,
          type: "choice",
          exclusive: true,
          options: [
            {
              label: "Handyman (Recommended) ($150.00)",
              caption:
                "Marble will take care of everything. We will ship the lock to your property and one of our vetted handymen will do the entire installation so you don't have to!",
              value: true,
            },
            {
              label: "I will do it.",
              caption:
                "Marble will ship you a lock to your preferred shipping address, and you will be responsible for the installation.",
              value: false,
            },
          ],
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const res = await call(api.shipment.update, {
            shipment: {
              id: shipment?.id,
              handyman: values.handyman.handyman,
              address: values.handyman.handyman
                ? {addr1: '', addr2: '', city: '', state: '', zip: ''}
                : shipment?.address,
            },
          });
          console.log(res);

          if (res) {
            setShipment(res.shipment);
            setValues(res.shipment);
            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
    access_instructions: {
      label: "Access Instructions",
      description: "A lock will be installed by a handyman at this property. How can the handyman access your property?",
      disabled: (values) => !values.handyman.handyman && experiment !== 'd',
      initial: {
        access_instructions: shipment?.access_instructions,
      },
      validationSchema: validateObject({
        access_instructions: validateString().required(
          "Access instructions are required for the handyman to install the lock"
        ),
      }),
      steps: (values) => [
        {
          name: "access_instructions",
          label: "Access Instructions",
          placeholder: "Access Instructions",
          required: true,
          type: "paragraph",
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const res = await call(api.shipment.update, {
            shipment: {
              id: shipment?.id,
              ...values.access_instructions,
            },
          });

          if (res) {
            setShipment(res.shipment);
            setValues(res.shipment);
            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
    confirmation: {
      label: 'Confirm',
      description: 'Your lock will now be sent to your property and installed by a handyman! All fees will be covered under the leasing setup fee.',
      initial: {
      },
      validationSchema: validateObject({
      }),
      steps: (values) => [
      ],
      disabled: () => experiment !== 'd',
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const updatedCharge = await call(api.shipment.confirmManual, {
            id: shipment?.id,
          });

          if (!updatedCharge) {
            return [false, "Something went wrong"];
          }

          if(updatedCharge.error){
            return [false, updatedCharge.error]
          }

          const res = await call(api.flows.complete, {
            id: flowActivityId,
            metadata: {
              shipmentId: shipment?.id,
            },
          });
          console.log(res);

          if (res) {
            done();
            const action = res.activity as FlowAction<FlowDefinition>;
            onFinish(action?.action_type, action?.id);
            return [true];
          }
          return [false, "Something went wrong"];
        });
      },
    },
    address: {
      label: "Shipping Address",
      description: "Where to ship the lock to?",
      disabled: (values) => (!!values.handyman.handyman && experiment !== 'd') || experiment === 'd',
      initial: {
        address: shipment?.address,
        check: sameAddress(shipment?.address, property?.address),
      },
      validationSchema: validateObject({
        check: validateBoolean(),
        address: validateObject().when("check", {
          is: true,
          then: validateObject(),
          otherwise: validateObject()
            .test(
              "checkAddr1",
              "Enter a valid address",
              // @ts-ignore
              (value) => (value?.addr1?.length ?? 0) >= 1
            )
            .test(
              "checkCity",
              "Enter a valid city",
              // @ts-ignore
              (value) => (value?.city?.length ?? 0) >= 1
            )
            .test("checkState", "Enter a valid state", (value) =>
              States.includes(value?.state)
            )
            .test("checkZip", "Enter valid 5 digit zip code", (value) => {
              const regex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
              return regex.test(value?.zip);
            })
            .required("An address is required"),
        }),
      }),  
      steps: (values) => {
        // values.address.check = sameAddress(shipment?.address, property?.address);
        const finalSteps: any = [
          {
            name: "address",
            label: "Shipping Address",
            placeholder: "ShippingAddress",
            required: true,
            type: "address-auto-complete",
          },
          {
            name: "check",
            label: "My shipping address is the same as the property address",
            type: "checkbox",
          },
        ];
        return finalSteps;
      },
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const res = await call(api.shipment.update, {
            shipment: {
              id: shipment?.id,
              address: values.address.check
                ? property.address
                : values.address.address,
            },
          });

          if (res) {
            setShipment(res.shipment);
            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
    payment: {
      label: "Review Payment",
      description: "Review your installation price",
      initial: {
        charge: shipment?.charge,
      },
      validationSchema: validateObject({
        charge: validateObject(),
      }),  
      disabled: () => experiment === 'd',
      steps: (values) => {
        let shippingFee = 0;
        const charge = getShipmentChargeDetails(
          1,
          values?.lock_type?.item,
          values?.handyman?.handyman,
          shippingFee
        );
        const finalSteps: any = [
          {
            name: "charge",
            label:
              "All purchases are refundable within 90 days.",
            placeholder: "Access Instructions",
            required: true,
            type: "checkout",
            charge: charge,
          },
        ];
        return finalSteps;
      },
      submit: ({ values, done }) => {
        let shippingFee = 0;
        const charge = getShipmentChargeDetails(
          1,
          values?.lock_type?.item,
          values?.handyman?.handyman,
          shippingFee
        );
        const finalCharge = {
          ...charge,
          ...values.payment.charge,
        };
        console.log('submitting payment', finalCharge, defaultPaymentMethod)
        if (finalCharge && (finalCharge.token || defaultPaymentMethod)) {
          console.log('submitting shipment', shipment?.id, values.payment.charge, defaultPaymentMethod)
          apiDispatch(async (call, api) => {
            const updatedCharge = await call(api.shipment.confirm, {
              id: shipment?.id,
              charge: finalCharge,
            });

            if (!updatedCharge) {
              return [false, "Something went wrong"];
            }

            const res = await call(api.flows.complete, {
              id: flowActivityId,
              metadata: {
                shipmentId: shipment?.id,
              },
            });
            console.log(res);

            if (res) {
              done();
              const action = res.activity as FlowAction<FlowDefinition>;
              onFinish(action?.action_type, action?.id);
              return [true];
            }

            return [false, "Something went wrong"];
          });
        } else {
          console.log("skipping shipment payment")
          return [false, "Something went wrong"];
        }
      },
    },
  });

  return pages;
};


export const useIntroForm = (
  me: User,
  onFinish: () => void,
) => {
  const apiDispatch = useApiDispatch();

  const [started, startFlow] = useApiStore(api => api.flows.start);
  
  const refresh = usePortfolioRefresher();

  const pages = useMultiPageForm({
    intro: {
      initial: {
      },
      validationSchema: validateObject({
      }),
      label: 'Intro',
      description: 'Let’s get started with a few questions and add your first property. This will help optimize your onboarding experience to our services.',
      steps: (values) => [
      ],
      submit: ({ values, done }) => {
        done();
      },
    },
    prop_choice: {
      initial: {
        choice: 'listing-setup',
      },
      validationSchema: validateObject({
        choice: validateString()
          .oneOf(
            [
              "existing-renter",
              "listing-setup"
            ],
            'Choice is required'
          )
          .required(),
      }),
      label: 'Purpose',
      description: 'Do you have tenants? Or are you looking for tenants?',
      steps: (values) => [
       {
          name: 'choice',
          label: 'Property Vacancy',
          required: true,
          type: 'choice',
          exclusive: true,
          options: [
            {
              label: "Vacant (Looking for Tenants)",
              caption: "The home is currently empty and I need to find a tenant.",
              value: 'listing-setup',
            },
            {
              label: "Leased (Currently have existing Tenants)",
              caption:
                "The home is currently leased and a tenant is still residing at the property.",
              value: 'existing-renter',
            },
          ],
        },
      ],
      submit: ({ values, done }) => {
        done();
      },
    },
    add_property: {
      initial: {
        address: undefined,
      },
      label: 'Add Property',
      description: `Enter your rental property's address`,
      validationSchema: validateObject({
        address: validateObject().when("check", {
          is: true,
          then: validateObject(),
          otherwise: validateObject()
            .test(
              "checkAddr1",
              "Enter a valid address",
              // @ts-ignore
              (value) => (value?.addr1?.length ?? 0) >= 1
            )
            .test(
              "checkCity",
              "Enter a valid city",
              // @ts-ignore
              (value) => (value?.city?.length ?? 0) >= 1
            )
            .test("checkState", "Enter a valid state", (value) =>
              States.includes(value?.state)
            )
            .test("checkZip", "Enter valid 5 digit zip code", (value) => {
              const regex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
              return regex.test(value?.zip);
            })
            .required("An address is required"),
        }),
      }),
      steps: (values) => [
        {
          name: "address",
          label: "Address",
          placeholder: "Address",
          required: true,
          type: "address-auto-complete",
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          console.log(values.add_property.address)
          const p = await call(api.property.create, {
            address: values.add_property.address
          });

          if (p) {
            // sendOwnerPropertyAddedConversion();
            if(!values.prop_choice.choice){
              return [false, "Failed to start workflow"];
            }
            startFlow({
              params: {
                type: values.prop_choice.choice === 'listing-setup' ? 'listing-setup' : 'existing-renter',
                args: {
                  propertyId: p.id ?? 0,
                  userId: me?.id ?? 0,
                }
              }
            });
            await refresh.properties();
            done();
            onFinish();
            return [true];
          }

          return [false, "Failed to create property"];
        });
      },
    },

  });

  return pages;
};