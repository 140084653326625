import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { call } from '../call';
import { api } from 'server-sdk/src/api';
import { Availability, OutsideTour } from 'server-sdk/src/types';
import { track } from '../util/track';
import { useApiDispatch, useGlobal, useReduxState } from '.';
import { updateAvailabilities } from '../ducks/discover';

interface EpochRange {
  original?: Availability;
  start: DateTime;
  end: DateTime;
}

const toEpochRange = (a: Availability): EpochRange => ({
  original: a,
  start: DateTime.fromMillis(a.startTime),
  end: DateTime.fromMillis(a.endTime),
});

// while using hardcoded
const HOUR = 3600000;

export const useOutsideTour = (tourId: number) => {
  const dispatch = useApiDispatch();

  const [tour, setTour] = useState<OutsideTour>(undefined);

  const refresh = () => {
    // eslint-disable-next-line no-shadow
    dispatch(async (call, api) => {
      // eslint-disable-next-line no-shadow
      const tour = await call(api.schedule.with.renter.details, {
        tourId,
      });

      if (tour) {
        setTour(tour);
        return [true];
      }

      return [false, 'Failed to tour data, please try refreshing the page'];
    });
  };

  return {
    refresh,
    tour,
  };
};

export const useRenterSchedule = (renterId: number) => {
  const dispatch = useApiDispatch();

  const [availability, setAvailability] = useState<EpochRange[]>([]);
  const [renterName, setRenterName] = useState<string>(undefined);

  const refresh = () => {
    // eslint-disable-next-line no-shadow
    dispatch(async (call, api) => {
      const { availabilities, renterFirstName } = await call(
        api.schedule.with.renter.get,
        {
          renterId,
        },
      );

      if (availabilities) {
        setAvailability(availabilities.map((a) => toEpochRange(a)));
        setRenterName(renterFirstName);
        return [true];
      }

      return [false, 'Failed to load schedule, please try refreshing the page'];
    });
  };

  const isSchedulable = (date: DateTime) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const a of availability) {
      if (a.start.startOf('day') <= date && date < a.end.endOf('day')) {
        return true;
      }
    }

    return false;
  };

  const book = (
    start: DateTime,
    firstName: string,
    lastName: string,
    email: string,
    // eslint-disable-next-line no-unused-vars
    onSuccess: (tourId: number) => void,
  ) => {
    dispatch(async (call, api) => {
      const booking = await call(api.schedule.with.renter.book, {
        start: start.toMillis(),
        end: start.plus({ minutes: 30 }).toMillis(),
        renterId,
        schedulerFirstName: firstName,
        schedulerLastName: lastName,
        schedulerEmail: email,
      });
      if (booking) {
        onSuccess(booking.scheduledTourId);
        return [true, 'Succesfully booked tour'];
      }

      return [false, 'Failed to book tour, please try again later.'];
    });
  };

  return {
    book,
    refresh,
    availability,
    isSchedulable,
    renterName,
  };
};

export const useMySchedule = () => {
  const { availabilities, reservations } = useReduxState(
    (state) => state.discover,
  );
  const dispatch = useDispatch();
  const global = useGlobal();

  const refresh = () => {
    dispatch(async () => {
      // eslint-disable-next-line no-shadow
      const res = await call(api.schedule.mine.get);

      dispatch(updateAvailabilities(res?.availabilities));
    });
  };

  const availability: EpochRange[] = useMemo(() => {
    if (availabilities?.length > 0) {
      return availabilities
        .map((a) => toEpochRange(a))
        .sort((a, b) => a.start.toMillis() - b.start.toMillis());
    }

    return undefined;
  }, [availabilities]);

  const backup: EpochRange[] = useMemo(() => {
    if (!availability?.length) {
      const nextRez = reservations?.find((r) => r);
      if (nextRez) {
        return [
          {
            start: DateTime.fromMillis(nextRez.startTime - HOUR),
            end: DateTime.fromMillis(nextRez.startTime),
          },
          {
            start: DateTime.fromMillis(nextRez.endTime),
            end: DateTime.fromMillis(nextRez.endTime + HOUR),
          },
        ];
      }

      const next = DateTime.now().startOf('hour').plus({ hour: 1 });
      return [
        {
          start: next,
          end: next.plus({ hour: 1 }),
        },
      ];
    }

    return undefined;
  }, [reservations, availabilities]);

  const addBackups = (ranges: EpochRange[]) => {
    dispatch(async () => {
      try {
        const added = await call(api.schedule.mine.add, {
          availabilities: ranges.map((r) => ({
            startTime: r.start.toMillis(),
            endTime: r.end.toMillis(),
          })),
        });

        if (added) {
          dispatch(updateAvailabilities(added.availabilities));
          global.success('Saved new tour slots');
          return;
        }
      } catch (e) {
        global.error('Failed to update schedule');
      }
    });
    track('Add Availability');
  };

  const add = () => {
    if (!availability?.length) {
      addBackups(backup);
    } else {
      dispatch(async () => {
        try {
          const last = availability[availabilities.length - 1];
          const next = last.end;
          const added = await call(api.schedule.mine.add, {
            availabilities: [
              {
                startTime: next.toMillis(),
                endTime: next.plus({ hour: 1 }).toMillis(),
              },
            ],
          });

          if (added?.availabilities?.length) {
            refresh();
            global.success('Added new slot');
            return;
          }
        } catch (e) {
          global.error('Failed to add new slot');
        }
      });
    }
    track('Add Availability');
  };

  const update = (index: number, range: EpochRange) => {
    dispatch(async () => {
      try {
        const updated = await call(api.schedule.mine.update, {
          id: range.original.id,
          startTime: range.start.toMillis(),
          endTime: range.end.toMillis(),
        });

        if (updated) {
          refresh();
          global.success('Updated tour time');
          return;
        }
      } catch (e) {
        global.error('Failed to update tour slot');
      }
    });

    // setAvailability([
    //   ...availability
    // ]);
    track('Update Availability');
  };

  const remove = (range: EpochRange) => {
    if (backup) {
      global.success('This is a demo date and it cannot be deleted');
      return;
    }
    dispatch(async () => {
      try {
        const res = await call(api.schedule.mine.delete, {
          id: range.original.id,
        });
        if (res) {
          refresh();
          global.success('Time slot removed');
          return;
        }
      } catch (e) {
        global.error('Failed to delete time slot');
      }
    });
    track('Remove Availability');
  };

  return {
    add,
    addBackups,
    availability,
    backup,
    refresh,
    remove,
    update,
  };
};
