import React from 'react';
import { theme } from 'src/styles/theme';

interface AvatarProps {
  src: string;
  alt?: string;
  size?: number;
}

export const Avatar: React.FC<AvatarProps> = ({ alt, size = 1, src }) => (
  <img
    src={src}
    alt={alt}
    style={{
      // because theme.spacing changed to be a string instead of number. this chagned to be "num"px.
      width: size * Number(theme.spacing(6).slice(0, -2)),
      height: size * Number(theme.spacing(6).slice(0, -2)),
      borderRadius: '50%',
    }}
  />
);
