let _hook: GuidHook;

type GuidHook = () => string;

export const setupGuidGenerator = (hook: GuidHook) => {
  _hook = hook;
}

export const generateGuid: GuidHook = () => {
  return _hook && _hook();
}