export interface TextPhone {
  id?: number;
  phone?: string;
  isDeactivated?: boolean;
  creationTime?: number;
}

export interface TextMessage {
  id?: number;
  conversationId: number;
  message?: string;
  text?: string;
  content_type?: number;
  phone?: TextPhone;
  twilioId?: string;
  messageStatus?: string;
  creationTime?: number;
  isSentByMe?: boolean;
  user?:{
    id?: string,
    firstName?: string;
    lastName?: string;
  }
  callStatus?: string;
  callDuration?: string;
}

export interface TextChat {
  id?: number;
  name?: string;
  leasingPropertyPhone?: TextPhone;
  withPhones?: TextPhone[];
  messages?: TextMessage[];
  lastSeen?: number;
  lastMessageFrom?: string;
  lastMessageText?: string;
  creationTime?: number;
  modificationTime?: number;
  replySuggestions?: string[];
  betaEnabled?: boolean; // flag to enable calling in marble chat
  propertySubscribed?: boolean;
}

export interface TextSuggestionsRequest {
  clientMessages: string[];
  replySuggestions: string[];
}

export interface LeasingTexts {
  chats?: TextChat[];
  page?: number;
}

export enum MessageType {
  Text = 1,
  Image = 2,
  Video = 3,
  Pdf = 4,
}