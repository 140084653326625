import React from 'react';
import { theme as marbleTheme } from 'ui-sdk/src/theme';
import { Grid, InputLabel, TextField } from '@mui/material';
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { makeStyles } from '@mui/styles';
import { ErrorText } from './Form';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      fontSize: '15px',
      fontWeight: '500',
      lineHeight: '150%',
      fontStyle: 'normal',
      color: `${marbleTheme.colors.gray[1]}`,
      background: `${marbleTheme.colors.gray[5]}`,
      marginBottom: theme.spacing(1),
      '&.Mui-error fieldset': {
        border: `2px solid ${marbleTheme.colors.red.main}`,
      },
      '&.Mui-focused fieldset': {
        border: `2px solid ${marbleTheme.colors.blue.main}`,
      },
      '&.Mui-focused .MuiOutlinedInput-input': {
        backgroundColor: marbleTheme.colors.white,
      },
    },
    '& .MuiOutlinedInput-root fieldset': {
      borderWidth: '0px',
    },
    '& .MuiFormHelperText-root .Mui-error': {
      color: marbleTheme.colors.red.main,
    },
  },
  label: {
    fontSize: '15px',
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
    color: marbleTheme.colors.black,
    lineHeight: '150%',
  },
  labelAsterisk: {
    fontSize: '15px',
    color: marbleTheme.colors.red.main,
  },
}));

interface DatePickerProps {
  placeholder: string;
  value: Date;
  onChange: any;
  required?: boolean;
  removeLabel?: boolean;
  minDate?: any;
  maxDate?: any;
  error?: string;
}

export const MarbleDatePicker: React.FC<DatePickerProps> = ({
  placeholder,
  value,
  onChange,
  required,
  removeLabel,
  minDate,
  maxDate,
}) => {
  const classes = useStyles();

  return (
    <Grid>
      {!removeLabel && (
        <InputLabel
          classes={{ root: classes.label, asterisk: classes.labelAsterisk }}
          required={required}
        >
          {placeholder}
        </InputLabel>
      )}

      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePicker
          value={value}
          onChange={onChange}
          // @ts-ignore
          inputProps={{
            placeholder,
          }}
          renderInput={(props) => (
            <TextField
              style={{ width: '100%' }}
              {...props}
              className={classes.root}
            />
          )}
          minDate={minDate}
          maxDate={maxDate}
        />
      </LocalizationProvider>
    </Grid>
  );
};

export const MarbleDesktopDatePicker: React.FC<DatePickerProps> = ({
  placeholder,
  value,
  onChange,
  required,
  removeLabel,
  minDate,
  maxDate,
  error,
}) => {
  const classes = useStyles();

  return (
    <Grid>
      {!removeLabel && (
        <InputLabel
          classes={{ root: classes.label, asterisk: classes.labelAsterisk }}
          required={required}
        >
          {placeholder}
        </InputLabel>
      )}
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DesktopDatePicker
          value={value}
          onChange={onChange}
          minDate={minDate}
          maxDate={maxDate}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: 'mm/dd/yyyy',
              }}
              className={classes.root}
            />
          )}
          inputFormat="MM/dd/yyyy"
        />
      </LocalizationProvider>
      <ErrorText error={error} />
    </Grid>
  );
};
