export const usd = (value: number, numDigits?: number) => {
  if (!value) {
    value = 0;
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: numDigits ?? 2,
  });

  return formatter.format(value);
};
