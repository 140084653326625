import {
  alpha,
  FormControl,
  MenuItem,
  Select,
  Grid,
  InputLabel,
  SxProps,
} from '@mui/material';
import React from 'react';
import { InputListProps, InputProps } from 'src/base/inputs/props';
import { CssStyleProps } from 'src/base/props/CssStyleProps';
import { theme as marbleTheme } from 'ui-sdk/src/theme';
import { P } from '../texts';
import { MarbleText } from '../texts/Text';

export const Dropdown: React.FC<
  CssStyleProps & InputProps & InputListProps
> = ({ name, placeholder, value, options, onBlur, onChange, style }) => (
  <FormControl style={style}>
    <InputLabel>{placeholder}</InputLabel>
    <Select
      label={placeholder}
      name={name}
      value={value}
      onBlur={onBlur}
      // @ts-ignore
      onChange={(e) => onChange(e)}
    >
      {options?.map(({ value, label }) => (
        <MenuItem value={value}>{label}</MenuItem>
      ))}
    </Select>
  </FormControl>
);

interface MarbleDropdownInputProps {
  required?: boolean;
  invalid?: boolean;
  disabled?: boolean;
  labelPosition?: 'top' | 'left' | 'right';
  defaultValue?: any;
  selectStyles?: SxProps;
}

export const MarbleDropdown: React.FC<
  CssStyleProps & MarbleDropdownInputProps & InputProps & InputListProps
> = ({
  name,
  placeholder,
  value,
  defaultValue,
  required,
  invalid,
  disabled,
  labelPosition = 'top',
  options,
  onBlur,
  onChange,
  style,
  error,
  selectStyles,
}) => (
  <div
    style={{
      display:
        labelPosition === 'left' || labelPosition === 'right' ?
          'flex' :
          'block',
      width: '100%',
      padding: '0px',
    }}
  >
    {placeholder &&
      (!labelPosition ||
        labelPosition === 'left' ||
        labelPosition === 'top') && (
        <div>
          <InputLabel
            sx={{
              lineHeight: '150%',
              margin:
                !labelPosition || labelPosition === 'top' ?
                  `0 0 ${marbleTheme.spacing(1)} 0` :
                  '0px',
              paddingRight: marbleTheme.spacing(3),
              height: '100%',
            }}
          >
            <Grid
              item
              container
              p={0}
              m={0}
              alignContent="center"
              height="100%"
            >
              <P
                style={{
                  margin: '0px',
                  fontSize: '15px',
                  fontWeight: 700,
                  color: marbleTheme.colors.black,
                }}
              >
                {placeholder}
              </P>
              <P
                style={{
                  margin: '0px',
                  fontSize: '15px',
                  fontWeight: 700,
                }}
              >
                {required && (
                  <sup style={{ color: marbleTheme.colors.red.main }}>
                    &nbsp;*
                  </sup>
                )}
              </P>
            </Grid>
          </InputLabel>
        </div>
      )}
    <div style={{ flexGrow: 1 }}>
      <FormControl
        style={{
          ...style,
          minWidth: '100%',
        }}
        variant="outlined"
      >
        <Select
          value={value}
          defaultValue={defaultValue}
          onBlur={onBlur}
          name={name}
          // @ts-ignore
          onChange={(e) => onChange(e)}
          disabled={disabled}
          sx={{
            fontSize: '15px',
            '.MuiOutlinedInput-input': {
              backgroundColor: invalid ?
                marbleTheme.colors.white :
                marbleTheme.colors.gray[5],
              borderRadius: '5px',
              paddingTop: marbleTheme.spacing(2),
              paddingBottom: marbleTheme.spacing(2),
              paddingLeft: marbleTheme.spacing(3),
              paddingRight: marbleTheme.spacing(3),
              marginBottom: marbleTheme.spacing(1),
            },
            '.MuiOutlinedInput-notchedOutline': {
              borderWidth: '2px',
              borderColor: invalid ?
                alpha(marbleTheme.colors.red.main, 0.5) :
                marbleTheme.colors.white,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: alpha('#3E6EEB80', 0.5),
            },
            '&.Mui-focused .MuiOutlinedInput-input': {
              backgroundColor: marbleTheme.colors.white,
            },
            '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
              borderColor: marbleTheme.colors.white,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: invalid ?
                alpha(marbleTheme.colors.red.main, 0.5) :
                marbleTheme.colors.white,
            },
            ...selectStyles,
          }}
          disableUnderline
        >
          {options?.map(({ value, label, disabled }) => (
            <MenuItem value={value} disabled={disabled}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
    {placeholder && labelPosition === 'right' && (
      <div>
        <InputLabel
          required={required}
          sx={{
            lineHeight: '150%',
            margin: '0px',
            paddingLeft: marbleTheme.spacing(3),
            height: '100%',
          }}
        >
          <Grid item container p={0} m={0} alignContent="center" height="100%">
            <P
              style={{
                margin: '0px',
                fontSize: '15px',
                fontWeight: 700,
                color: marbleTheme.colors.black,
              }}
            >
              {placeholder}
            </P>
            <P
              style={{
                margin: '0px',
                fontSize: '15px',
                fontWeight: 700,
              }}
            >
              {required && (
                <sup style={{ color: marbleTheme.colors.red.main }}>
                  &nbsp;*
                </sup>
              )}
            </P>
          </Grid>
        </InputLabel>
      </div>
    )}
    {error && (
      <MarbleText size="xs" color="red.main">
        {error}
      </MarbleText>
    )}
  </div>
);
