import { Box } from '@mui/material';
import { motion } from 'framer-motion';
import React, { useMemo } from 'react';
import { CssStyleProps } from 'src/base/props';
import { TextProps } from 'src/base/texts/props';
import { TextSize, TextWeight, theme } from 'ui-sdk/src/theme';
import { MarbleText } from './Text';

export interface TagProps {
  color: 'green' | 'white' | 'red' | 'black' | 'blue' | 'gray';
  type: 'primary' | 'secondary' | 'outlined' | 'disabled' | 'shadow';
  tagSize?: TextSize;
  tagWeight?: TextWeight;
  blinking?: boolean;
  startIcon?: any;
  truncateText?: boolean;
}

export const Tag: React.FC<CssStyleProps & TextProps & TagProps> = ({
  align,
  children,
  color,
  type,
  style,
  tagWeight = 'med',
  tagSize = 'xs',
  blinking,
  startIcon,
  truncateText,
}) => {
  const backgroundColor = useMemo(() => {
    switch (color) {
      case 'black':
        return theme.colors.black;
      case 'green':
        return theme.colors.green.main;
      case 'white':
        return theme.colors.white;
      case 'red':
        return theme.colors.red.main;
      case 'gray':
        return theme.colors.gray[4];
      default:
        return theme.colors.blue.main;
    }
  }, [color]);
  const textColor = useMemo(() => {
    if (type === 'shadow') {
      return 'black';
    }
    if (type !== 'primary') {
      switch (color) {
        case 'black':
          return 'black';
        case 'green':
          return 'green.main';
        case 'white':
          return 'white';
        case 'red':
          return 'red.main';
        case 'gray':
          return 'black';
        default:
          return 'blue.main';
      }
    } else {
      switch (color) {
        case 'white':
        case 'gray':
          return 'black';
        default:
          return 'white';
      }
    }
  }, [color, type]);

  const typeStyle = useMemo(() => {
    switch (type) {
      case 'secondary':
        switch (color) {
          case 'black':
            return {
              backgroundColor: theme.colors.gray[4],
            };
          case 'green':
            return {
              backgroundColor: theme.colors.green.glass,
            };
          case 'white':
            return {
              backgroundColor: '#FFFFFF26',
            };
          case 'red':
            return {
              backgroundColor: theme.colors.red.glass,
            };
          default:
            return {
              backgroundColor: theme.colors.blue.glass,
            };
        }
      case 'outlined':
        return {
          paddingInline: theme.spacing(2 - 0.6),
          paddingBlock: theme.spacing(0.6),
          border: `2px solid ${
            color === 'black' ? theme.colors.gray[4] : `${backgroundColor}80`
          }`,
        };
      case 'disabled':
        return {
          backgroundColor: `${theme.colors.black}32`,
          color: theme.colors.black,
        };
      case 'shadow':
        return {
          backgroundColor: `${theme.colors.white}`,
          boxShadow: '0px 5px 20px 0px rgba(0, 0, 0, 0.15)',
        };
      default:
        return {
          backgroundColor,
        };
    }
  }, [type]);

  return (
    <Box
      sx={{
        ...style,
        display: 'inline-flex',
        borderRadius: 10,
        paddingInline: theme.spacing(2),
        paddingBlock: theme.spacing(1),
        marginTop: 1,
        alignItems: 'center',
        ...typeStyle,
      }}
    >
      {startIcon}
      <MarbleText
        align={align}
        weight={tagWeight}
        size={tagSize}
        color={textColor}
        noWrap={!!truncateText}
      >
        {children.toString().toUpperCase()}
      </MarbleText>
      {blinking && (
        <motion.div
          style={{
            width: theme.spacing(2),
            height: theme.spacing(2),
            marginLeft: theme.spacing(1),
            borderRadius: 5,
            backgroundColor: theme.colors.blue.main,
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: [1, 0.1, 1, 1] }}
          transition={{
            duration: 2,
            ease: 'easeInOut',
            times: [0, 0.75, 1, 1.75],
            repeat: Infinity,
          }}
        />
      )}
    </Box>
  );
};
