import { MaintenanceFeedbackRequest } from "./maintenance";

export interface User {
  id?: number
  firstName?: string
  lastName?: string
  fullName?: string
  email?: string
  phone?: string
  leasingPhoneNumber?: string
  password?: string
  authToken?: string
  type?: UserType
  avatar?: string
  code?: string
  subscribed?: boolean
  needs_to_resubscribe?: boolean
  idVerified?: boolean
  idVerificationPending?: boolean
  idVerificationFailed?: boolean
  idVerificationStatus?: string
  idVerificationPlatform?: UserIdVerificationPlatform
  refSource?: string
  inboundReferralCode?: string;
  isInboundAffiliate?: boolean;
  killswitched?: boolean
  isHandymanReserved?: boolean
  // from account creation form
  reportedPortfolioCount?: number,
  // for segment
  portfolio?: {
    total?: number;
    subscribed?: number;
    legacy?: number;
  }

  portfolioCount?: number;

  scam_reviewed?: boolean;
  trial_days_remaining?: number;
  trial_start_time?: number;
  trial_end_time?: number;
  is_deactivated_phone?: boolean;
  experiment?: string;

  name_modification_time?: number;
  has_rental_agreement?: boolean;
  landlord_experiment?: string;
  landlord_subscribed?: boolean;
  renewal_status?: {
    started?: boolean;
    endLease?: number;
    extension?: boolean;
    completed?: boolean;
  };
  move_in_status?: {
    startLease?: number;
    completedChecklist?: boolean;
  };

  dismissed_tooltips?: DismissedTooltips;
  upcoming_trips?: UserTrip[];
  feedback_requests?: MaintenanceFeedbackRequest[];
};

export interface DismissedTooltips {
  'start-workflow'?: number;
}

export interface DismissTooltip {
  tooltip?: ToolTipType;
}

export type ToolTipType = keyof DismissedTooltips; 

export enum UserIdVerificationPlatform {
  Stripe = 'Stripe',
  Persona = 'Persona'
}

export interface Login {
  email?: string
  phone?: string
  exists?: boolean
  otp?: string
  type?: UserType;
  isWebApp?: boolean;
}

export interface ChangeIdentity {
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
  otp?: string;
  currentUser?: User;
  isWebApp?: boolean;
}

export interface LoginInvitation extends Login {
  invitationId?: number;
}

export enum UserType {
  Renter,
  Owner,
  Vendor
}

export interface UserTrip {
  tripTitle?: string;
  tripReason?: string;
  tripStartWindowBegin?: number;
  tripStartWindowEnd?: number
  tripEndTime?: number;
  tripDuration?: number;
  tripVendor?: string;
}