enum ActionType {
  UpdateSnack = 'global/update_success',
}

type Level = 'success' | 'error';

interface GlobalState {
  message?: string;
  level?: Level;
}

export const updateSnack = (
  level: Level,
  message: string,
): {
  type: typeof ActionType.UpdateSnack;
  level: string;
  message: string;
} => ({
  type: ActionType.UpdateSnack,
  level,
  message,
});

export default (
  state: GlobalState = {},
  action: ReturnType<typeof updateSnack>,
) => {
  switch (action.type) {
    case ActionType.UpdateSnack:
      return {
        ...state,
        level: action.level,
        message: action.message,
      };
    default:
      return state;
  }
};
