import { Property, UnregisteredRenter } from "server-sdk/src/types";
import { usePortfolioRefresher } from "./property";
import { useApiDispatch, useApiStore } from "./global";
import { cents, usd, useMultiPageForm } from "./useForm";
import { validateArray, validateBoolean, validateNumber, validateObject, validateString } from "../util/validate";
import { useEffect, useState } from "react";
import { api } from "server-sdk/src/api";
import { buildLateFees, convertLateFees, lateFeesStep } from "./moveInForms";
import { FlowAction, FlowDefinition } from "server-sdk/src/types/workflow";

export const useRentTermsForm = (
  property: Property,
  flowActivityId: number,
  onFinish: () => void,
) => {
  const apiDispatch = useApiDispatch();
  const refresh = usePortfolioRefresher();

  const [unregRenter, setUnregRenter] = useState<
    UnregisteredRenter | undefined
  >(
    property?.unregistered_renters && property?.unregistered_renters.length > 0
      ? property?.unregistered_renters[0]
      : undefined
  );

  const initialLateFee = buildLateFees(
    property?.late_fees,
    property?.listing_price, false
  );

  const setValues = (unReg: UnregisteredRenter | undefined) => {
    pages.rent.form.values.listing_price = unReg?.base_rent;
    pages.deposit.form.values.security_deposit = unReg?.security_deposit;
    pages.rent_terms.form.values.rent_due_day = unReg?.rent_due_day;
    pages.rent_terms.form.values.start_lease = unReg?.start_lease;
    pages.rent_terms.form.values.end_lease = unReg?.end_lease;
  };

  const pages = useMultiPageForm({
    rent: {
      label: 'Rent Price',
      description: 'Enter in your monthly rental pricing',
      initial: {
        listing_price: usd(unregRenter?.base_rent) ?? undefined,
      },
      validationSchema: validateObject({
        listing_price: validateNumber().required("Rental price is required"),
      }),
      resources: (values) => [],
      steps: (values) => [
        {
          name: "listing_price",
          label: "Listing Price",
          placeholder: "Rent",
          required: true,
          type: "usd",
        },
      ],
      submit: ({ values, done }) => {
        console.log("values", values);
        apiDispatch(async (call, api) => {
          const res = await call(api.property.update, {
            id: property.id,
            listing_price: cents(values.rent.listing_price),
          });

          if (!res) {
            return [false, "Something went wrong"];
          }
          let resReg = unregRenter;
          if(!unregRenter){
            resReg = await call(api.property.createUnregisteredRenter, {
              property_id: property.id,
            });

            if (!resReg) {
              return [false, "Something went wrong"];
            }

            setUnregRenter(resReg);
          }

          const renterRes = await call(api.property.updateUnregisteredRenter, {
            id: resReg?.id,
            base_rent: cents(values.rent.listing_price),
          });

          if (renterRes) {
            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
    deposit: {
      label: 'Deposit',
      description: 'What is the security deposit for this property?',
      initial: {
        security_deposit: usd(unregRenter?.security_deposit),
      },
      validationSchema: validateObject({
        security_deposit: validateNumber().required("Security Deposit is required"),
      }),
      resources: (values) => [],
      steps: (values) => [
        {
          name: "security_deposit",
          label: "Security Deposit",
          placeholder: "Deposit",
          required: true,
          type: "usd",
        },
      ],
      submit: ({ values, done }) => {
        console.log("values", values);
        apiDispatch(async (call, api) => {
          const res = await call(api.property.update, {
            id: property.id,
            security_deposit: cents(values.deposit.security_deposit),
          });

          if (!res) {
            return [false, "Something went wrong"];
          }

          const renterRes = await call(api.property.updateUnregisteredRenter, {
            id: unregRenter?.id,
            security_deposit: cents(values.deposit.security_deposit),
          });

          if (renterRes) {
            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
    late_fees: {
      label: 'Late Fees',
      description: 'What is the late fee policy for this property?',
      ...lateFeesStep(initialLateFee, property?.listing_price),
      submit: ({ values, done }) => {
        console.log("values", values);
        apiDispatch(async (call, api) => {
          const convertedLateFee = convertLateFees({
            choice_conditional: {
              choice: values.late_fees.choice,
              one_time_collection: {
                type_conditional: {
                  type: values.late_fees.one_time_type,
                  value: values.late_fees.one_time_value,
                },
              },
              recurring_collection: {
                initial_fee: values.late_fees.recurring_initial_fee,
                recurring_fee: values.late_fees.recurring_recurring_fee,
              },
            },
            days: values.late_fees.days,
          });

          const updated = await call(api.property.update, {
            id: property?.id,
            late_fees: convertedLateFee,
          });

          if (updated) {
            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
    rent_terms: {
      initial: {
        rent_due_day: unregRenter?.rent_due_day,
        start_lease: unregRenter?.start_lease,
        end_lease: unregRenter?.end_lease,
      },
      validationSchema: validateObject({
        rent_due_day: validateNumber()
          .transform((value) => (isNaN(value) ? 0 : value))
          .min(1, "Rent due day must be within the month")
          .max(31, "Rent due day must be within the month")
          .required("A rent due day is required."),
        start_lease: validateNumber()
          .required("A start lease date is required."),
        end_lease: validateNumber()
          .required("A end lease date is required."),
      }),
      label: "Rent Details",
      description: "Fill out the rental details for this lease",
      steps: (values) => [
        {
          name: "rent_due_day",
          label: "Rent Due Day (The day of the month when rent is due.)",
          placeholder: "Rent Due Day",
          required: true,
          type: "number",
        },
        {
          name: "start_lease",
          label: "Start Lease",
          placeholder: "Start Lease",
          required: true,
          type: "date",
        },
        {
          name: "end_lease",
          label: "End Lease",
          placeholder: "End Lease",
          required: true,
          type: "date",
          minDate: values?.rent_terms?.start_lease,
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {

          const renterRes = await call(api.property.updateUnregisteredRenter, {
            id: unregRenter?.id,
            ...values.rent_terms
          });

          if (!renterRes) {
            return [false, "Something went wrong"];
          }

          const res = await call(api.flows.complete, {
            id: flowActivityId,
          });

          if (res) {
            refresh.properties();
            done();
            onFinish();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
  });

  return pages;
};

export const useLeaseUploadForm = (
  property: Property,
  flowActivityId: number,
  onFinish: () => void,
) => {
  const apiDispatch = useApiDispatch();
  const refresh = usePortfolioRefresher();

  const pages = useMultiPageForm({
    lease_upload: {
      initial: {
        lease_documents: property?.lease_files
      },
      label: "Lease",
      description: "Please upload any documents related to your existing lease",
      validationSchema: validateObject({
        lease_documents: validateArray()
          .min(1, "At least 1 lease document is required.")
          .required("Lease is required"),
      }),
      steps: (values) => [
        {
          name: "lease_documents",
          label: "Add Lease Documents",
          required: true,
          type: "upload-documents",
          uploadFields: {
            allowedFileTypes: ["jpg", "jpeg", "png", 'pdf'],
            id: property?.id,
            field: "lease_files",
            base: api.forms.onboarding.owner,
          },
        },
      ],
      submit: ({ values, done }) => {
        console.log("values", values);
        apiDispatch(async (call, api) => {
          const res = await call(api.flows.complete, {
            id: flowActivityId,
          });

          if (res) {
            refresh.properties();
            done();
            onFinish();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
  });

  return pages;
};

export const useRenterInviteForm = (
  property: Property,
  flowActivityId: number,
  onFinish: () => void
) => {
  const apiDispatch = useApiDispatch();
  const refresh = usePortfolioRefresher();
  const [me, refreshMe] = useApiStore((api) => api.users.v2.me);
  useEffect(() => {
    refreshMe();
  }, []);

  // useEffect(() => {
  //   if (me?.idVerified) {
  //     pages.id_verification.form.setValue("verified", true)
  //   }

  // }, [me?.idVerified])

  const [unregRenter, setUnregRenter] = useState<
    UnregisteredRenter | undefined
  >(
    property?.unregistered_renters && property?.unregistered_renters.length > 0
      ? property?.unregistered_renters[0]
      : undefined
  );

  const setValues = (unReg: UnregisteredRenter | undefined) => {
    pages.contact_info.form.values.first_name = unReg?.first_name ?? '';
    pages.contact_info.form.values.last_name = unReg?.last_name ?? '';
    pages.contact_info.form.values.renter_email = unReg?.renter_email ?? '';
    pages.contact_info.form.values.renter_phone = unReg?.renter_phone ?? '';
  };

  const pages = useMultiPageForm({
    contact_info: {
      label: "Renter Contact Info",
      description: "Enter in the current tenant's contact information",
      initial: {
        first_name: unregRenter?.first_name ?? '',
        last_name: unregRenter?.last_name ?? '',
        renter_email: unregRenter?.renter_email ?? '',
        renter_phone: unregRenter?.renter_phone ?? '',
      },
      validationSchema: validateObject({
        first_name: validateString()
          .required("Please enter your first name")
          .max(30, "Maximum length is 30 characters"),
        last_name: validateString()
          .required("Please enter your last name")
          .max(30, "Maximum length is 30 characters"),
        renter_email: validateString()
          .required("Please enter your email")
          .email("Invalid email address"),
        renter_phone: validateString()
          .required("Please enter a phone number")
          .min(10)
          .max(16),
      }),
      resources: (values) => [],
      steps: (values) => [
        {
          name: "first_name",
          label: "First Name",
          placeholder: "First Name",
          required: true,
          type: "text",
        },
        {
          name: "last_name",
          label: "Last Name",
          placeholder: "Last Name",
          required: true,
          type: "text",
        },
        {
          name: "renter_email",
          label: "Email",
          placeholder: "Email",
          required: true,
          type: "text",
        },
        {
          name: "renter_phone",
          label: "Phone Number",
          placeholder: "Phone Number",
          required: true,
          type: "phone",
        },
      ],
      submit: ({ values, done }) => {
        console.log("values", values);
        apiDispatch(async (call, api) => {
          let resReg;
          if (!unregRenter) {
            resReg = await call(api.property.createUnregisteredRenter, {
              property_id: property.id,
            });

            if (!resReg) {
              return [false, "Something went wrong"];
            }
            setValues(resReg);
            setUnregRenter(resReg);
          }

          const renterRes = await call(api.property.updateUnregisteredRenter, {
            id: unregRenter?.id ?? resReg?.id,
            ...values.contact_info,
          });

          if (!renterRes) {
            return [false, "Something went wrong"];
          }

          if (me?.experiment !== 'd') {
            const res = await call(api.flows.complete, {
              id: flowActivityId,
            });

            onFinish();

            if (!res) {
              return [false, "Something went wrong"];
            }

            done();
            return [true];
          } else {
            refresh.properties();
            done();

            return [true];
          }

        });
      },
    },
    monthly_fee_consent: {
      initial: {
        yesno: undefined,
      },
      validationSchema: validateObject({
        yesno: validateString()
          .oneOf(
            [
              'yes',
            ],
            'You must approve the monthly fee policy.'
          )
          .required('You must approve the monthly fee policy.'),
      }),
      label: 'Monthly Fee Policy',
      description: 'Do you allow Marble to deduct a monthly fee of $65 from rent?',
      disabled: () => me?.experiment !== 'd',
      steps: () => [
        {
          name: 'yesno',
          required: true,
          label: 'Approve Monthly Fee',
          type: 'choice',
          exclusive: true,
          options: [
            {
              label: 'I Approve',
              caption: 'Marble will deduct the monthly fee when tenant\'s pay rent before dispersing payouts.',
              value: 'yes',
            },
          ]
        }
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          if (values?.monthly_fee_consent.yesno !== 'yes') {
            return [false, "Please accept the monthly fee policy to continue."];
          }

          const updated = await call(api.property.subscribeTransactional, {
            propertyId: property.id,
          });

          if (!updated) {
            return [false, "Something went wrong"];
          }

          if (updated.error) {
            return [false, updated.error];
          }

          const res = await call(api.flows.complete, {
            id: flowActivityId,
          });

          onFinish();

          if (!res) {
            return [false, "Something went wrong"];
          }

          done();
          return [true];
        });

      },
    },
    // id_verification: {
    //   initial: {
    //     verified: me?.idVerified,
    //   },
    //   validationSchema: validateObject({
    //     verified: validateBoolean().required('ID Verification is required'),
    //   }),
    //   label: "ID Verification",
    //   description: "To ensure the safety of the Marble community, we require all owners to verify their identity.",
    //   steps: (values) => {
    //     return [
    //       {
    //         name: 'verified',
    //         label: 'ID Verification',
    //         required: true,
    //         type: 'id-check',
    //       },
    //     ];
    //   },
    //   submit: ({ values, done }) => {
    //     apiDispatch(async (call, api) => {
    //       const updated = await call(api.property.subscribeTransactional, {
    //         propertyId: property.id,
    //       });

    //       if (!updated) {
    //         return [false, "Something went wrong"];
    //       }

    //       if (updated.error) {
    //         return [false, updated.error];
    //       }

    //       const res = await call(api.flows.complete, {
    //         id: flowActivityId,
    //       });

    //       const action = res.activity as FlowAction<FlowDefinition>;
    //       onFinish();

    //       if (!res) {
    //         return [false, "Something went wrong"];
    //       }

    //       done();
    //       return [true];
    //     });
    //   },
    // }

  });

  return pages;
};