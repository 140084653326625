let _hook: StripeHook;
let _apiKey: string;

export type CrossPlatformStripe = {
  verifyIdentity: (secret: string) => Promise<any>;
}

type StripeHook = (publishableKey: string, options?: any) => Promise<CrossPlatformStripe>;

export const setupStripe = (hook: StripeHook, apiKey: string) => {
  _hook = hook;
  _apiKey = apiKey;
}

export const useStripe = async (): Promise<CrossPlatformStripe> => {
  return await _hook(_apiKey);
}