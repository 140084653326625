import { Close } from '@mui/icons-material';
import {
  Box,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from '@mui/material';
import Image from 'next/image';
import React, { useCallback, useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import reactImageSize from 'react-image-size';
import Gallery from 'react-photo-gallery';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import Loading from 'src/base/animations/Loading';

interface PhotoItem {
  id: number;
  src: string;
  sortKey: number;
}

interface PhotoGalleryRowProps {
  photos: PhotoItem[];
}

interface SortablePhotoGalleryRowProps {
  photos: PhotoItem[];
  swapPhotos: (oldIdx: number, newIdx: number) => void;
  removePhoto: (id: number) => void;
}

const Handle = SortableHandle(({ children }) => <Box>{children}</Box>);

const SortablePhoto = SortableElement(({ src, removePhoto, onSelectPhoto }) => (
  <ImageListItem>
    <Handle>
      <Image
        onClick={() => onSelectPhoto(src)}
        // width={540}
        // height={313}
        style={{ cursor: 'pointer' }}
        layout="fill"
        objectFit="cover"
        src={src}
      />
    </Handle>
    <ImageListItemBar
      sx={{
        background:
          'linear-gradient(to bottom, rgba(0,0,0,0.4) 0%, ' +
          'rgba(0,0,0,0.1) 70%, rgba(0,0,0,0) 100%)',
      }}
      position="top"
      actionIcon={
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            removePhoto();
          }}
          sx={{
            color: 'white',
            paddingTop: 1,
          }}
        >
          <Close />
        </IconButton>
      }
    />
  </ImageListItem>
  // <Photo {...item} removePhoto={item.removePhoto} />
));
const SortableGallery = SortableContainer(({ children }) => (
  <Box
    sx={{
      height: '100%',
      position: 'relative',
      WebkitMaskImage:
        'linear-gradient(to bottom, black 90%, transparent 100%)',
      maskImage: 'linear-gradient(to bottom, black 90%, transparent 100%)',
      // backgroundImage:
      //   `linear-gradient(to bottom,
      //   rgba(255,255,255, 0),
      //   rgba(255,255,255, 1) 90%)`,
      overflowY: 'scroll',
    }}
  >
    <ImageList
      sx={{
        ':after': {
          position: 'absolute',
          height: 500,
          zIndex: 1,
          bottom: 0,
          left: 0,
        },
      }}
      rowHeight={400}
      gap={2}
    >
      {children}
    </ImageList>
  </Box>
  // <Gallery
  //   targetRowHeight={200}
  //   photos={items}
  //   renderImage={(props) => (

  //     <SortablePhoto
  //       removePhoto={removePhoto}
  //       {...props}
  //     />
  //   )}
  // />
));

export const SortablePhotoGalleryRow = ({
  photos,
  swapPhotos,
  removePhoto,
}: SortablePhotoGalleryRowProps) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    swapPhotos(oldIndex, newIndex);
  };
  const [currentImage, setCurrentImage] = useState();
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const closeFullScreenImage = () => {
    setCurrentImage(undefined);
    setViewerIsOpen(false);
  };

  if (photos) {
    return (
      <SortableGallery onSortEnd={onSortEnd} axis="xy" pressDelay={150}>
        {viewerIsOpen && (
          <Lightbox
            reactModalStyle={{ zIndex: 3000 }}
            mainSrc={currentImage}
            onCloseRequest={() => closeFullScreenImage()}
          />
        )}
        {photos
          ?.filter((p) => p.src)
          .map((item, index) => (
            <SortablePhoto
              onSelectPhoto={(photoSrc) => {
                setCurrentImage(photoSrc);
                setViewerIsOpen(true);
              }}
              index={index}
              removePhoto={() => {
                removePhoto(index);
              }}
              {...item}
            />
          ))}
      </SortableGallery>
    );
  }
  return null;
};

export const PhotoGalleryRow: React.FC<PhotoGalleryRowProps> = ({ photos }) => {
  const [galleryPhotos, setGalleryPhotos] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openFullScreenImage = useCallback((event, { index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
    document.body.style.overflow = 'hidden';
  }, []);

  const closeFullScreenImage = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
    document.body.style.overflow = 'auto';
  };

  useEffect(() => {
    /**
     * Need to get the dimensions of the image so that the Gallery viewer is able to calculate the
     * best way to view the images.
     */
    const getGalleryPhotos = async () => {
      const photosWithDimentions = await Promise.all(
        photos.map(async (p) => {
          const { width, height } = await reactImageSize(p.src);
          return {
            id: p?.id,
            src: p?.src,
            height,
            width,
          };
        }),
      );
      setGalleryPhotos(photosWithDimentions.filter((p) => p.src));
    };
    getGalleryPhotos();
  }, [photos]);

  if (galleryPhotos) {
    return (
      <div>
        <Gallery photos={galleryPhotos} onClick={openFullScreenImage} />
        {viewerIsOpen && (
          <Lightbox
            reactModalStyle={{ zIndex: 3000 }}
            mainSrc={galleryPhotos[currentImage].src}
            nextSrc={
              galleryPhotos[(currentImage + 1) % galleryPhotos.length].src
            }
            prevSrc={
              galleryPhotos[
                (currentImage + galleryPhotos.length - 1) % galleryPhotos.length
              ].src
            }
            onCloseRequest={() => closeFullScreenImage()}
            onMovePrevRequest={() =>
              setCurrentImage(
                (currentImage + galleryPhotos.length - 1) %
                  galleryPhotos.length,
              )
            }
            onMoveNextRequest={() =>
              setCurrentImage((currentImage + 1) % galleryPhotos.length)
            }
          />
        )}
      </div>
    );
  }
  return (
    <div>
      <Loading />
    </div>
  );
};
