import { call } from '../call';
import { api } from 'server-sdk/src/api';
import { DocTemplate } from 'server-sdk/src/types';
import { useApiDispatch } from './global';
import { useState } from 'react';

export const useDocusign = () => {
  const dispatch = useApiDispatch();
  const [loading, setLoading] = useState(false);

  const sendLeaseEnvelope = (
    applicationId: number,
    email: string,
    doc: DocTemplate,
    onSuccess?: () => void,
  ) => {
    dispatch(async () => {
      try {
        setLoading(true)
        const templateDraftResponse = await call(api.doc.template.saveDraft, {
          id: applicationId,
          content: doc.content,
          envelopeType: 'lease'
        });
        console.log(templateDraftResponse);
        if (!templateDraftResponse) {
          return [false, 'Failed to save lease'];
        }
        // need to get rid of the content property to make sure the api doesnt get payload overload
        const docWithoutContents = { ...doc };
        delete docWithoutContents.content;
        const response = await call(api.docusign.sendEnvelope, {
          application_id: applicationId,
          email,
          type: 'lease',
          doc: docWithoutContents,
        });
        if (response) {
          if (response.error) {
            return [false, response.error];
          }
          if (onSuccess) {
            onSuccess();
          }
          setLoading(false)
          return [true, `Document sent to ${email}! Check your email to sign`];
        }
        setLoading(false)
        return [false, 'Failed to send document'];
      } catch (e) {
        // console.error(e);
        setLoading(false)
        return [false, 'Failed to send document'];
      }
    });
  };

  const sendAddendumEnvelope = (
    leaseAddendumInfoId: number,
    email: string,
    doc: DocTemplate,
    onSuccess?: () => void,
  ) => {
    dispatch(async () => {
      try {
        setLoading(true)
        const templateDraftResponse = await call(api.doc.template.saveDraft, {
          id: leaseAddendumInfoId,
          content: doc.content,
          envelopeType: 'lease-addendum'
        });
        console.log(templateDraftResponse);
        if (!templateDraftResponse) {
          return [false, 'Failed to save lease'];
        }
        // need to get rid of the content property to make sure the api doesnt get payload overload
        const docWithoutContents = { ...doc };
        delete docWithoutContents.content;
        const response = await call(api.docusign.sendEnvelope, {
          lease_addendum_info_id: leaseAddendumInfoId,
          email,
          type: 'lease-addendum',
          doc: docWithoutContents,
        });
        if (response) {
          if (response.error) {
            return [false, response.error];
          }
          if (onSuccess) {
            onSuccess();
          }
          setLoading(false)
          return [true, `Document sent to ${email}! Check your email to sign`];
        }
        setLoading(false)
        return [false, 'Failed to send document'];
      } catch (e) {
        // console.error(e);
        setLoading(false)
        return [false, 'Failed to send document'];
      }
    });
  };

  return {
    loading,
    sendLeaseEnvelope,
    sendAddendumEnvelope
  };
};
