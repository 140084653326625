export interface VoiceToken {
  identity?: string;
  token?: string;
}

export enum VoiceStatus {
  notReady,
  ready,
  error,
  calling,
  callAccepted,
  callDisconnected,
  incomingCall
}
