import DialogTitle from '@mui/material/DialogTitle';
import Uppy from '@uppy/core';
import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import '@uppy/drop-target/dist/style.css';
import { DragDrop } from '@uppy/react';
import React, { useState } from 'react';
import { Button, P } from 'src/base';
import { Dialog, DialogActions } from 'src/deps/mui';
import { theme as marbleTheme } from 'ui-sdk/src/theme';

export default function UppyUploader({ handleUpload, allowedFileTypes }) {
  const allowedTypes = allowedFileTypes.map((t) => `.${t}`);

  const [modal, setModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploads, setUploads] = useState({ success: [], failed: [] });

  const hasFailedUploads = uploads?.failed?.length > 0;
  const uppy = new Uppy({
    restrictions: {
      allowedFileTypes: allowedTypes,
    },
  });

  uppy.on('files-added', async (files) => {
    console.log('uppy files-added event triggered');
    setIsUploading(true);
    const newUploads = await handleUpload(files);
    setIsUploading(false);
    setUploads(newUploads);
    setModal(true);
    for (const f of files) {
      uppy.removeFile(f.id);
    }
  });

  if (isUploading) {
    return (
      <div
        style={{
          textAlign: 'center',
          borderRadius: '7px',
          border: `2px solid ${marbleTheme.colors.blue.main}`,
          color: marbleTheme.colors.blue.main,
        }}
      >
        <div style={{ fontSize: '50px' }}>+</div>
        <div
          style={{
            fontFamily: 'Inter',
            fontSize: '20px',
            marginBottom: marbleTheme.spacing(6),
          }}
        >
          Uploading...
        </div>
      </div>
    );
  }
  return (
    <div>
      <style>
        {`
          .uppy-DragDrop-label {
            font-family: 'Inter'
          }
          .uppy-DragDrop-inner {
            padding-bottom: auto;
            padding-top: auto;
          }
          .uppy-DragDrop-inner > svg {
            display:none
          }

          ${
            hasFailedUploads ?
              `.uppy-DragDrop-inner::before {
            content: "x";
            font-size: 30px;
            display:flex;
            color: ${marbleTheme.colors.red.main};
            justify-content:center;
          }` :
              `.uppy-DragDrop-inner::before {
            content: "+";
            font-size: 50px;
            display:flex;
            justify-content:center;
          }`
          }
          .uppy-DragDrop-browse {
            color: ${
              hasFailedUploads ?
                `${marbleTheme.colors.red.main}` :
                `${marbleTheme.colors.black}`
            }
          }
          .uppy-DragDrop--isDragDropSupported {
            border: ${
              hasFailedUploads ?
                `2px dashed ${marbleTheme.colors.red.main}` :
                `2px dashed ${marbleTheme.colors.gray[4]}`
            };
          }
        `}
      </style>
      <DragDrop
        uppy={uppy}
        height="10em"
        note={allowedTypes.join(', ')}
        locale={{
          strings: {
            // Text to show on the droppable area.
            // `%{browse}` is replaced with a link that opens the system file selection dialog.
            dropHereOr: '%{browse}',
            // Used as the label for the link that opens the system file selection dialog.
            browse: hasFailedUploads ? 'Error' : 'Click to upload',
          },
        }}
      />
      <Dialog
        open={modal}
        onClose={() => {
          setModal(false);
        }}
      >
        {hasFailedUploads ? (
          <div>
            <DialogTitle>Failed to upload the following files: </DialogTitle>
            {uploads.failed.map((fileName) => (
              <P
                style={{
                  marginLeft: '2em',
                  marginRight: '2em',
                }}
              >
                {fileName}
              </P>
            ))}
          </div>
        ) : (
          <DialogTitle>Successfully uploaded files!</DialogTitle>
        )}
        <DialogActions
          style={{
            alignSelf: 'center',
            marginBottom: marbleTheme.spacing(3),
            marginTop: marbleTheme.spacing(1),
          }}
        >
          <Button
            title="Confirm"
            onClick={() => setModal(false)}
            color="primary"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
