import { UserInfo } from 'server-sdk/src/types';

enum ActionType {
  UpdatePII = 'application/update_pii',
  UpdateLandlordNames = 'application/update_landlord_names',
  UpdateUserInfo = 'application/update_user_info',
  UpdateIdentifier = 'application/update_guid',
}

interface ApplicationState {
  pii: PII;
  landlordNames: {};
  userInfo: UserInfo;
  identifier: string;
}

export interface PII {
  dob: string;
  ssn: string;
}

export const updatePII = (
  pii: PII,
): {
  type: ActionType.UpdatePII;
  pii: PII;
} => ({
  type: ActionType.UpdatePII,
  pii,
});

export const updateLandlordNames = (landlordNames: {}): {
  type: typeof ActionType.UpdateLandlordNames;
  landlordNames: {};
} => ({
  type: ActionType.UpdateLandlordNames,
  landlordNames,
});

export const updateUserInfo = (
  userInfo: UserInfo,
): {
  type: typeof ActionType.UpdateUserInfo;
  userInfo: UserInfo;
} => ({
  type: ActionType.UpdateUserInfo,
  userInfo,
});

export const updateIdentifier = (
  identifier: string,
): {
  type: typeof ActionType.UpdateIdentifier;
  identifier: string;
} => ({
  type: ActionType.UpdateIdentifier,
  identifier,
});

export default (
  state: ApplicationState = {
    pii: undefined,
    landlordNames: undefined,
    userInfo: undefined,
    identifier: undefined,
  },
  action:
    | ReturnType<typeof updatePII>
    | ReturnType<typeof updateLandlordNames>
    | ReturnType<typeof updateUserInfo>
    | ReturnType<typeof updateIdentifier>,
) => {
  switch (action.type) {
    case ActionType.UpdatePII:
      return {
        ...state,
        pii: action.pii,
      };
    case ActionType.UpdateLandlordNames:
      return {
        ...state,
        landlordNames: action.landlordNames,
      };
    case ActionType.UpdateUserInfo:
      return {
        ...state,
        userInfo: action.userInfo,
      };
    case ActionType.UpdateIdentifier:
      return {
        ...state,
        identifier: action.identifier,
      };
    default:
      return state;
  }
};
