/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

interface UploadButtonProps {
  onChange: (e: any) => Promise<void>;
  accept: string;
  children: JSX.Element;
  labelStyle?: React.CSSProperties;
}

export const UploadButton = ({
  onChange,
  accept,
  labelStyle,
  children,
}: UploadButtonProps) => (
  <>
    <label style={labelStyle} htmlFor="contained-button-file">
      {children}
    </label>
    <input
      accept={accept}
      id="contained-button-file"
      multiple
      type="file"
      style={{
        display: 'none',
      }}
      onChange={onChange}
    />
  </>
);
