import {
  AppBar,
  Box,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  SwipeableDrawer,
  Toolbar,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'ui-sdk/src/router';
import React, { useState } from 'react';
import { List as ListIcon, PersonCircle } from 'react-bootstrap-icons';
import { Button, MarbleButton, P } from 'src/base';
import { MarbleBlue, marblebluelight } from 'src/Constants';
import { openExternalLink } from 'src/utils/url';
import { Experiment } from 'src/components/Experiment';
import { MarbleIcon } from 'src/base/outputs/icons';
import { BaseContainer } from '../BaseContainer';

interface TabProps {
  path: string;
  title: string;
  isPrimary?: boolean;
  isHidden?: boolean;
  openWithNewTab?: boolean;
}

interface NavigationProps {
  actions?: TabProps[];
  homePath: string;
  tabs: TabProps[];
  banner?: boolean;
  profile?: TabProps;
}

const Root = styled(Container)(() => ({
  backgroundColor: 'white',
}));

const Tab = styled('div')(() => ({
  margin: 10,
}));

const Navigation: React.FC<NavigationProps> = ({
  actions,
  banner,
  homePath,
  tabs,
  profile,
}: NavigationProps) => {
  const [drawer, setDrawer] = useState(false);
  const router = useRouter();
  const pathName = `${router.pathname}/`;

  const isCurrentPath = (path, pathName) => {
    // path without home path
    const endPath = path.substring(
      path.lastIndexOf(homePath) + homePath.length,
    );
    const endPathName = pathName.substring(
      pathName.indexOf(homePath) + homePath.length,
    );
    return (
      (path === homePath && pathName === homePath) ||
      (pathName !== homePath &&
        endPath.length > 0 &&
        endPathName.startsWith(endPath))
    );
  };

  return (
    <>
      <style>
        {`
        .nav-tab.active {
          border-bottom: 3px solid rgba(64, 123, 255, 0.78);
        }
      `}
      </style>
      <Box
        sx={{
          backgroundColor: marblebluelight,
          padding: 1,
          display: !banner ? 'none' : undefined,
        }}
      >
        <Grid container justifyContent="center">
          <Experiment
            control={
              <MarbleButton
                href="/invest"
                title="Buy and sell rental properties direct with other Marble investors."
                variant="nobg"
                endIcon={<MarbleIcon name="chevron-right" />}
                sx={{
                  textDecoration: 'underline',
                }}
                // target="_blank"
              />
            }
            d={
              (router.pathname.includes('discover') ||
                router.pathname.includes('renter-rewards')) && (
                <div>
                  <MarbleButton
                    href="/renter-rewards"
                    title="Coming soon! Earn cashback on rent payments."
                    variant="nobg"
                    endIcon={<MarbleIcon name="chevron-right" />}
                    sx={{
                      textDecoration: 'underline',
                    }}
                  />
                </div>
              )
            }
          />
        </Grid>
      </Box>
      <BaseContainer wide padding={0}>
        <Root maxWidth={false}>
          <AppBar color="inherit" position="static" elevation={0}>
            <Toolbar
              style={{ padding: 0 }}
              sx={{
                paddingLeft: 0,
                paddingRight: 0,
                justifyContent: 'space-between',
              }}
            >
              <Link href={homePath ?? '/'} passHref>
                <a href={homePath ?? '/'}>
                  <Image
                    src="/img/marble_text_black.png"
                    alt="logo"
                    width={135}
                    height={38}
                    style={{ cursor: 'pointer' }}
                  />
                </a>
              </Link>
              <Box
                sx={{
                  display: {
                    xs: 'none',
                    md: 'flex',
                  },
                }}
              >
                {tabs.map(({ path, title, openWithNewTab }) => (
                  <Tab
                    key={title}
                    sx={{
                      borderBottom: isCurrentPath(path, pathName) ?
                        '3px solid rgba(64, 123, 255, 0.78)' :
                        '',
                    }}
                  >
                    <Button
                      title={title}
                      onClick={() => {
                        if (openWithNewTab) {
                          openExternalLink(path);
                        } else {
                          router.push(path);
                        }
                      }}
                    />
                  </Tab>
                ))}
                {actions
                  ?.filter((curAction) => !curAction.isHidden)
                  .map(({ path, title, isPrimary }) => (
                    <Tab key={title}>
                      <Button
                        title={title}
                        onClick={() => {
                          router.push(path);
                        }}
                        color={isPrimary ? 'primary' : 'secondary'}
                        size="medium"
                      />
                    </Tab>
                  ))}
              </Box>
              {profile && (
                <IconButton
                  sx={{
                    display: {
                      xs: 'none',
                      md: 'flex',
                    },
                  }}
                  onClick={() => {
                    router.push(profile.path);
                  }}
                  size="large"
                >
                  <PersonCircle
                    color={
                      isCurrentPath(profile.path, pathName) ?
                        MarbleBlue :
                        'grey'
                    }
                  />
                </IconButton>
              )}
              <IconButton
                sx={{
                  display: {
                    xs: 'flex',
                    md: 'none',
                  },
                }}
                onClick={() => {
                  setDrawer(true);
                }}
                size="large"
              >
                <ListIcon />
              </IconButton>
              <SwipeableDrawer
                anchor="top"
                open={drawer}
                onClose={() => {
                  setDrawer(false);
                }}
                onOpen={() => {
                  setDrawer(true);
                }}
              >
                <List>
                  <ListItem
                    sx={{
                      justifyContent: 'space-between',
                    }}
                  >
                    <Link href={homePath ?? '/'}>
                      <Image
                        src="/img/marble_text_black.png"
                        alt="logo"
                        width={135}
                        height={38}
                        style={{ cursor: 'pointer' }}
                      />
                    </Link>
                    <IconButton
                      sx={{
                        display: {
                          xs: 'flex',
                          md: 'none',
                        },
                      }}
                      onClick={() => {
                        setDrawer(false);
                      }}
                      size="large"
                    >
                      <ListIcon />
                    </IconButton>
                  </ListItem>
                  {tabs.map(({ path, title, openWithNewTab }) => (
                    <ListItem key={title}>
                      <Button
                        title={
                          <P
                            style={{
                              ...(isCurrentPath(path, pathName) ?
                                { color: MarbleBlue } :
                                { color: 'black' }),
                            }}
                          >
                            {title}
                          </P>
                        }
                        onClick={() => {
                          setDrawer(false);
                          if (openWithNewTab) {
                            openExternalLink(path);
                          } else {
                            router.push(path);
                          }
                        }}
                      />
                    </ListItem>
                  ))}
                  {actions
                    ?.filter((curAction) => !curAction.isHidden)
                    .map(({ path, title, isPrimary }) => (
                      <ListItem key={title}>
                        <Button
                          title={title}
                          onClick={() => {
                            setDrawer(false);
                            router.push(path);
                          }}
                          color={isPrimary ? 'primary' : 'secondary'}
                          size="medium"
                          fullWidth
                        />
                      </ListItem>
                    ))}
                  {profile && (
                    <ListItem>
                      <IconButton
                        sx={{
                          display: {
                            xs: 'flex',
                            md: 'none',
                          },
                        }}
                        onClick={() => {
                          setDrawer(false);
                          router.push(profile.path);
                        }}
                        size="large"
                      >
                        <PersonCircle
                          color={
                            isCurrentPath(profile.path, pathName) ?
                              MarbleBlue :
                              'grey'
                          }
                        />
                      </IconButton>
                      <Button
                        title={
                          <P
                            style={{
                              ...(isCurrentPath(profile.path, pathName) ?
                                { color: MarbleBlue } :
                                { color: 'black' }),
                            }}
                          >
                            Profile
                          </P>
                        }
                        onClick={() => {
                          setDrawer(false);
                          router.push(profile.path);
                        }}
                      />
                    </ListItem>
                  )}
                </List>
              </SwipeableDrawer>
            </Toolbar>
          </AppBar>
        </Root>
      </BaseContainer>
    </>
  );
};

export default Navigation;
