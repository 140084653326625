import { call } from 'ui-sdk/src/call';
import { api } from 'server-sdk/api';
import { track } from 'ui-sdk/src/util/track';

export const personaVerification = async () => {
  // eslint-disable-next-line global-require
  const Persona = require('persona');
  return new Promise((resolve) => {
    const client = new Persona.Client({
      templateId: 'itmpl_k9vk8EVd8LriNuvKxpmybcqJ',
      environment: 'production',
      onReady: () => {
        track('ID Verification Started');
        client.open();
      },
      onComplete: async ({ inquiryId }) => {
        const p = await call(api.identity.verification.persist, {
          inquiryId,
        });
        track('ID Verification Complete');
        if (p) {
          resolve(true);
          console.log('id success');
          return;
        }

        console.log('persist fail');
        resolve(false);
      },
      onCancel: () => {
        console.log('id cancel');
        track('ID Verification Cancelled');
        resolve(false);
      },
      onError: () => {
        console.log('id error');
        track('ID Verification Errored');
        resolve(false);
      },
    });
  });
};
