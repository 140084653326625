import { ApplicationUser } from "./application"
import { Invite } from "./invite";
import { Charge } from "./payment"
import { UserAddress } from "./userAddress"

export interface AuthenticationInfo {
  firstName?: string,
  lastName?: string,
  currentAddressLine1?: string,
  currentAddressLine2?: string,
  currentAddress?: string,
  currentCity?: string,
  currentState?: string,
  currentZip?: string,
  ssn?: string,
  dob?: string,
  ipAddress?: string,
  jscPayload?: string,
  guid?: string,
  landlord?: boolean;
};

export interface Answers {
  authSession?: string,
  answer?: number[],
  guid?: string
  landlord?: boolean;
};

export interface KIQAnswersResponse {
  success?: boolean,
  error?: {
    Code?: string,
    Message?: string,
    Status?: number,
    FieldErrors?: string
  },
  referenceID?: number,
  UserToken?: string
};

export interface ReportPayload {
  productId?: number,
  consumerToken?: string,
  purposeType?: string,
  includeImage?: string
  includeRace?: string
};

export interface ShareReportPayload {
  transactionId?: number,
  consumerToken?: string,
  endUserToken?: string,
  purposeType?: string,
  propertyZip?: string
};

export interface GetRenterReportPayload {
  consumerToken?: string,
  transactionId?: number
};

export interface GetShareReportPayload {
  endUserToken?: string,
  shareId?: number
};


export interface ExpireTokenPayload {
  userToken?: string,
};

export interface ExpireTokenResponse {
  success?: boolean,
  error?: any,
};

export interface GetSharedReportInfo {
  applicationUserId?: number
  guid?: string;
};

export interface GetSharedReportGroupInfo {
  applicationId?: number
  guid?: string;
};
export interface ExperianReports {
  success?: boolean,
  reports?: {
    CreditProfile?: CreditProfile,
    BackgroundData?: BackgroundData
  },
  transactionId?: string,
  shareId?: string,
  error?: {
    Code?: string,
    Message?: string,
    Status?: number,
    FieldErrors?: string
  }
};

export interface GroupReportResponse {
  reports: ReportResponse[];
}
export interface ReportResponse {
  Report?: ExperianReports,
  Error?: ExperianError,
  guid?: string,
  applicationUser?: ApplicationUser
}

export interface PaymentProcessed {
  Success?: boolean,
  Error?: string
}

export interface CreditProfile {
  success?: boolean,
  error?: {
    Code?: string,
    Message?: string,
    Status?: number,
    FieldErrors?: string
  },
  report?: {
    Header?: {
      ReportDate?: string,
      ReportTime?: string,
    },
    ConsumerIdentity?: ConsumerIdentity[],
    EmploymentInformation?: EmploymentInformation[],
    AddressInformation?: AddressInformation[],
    TradeLine?: TradeLine[],
    RiskModel?: RiskModel[],
    PublicRecord?: PublicRecord[],
    Inquiry?: CreditInquiry[],
    Statement?: Statement[],
    Debt?: {
      CreditCards?: TradeLine[],
      Mortgages?: TradeLine[],
      MedicalBills?: TradeLine[],
      AutoLoans?: TradeLine[],
      StudentLoans?: TradeLine[],
      OtherRevolvingLines?: TradeLine[],
      OtherInstallmentLoans?: TradeLine[]
    }
  }
}

export interface Statement {
  DateReported?: string,
  Type?: string,
  StatementText?: string
}

export interface CreditInquiry {
  Date?: string,
  Amount?: string,
  Type?: DescAndCode,
  Terms?: DescAndCode,
  Subcode?: string,
  KOB?: DescAndCode,
  SubscriberDisplayName?: string
}

export interface PublicRecord {
  Status?: DescAndCode,
  StatusDate?: string,
  FilingDate?: string,
  Evaluation?: DescAndCode,
  Amount?: string,
  ConsumerComment?: string,
  Court?: DescAndCode,
  ReferenceNumber?: string,
  PlaintiffName?: string,
  DisputeFlag?: string,
  ECOA?: DescAndCode,
  Bankruptcy?: Bankruptcy,
  BookPageSequence?: string
}

export interface Bankruptcy {
  Type?: DescAndCode,
  AssetAmount?: string,
  LiabilitiesAmount?: string,
  RepaymentPercent?: string,
  AdjustmentPercent?: string,
}

export interface ConsumerIdentity {
  Name?: {
    Type?: {
      desc: any,
      code: any
    }
    Surname?: string,
    First?: string,
    Middle?: string
  }
}

export interface EmploymentInformation {
  FirstReportedDate?: string,
  LastUpdatedDate?: string,
  Origination?: DescAndCode,
  Name?: string,
  AddressFirstLine?: string,
  AddressSecondLine?: string,
  AddressExtraLine?: string,
  Zip?: string
}

export interface AddressInformation {
  FirstReportedDate?: string,
  LastUpdatedDate?: string,
  Origination?: {
    desc?: string,
    code?: string
  },
  TimesReported?: string,
  LastReportingSubcode?: string,
  DwellingType?: {
    desc?: string,
    code?: string
  },
  HomeOwnership?: DescAndCode,
  StreetPrefix?: string,
  StreetName?: string,
  StreetSuffix?: string,
  City?: string,
  State?: string,
  Zip?: string,
  CensusGeoCode?: string,
  CountyCode?: string
}

export interface TradeLine {
  SpecialComment?: DescAndCode,
  Evaluation?: DescAndCode,
  OpenDate?: string,
  StatusDate?: string,
  MaxDelinquencyDate?: string,
  AccountType?: DescAndCode,
  TermsDuration?: DescAndCode,
  ECOA?: DescAndCode,
  Amount?: TradeLineAmount[],
  BalanceDate?: string,
  BalanceAmount?: string,
  Status?: DescAndCode,
  AmountPastDue?: string,
  OpenOrClosed?: DescAndCode,
  RevolvingOrInstallment?: DescAndCode,
  ConsumerComment?: string,
  AccountNumber?: string,
  MonthsHistory?: string,
  DelinquenciesOver30Days?: string,
  DelinquenciesOver60Days?: string,
  DelinquenciesOver90Days?: string,
  DerogCounter?: string,
  PaymentProfile?: string,
  MonthlyPaymentAmount?: string,
  MonthlyPaymentType?: string,
  LastPaymentDate?: string,
  Subcode?: string,
  KOB?: DescAndCode,
  SubscriberDisplayName?: string,
  EnhancedPaymentData?: EnhancedPaymentData,
  MaxPayment?: DescAndCode,
  FirstDelinquencyDate?: string,
  SecondDelinquencyDate?: string,
}

export interface EnhancedPaymentData {
  InitialPaymentLevelDate: string,
  AccountCondition: DescAndCode,
  PaymentStatus: DescAndCode,
  AccountType: DescAndCode,
  SpecialComment: DescAndCode
}

export interface TradeLineAmount {
  Qualifier?: DescAndCode
  Value?: string
}

export interface RiskModel {
  positiveScoreFactorCodeOne?: string,
  positiveScoreFactorCodeTwo?: string,
  positiveScoreFactorCodeThree?: string,
  positiveScoreFactorCodeFour?: string,
  ModelIndicator?: DescAndCode,
  Score?: string,
  ScoreFactorCodeOne?: string,
  ScoreFactorCodeTwo?: string,
  ScoreFactorCodeThree?: string,
  ScoreFactorCodeFour?: string,
  ScoreFactorCodeFive?: string,
  Evaluation?: DescAndCode,
}

export interface BackgroundData {
  success?: boolean,
  error?: {
    Code?: string,
    Message?: string,
    Status?: number,
    FieldErrors?: string
  },
  report?: {
    criminalReport?: CriminalReport,
    evictionReport?: EvictionReport
  }
}

export interface CriminalReport {
  success?: boolean,
  response?: {
    XMLCRIM?: {
      OrderResponse?: {
        Name?: string,
        Message?: string,
        ResponseID?: string
      },
      CICcriminal?: {
        Candidates?: {
          count?: string,
          Message?: string,
          Candidate?: CriminalCandidate | CriminalCandidate[]
        }
      }
    }
  }
}

export interface CriminalCandidate {
  Subject?: {
    FullName?: string,
    DOB?: string,
    Category?: string,
    Source?: string,
    Sex?: string,
    Race?: string,
    DriversLicense?: string,
    CaseNumber?: string,
    State?: string,
    Jurisdiction?: string,
    Address?: string,
  },
  Offenses?: {
    count?: string,
    Offense?: CriminalOffense | CriminalOffense[]
  }
}

export interface CriminalOffense {
  CaseType?: string,
  Description?: string,
  FilingDate?: string,
  Disposition?: string,
  DispositionDate?: string,
  Court?: string,
  Statute?: string,
  ConvictionDate?: string,
  ConvictionLocation?: string
}

export interface EvictionReport {
  success?: boolean,
  response?: {
    XML2025?: {
      OrderResponse?: {
        Name?: string,
        Reference?: string,
        Message?: string,
        ResponseID?: string
      },
      Response?: {
        Count?: string,
        'Count_West-4'?: string,
        'Count_National-1'?: string,
        Candidate?: EvictionCandidate | EvictionCandidate[]
      }
    }
  }
}

export interface EvictionCandidate {
  Zone?: string,
  LastName?: string,
  FirstName?: string,
  Address?: string,
  City?: string,
  State?: string,
  ZipCode?: string,
  Activity?: Activity
}

export interface Activity {
  Court?: string,
  Filing_Date?: string,
  Case_No?: string,
  Plaintiff?: string,
  Plaintiff_Phone?: string,
  Notice_Type?: string,
  Default?: string,
  Plaintiff_Atty?: string,
  Plaintiff_Atty_Phone?: string,
  Judgement_Date?: string,
  Judgement_Amount?: string
}

export interface Question {
  questionText?: string,
  questionSelect?: {
    questionChoice?: string[]
  }
};

export interface PassiveAuthResponse {
  AuthSession?: string,
  QuestionSet?: Question[],
  UserAuthenticated?: boolean,
  UserToken?: string,
  Error?: ExperianError
};

export interface GetUserQuestionsResponse {
  AuthSession?: string,
  QuestionSet?: Question[],
  Error?: ExperianError
};

export interface GetLandlordQuestionsResponse {
  AuthSession?: string,
  QuestionSet?: Question[],
  Error?: ExperianError
};

export interface GetUserTokenAuthStatus {
  success?: boolean,
  error?: any,
  authenticated?: boolean,
  type?: string,
  expiration?: string
};

export const ExperianError_WrongAnswer = '2502';
export const ExperianError_AuthSessionExpired = '2500';

export interface ExperianError {
  Code?: string,
  Message?: string,
  Status?: number,
  ErrorType?: string,
  FieldErrors?: string
}

export interface AnswerQuestionsResponse {
  UserAuthenticated?: boolean,
  UserToken?: string,
  Error?: ExperianError
};

export interface AnswerUserQuestionsResponse {
  UserAuthenticated?: boolean,
  Error?: ExperianError
};

export interface AnswerLandlordQuestionsResponse {
  UserAuthenticated?: boolean,
  Error?: ExperianError
};

export interface AuthenticateResponse {
  AuthSession?: string,
  QuestionSet?: Question[],
  UserAuthenticated?: boolean,
  Error?: ExperianError
};

export interface ApplicationPaymentInfo {
  token?: string;
  idempotencyKey?: string;
  applicationUserId?: number;
  propertyId?: number;
  guid?: string;
};

export interface ReportPrice {
  propertyId?: number;
  charge?: Charge;
};

export interface UserInfo {
  guid?: string;
  authenticated?: boolean;
  kiqFailedCount?: number;
  applications?: ApplicationUser[];
  address?: UserAddress;
  caller?: string;
  applicationInvites?: Invite[];
};

export interface DescAndCode {
  desc?: string,
  code?: string
};

export const GetErrorMessage = (error: ExperianError) => {
  let errorMessage;
  if (error.Code) {
    errorMessage = GetErrorFromCode(error.Code);
  } else {
    errorMessage =
      'Encountered an error when communicating with Experian. Please try again later.';
    if (error.Status) {
      errorMessage = `${errorMessage} Status: ${error.Status}`;
    }
    if (error.Message) {
      errorMessage = `${errorMessage} Message: ${error.Message}`;
    }
  }

  return errorMessage;
}

const GetErrorFromCode = (code: string) => {
  switch (code) {
    case ExperianError_AuthSessionExpired: {
      return `Authentication Session has expired. Please be sure to respond
      within 5 minutes. Please try again.`;
    }
    case ExperianError_WrongAnswer: {
      return `Your previous response did not match what we have on record. 
      Please try again. If you believe your responses were correct, 
      please contact Experian to dispute your data.`;
    }
    default: {
      const message = `Encountered an error when communicating with Experian. Please try again later. Code: ${code}.`;
      console.error(message);
      return message;
    }
  }
};