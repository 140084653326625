import { DocumentFileType } from "./documentFiles";
import { IncomeDocument } from "./incomeDocument";
import { Invite } from "./invite";
import { Property } from "./property";
import { Report } from "./report";
import { SharedReport } from "./sharedReport";
import { UserAddress } from "./userAddress";

export interface Application {
  id?: number;
  creationTime?: number;
  property?: Property;
  applicants?: ApplicationUser[];
  invites?: string[];
  invitationDetails?: Invite[];
  status?: ApplicationStatus;
  driveUrl?: string;
  type?: ApplicationType;
  marbleHostedApplication?: boolean;
  isMobile?: boolean;
  leaseInfo?: LeaseInfo;
  isTest?: boolean;
}

export interface ApplicationUser {
  id?: number;
  applicationId?: number;
  userId?: number;
  url?: string;
  sk_pure_api_url?: string;
  name?: string;
  email?: string;
  phone?: string;
  skUrl?: string;
  chargeId?: number;
  propertyId?: number;
  report?: Report;
  sharedReport?: SharedReport;
  incomeDocuments?: IncomeDocument[];
  type?: ApplicationType;
  has_pets?: boolean;
  pets?: {
    pets?: Pet[]
  };
  has_other_occupants?: boolean;
  other_occupants?: {
    other_occupants?: Occupants[]
  };
  company?: string;
  job_title?: string;
  annual_income?: number;
  is_section_8?: boolean;
  section_8_voucher_amount?: number;
  move_in_date?: number;
  rental_histories?: RentalHistory[],
  landlord_references?: LandlordReference[],
  landlord_guid?: string;
  enable_resubmit?: boolean;
  guid?: string;
  date_of_birth?: string;
}

export interface RentalHistory {
  user_address?: UserAddress,
  landlord_lead?: LandlordLead
}

export interface LandlordLead {
  name?: string;
  email?: string;
  phone?: string;
}

export interface LandlordReference {
  id?: number;
  applicationUserId?: number;
  landlordLeadId?: number;
  guid?: string;
  applicantName?: string;
  landlordName?: string;
  overall?: number;
  pays_rent_on_time?: number;
  cleanliness?: number;
  did_not_damage_property?: number;
  additional_comments?: string;
  emails_sent?: number;
  landlord_lead?: LandlordLead;
  status?: LandlordReferenceStatus;
  address?: UserAddress;
}

export enum LandlordReferenceStatus {
  Sent = "Sent",
  Submitted = "Submitted"
}

export enum ApplicationType {
  SingleKey = 'SingleKey', // legacy single key hosted flow
  Experian = 'Experian',
  SingleKey_PureApi = 'SingleKey_PureApi' // new form hosted by marble
}

export enum ApplicationStatus {
  Started, // Renter has started
  Ready,
  Approved, // Owner has Approved
  Rejected, // Owner has rejected
  Accepted,
  Denied,
  LeaseCreated
}

const Reseller = 'Marble Living, Inc.';
export const Experian = 'Experian';
export const CIC = 'CIC';
export const SingleKey = 'Single Key';
export const getRenterConsent_FCA = (creditScoreSource: string, backgroundCheckSource: string) => {
  return `You understand that by clicking on the 'I agree to these terms' button
    immediately following this notice, you are providing “written instructions”
    to ${Reseller} under the Fair Credit Reporting Act authorizing ${Reseller} to
    obtain information from your personal credit report from ${creditScoreSource} and
    background check from ${backgroundCheckSource}. You authorize ${Reseller} to obtain such information to
    confirm your identity, access and share your credit report, 
    Vantage Score credit score, and background check with an end user whom you have 
    authorized to view your report and score for tenant screening purposes.`
}

export const getRenterConsent_ShareWithLandlord = (landlordName: string) => {
  return `You understand that by clicking on the 'I agree to these terms' button
  immediately following this notice, you are providing “written instructions”
  to ${Reseller} under the Fair Credit Reporting Act authorizing ${Reseller} 
  to access and share your credit report, Vantage Score credit score, and background 
  check with the end user, ${landlordName}.`
}

export const getLandlordConsent = (source: string) => {
  return `You understand that by clicking on the 'I agree to these terms' button
  immediately following this notice, you are providing “written instructions”
  to ${Reseller} under the Fair Credit Reporting Act authorizing ${Reseller} to
  obtain information from your personal credit report or other information
  from ${source}. You authorize ${Reseller} to obtain such information solely to
  confirm your identity, for tenant screening purposes.`;
}

export const isApplicationUserSubmitted = (applicationUser: ApplicationUser) => {
  if (applicationUser?.type === ApplicationType.SingleKey_PureApi) {
    // Single Key is 'submitted' after they finish paying. We might need to wait a bit for the report link to generate
    return applicationUser?.chargeId && !applicationUser?.enable_resubmit;
  } else if (applicationUser?.type === ApplicationType.Experian) {
    return applicationUser?.sharedReport?.enabled;
  // legacy SingleKey embedded applications
  } else {
    return applicationUser?.url;
  }
}

export const isApplicationUserReady = (applicationUser: ApplicationUser) => {
  if (applicationUser?.type === ApplicationType.SingleKey_PureApi) {
    // Single Key could have a report generated, even if they haven't paid yet.
    // must verify that both a report link is generated AND they paid
    return applicationUser?.chargeId && applicationUser?.sk_pure_api_url;
  } else if (applicationUser?.type === ApplicationType.Experian) {
    return applicationUser?.sharedReport?.enabled;
  // legacy SingleKey embedded applications
  } else {
    return applicationUser?.url;
  }
}

export const getApplicantNames = (application: Application) => {
  const applicants = application?.applicants;
  let applicantStr = '';
  if (applicants && applicants.length > 0) {
    applicantStr = `${applicants[0].name?.split(' ', 1)[0]}`;
    if (applicants.length > 2) {
      applicantStr += `, ${applicants[1].name?.split(' ', 1)[0]}, and ${
        applicants.length - 2
      } other${applicants.length > 3 ? 's' : ''}`;
    } else if (applicants.length === 2) {
      applicantStr += ` and ${applicants[1].name?.split(' ', 1)[0]}`;
    }
  }
  return applicantStr;
}

export interface Pet {
  type: string;
  other?: string;
}

export interface Occupants {
  name: string;
  age: number;
  relationship: string;
}

export enum LeaseSigningType {
  Individual,
  Business,
}

export interface LeaseInfo {
  id?: number;
  signing_type?: LeaseSigningType;
  individual_legal_name?: string;
  individual_title?: string;
  business_name?: string;
  addr1?: string;
  addr2?: string;
  city?: string;
  state?: string;
  zip?: string;

  is_furnished?: boolean;
  parking_fee?: number;
  needs_lead_paint_warning?: boolean;
  key_location?: string;
  general_details?: string;
  terms_conditions?: string;
  has_additional_clauses?: boolean;
  additional_clauses?: object;

  // fields for lease state questions
  allow_pass_through_tax?: boolean;
  is_property_higher_3_floors?: boolean;
  has_property_flooded_3_times?: boolean;
  does_property_have_bed_bugs?: boolean;
  has_property_flooded_1_time?: boolean;
  is_property_in_100_year_floodplain?: boolean;
  has_property_produced_meth?: boolean;
  does_property_have_mold?: boolean;
  has_defective_drywall?: boolean;
  is_adjacent_air_base?: boolean;
  is_single_family?: boolean;
  is_property_on_3_floor_higher?: boolean;
  was_radon_found?: boolean;
  does_property_have_more_than_2_units?: boolean;
  is_property_flood_zone?: boolean;
  has_bed_bugs_within_last_year?: boolean;
  bed_bugs_floor?: number;
  bed_bugs_eradicated?: boolean;
  is_property_more_than_4_units?: boolean;
  is_property_in_urban_growth_boundary?: boolean;

  lease_review?: object;
  rent_due_day?: number;
  start_lease?: number;
  end_lease?: number;
  review_property?: boolean;
  landlord_guid?: string;

  move_in_instructions?: string;
  water_company?: string;
  electric_company?: string;
  gas_company?: string;
  garbage_sewage_company?: string;

  addresses?: LeaseInfoAddress[];
}

export interface LeaseInfoAddress {
  id?: number;
  individual_legal_name?: string;
  individual_email?: string;
  addr1?: string;
  addr2?: string;
  city?: string;
  state?: string;
  zip?: string;
  lease_info_id?: number;
}

export interface ValidateLeaseGuid {
  id?: number;
  guid?: string;
  error?: string;
}