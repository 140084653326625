import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { call } from '../call';
import { api } from 'server-sdk/src/api';
import { Application, Lock } from 'server-sdk/src/types';
import { useGlobal } from './global';
import { useReduxState } from './useReduxState';
import {
  updateApplications,
  updateInvitations,
} from '../ducks/discover';
import { refreshApplications } from '../ducks/portfolio';

const blah: Lock = {}

export const useApplication = () => {
  const dispatch = useDispatch();
  const apps = useReduxState((state) => state.portfolio.applications);
  const { error, success } = useGlobal();
  const refreshApps = () => {
    dispatch(refreshApplications());
  };

  useEffect(() => {
    refreshApps();
  }, []);

  const reject = (app: Application) => {
    dispatch(async () => {
      const a = await call(api.application.reject, app, {
        timeout: 200000,
      });
      if (a) {
        refreshApps();
        success('Application has been rejected');
      } else {
        error('Failed to reject application. Please try again later');
      }
    });
  };

  const approve = (app: Application) => {
    dispatch(async () => {
      try {
        const a = await call(api.application.approve, app);
        if (a) {
          refreshApps();
        } else {
          error(`Failed to approve applicant. Please try again later.`);
        }
      } catch (e) {
        error(`Failed to approve applicant. Please try again later.`);
      }
    });
  };

  const redoLease = (app: Application) => {
    dispatch(async () => {
      try {
        await call(api.docusign.redoEnvelope, {
          application_id: app.id,
        });
      } catch (e) {
        error(`Failed to redo lease. Please try again later.`);
      }
    });
  };

  const accept = (invitation: Application) => {
    dispatch(async () => {
      try {
        invitation.marbleHostedApplication = true;
        const a = await call(api.application.invite.accept, invitation);
        if (a) {
          const applications = await call(api.application.list.renter);
          dispatch(updateApplications(applications));
          const invitations = await call(api.application.list.invites);
          dispatch(updateInvitations(invitations));
        } else {
          error(`Failed to accept invitation. Please try again later.`);
        }
      } catch (e) {
        error(`Failed to accept invitation. Please try again later.`);
      }
    });
  };

  const decline = (invitation: Application) => {
    dispatch(async () => {
      try {
        const a = await call(api.application.invite.decline, invitation);
        if (a) {
          const applications = await call(api.application.list.renter);
          dispatch(updateApplications(applications));
          const invitations = await call(api.application.list.invites);
          dispatch(updateInvitations(invitations));
        } else {
          error(`Failed to decline invitation. Please try again later.`);
        }
      } catch (e) {
        error(`Failed to decline invitation. Please try again later.`);
      }
    });
  };

  return {
    accept,
    approve,
    apps,
    decline,
    refreshApps,
    reject,
    redoLease,
  };
};
