import { Property } from "./property";
import { User } from "./user";

export interface Message {
  id?: number;
  chatId?: number;
  text?: string;
  image?: string;
  video?: string;
  content_type?: number;
  user?: User;
  creationTime?: number;
  isSentByMe?: boolean;
}

export type InboxItem = Chat | FormChat;

export interface Chat {
  id?: number;
  name?: string;
  secondaryName?: string;
  withUsers?: User[];
  lastSeen?: number;
  timeSince?: string;
  messages?: Message[];
  creationTime?: number;
  modificationTime?: number;
  type?: 'chat'
  chatType?: ChatType;
  filterTypes?: FilterType[];
}

export interface TextSMSMessage {
  text?: string;
  creationTime?: number;
  from?: string
  to?: string;
  status?: string;
}

export interface EmailMessage {
  id?: number;
  text?: string;
  creationTime?: number;
  from?: string;
  to?: string;
  status?: string;
  attachments?: string[];
}

export interface TextSMSConversation {
  messages?: TextSMSMessage[]
  with?: string
  lastSeen?: number;
  replySuggestions?: string[];
}

export interface LeasingSMS {
  messages?: TextSMSConversation[]
  page?: number
};

export interface FormChat {
  id?: number;
  name?: string;
  preview?: string;
  modificationTime?: number;
  timeSince?: string;
  url?: string;
  type?: 'form' | 'marble_form';
  formType?: string;
  alertTitle?: string;
  alertDescription?: string;
}

export interface Inbox {
  messages?: Chat[]
  timeSince?: string;
  forms?: FormChat[]
  page?: number
};

export interface LeasingInbox {
  messages?: Chat[]
  page?: number
};

export enum ChatType {
  MC,
  Maintenance,
  TextMessage,
  Email,
  OpsTask,
}

export interface Email {
  id?: number;
  email?: string;
  property_id?: number;
  property?: Property;
  creationTime?: number;
}

export interface EmailChat {
  id?: number;
  text?: string;
}

export interface EmailMessage {
  id?: number;
  conversationId: number;
  message?: string;
  text?: string;
  content_type?: number;
  email?: Email;
  messageStatus?: string;
  creationTime?: number;
  isSentByMe?: boolean;
  user?: {
    id?: string,
    firstName?: string;
    lastName?: string;
  }
}

export enum FilterType {
  Support,
  All,
  Unread,
  Maintenance,
  Leasing
}
