import { Charge, ChargeType, ItemType, SubCharge } from "server-sdk/src/types";
import { FRIDAY_UNO, HANDYMAN_PRICE, IGLOO_DEADBOLT_PRICE, IGLOO_KEYBOX_PRICE } from "../constants";

const SALES_TAX = 0.1;
export const getShipmentChargeDetails = (quantity: number, item: ItemType | undefined, handyman?: boolean, shipping_fee?: number): Charge => {
    const baseLockPrice = item === ItemType.FridayUnoLock ? FRIDAY_UNO * quantity : (item === ItemType.IglooKeybox3E) ? IGLOO_KEYBOX_PRICE * quantity : IGLOO_DEADBOLT_PRICE * quantity;
    const tax = SALES_TAX * (baseLockPrice + (handyman ? HANDYMAN_PRICE : 0));
    const shipping = shipping_fee ?? 0;
    const total = baseLockPrice + tax + shipping + (handyman ? HANDYMAN_PRICE : 0);
    const breakdown : SubCharge[] = [
      {
        name: 'Sales Tax',
        value: tax,
      },
      {
        name: 'Shipping & Handling',
        value: shipping,
      },
    ];
  
    if(handyman){
      breakdown.unshift({name: "Installation service", value: HANDYMAN_PRICE})
    }
  
    breakdown.unshift({
      name: `${quantity} ${item === ItemType.FridayUnoLock ? 'Friday Uno' : item === ItemType.IglooKeybox3E ? 'Igloo Keybox 3E' : 'Igloo Deadbolt 2E'} Lock ${quantity == 1 ? '' : '(s)'}`,
      value: baseLockPrice,
    }) 
    return {
      amount: total,
      breakdown: breakdown,
      type: ChargeType.Shipment,
    };
  }