import { InputBaseComponentProps } from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';
import React from 'react';
import NumberFormat from 'react-number-format';
import { InputProps } from 'src/base/inputs/props';

interface TextInputProps {
  autoFocus?: boolean;
  required?: boolean;
  type?: string;
  mask?: 'phone' | 'otp' | 'usd';
  focused?: boolean;
  rows?: number;
  multiline?: boolean;
  inputRef?: React.Ref<any>;
  inputId?: string;
  inputProps?: any;
}

const PhoneMask: React.FC<InputBaseComponentProps> = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    // @ts-ignore
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            // @ts-ignore
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="+1 (###) ###-####"
      style={{
        outline: 'none',
        border: 0,
      }}
    />
  );
};

const CodeMask: React.FC<InputBaseComponentProps> = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    // @ts-ignore
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            // @ts-ignore
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="###-###"
      style={{
        outline: 'none',
        border: 0,
      }}
    />
  );
};

const UsdMask: React.FC<InputBaseComponentProps> = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    // @ts-ignore
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            // @ts-ignore
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandsGroupStyle="thousand"
      fixedDecimalScale
      allowNegative={false}
      decimalScale={0}
      style={{
        outline: 'none',
        border: 0,
      }}
    />
  );
};

export const TextInput: React.FC<TextInputProps & InputProps> = ({
  autoFocus,
  error,
  focused,
  inputRef,
  mask,
  rows,
  multiline,
  name,
  onBlur,
  onChange,
  placeholder,
  required,
  type,
  value,
  inputId,
  inputProps,
}) => (
  <TextField
    fullWidth
    id={inputId}
    inputRef={inputRef}
    autoFocus={autoFocus}
    error={Boolean(error)}
    focused={focused}
    helperText={error}
    multiline={multiline}
    name={name}
    label={placeholder}
    placeholder={placeholder}
    onBlur={onBlur}
    onChange={onChange}
    required={required}
    rows={rows}
    type={type}
    value={value}
    InputLabelProps={{ shrink: value !== undefined && value !== null }}
    {...(mask === 'phone' && {
      InputProps: {
        ...inputProps,
        inputComponent: PhoneMask,
      },
    })}
    {...(mask === 'otp' && {
      InputProps: {
        ...inputProps,
        inputComponent: CodeMask,
      },
    })}
    {...(mask === 'usd' && {
      InputProps: {
        ...inputProps,
        inputComponent: UsdMask,
      },
    })}
  />
);
