import { PropertyUserReservation } from "./reservation";
import { TextPhone } from "./text"
import { RentPayment } from "./payment";
import { Tour } from "./tour";
import { User } from "./user";

export enum PropertyType {
  Other = 0,
  House = 1,
  TownHome = 2,
  Condo = 3,
}

export enum LaundryType {
  None = 0,
  InUnit = 1,
  Shared = 2,
}

export enum PropertySubscription {
  None = 0,
  Legacy,
  Subscribed,
}

export enum UtilityAssignment {
  NA = 0,
  Owner = 1,
  Renter = 2,
}

export interface PropertyPhoto {
  id?: number;
  src?: string;
  sortKey?: number;
  creationTime?: number;
}

export type ParkingType = 'carport' | 'garage attached' | 'off street' | 'on street' | 'none';

export interface Property {
  id?: number;

  address?: Address;
  prop_type?: PropertyType;

  photos?: string[];
  photosTyped?: PropertyPhoto[];

  photosDb?: any[];
  lease_files?: any[];

  nickname?: string;
  allow_dogs?: boolean;
  allow_cats?: boolean;
  description?: string;
  access_instructions?: string;
  bedrooms?: number;
  bathrooms?: number;
  sq_footage?: number;
  listing_price?: number;
  security_deposit?: number;
  listing_status?: ListingStatus;
  subscription?: PropertySubscription;
  direct_tour?: boolean;
  direct_tour_text_override?: string;

  // New Late Fees
  late_fees?: LateFeesData;

  rented?: boolean;
  lease_days_remaining?: number;
  application_accepted?: boolean;

  lock_id?: number;

  laundry_type?: LaundryType;

  // DEPRECATED
  garage_parking?: boolean;
  offstreet_parking?: boolean;

  // New Appliances
  appliances?: AppliancesData;

  // New Parking fees
  parking_count?: number;
  parking_type?: ParkingType;
  parking_fee?: number;

  dog_deposit?: number;
  dog_fee?: number;

  cat_deposit?: number;
  cat_fee?: number;

  garbage_sewage_utilities?: UtilityAssignment;
  water_utilities?: UtilityAssignment;
  electric_utilities?: UtilityAssignment;
  gas_utilities?: UtilityAssignment;

  num_inquiries?: number;
  num_scheduled_tours?: number;
  num_completed_tours?: number;
  isLeasing?: boolean;

  landlord_user_id?: number;
  is_onboarded?: boolean;

  existing_renter?: boolean;
  lock_ordered?: boolean;
  availability_date?: number;

  renters?: User[];
  current_renters?: User[];
  past_renters?: User[];
  tours?: Tour[];
  propertyUserReservations?: PropertyUserReservation[];
  leasing_phone_id?: number;
  leasing_phone?: TextPhone;
  prepaid_listing_active?: boolean;
  landlord_phone?: string;
  landlord_email?: string;
  landlord_name?: string;
  error?: string;
  last_change_review_time?: number;
  is_property_trialing?: boolean;
  is_pending_review?: boolean;
  tour_start_time?: number;
  key_handoff?: PropertyKeyHandoff;
  setup_deduct_consent?: boolean;
  // listing start flow
  disabled?: boolean;
  disabled_reason?: string;

  unregistered_renters?: UnregisteredRenter[];
}

export enum PropertyKeyHandoff {
  LockboxDropOff = 'marble-lockbox', // drop off keys in lockbox prepared by marble
  Ship = 'ship-keys',
}

export interface InviteProperty {
  invitationEmail?: string;
  invitationPhone?: string;
  property?: Property;
}

export interface PropertyRentEstimate {
  propertyId?: number;

  mean: number;
  median: number;
  min: number;
  max: number;
  percentile_25: number;
  percentile_75: number;
  std_dev: number;
  samples: number;
  radius_miles: number;
}

export interface UpdateCard {
  creationToken?: string
  success?: boolean
  error?: string
}

export interface SubscribeProperty {
  propertyId?: number
  creationToken?: string
  error?: string
}

export interface SubscribeTransactional {
  propertyId?: number

  error?: string
}

export interface UnsubscribeTransactional {
  propertyId?: number

  error?: string
}

export interface UnsubscribeProperty {
  propertyId?: number
}

export enum ListingStatus {
  Off,
  Waitlist,
  Touring,
  ManualTouring
}

export interface Address {
  addr1: string;
  addr2?: string;
  city?: string;
  state?: string;
  zip?: string;
}

export interface Demo {
  properties?: Property[];
  payments?: RentPayment[];
  tours?: Tour[];
}

export enum LeasingStep {
  NotStarted,
  Waitlist,
  Touring,
  Screening,
  Signing,
  MoveIn,
}

export interface LeasingData {
  propertyId?: number;
  step?: LeasingStep;
}

export const matchPropertyQuery = (property: Property, query: string): boolean => {
  if (!query || query === '') {
    return true;
  }

  const normalize = (s: string | undefined) => {
    if (!s || s === '') {
      return '';
    }

    let normed = s;
    normed = normed.toLowerCase();

    return normed;
  };

  const queryNormalized = normalize(query);

  const queryableFields = [
    property.address?.addr1,
    property.address?.addr2,
    property.address?.city,
    property.address?.state,
    property.address?.zip,
  ];

  for (let f of queryableFields) {
    const fNormalized = normalize(f);
    if (fNormalized.includes(queryNormalized)) {
      return true;
    }
  }

  return false;
}

export const streetAddress = (addr1: string, addr2: string | undefined): string => {
  return `${addr1}${addr2 ? ` ${addr2}` : ''}`;
}

export const fullAddressString = (address: Address): string => {
  return `${streetAddress(address?.addr1, address?.addr2)}, ${address?.city}, ${address?.state} ${address?.zip}`;
}

export interface LateFeesData {
  one_time?: {
    absolute?: number | undefined;
    percentage?: number | undefined;
  };
  recurring?: {
    initial_fee: number | undefined;
    recurring_fee: number | undefined;
  };
  days: number | undefined;
}

export enum Appliances {
  HEATER = 'heater',
  AIRCONDITIONER = 'air_conditioner',
  WASHER = 'washer',
  DRYER = 'dryer',
  DISHWASHER = 'dishwasher',
  REFRIGERATOR = 'refrigerator',
  FREEZER = 'freezer',
  STOVE = 'stove',
  OVEN = 'oven',
  MICROWAVE = 'microwave',
  TELEVISION = 'television',
  SOLARPANELS = 'solar_panels',
}

export interface AppliancesData {
  [Appliances.HEATER]?: boolean
  [Appliances.AIRCONDITIONER]?: boolean
  [Appliances.WASHER]?: boolean
  [Appliances.DRYER]?: boolean
  [Appliances.DISHWASHER]?: boolean
  [Appliances.REFRIGERATOR]?: boolean
  [Appliances.FREEZER]?: boolean
  [Appliances.STOVE]?: boolean
  [Appliances.OVEN]?: boolean
  [Appliances.MICROWAVE]?: boolean
  [Appliances.TELEVISION]?: boolean
  [Appliances.SOLARPANELS]?: boolean
}

export const appliancesField = {
  [Appliances.HEATER]: 'Heater',
  [Appliances.AIRCONDITIONER]: 'Air Conditioner',
  [Appliances.WASHER]: 'Washer',
  [Appliances.DRYER]: 'Dryer',
  [Appliances.DISHWASHER]: 'Dishwasher',
  [Appliances.REFRIGERATOR]: 'Refrigerator',
  [Appliances.FREEZER]: 'Freezer',
  [Appliances.STOVE]: 'Stove',
  [Appliances.OVEN]: 'Oven',
  [Appliances.MICROWAVE]: 'Microwave',
  [Appliances.TELEVISION]: 'Television',
  [Appliances.SOLARPANELS]: 'Solar Panels',
};

export const emptyAppliances: AppliancesData = {
  [Appliances.HEATER]: false,
  [Appliances.AIRCONDITIONER]: false,
  [Appliances.WASHER]: false,
  [Appliances.DRYER]: false,
  [Appliances.DISHWASHER]: false,
  [Appliances.REFRIGERATOR]: false,
  [Appliances.FREEZER]: false,
  [Appliances.STOVE]: false,
  [Appliances.OVEN]: false,
  [Appliances.MICROWAVE]: false,
  [Appliances.TELEVISION]: false,
  [Appliances.SOLARPANELS]: false
};

export const webAppliancesIcon = {
  [Appliances.HEATER]: 'LocalFireDepartmentOutlined',
  [Appliances.AIRCONDITIONER]: 'AirOutlined',
  [Appliances.WASHER]: 'LocalLaundryServiceOutlined',
  [Appliances.DRYER]: 'DryCleaningOutlined',
  [Appliances.DISHWASHER]: 'RestaurantOutlined',
  [Appliances.REFRIGERATOR]: 'KitchenOutlined',
  [Appliances.FREEZER]: 'AcUnitOutlined',
  [Appliances.STOVE]: 'HvacOutlined',
  [Appliances.OVEN]: 'FireplaceOutlined',
  [Appliances.MICROWAVE]: 'MicrowaveOutlined',
  [Appliances.TELEVISION]: 'TvOutlined',
  [Appliances.SOLARPANELS]: 'SolarPowerOutlined',
}

export const mobileAppliancesIcon = {
  [Appliances.HEATER]: {
    name: 'fire',
    type: 'font-awesome-5'
  },
  [Appliances.AIRCONDITIONER]: {
    name: 'ac-unit',
    type: 'material-icons'
  },
  [Appliances.WASHER]: {
    name: 'local-laundry-service',
    type: 'material-icons'
  },
  [Appliances.DRYER]: {
    name: 'dry-cleaning',
    type: 'material-icons'
  },
  [Appliances.DISHWASHER]: {
    name: 'dishwasher',
    type: 'material-community'
  },
  [Appliances.REFRIGERATOR]: {
    name: 'fridge',
    type: 'material-community'
  },
  [Appliances.FREEZER]: {
    name: 'ice-cream',
    type: 'material-community'
  },
  [Appliances.STOVE]: {
    name: 'stove',
    type: 'material-community'
  },
  [Appliances.OVEN]: {
    name: 'fireplace',
    type: 'material-icons'
  },
  [Appliances.MICROWAVE]: {
    name: 'microwave',
    type: 'material-community'
  },
  [Appliances.TELEVISION]: {
    name: 'television',
    type: 'material-community'
  },
  [Appliances.SOLARPANELS]: {
    name: 'solar-panel',
    type: 'material-community'
  },
}

export interface LeasingReport {
  property?: Property;
  property_id?: number;
  reports: LeasingReportWeek[];
}

export interface LeasingReportWeek {
  startDate?: number;
  numInquiries?: number;
  numScheduledTours?: number;
  numCompletedTours?: number;
  numApplications?: number;
}

export interface UnregisteredRenter {
  id?: number;
  landlord_user_id?: number;
  renter_email?: string;
  renter_phone?: string;
  first_name?: string;
  last_name?: string;
  base_rent?: number;
  security_deposit?: number;
  code?: string;
  creation_time?: number;
  property_id?: number;
  non_standard_fee?: number;
  rent_due_day?: number;

  creation_source?: string;

  start_lease?: number;
  end_lease?: number;
}
