import React from 'react';
import { ABVariant, AB_COOKIE } from 'server-sdk/src/types';
import { useCookie } from 'src/utils/useCookie';
import { ExperimentProps } from 'ui-sdk/src/props/experiment';

export const Experiment = ({
  control,
  b,
  c,
  d,
  e,
}: // eslint-disable-next-line no-undef
ExperimentProps): React.ReactElement => {
  const variant = useCookie(AB_COOKIE) as ABVariant;

  switch (variant) {
    case 'b':
      if (b) {
        return b;
      }
      break;
    case 'c':
      if (c) {
        return c;
      }
      break;
    case 'd':
      if (d) {
        return d;
      }
      break;
    case 'e':
      if (e) {
        return e;
      }
      break;
    default:
      break;
  }
  return control;
};
