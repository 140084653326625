import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { call } from '../call';
import { api } from 'server-sdk/src/api';
import {
  ChangeIdentity,
  Login,
  Reservation,
  TenantLead,
  TenantLeadSource,
  User,
  UserType,
} from 'server-sdk/src/types';
import { useGlobal } from './global';
import { useForm } from './useForm';
import { useReduxState } from './useReduxState';
import { updateUser } from '../ducks/identity';
import {
  changeEmail,
  changePhoneNumber,
  login,
  signUp,
} from '../thunks/identity';
import { RootState } from '../store';
import {
  validateNumber,
  validateObject,
  validateString,
  validateUserExists,
} from '../util/validate';
import { useDiscover } from './discover';
import { CrossPlatformRouter, useRouter } from '../router';


const navigateToLogin = (
  router: CrossPlatformRouter,
  email?: string,
  phone?: string,
) => {
  if (email) {
    router.push(`/login/logged-in/?email=${encodeURIComponent(email)}`);
  } else if (phone) {
    router.push(`/login/logged-in/?phone=${encodeURIComponent(phone)}`);
  }
};

export const useChangeIdentity = () => {
  const dispatch = useDispatch();
  const { error } = useGlobal();

  const trySendChangeIdentity = (
    user: User,
    email?: string,
    phone?: string,
    first_name?: string,
    last_name?: string
  ) => {
    dispatch(async () => {
      try {
        await call(api.users.auth.startChangeIdentity, {
          currentUser: user,
          email,
          phone,
          first_name,
          last_name,
          isWebApp: true,
        });
      } catch (e) {
        error('Something went wrong. Please try again.');
      }
    });
  };

  return {
    trySendChangeIdentity,
  };
};

export const useAuth = () => {
  const identity = useReduxState((state) => state.identity);
  const dispatch = useDispatch();
  const { error } = useGlobal();

  const sendOtp = (phone?: string, email?: string) => {
    dispatch(async () => {
      try {
        await call(api.users.auth.otp, {
          phone,
          email,
          isWebApp: true,
        });
      } catch (e) {
        error('Failed to send code, please try again.');
      }
    });
  };

  const trySendOtp = (router: CrossPlatformRouter, email?: string, phone?: string, onSend?: () => void) => {
    dispatch(async () => {
      try {
        const v = await call(api.users.auth.validate, {
          email,
          phone,
        });

        if (!v.exists && !onSend) {
          router.push('/create-account');
          return;
        }

        await call(api.users.auth.otp, {
          phone,
          email,
          isWebApp: true,
        });

        if (onSend) {
          onSend()
        } else {
          navigateToLogin(router, email, phone);
        }
      } catch (e) {
        error('Something went wrong. Please try again.');
      }
    });
  };

  return {
    authenticated: identity.token && identity.token !== '',
    isOwner: identity.token ? identity.user?.type === UserType.Owner : true,
    trySendOtp,
    sendOtp,
  };
};

export const useCreateAccount = (initial?: User, overrideAfter?: () => void) => {
  const dispatch = useDispatch();
  const { refCode, isInboundAffiliate } = useSelector((state: RootState) => state.onboarding);
  const router = useRouter();

  const form = useForm<User>({
    initial: initial ?? {
      firstName: '',
      lastName: '',
      type: undefined,
      email: '',
    },
    validationSchema: validateObject({
      firstName: validateString()
        .required('Please enter your first name')
        .max(30, 'Maximum length is 30 characters'),
      lastName: validateString()
        .required('Please enter your last name')
        .max(30, 'Maximum length is 30 characters'),
      email: validateString()
        .required('Please enter your email')
        .email('Invalid email address')
        .test(
          'testUserExists',
          'An account with this email address already exists',
          async (value) => {
            const isValid = await validateUserExists(value, true);
            return isValid;
          },
        ),
      type: validateNumber()
        .required('Please enter whether you are an owner or renter')
        .oneOf([UserType.Renter, UserType.Owner]),
      phone: validateString()
        .required('Please enter a phone number')
        .min(10)
        .max(16)
        .test(
          'testUserExists',
          'An account with this phone number already exists',
          async (value) => {
            const isValid = await validateUserExists(value, false);
            return isValid;
          },
        ),
      inboundReferralCode: validateString()
        .max(30, 'Maximum length is 30 characters')
        .nullable(true),
      refSource: validateString(),
      portfolioCount: validateNumber(),
    }),
    submit: async (user) => {
      if (!user.inboundReferralCode) {
        user.inboundReferralCode = refCode;
      }
      if (!isInboundAffiliate) {
        user.isInboundAffiliate = true;
      }
      const signupResult = await dispatch(signUp(user));
      if (signupResult) {
        if (overrideAfter) {
          overrideAfter();
        } else {
          navigateToLogin(router, user.email);
        }
      }
    },
  });
  return form;
};

export const useCreateLead = (rez: Reservation) => {
  const router = useRouter();
  const { book } = useDiscover({
    selectedId: rez.propertyId,
  });
  const form = useForm<TenantLead>({
    initial: {
      firstName: '',
      lastName: '',
      source: TenantLeadSource.Touring,
      email: '',
      phone: '',
    },
    validationSchema: validateObject({
      firstName: validateString()
        .required()
        .max(30, 'Maximum length is 30 characters'),
      lastName: validateString()
        .required()
        .max(30, 'Maximum length is 30 characters'),
      email: validateString().required().email('Invalid email address'),
      phone: validateString().required().min(10).max(16),
    }),
    submit: async (lead) => {
      rez.phone = lead.phone;
      rez.email = lead.email;
      rez.firstName = lead.firstName;
      rez.lastName = lead.lastName;
      console.log(rez);
      const booked = await book(rez);
      if (booked) {
        router.push('/discover/tour-confirmation');
      }
      },
  });
  return form;
};

export const useChangePhoneNumberForm = (currentUser: User) => {
  const { trySendChangeIdentity } = useChangeIdentity();
  const router = useRouter();

  const changeIdentityForm = useForm<ChangeIdentity>({
    initial: {
      phone: '',
    },
    validationSchema: validateObject({
      phone: validateString()
        .required()
        .min(10)
        .max(16)
        .test(
          'testUserExists',
          'An account with this phone number already exists',
          async (value) => {
            const isValid = await validateUserExists(value, false);
            return isValid;
          },
        ),
    }),
    submit: (changeIdentity) => {
      trySendChangeIdentity(currentUser, undefined, changeIdentity.phone);
      router.push(
        `/phone-verification/?phone=${encodeURIComponent(
          changeIdentity.phone,
        )}&userId=${encodeURIComponent(currentUser.id)}`,
      );
    },
  });

  return changeIdentityForm;
};

export const useChangeEmailForm = (currentUser: User) => {
  const { trySendChangeIdentity } = useChangeIdentity();
  const router = useRouter();

  const changeIdentityForm = useForm<ChangeIdentity>({
    initial: {
      email: '',
    },
    validationSchema: validateObject({
      email: validateString()
        .required()
        .email('Invalid email address')
        .test(
          'testUserExists',
          'An account with this email address already exists',
          async (value) => {
            const isValid = await validateUserExists(value, true);
            return isValid;
          },
        ),
    }),
    submit: (changeIdentity) => {
      trySendChangeIdentity(currentUser, changeIdentity.email, undefined);
      router.push(
        `/email-verification/?email=${encodeURIComponent(
          changeIdentity.email,
        )}&userId=${encodeURIComponent(currentUser.id)}`,
      );
    },
  });

  return changeIdentityForm;
};

export const useChangeNameForm = (currentUser: User) => {
  const { trySendChangeIdentity } = useChangeIdentity();
  const dispatch = useDispatch();

  const changeIdentityForm = useForm<ChangeIdentity>({
    initial: {
      first_name: currentUser?.firstName,
      last_name: currentUser?.lastName
    },
    validationSchema: validateObject({
      first_name: validateString().required(),
      last_name: validateString().required(),
    }),
    submit: (changeIdentity) => {
      trySendChangeIdentity(currentUser, undefined, undefined, changeIdentity.first_name, changeIdentity.last_name);
      dispatch(updateUser({...currentUser, firstName: changeIdentity.first_name, lastName: changeIdentity.last_name, name_modification_time: new Date().getTime()}));
    },
  });

  return changeIdentityForm;
};

export const useOtpForm = (onSend?: () => void) => {
  const { trySendOtp } = useAuth();
  const router = useRouter();

  const loginForm = useForm<User>({
    initial: {
      email: '',
      phone: '',
    },
    validationSchema: validateObject({
      email: validateString().email('Please enter valid email'),
      phone: validateString().min(10),
    }),
    submit: (user) => {
      if (onSend) {
        trySendOtp(router, user.email, user.phone, onSend);
      } else {
        trySendOtp(router, user.email, user.phone);
      }
    },
  });

  return loginForm;
};

export const usePhoneConfirmationForm = () => {
  const dispatch = useDispatch();
  const { success } = useGlobal();
  const router = useRouter();

  const confirmationForm = useForm<ChangeIdentity>({
    initial: {
      otp: '',
    },
    validationSchema: validateObject({
      otp: validateString().required(),
    }),
    submit: (lg) => {
      dispatch(changePhoneNumber(lg.phone, lg.currentUser.id, lg.otp));
      success('Phone number has been updated successfully');
      router.back();
    },
  });
  return confirmationForm;
};

export const useEmailConfirmationForm = () => {
  const dispatch = useDispatch();
  const { success } = useGlobal();
  const router = useRouter();

  const confirmationForm = useForm<ChangeIdentity>({
    initial: {
      otp: '',
    },
    validationSchema: validateObject({
      otp: validateString().required(),
    }),
    submit: (lg) => {
      dispatch(changeEmail(lg.email, lg.currentUser.id, lg.otp));
      success('Email has been updated successfully');
      router.back();
    },
  });
  return confirmationForm;
};

export const useLoginForm = (
  initial: Login = {
    email: '',
    phone: '',
    otp: '',
  },
  onLoginOverride?: () => void,
) => {
  const router = useRouter();
  const user = useReduxState((state) => state.identity.user);
  const dispatch = useDispatch();
  const loginForm = useForm<Login>({
    initial,
    validationSchema: validateObject({
      email: validateString().email('Please enter valid email'),
      otp: validateString(),
    }),
    submit: (lg) => {
      dispatch(login(lg.email, lg.phone, lg.otp));
    },
  });

  useEffect(() => {
    if (user) {
      if (onLoginOverride) {
        onLoginOverride();
      } else {
        if (user.type === UserType.Owner) {
            dispatch(async () => {
              const properties = await call(api.property.list, []);
              if(properties?.length === 0){
                router.push('/owner/intro')
              }
              else{
                router.push('/owner/');
              }
            });
        } else {
          router.push('/renter');
        }
      }
    }
  }, [user]);

  return loginForm;
};

export const useUser = () => {
  const me = useReduxState((state) => state.identity?.user);
  const dispatch = useDispatch();
  const { error } = useGlobal();

  const refreshMe = async () => {
    await dispatch(async () => {
      try {
        const u = await call(api.users.v2.me);
        await dispatch(updateUser(u));
        console.log(u);
      } catch (e) {
        error('Problem fetching user data...');
      }
    });
  };

  return {
    me,
    refreshMe,
  };
};
