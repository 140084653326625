import Head from 'next/head';
import React from 'react';

export default () => (
  <Head>
    <style
      dangerouslySetInnerHTML={{
        __html: `
            .pac-container {
              border: 1px solid #949494;
              border-top: 1px solid #d9d9d9;
              box-shadow: none;
            }
          `,
      }}
    />
    <meta charSet="utf-8" />
    <meta httpEquiv="x-ua-compatible" content="ie=edge" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <meta
      property="og:url"
      content="https://www.rentmarble.com/"
      key="og:url"
    />
    <meta
      property="og:image"
      content="https://www.rentmarble.com/img/home2.png"
      key="og:image"
    />
    <meta
      property="keywords"
      content="properties, property management, rental management, homeowner, manager, management"
      key="keywords"
    />

    {/* inter font cdn - https://rsms.me/inter/ */}
    <link rel="preconnect" href="https://rsms.me/" />
    <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
  </Head>
);
