import React from 'react';
import { TextProps } from 'src/base/texts/props';
import { Text } from 'src/base/texts/Text';
import { CssStyleProps } from 'src/base/props/CssStyleProps';

export const C: React.FC<CssStyleProps & TextProps> = ({
  align,
  children,
  style,
}) => (
  <Text align={align} variant="caption" style={style}>
    {children}
  </Text>
);
