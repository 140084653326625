import { FormikErrors, useFormik } from "formik";
import { ReactNode, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { FormBase, FormUploadBase } from "server-sdk/src/api";
import { InputListProps, InputProps } from "../props/inputProps";
import { Charge, MaintenanceCompanyQuote } from "server-sdk/src/types";
import { track } from "../util/track";

export type FormStep = FormStepProps & InputProps & InputListProps;

export interface FormProps<T> {
  form: FormState<T>;
  steps: FormStep[];
  resources: (FormResourceProps & { name: string })[];
  setSelected?: React.Dispatch<React.SetStateAction<string>>;
}

interface FormOptions<T> {
  initial: T;
  submit?: (values: T) => void;
  validationSchema: any;
  dontValidateOnChange?: boolean;
}

export interface FormState<T> {
  values: T;
  errors: FormErrors<T>;
  hasSubmitted: boolean;
  onBlur: (e: React.FocusEvent<any>) => void;
  onChange: (e: React.ChangeEvent<any>) => void;
  onSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  setValue: (field: keyof T, value: any) => void;
  dirty: boolean;
  validate: (
    field?: string
  ) => Promise<void> | Promise<string | undefined> | Promise<FormErrors<T>>;
  resetForm: (nextState?: any) => void;
}

type FormErrors<T> = FormikErrors<T>;

interface StepOptionProps {
  value: any;
  caption?: string;
  label: string;
}

type InputType =
  | "text"
  | "email"
  | "phone"
  | "radio"
  | "dropdown"
  | "otp"
  | "number"
  | "usd"
  | "paragraph"
  | "address"
  | "lockcode"
  | "date"
  | "date-schedule"
  | "choice"
  | "choice-icons"
  | "upload-photos"
  | "upload-documents"
  | "address-auto-complete"
  | "checkbox"
  | "note"
  | "checkout" 
  | "dynamic-collection"
  | "yes-no"
  | "percentage"
  | "quote-chooser"
  | "id-check"
  | "satisfaction-rating";

export interface FormStepProps {
  label?: string;
  required: boolean;
  hidden?: boolean;
  type: InputType;
  color?: "primary" | "secondary" | "default";
  dontValidateOnChange?: boolean;
  placeholder?: string;
  disabled?: string;
  maxLength?: number;
  
  // choice
  exclusive?: boolean;
  options?: StepOptionProps[];
  quoteOptions?: MaintenanceCompanyQuote[];
  // photo/document uploader
  uploadFields?: {
    base: FormBase | FormUploadBase;
    id: number | undefined;
    field: string;
    allowedFileTypes?: string[]

  }
  processing?: boolean;
  handleUpload?: any;
  handleUpdate?: any;

  // datepicker
  minDate?: number;
  maxDate?: number;

  // checkout
  charge?: Charge;

  // dynamic collection
  newRow?: any;
  rowLabel?: string;
  subForms?: {field?: string, input: FormStepProps & InputListProps}[];

  // percentage
  percentBase?: number

  // Hide input
  secured?: boolean;
  
}

export interface FormResourceProps {
  title: string;
  description: string;
  action: () => void;
  actionTitle: string;
}

type MultiPageFormOptions<T> = {
  [K in keyof T]: Omit<FormOptions<T[K]>, "submit"> & {
    resources?: (values: T) => (FormResourceProps & { name: keyof T[K] })[];
    label?: string;
    description?: string;
    descriptionContent?: ReactNode;
    steps: (values: T) => (FormStepProps & { name: keyof T[K] })[];
    submit: ({ values, done }: { values: T; done: () => void }) => void;
    disabled?: (values: T) => boolean;
    isSubmitting?: boolean;
  };
};

export type MultiPageFormState<T> = {
  [K in keyof T]: {
    form: Omit<FormState<T[K]>, "onSubmit"> & {
      onSubmit: () => void;
    };
    label?: string;
    description?: string;
    descriptionContent?: ReactNode;
    steps: FormStepProps[];
    resources: (FormResourceProps & { name: keyof T[K] })[];
    isSubmitting?: boolean;
    disabled?: boolean;
  };
};

export const usd = (cents: number | undefined) => {
  return cents !== null && cents !== undefined ? cents / 100 : undefined;
}

export const cents = (usd: number | undefined) => {
  return usd !== null && usd !== undefined ? usd * 100 : undefined;
}

export const useMultiPageForm = <T>(options: MultiPageFormOptions<T>) => {
  const [submitting, setSubmitting] = useState<{ [K in keyof T]?: boolean }>(
    {}
  );
  const pages = {} as any;
  const values = {} as any;
  for (const [k, v] of Object.entries(options)) {
    //@ts-ignore
    const curFormSteps = v.steps();
    //@ts-ignore
    const form = useForm({
      ...(v as any),
      // disable submit on form hook level
      submit: () => {
        setSubmitting({
          ...submitting,
          [k]: true,
        });
        try {
          track('Multi Page Form Submission Attempt', {
            page: k,
            values: values[k],
          })
        } catch (e) {

        }
        console.log("submitting", values);
        //@ts-ignore
        v.submit({
          values,
          done: () => {
            setSubmitting({
              ...submitting,
              [k]: false,
            });
            try {
              track('Multi Page Form Submission Complete', {
                page: k,
                values: values[k],
              })
            } catch (e) {

            }
          },
        });
      },
    });

    // add data to each value in the form. Used for displaying the form values
    for (const curFormValue of Object.keys(form.values)) {
      const foundValue = curFormSteps.find((s) => s.name === curFormValue);
      if (foundValue) {
        // add the label to the value
        //@ts-ignore
        form.values.displayLabel = v.label;
        // the type to each form value
        //@ts-ignore
        form.values.valueTypes = {
          //@ts-ignore
          ...form.values.valueTypes,
          [curFormValue]: foundValue.type,
        };
        if (foundValue.type === "choice") {
          // for choice and the options with a readable display string
          //@ts-ignore
          form.values.choiceOptions = {
            //@ts-ignore
            ...form.values.choiceOptions,
            [curFormValue]: foundValue.options,
          };
        }
      }
    }

    values[k] = form.values;
    pages[k] = {
      form,
    };
  }
  for (const [k, v] of Object.entries(options)) {
    pages[k] = {
      ...pages[k],
      //@ts-ignore
      steps: v.steps(values),
      //@ts-ignore
      resources: v.resources ? v.resources(values) : null,
      // @ts-ignore
      isSubmitting: submitting[k],
      // @ts-ignore
      disabled: v.disabled ? v.disabled(values) : false,
      // @ts-ignore
      label: v.label,
      // @ts-ignore
      description: v.description,
      // @ts-ignore
      descriptionContent: v.descriptionContent
    };
  }
  return pages as MultiPageFormState<T>;
};

export const useForm = <T>({
  initial,
  submit,
  validationSchema,
  dontValidateOnChange = false 
}: FormOptions<T>): FormState<T> => {
  const dispatch = useDispatch();
  const formik = useFormik<T>({
    initialValues: initial,
    validationSchema,
    validateOnBlur: true,
    validateOnChange : !dontValidateOnChange ,
    onSubmit: submit,
  });

  const dirty = useMemo(() => {
    for (const [, v] of Object.entries(formik.errors)) {
      if (v) {
        return true;
      }
    }
    return false;
  }, [formik.errors]);

  // const [warning, setWarning] = useState(false);
  // useMemo(() => {
  //   dispatch(async () => {
  //     setWarning(false);
  //     const softErrors = await formik.validateForm();
  //     for (const [k, v] of Object.entries(softErrors)) {
  //       if (v) {
  //         console.log('warn')
  //         setWarning(true);
  //       }
  //     }
  //   });
  // }, [formik.values, formik.errors]);

  const validate = (field?: string) => {
    console.log("field", field);
    if (field) {
      return formik.validateField(field);
    }
    return formik.validateForm(formik.values);
  };

  return {
    values: formik.values,
    hasSubmitted: formik.submitCount > 0,
    errors: formik.errors,
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
    onSubmit: formik.handleSubmit,
    setValue: (field, value) => {
      dispatch(() => {
        // @ts-ignore
        formik.setFieldValue(field, value);
      });
    },
    resetForm: formik.resetForm,
    dirty,
    validate,
  };
};