import { setUser } from '@sentry/browser';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Head from 'next/head';
import React, { useEffect } from 'react';
import Navigation from 'src/base/nav/Navigation';
import CopyrightFooter from 'src/components/layout/footer/CopyrightFooter';
import EtcHead from 'src/components/layout/head/EtcHead';
import TrackingScript from 'src/components/layout/head/TrackingScript';
import { Snack } from 'src/components/layout/Snack';
import { useUser } from 'ui-sdk/src/hooks/identity';
import { NextPageWithLayout } from 'src/utils/types';
import { Experiment } from '../Experiment';
import AdsHead from './head/AdsHead';

const stripe = loadStripe(process.env.STRIPE_API_KEY);

const NavWithCashFlow = () => (
  <Navigation
    homePath="/owner/"
    tabs={[
      {
        path: '/owner/',
        title: 'Home',
      },
      {
        path: '/owner/portfolio/',
        title: 'Portfolio',
      },
      {
        path: '/owner/inbox/',
        title: 'Inbox',
      },
      // {
      //   path: 'https://hub.rentmarble.com/',
      //   title: 'Community',
      //   openWithNewTab: true,
      // },
    ]}
    profile={{
      path: '/owner/account/',
      title: 'Account',
    }}
  />
);

const Layout = ({ children }) => {
  const { me: u } = useUser();
  useEffect(() => {
    if (u) {
      console.log('setting sentry user', u);
      setUser({
        ...u,
        id: u.id?.toString(),
      });
    }
  }, [u]);
  return (
    <>
      <AdsHead />
      <Head>
        <title>Owner - Marble</title>
      </Head>
      <EtcHead />
      <Experiment control={<NavWithCashFlow />} />
      <Snack />
      <TrackingScript />
      <Elements stripe={stripe}>{children}</Elements>
      <CopyrightFooter />
    </>
  );
};

export default function withOwnerLayout(page: NextPageWithLayout) {
  page.getLayout = function getLayout(pageElement: React.ReactElement) {
    return <Layout>{pageElement}</Layout>;
  };

  return page;
}
