import { Charge } from "./payment";

export interface Form {
  id?: any;
  value?: any;
  error?: any;
  input?: FormInput;
  field?: string;
  prev?: string;
  progress?: number;
  context?: any;
  cache?: any; // data stored on the front-end

  helpUrl?: string;
  hasFormError?: boolean;
}

export const StartField = 'start';

export type FormUpdates = {
  [k: string]: any
}
export type FormFields = {
  [k: string]: FormInput
}
export type FormErrors = {
  [k: string]: string
}

export interface BaseInput {
  label?: string;
  caption?: string;
  required?: boolean;
  width?: number;
  textAlign?: string;
  saveToMemory?: boolean;
  subForms?: Form[];
  helpUrl?: string;
}

export type FormInput =
  (
    | TextInput
    | EmailInput
    | PhoneInput
    | ChoiceInput
    | DropdownInput
    | RadioInput
    | NumberInput
    | ParagraphInput
    | AddressInput
    | AutoCompleteAddressInput
    | UploadPhotosInput
    | UploadDocumentsInput
    | YesNo
    | IconsChoiceInput
    | DateInput
    | DateWithTextInput
    | CollectionInput
    | DynamicCollectionInput
    | MessageInput
    | CheckboxInput
    | ExtensionInput
    | InfoInput
    | CheckoutInput
    | ArrayInput
    | DataInput
    | RedirectInput
    | ConditionalInput
    | PersonaInput
    | FormCheckInput
    | SecurityQuestionsInput
  ) & FormSection;

export interface FormSection {
  asset?: string;
  title?: string;
}

export interface InfoInput extends BaseInput {
  buttonText?: string;
  link?: string;
  type?: 'info';
  important?: boolean;
}

export interface TextInput extends BaseInput {
  type?: 'text';
  password?: boolean;
}

export interface EmailInput extends BaseInput {
  type?: 'email';
} 

export interface PhoneInput extends BaseInput{
  type?: 'phone';
}

export interface YesNo extends BaseInput{
  type?: 'yes_no';
}

export interface ChoiceInput extends BaseInput{
  choices?: {
    value?: any;
    label?: string;
    caption?: string;
    infoURL?: string;
  }[]

  type?: 'choice'
}

export interface Choice {
  value?: string;
  label?: string;
  webIcon?: any;
  mobileIcon?: {
    name?: string;
    type?: string;
  };
}
export interface IconsChoiceInput extends BaseInput {
  maxChoices?: number;
  exclusive?: boolean;

  choices?: Choice[];

  type?: 'icons_choice'
}

export interface DropdownInput extends BaseInput {
  choices?: {
    value?: any;
    label?: string;
    disabled?: boolean;
  }[];
  defaultValue?: string;

  type?: 'dropdown';
}

export interface RadioInput extends BaseInput {
  choices?: {
    value?: any;
    label?: string;
  }[]

  type?: 'radio'
}


export interface NumberInput extends BaseInput {
  currency?: string;

  decimals?: number;
  increment?: number;

  halfIncrement?: boolean;

  min?: number;
  max?: number;
  inputCaption?: string;
  base?: number;

  value?: number;
  type?: 'number';
}

export interface ParagraphInput extends BaseInput {
  minWarning?: number;  // if specified, amount of words minimum for no warnings
  value?: string;
  warningText?: string;
  type?: 'paragraph';
}

export interface AddressInput extends BaseInput {
  type?: 'address'
}

export interface AutoCompleteAddressInput extends BaseInput {
  type?: 'auto_complete_address'
}

export interface UploadPhotosInput extends BaseInput {
  base?: string;

  min?: number;
  max?: number;
  allowedFileTypes?: ('jpg' | 'jpeg' | 'png')[];

  type?: 'upload_photos';
}

export interface UploadDocumentsInput extends BaseInput {
  buttonText?: string;

  base?: string;

  min?: number;
  max?: number;
  allowedFileTypes?: ('jpg' | 'jpeg' | 'png' | 'pdf')[];

  type?: 'upload_documents';
}

export interface DateInput extends BaseInput {
  minDate?: number;
  maxDate?: number;

  type?: 'date';
}

export interface DateWithTextInput extends BaseInput {
  type?: 'date_with_text';
}

export interface CheckoutInput extends BaseInput  {
  charge?: Charge;
  additionalInfo?: string;

  type?: 'checkout';
}

export interface DynamicCollectionInput extends BaseInput {
  min?: number;
  max?: number;
  rowLabel?: string;

  newRow?: any;

  type?: 'dynamic_collection';
}

export interface CollectionInput extends BaseInput {
  type?: 'collection';
}

export interface MessageInput extends BaseInput {
  type?: 'message';
  fontSize?: number;
  color?: string;
}

export interface CheckboxInput extends BaseInput {
  type?: 'checkbox';
}

export interface ExtensionInput extends BaseInput {
  type?: 'extension';
  mainForms?: Form[];
  triggerField?: string;
  triggerValue?: any;
}

export interface ConditionalInput extends BaseInput {
  type?: 'conditional';
  mainForms?: Form[];
  triggerField?: string;
  triggerValues?: any[];
}

export interface ArrayInput extends BaseInput {
  type?: 'array';
  deleteableRows?: boolean;
  emptyArrayMessage?: string;
}

export interface DataInput extends BaseInput {
  type?: 'data';
}

export interface RedirectInput extends BaseInput {
  type?: 'redirect';
  mobileOnlyMessage?: string;
}

export interface PersonaInput extends BaseInput {
  type?: 'persona';
  disable?: boolean;
}

export interface FormCheckInput extends BaseInput {
  type?: 'form_check';
}

export interface SecurityQuestionsInput extends BaseInput {
  type?: 'multiple_choice_question';
  questionSet?: {
    question: string,
    choices: string[]
  }[]
}

export interface FormUploadAdd {
  id?: any;
  field?: string;
  context?: any;
  cache?: any;
  file?: any;
}

export interface FormUploadUpdate {
  id?: any;
  field?: string;
  context?: any;
  cache?: any;
  files?: any[];
}

export interface FormSubmitData {
  error?: string;
  cache?: any;
  refreshPage?: boolean;
}

export interface FormUploadDelete {
  id?: any;
  field?: string;
  context?: any;
  cache?: any;
  files?: any;
}

export class FormBuilder implements Form {
  id?: any;
  value?: any;
  error?: any;
  input?: FormInput;
  field?: string;
  prev?: string;
  progress?: number;
  context?: any;
  cache?: any; // data stored on the front-end
  
  setValue(value: any) {
    this.value = value;
    return this;
  }
  setError(error: any) {
    this.error = error;
    return this;
  }
  setInput(input: FormInput) {
    this.input = input;
    return this;
  }
  setField(field: string) {
    this.field = field;
    return this;
  }
}