import { Grid, IconButton } from '@mui/material';
import React, { useState } from 'react';
import {
  BoxArrowUpRight,
  CheckSquare,
  ChevronDown,
  ChevronUp,
  Square,
} from 'react-bootstrap-icons';
import { MaintenanceCompanyQuote } from 'server-sdk/src/types';
import { ErrorText } from 'src/base';
import { MarbleText } from 'src/base/texts/Text';
import { openExternalLink } from 'src/utils/url';
import { theme } from 'ui-sdk/src/theme';

export interface QuoteChooserProps {
  error?: string;
  value: any;
  quoteOptions?: MaintenanceCompanyQuote[];
  onChange: any;
}

export const QuoteChooser: React.FC<QuoteChooserProps> = ({
  value,
  onChange,
  quoteOptions,
  error,
}: QuoteChooserProps) => {
  const [showDetailsIndex, setShowDetailsIndex] = useState<number | undefined>(
    undefined,
  );

  const renderDetails = (item) => (
    <>
      {item.notes && (
        <Grid container p={2} direction="column">
          <Grid item pl={0}>
            <MarbleText size="s" weight="bold">
              Note
            </MarbleText>
          </Grid>
          <Grid item pl={0} mt={2}>
            <MarbleText size="s">{item.notes}</MarbleText>
          </Grid>
        </Grid>
      )}
      {item.earliestDate && (
        <Grid container p={2} direction="column">
          <Grid item pl={0}>
            <MarbleText size="s" weight="bold">
              Earliest Availability Date
            </MarbleText>
          </Grid>
          <Grid item pl={0} mt={2}>
            <MarbleText size="s">
              {new Date(item.earliestDate).toLocaleString('en-us', {
                dateStyle: 'medium',
                timeStyle: 'short',
              })}
            </MarbleText>
          </Grid>
        </Grid>
      )}
      {(item?.estimates?.length ?? 0) > 0 && (
        <Grid container p={2}>
          <MarbleText size="s" weight="bold">
            Estimates
          </MarbleText>
          {item.estimates?.map((e) => (
            <Grid container direction="row" mt={2}>
              <Grid item xs={2} pl={0} pr={0}>
                <MarbleText noWrap size="s">
                  {e.title}
                </MarbleText>
              </Grid>
              <Grid item xs={7}>
                <MarbleText size="s">{e.description}</MarbleText>
              </Grid>
              <Grid container item xs={3} pl={1} pr={0} justifyContent="end">
                <MarbleText size="s" weight="med">
                  {e.value.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </MarbleText>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
  return (
    <Grid container>
      {quoteOptions.map((item) => {
        const isItemSelected = value === item.quoteId;
        return (
          <Grid
            alignItems="center"
            container
            item
            direction="row"
            p={3}
            mt={2}
            sx={{
              border: `2px solid ${
                isItemSelected ?
                  `${theme.colors.blue.main}80` :
                  theme.colors.gray[4]
              }`,
              cursor: 'pointer',
              borderRadius: theme.spacing(1),
            }}
            onClick={() =>
              setShowDetailsIndex(
                showDetailsIndex === item.quoteId ? undefined : item.quoteId,
              )
            }
          >
            <Grid item xs={2} pl={0}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  if (!isItemSelected) {
                    onChange(item.quoteId);
                  } else {
                    onChange(undefined);
                  }
                }}
              >
                {isItemSelected ? (
                  <CheckSquare color={theme.colors.blue.main} />
                ) : (
                  <Square color={theme.colors.gray[3]} />
                )}
              </IconButton>
            </Grid>

            <Grid item xs={7} style={{ paddingRight: theme.spacing(1) }}>
              <MarbleText size="s">{item.companyName}</MarbleText>
              {!!item.currentRating && !!item.currentReviews && (
                <MarbleText size="xs" color="gray.1">
                  {item.currentRating} stars (
                  {item.currentReviews?.toLocaleString('en-US')} reviews)
                </MarbleText>
              )}
            </Grid>
            {item.totalEstimate && (
              <Grid
                container
                item
                xs={3}
                pl={1}
                pr={0}
                justifyContent="end"
                direction="row"
                alignItems="center"
              >
                <MarbleText size="s" weight="med">
                  ~
                  {item.totalEstimate?.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </MarbleText>
                {item.companyUrl && (
                  <IconButton
                    onClick={() => openExternalLink(item.companyUrl)}
                    size="medium"
                  >
                    <BoxArrowUpRight />
                  </IconButton>
                )}
                {showDetailsIndex === item.quoteId ? (
                  <ChevronUp size={20} />
                ) : (
                  <ChevronDown size={20} />
                )}
              </Grid>
            )}
            {showDetailsIndex === item.quoteId && renderDetails(item)}
          </Grid>
        );
      })}
      <ErrorText error={error} />
    </Grid>
  );
};
