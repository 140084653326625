import React from 'react';
import Head from 'next/head';

export default () => (
  <Head>
    {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=UA-46752846-3"
    />
    <script
      dangerouslySetInnerHTML={{
        __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'UA-46752846-3');
        `,
      }}
    />
    {/* <!-- Google Tag Manager --> */}
    <script
      dangerouslySetInnerHTML={{
        __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-5SZ952W');
          `,
      }}
    >
      {/* <!-- End Google Tag Manager --> */}
    </script>
    <meta
      name="facebook-domain-verification"
      content="elqnn31jasntcamrvuyf9w1s7xkmxj"
    />
  </Head>
);
