import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { call } from '../call';
import { ApiBase, ApiCall, api } from 'server-sdk/src/api';
import { useReduxState } from './useReduxState';
import { updateSnack } from '../ducks/global';

export const useGlobal = () => {
  const dispatch = useDispatch();
  const { level, message } = useReduxState((state) => state.global);

  const success = (message: string) => {
    dispatch(updateSnack('success', message));
  };

  const error = (message?: string) => {
    let errorMsg = message;
    if (!errorMsg) {
      errorMsg =
        'Something went wrong. Please try again later or reach out to team@rentmarble.com';
    }

    dispatch(updateSnack('error', errorMsg));
  };

  const clear = () => {
    dispatch(updateSnack(undefined, undefined));
  };

  return {
    level,
    message,
    clear,
    success,
    error,
  };
};

export const useWindowDimensions = () => {
  const isBrowser = typeof window !== 'undefined';
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0);
  const [height, setHeight] = useState(isBrowser ? window.innerHeight : 0);

  useEffect(() => {
    if (!isBrowser) {
      return;
    }

    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    width,
    height,
  };
};

type Call = typeof call;
type Api = typeof api;

export const useApiDispatch = () => {
  const dispatch = useDispatch();
  const global = useGlobal();

  return (
    apiCallFn: (c: Call, a: Api) => Promise<[boolean] | [boolean, string]>,
    catchErrorMessage?: string,
  ) => {
    dispatch(async () => {
      try {
        const [success, message] = await apiCallFn(call, api);
        if (success) {
          if (message) {
            global.success(message);
          }
        } else if (message) {
          global.error(message);
        }
      } catch (e) {
        console.warn('Error while calling api', e)
        global.error(
          catchErrorMessage ?? 'Something went wrong, please try again.',
        );
      }
    });
  };
};

export const useApiStore = <S, P extends keyof S, PT extends Pick<S, P>>(
  selector: (a: Api) => ApiCall<S, P, PT>,
  onFinish?: (res: S) => void,
): [S | undefined, (p?: PT, after?: (s: S) => void) => void] => {
  const [state, setState] = useState<S>();

  const dispatch = useDispatch();

  const refresh = (params?: PT, after?: (s: S) => void) => {
    dispatch(async () => {
      const apiCall = selector(api);
      const res = await call(apiCall, params)
      setState(res);
      if (onFinish) {
        onFinish(res);
      }
      if (after) {
        after(res);
      }
    });
  }

  return [state, refresh];
}