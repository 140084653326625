import React from 'react';
import { theme as marbleTheme } from 'ui-sdk/src/theme';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  alpha,
} from '@mui/material';
import { P } from '../texts';

interface CheckboxProps {
  defaultChecked?: boolean;
  disabled?: boolean;
  label?: string;
  value?: boolean;
  onChange?: any;
}

export const MarbleCheckbox: React.FC<CheckboxProps> = ({
  defaultChecked,
  disabled,
  label,
  value,
  onChange,
}) => (
  <Grid
    container
    sx={{
      borderRadius: '10px',
      paddingTop: marbleTheme.spacing(1),
      paddingBottom: marbleTheme.spacing(1),
    }}
  >
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            sx={{
              color: marbleTheme.colors.gray[3],
              '&.Mui-disabled': {
                color: alpha(marbleTheme.colors.gray[3], 0.5),
              },
              '&.Mui-checked': {
                '&.Mui-disabled': {
                  color: alpha(marbleTheme.colors.blue.main, 0.5),
                },
              },
            }}
            defaultChecked={defaultChecked || value}
            disabled={disabled}
          />
        }
        label={<P style={{ fontSize: '14px', margin: '0px ' }}>{label}</P>}
        value={value}
        onChange={onChange}
      />
    </FormGroup>
  </Grid>
);
