import {
  Box,
  Fade,
  Grid,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from '@mui/material';
import React, { FC, useState } from 'react';
import { P } from 'src/base/texts/P';
import { Collapse } from 'src/base/animations/Collapse';
import { Button } from 'src/base/inputs/Button';
import { StepperProps } from './props';

export const VerticalStepper: FC<StepperProps> = ({
  onNext,
  finishedComp,
  controlled = true,
  steps,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onNext) {
      onNext(activeStep + 1, e);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const filteredSteps = steps.filter((s) => !s.disabled);

  return (
    <div>
      <Stepper
        orientation="vertical"
        style={{
          padding: 0,
          backgroundColor: 'transparent',
        }}
        {...(controlled && {
          activeStep,
        })}
        {...(!controlled && {
          nonLinear: true,
        })}
      >
        {filteredSteps.map(({ label, validated = true, content }, index) => (
          <Step
            key={index}
            {...(!controlled && {
              active: true,
              expanded: true,
            })}
          >
            <StepLabel
              onClick={() => {
                if (index === activeStep + 1 && validated) {
                  setActiveStep(index);
                } else if (index < activeStep) {
                  setActiveStep(index);
                }
              }}
            >
              <P>{label}</P>
            </StepLabel>
            <StepContent
              TransitionComponent={Collapse}
              TransitionProps={{
                unmountOnExit: false,
              }}
            >
              {content}
              {controlled && (
                <Grid
                  container
                  style={{
                    paddingTop: 20,
                  }}
                >
                  <Box
                    style={{
                      paddingLeft: 5,
                    }}
                  >
                    <Button
                      title="Back"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                    />
                  </Box>
                  <Box
                    style={{
                      paddingLeft: 5,
                    }}
                  >
                    <Button
                      title={
                        activeStep === filteredSteps.length - 1 ?
                          'Finish' :
                          'Next'
                      }
                      onClick={handleNext}
                      color="primary"
                      type="submit"
                      disabled={controlled && !validated}
                    />
                  </Box>
                </Grid>
              )}
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === filteredSteps.length && Boolean(finishedComp) && (
        <Fade in={activeStep === filteredSteps.length && Boolean(finishedComp)}>
          <Paper
            square
            elevation={0}
            style={{
              padding: 5,
            }}
          >
            {finishedComp}
          </Paper>
        </Fade>
      )}
    </div>
  );
};
