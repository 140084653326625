import { Alert, Snackbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useGlobal } from 'ui-sdk/src/hooks/global';

export const Snack = () => {
  const { level, message, clear } = useGlobal();

  const useStyles = makeStyles(() => ({
    container: {
      padding: '7px 20px', // 7 because the default padding for alert message is 8. so 15 - 8
    },
  }));
  const classes = useStyles();

  return (
    <Snackbar
      open={Boolean(message)}
      autoHideDuration={6000}
      onClose={clear}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        icon={false}
        onClose={clear}
        severity={level}
        className={classes.container}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
