import { UserIdVerificationPlatform } from "./user";

export interface IdVerification {
  inquiryId?: string;
  idVerified?: boolean;
  stripeClientSecret?: string;
  propertyId?: number;
  verficationSessionId?: string;
  ephemeralKeySecret?: string;
}

export interface IdVerificationStatus {
  userId?: number;
  isActive?: boolean;
  propertyId?: number;
  status?: string;
}

export interface IdVerificationResults {
  idVerified?: boolean;
  idVerificationPending?: boolean;
  idVerificationFailed?: boolean;
  idVerificationStatus?: string;
  idVerificationPlatform?: UserIdVerificationPlatform;
}

export enum StripeIdentityStatus {
  VERIFIED = 'verified',
  CREATED = 'created',
  PROCESSING = 'processing',
  FAILED = 'requires_input',
  CANCELED = 'canceled',
  REDACTED = 'redacted'
}

export enum PersonaIdentityStatus {
  VERIFIED = 'completed',
  CREATED = 'created',
  PROCESSING = 'pending',
  EXPIRED = 'expired',
}