export const FRIDAY_UNO = 19900;
export const IGLOO_KEYBOX_PRICE = 17900;
export const IGLOO_DEADBOLT_PRICE = 19900;
export const HANDYMAN_PRICE = 15000;

export const CONTROL_LEASING_FEE = 39500;
export const OLD_CONTROL_LEASING_FEE = 19500;

export const SETUP_FEE = 72500;
export const SETUP_FEE_ANNUAL = 58000;

export const MARBLE_SUBSCRIPTION_PRICE = 6500;

export const getSubscriptionRate = (userId: number) => {
  return userId < 28980 ? 5000 : MARBLE_SUBSCRIPTION_PRICE;
}

export const APPLICATION_FEE = 4000;
export const NY_APPLICATION_FEE = 2000;
export const STATE_NY = 'NY';

export const StatesToFullName = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DC: "District of Columbia (DC)",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export const States = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NC',
  'ND',
  'NH',
  'NJ',
  'NM',
  'NV',
  STATE_NY,
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const NotApplicable = 'N/A';

export const States_With_NA = [NotApplicable].concat(States);

export const PetType_Cat = 'Cat';
export const PetType_SmallDog = 'Small Dog (less than 30 pounds)';
export const PetType_LargeDog = 'Large Dog (more than 30 pounds)';
export const PetType_Other = 'Other';

export const PetTypes = [
  PetType_Cat,
  PetType_SmallDog,
  PetType_LargeDog,
  'Bird',
  PetType_Other
];

export const ValidateEmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const CDN_URL = 'https://i.marblestatic.com';

export const Marble_Web_Url = "https://www.rentmarble.com";

const currDate = new Date();
export const demoCashFlowData = [
  {
    month: new Date(
      currDate.getFullYear(),
      currDate.getMonth() - 7,
    ).toLocaleString('en-us', {
      month: 'short',
      year: 'numeric',
    }),
    payments: [
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 7, 12),
          type: 1,
          amount: 125000,
        },
      },
    ],
  },
  {
    month: new Date(
      currDate.getFullYear(),
      currDate.getMonth() - 6,
    ).toLocaleString('en-us', {
      month: 'short',
      year: 'numeric',
    }),
    payments: [
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 6, 20),
          type: 2,
          amount: -12000,
        },
      },
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 6, 12),
          type: 1,
          amount: 125000,
        },
      },
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 6, 5),
          type: 2,
          amount: -25000,
        },
      },
    ],
  },
  {
    month: new Date(
      currDate.getFullYear(),
      currDate.getMonth() - 5,
    ).toLocaleString('en-us', {
      month: 'short',
      year: 'numeric',
    }),
    payments: [
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 5, 12),
          type: 1,
          amount: 125000,
        },
      },
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 5, 5),
          type: 2,
          amount: -25000,
        },
      },
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 5, 5),
          type: 2,
          amount: -25000,
        },
      },
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 5, 5),
          type: 2,
          amount: -25000,
        },
      },
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 5, 5),
          type: 2,
          amount: -25000,
        },
      },
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 5, 5),
          type: 2,
          amount: -25000,
        },
      },
    ],
  },
  {
    month: new Date(
      currDate.getFullYear(),
      currDate.getMonth() - 4,
    ).toLocaleString('en-us', {
      month: 'short',
      year: 'numeric',
    }),
    payments: [
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 4, 13),
          type: 1,
          amount: 70000,
        },
      },
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 4, 9),
          type: 2,
          amount: -20000,
        },
      },
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 4, 5),
          type: 2,
          amount: -25000,
        },
      },
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 4, 4),
          type: 2,
          amount: -25000,
        },
      },
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 4, 1),
          type: 2,
          amount: -15000,
        },
      },
    ],
  },
  {
    month: new Date(
      currDate.getFullYear(),
      currDate.getMonth() - 3,
    ).toLocaleString('en-us', {
      month: 'short',
      year: 'numeric',
    }),
    payments: [
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 3, 13),
          type: 1,
          amount: 125000,
        },
      },
    ],
  },
  {
    month: new Date(
      currDate.getFullYear(),
      currDate.getMonth() - 2,
    ).toLocaleString('en-us', {
      month: 'short',
      year: 'numeric',
    }),
    payments: [
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 2, 15),
          type: 1,
          amount: 125000,
        },
      },
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 2, 14),
          type: 1,
          amount: 3500,
        },
      },
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 2, 13),
          type: 1,
          amount: 7500,
        },
      },
    ],
  },
  {
    month: new Date(
      currDate.getFullYear(),
      currDate.getMonth() - 1,
    ).toLocaleString('en-us', {
      month: 'short',
      year: 'numeric',
    }),
    payments: [
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 1, 2),
          type: 1,
          amount: 125000,
        },
      },
    ],
  },
  {
    month: currDate.toLocaleString('en-us', {
      month: 'short',
      year: 'numeric',
    }),
    payments: [],
  },
];

export const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const CARDLESS_TRIAL_DAYS = 14;
export const SETUP_FEE_TRIAL_DAYS = 0;

export const SHORT_DOMAIN = 'https://mrble.co'

export const MARBLE_LEASING_PROPERTY_ID = 10

export const HOUR = 3600000;
export const DAY = 24 * HOUR;
export const MINUTE = 60000;

export const DAYS_BEFORE_RENEWAL = 70;
export const LISTING_BUFFER_DAYS = 60 * DAY;