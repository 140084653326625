import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { call } from '../call';
import { api } from 'server-sdk/src/api';
import {
  PropertySubscription,
  Subscription,
  UpdateCard,
} from 'server-sdk/src/types';
import { useReduxState } from './useReduxState';
import { useGlobal } from './global';
import { useUser } from './identity';
import { refreshPortfolio } from '../ducks/portfolio';

export const useSubscription = () => {
  const dispatch = useDispatch();
  const { success, error } = useGlobal();

  const { me, refreshMe } = useUser();
  const subscribed = useMemo(() => me?.subscribed, [me?.subscribed]);
  const needsToResubscribe = useMemo(
    () => me?.needs_to_resubscribe,
    [me?.needs_to_resubscribe],
  );

  const properties = useReduxState((state) => state.portfolio.properties);
  const used = useMemo(
    () =>
      properties?.filter(
        (p) => p.subscription === PropertySubscription.Subscribed,
      ).length,
    [properties],
  );

  const enable = async (propertyId: number, creationToken?: string, trackConversion?: () => void) => {
    await dispatch(async () => {
      try {
        console.log(creationToken);
        const p = await call(api.property.subscribe, {
          propertyId,
          creationToken,
        });
        if (p) {
          if (p.error !== undefined && p.error !== null && p.error !== '') {
            error(`Failed to create subscription: ${p.error}`);
            return;
          }
          await refreshMe();
          dispatch(refreshPortfolio());

          try {
            if (!!trackConversion) {
              trackConversion();
            }
          } catch (e) {
            console.error('Failed to track owner account creation');
          }
          success('Property is now subscribed');
        } else {
          error('Failed to add property to subscription');
        }
      } catch (e) {
        error(
          'Failed to add property to subscription. Please try again later.',
        );
      }
    });
  };

  const disable = (propertyId: number) => {
    dispatch(async () => {
      try {
        const p = await call(api.property.unsubscribe, {
          propertyId,
        });
        if (p) {
          refreshMe();
          dispatch(refreshPortfolio());
          success('Property is now unsubscribed');
        } else {
          error(
            'Failed to remove property from subscription. Please try again.',
          );
        }
      } catch (e) {
        error('Failed to remove property from subscription. Please try again.');
      }
    });
  };

  const updateCardAndSubscription = async (
    propertyId: number,
    creationToken?: string,
  ) => {
    await dispatch(async () => {
      try {
        console.log(creationToken);
        const p = await call(api.property.updateCardAndSubscription, {
          propertyId,
          creationToken,
        });
        if (p) {
          if (p.error !== undefined && p.error !== null && p.error !== '') {
            error(`Failed to update card: ${p.error}`);
            return;
          }
          refreshMe();
          dispatch(refreshPortfolio());
          success('Card has been updated successfully');
        } else {
          error('Failed to update card. Please try again later.');
        }
      } catch (e) {
        error('Failed to update card. Please try again later.');
      }
    });
  };

  const updateCardDontSubscribe = async (creationToken?: string) => {
    await dispatch(async () => {
      try {
        const p: UpdateCard = await call(api.property.updateCardDontSubscribe, {
          creationToken,
        });

        if (p?.success) {
          if (p.error) {
            error(`Failed to update card: ${p.error}`);
            return;
          }

          refreshMe();
          dispatch(refreshPortfolio());
          success('Card has been updated successfully');
        } else {
          error('Failed to update card. Please try again later.');
        }
      } catch (e) {
        error('Failed to update card. Please try again later.');
      }
    });
  };

  return {
    enable,
    disable,
    updateCardAndSubscription,
    updateCardDontSubscribe,
    subscribed,
    needsToResubscribe,
    used,
  };
};
