import { Application } from "./application";
import { LateFeesData, UtilityAssignment } from "./property";
import { RentalAgreement } from "./renter";
import { User } from "./user";

export interface LeasingActivities {
  property_id?: number;
  leasing_activities?: LeasingActivity[];
  stats?: LeasingActivityStats;
}

export interface LeasingActivityStats {
  inquiries?: number;
  applications?: number;
  scheduledTours?: number;
  completedTours?: number;
}

export interface LeasingActivity {
  id?: number;
  displayName?: string;
  status?: string;
  type?: LeasingActivityType;
  application?: Application;
  timeline?: TimelineDay[];
  users?: User[];
  seen?: boolean;
  creationTime?: number;
}

export enum LeasingActivityType {
  Application = 'Application',
  Inquiry = 'Inquiry',
  Tour = 'Tour'
}

export interface TimelineEvent {
  desc: string;
  time: number;
  count?: number;
}

export interface TimelineDay {
  events: string[];
  day: string;
  user?: string;
}

export enum LeaseAddendumType {
  // tenant
  EarlyTermination = 'early-termination',
  LeaseStart = 'lease-start',
  AddPet = 'add-pet',
  RemovePet = 'remove-pet',
  AddRoommate = 'add-roommate',
  RemoveRoommate = 'remove-roommate',
  
  // owner
  RentUpdate = 'rent-update',
  TransferAssignments = 'transfer-assignments',
  FeesChange = 'fees-change',

  // both
  PropertyChanges = 'property-changes',

  LeaseExtension = 'lease-extension',
  NonLeaseRenewal = 'non-renewal'
}

export interface LeaseAddendumInfo {
  id?: number;

  addendum_type?: LeaseAddendumType;
  rental_agreement_id?: number;
  user_id?: number;

  completion_time?: number;

  change_fields?: LeaseAddendumChanges;
  guid?: string;
  reason?: string;

  document_review?: object;
  template?: any;
  flowActivityId?: number;

  rental_agreement?: RentalAgreement;
}

export type LeaseAddendumChanges = (
  & StartLeaseChanges
  & EarlyTerminationChanges
  & PetChanges
  & RoommateChanges
  & RentUpdateChanges
  & TransferAssignmentsChanges
  & FeesChanges
  & PropertyChanges
  & ExtensionChanges
) & {
  additional_specifics?: string[];
  // rent update & transfer assignments (owner)
  effective_date?: number;
};

interface StartLeaseChanges {
  // start lease change (tenant)
  start_lease?: number;
}
interface EarlyTerminationChanges {
  // early termination (tenant)
  end_lease?: number;
  // early termination (owner)
  termination_penalty?: number;
}
interface PetChanges {
  // pet add & removal (tenant)
  pet_type?: string;
  pet_name?: string;
  pet_breed?: string;
  pet_weight?: number;
  pet_age?: number;
  pet_neutered?: boolean;
  pet_vaccines?: object;
  pet_license?: string;
  // pet add (owner)
  pet_refundable_fee?: boolean;
}

interface RoommateChanges {
  // roommate movein (tenant)
  rooomate_relationship?: string;
  roommate_email?: string;
  roommate_phone?: string;
  roommate_first_name?: string;
  roommate_last_name?: string;
  // roommate movein & moveout (tenant)
  roommate_name?: string;
  roommate_move?: number;
  remove_roommate_id?: number;
}

interface RentUpdateChanges {
  // rent update (owner)
  new_rent?: number;
  new_rent_due_day?: number;
}

interface TransferAssignmentsChanges {
  // transfer assignments (owner)
  garbage_sewage_utilities?: UtilityAssignment;
  water_utilities?: UtilityAssignment;
  electric_utilities?: UtilityAssignment;
  gas_utilities?: UtilityAssignment;

  utility_contact?: string;
  utility_contact_user?: string;
  utility_company?: string;
}

interface FeesChanges {
  // fees change
  new_late_fees?: any;
  new_parking_fee?: number;
  new_dog_deposit?: number;
  new_dog_fee?: number;
  new_cat_deposit?: number;
  new_cat_fee?: number;
}

interface PropertyChanges {
  property_change_location?: PropertyChangeLocation
  property_change_type?: PropertyChangeType
  property_change_description?: string;
}

interface ExtensionChanges {
  extend_lease?: boolean;
  extension_months?: number;
  requested_extension_months?: number;
}

export type PropertyChangeLocation = 'inside' | 'outside';
export type PropertyChangeType = 'addition' | 'removal' | 'repair';

export interface SubmitMoveOutFormRequest {
  flowActivityId?: number;
  formResponse: MoveOutFormResponse;
}

export interface MoveOutFormResponse {
  inspection_approval: {
    choice: any[];
  };
  security_deposit_return: {
    choice: string;
  };
  tour_setup: {
    pre_moveout_waitlist_choice: string;
    post_moveout_tours_choice: string;
  };
  thank_you_for_your_time: unknown;
}