import { Charge } from "./payment";
import { Address } from "./property";

export interface Shipment {
  id?: number;
  address?: Address;
  item?: ItemType;
  quantity?: number;
  charge?: Charge;
  status?: ShipmentStatus;
  property_id?: number;
  tracking_status?: string;
  tracking_url?: string;
  handyman?: boolean;
  access_instructions?: string;
  fulfiller?: FulfillerType;
  tracking_code?: string;
  fulfillment_invoice_id?: string;
  error?: string;
}

export interface UpdateShipment {
  shipment?: Shipment
}

export enum ItemType {
  FridayUnoLock = 0,
  IglooKeybox3E = 1,
  IglooDeadbolt2E = 2
}

export enum ShipmentStatus {
  Canceled = -1,
  Started = 0,
  Paid = 1,
  Ordered = 2,
}

export enum TrackingStatus {
  Shipped = 'TRANSIT',
  Delivered = 'DELIVERED',
  Ordered = 'ORDER',
  OutForDelivery = 'OUT_FOR_DELIVERY',
  Canceled = "CANCELED"
}

export enum FulfillerType {
  IGLOO = 'igloo',
  LYFESHOT = 'lyfeshot'
}
