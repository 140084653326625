import React, { useEffect } from 'react';
import { InputListProps, InputProps } from 'src/base/inputs/props';
import { RadioList } from 'src/base/inputs/RadioList';
import { CssStyleProps } from 'src/base/props';
import {
  FormState,
  FormStepProps,
  useActivate,
  useApiStore,
  useFileUpload,
} from 'ui-sdk/src/hooks';
import { getProperty } from 'src/utils/reflection';
import {
  Box,
  Checkbox,
  Divider,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  LinearProgress,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import { revertTimeZoneOffsetForUI } from 'src/components/ServerForm';
import { DateTime } from 'luxon';
import { theme } from 'ui-sdk/src/theme';
import { makeStyles } from '@mui/styles';
import { compress } from 'src/utils/compress';
import { CDN_URL } from 'ui-sdk/src/constants';
import { AddRounded, Description } from '@mui/icons-material';
import { arrayMoveImmutable } from 'array-move';
import { Charge } from 'server-sdk/src/types';
import { CardInput } from 'src/components/app/stripe';
import { usd } from 'server-sdk/src/util';
import { QuoteChooser } from 'src/components/app/maintenance/QuoteChooser';
import { MarbleIcon } from 'src/base/outputs/icons';
import MarbleSatisfactionRating from 'src/components/SatisfactionRating';
import { MarbleTextInput } from './MarbleTextInput';
import { MarbleButton, MarbleDropdown } from '.';
import { MultiSelect } from './MultiSelect';
import { MarbleDesktopDatePicker } from './DatePicker';
import UppyUploader from './UppyUploader';
import { MarbleText } from '../texts/Text';
import { CostBreakdown, SortablePhotoGalleryRow } from '../outputs';
import { AutoCompleteAddressForm } from './AutoCompleteAddress';
import { DateTimeScheduler } from './DateTimeScheduler';

export type FormStep = FormStepProps & InputProps & InputListProps;
interface FormProps<T> {
  form: FormState<T>;
  steps: FormStep[];
  setSelected?: React.Dispatch<React.SetStateAction<string>>;
}

export function Form<T>({
  form,
  steps,
  style,
  setSelected,
}: FormProps<T> & CssStyleProps) {
  const { processing, handleUpload, handleUpdate } = useFileUpload(compress);

  return (
    <form onSubmit={form?.onSubmit} style={style}>
      {steps &&
        steps?.map((s) => (
          <Box
            sx={{
              marginBlock: 3,
            }}
            onFocus={
              setSelected &&
              s.type !== 'upload-photos' &&
              s.type !== 'upload-documents' ?
                () => setSelected(s.name) :
                null
            }
            onClick={
              setSelected &&
              (s.type === 'upload-photos' || s.type === 'upload-documents') ?
                () => setSelected(s.name) :
                null
            }
            onBlur={setSelected ? () => setSelected('') : null}
          >
            <FormStep
              label={s.label}
              error={getProperty(form?.errors, s.name)}
              name={s.name}
              placeholder={s.placeholder?.toString()}
              options={s.options}
              quoteOptions={s.quoteOptions}
              color={s.color ?? 'primary'}
              required={s.required}
              type={s.type}
              value={getProperty(form?.values, s.name)}
              onBlur={() => {
                form?.validate(s.name);
              }}
              exclusive={s?.exclusive}
              uploadFields={s?.uploadFields}
              processing={processing}
              handleUpload={handleUpload}
              handleUpdate={handleUpdate}
              minDate={s?.minDate}
              maxDate={s?.maxDate}
              charge={s?.charge}
              newRow={s?.newRow}
              rowLabel={s?.rowLabel}
              subForms={s?.subForms}
              percentBase={s?.percentBase}
              secured={s?.secured}
              maxLength={s?.maxLength}
              dontValidateOnChange={s.dontValidateOnChange}
              onChange={(e) => {
                if (
                  !s.dontValidateOnChange &&
                  getProperty(form?.errors, s.name)
                ) {
                  form?.validate(s.name);
                }
                form?.onChange(e);
              }}
            />
          </Box>
        ))}
    </form>
  );
}

const getDate = (val) => {
  if (!val) {
    return null;
  }
  return new Date(revertTimeZoneOffsetForUI(DateTime.fromMillis(val)));
};

// date picker has time zone by default
// must convert to utc before sending to server
export const addTimeZoneOffsetForServer = (e) => {
  const utcOffset = e.offset;
  const utcTime = e.plus(utcOffset * 60 * 1000);
  return utcTime;
};

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '15px',
    marginBottom: theme.spacing(1),
    fontWeight: 700,
    color: theme.colors.black,
    lineHeight: '150%',
  },
  labelAsterisk: {
    fontSize: '15px',
    color: theme.colors.red.main,
  },
}));

// eslint-disable-next-line no-redeclare
function FormStep({
  error,
  name,
  onBlur,
  onChange,
  placeholder,
  label,
  options,
  required,
  type,
  value,
  color,
  exclusive,
  uploadFields,
  processing,
  handleUpload,
  handleUpdate,
  minDate,
  maxDate,
  charge,
  newRow,
  rowLabel,
  subForms,
  percentBase,
  secured,
  maxLength,
  quoteOptions,
}: FormStepProps & InputProps & InputListProps) {
  const classes = useStyles();
  const act = useActivate();
  const [me, refreshMe] = useApiStore((api) => api.users.v2.me);

  useEffect(() => {
    if (type === 'id-check') {
      refreshMe();
    }
    if (me?.idVerified) {
      onChange(convertToEvent(name, true));
    }
  }, [type, act?.verified, me]);

  switch (type) {
    case 'text':
      return (
        <MarbleTextInput
          name={name.toString()}
          placeholder={placeholder}
          error={error}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          required={required}
          secured={secured}
          maxLength={maxLength}
        />
      );
    case 'lockcode':
      return (
        <MarbleTextInput
          name={name.toString()}
          placeholder={placeholder}
          error={error}
          value={value}
          onBlur={onBlur}
          onChange={(e) => {
            const regex = /[^0-9!@#$%^&*()]/g;
            // @ts-ignore
            e.currentTarget.value = e.currentTarget.value.replace(regex, '');
            onChange(e);
          }}
          required={required}
        />
      );
    case 'email':
      return (
        <MarbleTextInput
          name={name.toString()}
          placeholder={placeholder}
          error={error}
          type="email"
          onBlur={onBlur}
          value={value}
          onChange={onChange}
          required={required}
        />
      );
    case 'phone':
      return (
        <MarbleTextInput
          mask="phone"
          name={name.toString()}
          placeholder={placeholder}
          error={error}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          required={required}
          inputProps={{
            autoComplete: 'off',
          }}
        />
      );
    case 'radio':
      return (
        <>
          <InputLabel
            classes={{ root: classes.label, asterisk: classes.labelAsterisk }}
            required={required}
          >
            {label}
          </InputLabel>
          <RadioList
            name={name.toString()}
            options={options as { value: any; label: string }[]}
            value={value ?? undefined}
            onChange={(e) => {
              if (e.target.value === 'true') {
                onChange(convertToEvent(name, true));
              } else if (e.target.value === 'false') {
                onChange(convertToEvent(name, false));
              } else {
                onChange(e);
              }
            }}
            color={color}
          />
          <ErrorText error={error} />
        </>
      );
    case 'yes-no':
      return (
        <>
          <InputLabel
            classes={{ root: classes.label, asterisk: classes.labelAsterisk }}
            required={required}
          >
            {label}
          </InputLabel>
          <RadioList
            name={name.toString()}
            options={[
              {
                value: true,
                label: 'Yes',
              },
              {
                value: false,
                label: 'No',
              },
            ]}
            value={value ?? undefined}
            onChange={(e) => {
              if (e.target.value === 'true') {
                onChange(convertToEvent(name, true));
              } else if (e.target.value === 'false') {
                onChange(convertToEvent(name, false));
              } else {
                onChange(e);
              }
            }}
            color={color}
          />
        </>
      );
    case 'dropdown':
      return (
        <MarbleDropdown
          name={name.toString()}
          placeholder={placeholder}
          options={options}
          value={value}
          error={error}
          onBlur={onBlur}
          onChange={onChange}
          style={{
            minWidth: '100%',
          }}
        />
      );
    case 'otp':
      return (
        <MarbleTextInput
          name={name.toString()}
          mask="otp"
          error={error}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          placeholder="One Time Code"
        />
      );
    case 'number':
      return (
        <MarbleTextInput
          name={name.toString()}
          error={error}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          mask="num"
          secured={secured}
          maxLength={maxLength}
        />
      );
    case 'usd':
      return (
        <MarbleTextInput
          name={name.toString()}
          error={error}
          value={value}
          onBlur={onBlur}
          onChange={(e) => {
            e.target.value = parseFloat(e.target.value.toString());
            onChange(e);
          }}
          placeholder={placeholder}
          mask="usd"
        />
      );
    case 'percentage':
      return (
        <>
          <MarbleTextInput
            name={name.toString()}
            value={value}
            error={error}
            onBlur={onBlur}
            onChange={(e) => {
              e.target.value = parseFloat(e.target.value.toString());
              onChange(e);
            }}
            placeholder={placeholder}
            mask="percent"
          />
          <MarbleText size="s">{`Final value: ${
            value ?
              usd(Math.round(percentBase * (value / 100) * 1000) / 1000) :
              0
          }`}</MarbleText>
        </>
      );
    case 'paragraph':
      return (
        <MarbleTextInput
          name={name.toString()}
          placeholder={placeholder}
          error={error}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          required={required}
          multiline
        />
      );
    case 'date':
      return (
        <MarbleDesktopDatePicker
          placeholder={label}
          value={getDate(value)}
          error={error}
          onChange={(e) => {
            if (e) {
              if (e instanceof DateTime) {
                const serverUTCTime = addTimeZoneOffsetForServer(e);
                onChange(convertToEvent(name, serverUTCTime.toMillis()));
              } else {
                onChange(convertToEvent(name, e.getTime()));
              }
            }
          }}
          minDate={minDate ? DateTime.fromMillis(minDate) : undefined}
          maxDate={maxDate ? DateTime.fromMillis(maxDate) : undefined}
          required={required}
        />
      );
    case 'date-schedule': {
      return (
        <DateTimeScheduler
          value={value}
          error={error}
          onChange={(e) => {
            onChange(convertToEvent(name, e));
          }}
          minDate={minDate ? DateTime.fromMillis(minDate) : undefined}
          maxDate={maxDate ? DateTime.fromMillis(maxDate) : undefined}
        />
      );
    }
    case 'choice':
      return (
        <>
          <InputLabel
            classes={{ root: classes.label, asterisk: classes.labelAsterisk }}
            required={required}
          >
            {label}
          </InputLabel>
          <MultiSelect
            val={value}
            onChange={(v) => {
              onChange(convertToEvent(name, v));
            }}
            type="default"
            layout="list"
            choices={options}
            exclusive={exclusive}
          />
          <ErrorText error={error} />
        </>
      );
    case 'choice-icons':
      return (
        <>
          <InputLabel
            classes={{ root: classes.label, asterisk: classes.labelAsterisk }}
            required={required}
          >
            {label}
          </InputLabel>
          <MultiSelect
            val={value}
            onChange={(v) => {
              onChange(convertToEvent(name, v));
            }}
            type="icon"
            layout="grid"
            choices={options}
            exclusive={exclusive}
          />
          <ErrorText error={error} />
        </>
      );
    case 'upload-photos':
      return (
        <Box>
          <ErrorText error={error} />
          <UppyUploader
            allowedFileTypes={uploadFields.allowedFileTypes}
            handleUpload={async (uppyFiles) => {
              const uploads = await handleUpload(
                uploadFields.base,
                uploadFields.id,
                uploadFields.field,
                uppyFiles,
                (files) => {
                  console.log('files', files);
                  onChange(
                    convertToEvent(
                      name,
                      value ? value.concat(files) : [].concat(files),
                    ),
                  );
                },
              );
              return uploads;
            }}
          />
          {(value?.length ?? 0) > 0 && (
            <MarbleText style={{ marginTop: '1.5em' }} />
          )}
          {processing && <LinearProgress variant="indeterminate" />}
          <SortablePhotoGalleryRow
            photos={
              value ?
                value.map((p, i) => {
                    console.log(value);
                    return {
                      id: i,
                      src: `${CDN_URL}/${p}`,
                    };
                  }) :
                []
            }
            swapPhotos={(oldIdx, newIdx) => {
              const updates: string[] = arrayMoveImmutable(
                value,
                oldIdx,
                newIdx,
              );
              onChange(convertToEvent(name, updates));
              handleUpdate(
                uploadFields.base,
                uploadFields.id,
                uploadFields.field,
                updates,
                (updated) => {
                  onChange(convertToEvent(name, updated));
                },
              );
            }}
            removePhoto={(photoId) => {
              const updates = value.filter((p, i) => i !== photoId);
              onChange(convertToEvent(name, updates));
              handleUpdate(
                uploadFields.base,
                uploadFields.id,
                uploadFields.field,
                updates,
                (updated) => {
                  onChange(convertToEvent(name, updated));
                },
              );
            }}
          />
        </Box>
      );
    case 'upload-documents':
      return (
        <Box>
          <ErrorText error={error} />
          <UppyUploader
            allowedFileTypes={uploadFields.allowedFileTypes}
            handleUpload={async (uppyFiles) => {
              const uploads = await handleUpload(
                uploadFields.base,
                uploadFields.id,
                uploadFields.field,
                uppyFiles,
                (files) => {
                  console.log('files', uppyFiles);
                  onChange(
                    convertToEvent(
                      name,
                      value ? value.concat(files) : [].concat(files),
                    ),
                  );
                },
              );
              return uploads;
            }}
          />
          {value?.length > 0 && <MarbleText style={{ marginTop: '1em' }} />}
          {processing && <LinearProgress variant="indeterminate" />}
          {value?.map((p) => (
            <Grid container justifyContent="center" p={2}>
              <Grid container item xs={0.6}>
                <Description />
              </Grid>
              <Grid
                container
                item
                md={4}
                xs={6}
                onClick={() => {
                  window.open(`${CDN_URL}/${p.key}`, '_blank');
                }}
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                <MarbleText>{p?.label ?? p?.name}</MarbleText>
              </Grid>
              <Grid container item xs={2}>
                <MarbleButton
                  title="X"
                  onClick={() => {
                    handleUpdate(
                      uploadFields.base,
                      uploadFields.id,
                      uploadFields.field,
                      [p],
                      (updated) => {
                        onChange(convertToEvent(name, updated));
                      },
                    );
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </Box>
      );
    case 'address-auto-complete':
      return (
        <>
          <AutoCompleteAddressForm
            address={{
              address_line_1: value?.addr1,
              address_line_2: value?.addr2,
              zipcode: value?.zip,
              city: value?.city,
              state: value?.state,
            }}
            handleChange={(addr) => {
              onChange(
                convertToEvent(name, {
                  addr1: addr.address_line_1,
                  addr2: addr.address_line_2,
                  zip: addr.zipcode,
                  city: addr.city,
                  state: addr.state,
                }),
              );
            }}
            required={required}
          />
          <ErrorText error={error} />
        </>
      );
    case 'checkbox':
      return (
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={value}
                onChange={(e) => {
                  const val = e.target.checked;
                  onChange(convertToEvent(name, val));
                }}
              />
            }
            label={
              <MarbleText size="base" weight="regular">
                {label}
              </MarbleText>
            }
          />
        </FormGroup>
      );
    case 'note':
      return (
        <Grid container flexDirection="column" pl={0}>
          <Grid item pl={0}>
            <MarbleText size="base" weight="bold">
              {label}
            </MarbleText>
          </Grid>
          <Grid item pl={0}>
            <MarbleText size="base">{value}</MarbleText>
          </Grid>
        </Grid>
      );
    case 'checkout':
      return (
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <CostBreakdown
              breakdown={charge.breakdown.map((b) => ({
                item: b.name,
                price: b.value,
              }))}
              total={charge.amount}
            />
          </Grid>
          <br />
          {label && (
            <Grid item xs={12}>
              <MarbleText>{label}</MarbleText>
            </Grid>
          )}
          <Grid item xs={12}>
            <CardInput
              paddingTop={10}
              tryDefault
              setToken={(token) => {
                if (token) {
                  const newCharge: Charge = {
                    ...charge,
                    token: token.id,
                    idempotencyKey: uuid(),
                  };
                  onChange(convertToEvent(name, newCharge));
                }
              }}
            />
          </Grid>
        </Grid>
      );
    case 'dynamic-collection':
      if (!value || !value?.length) {
        const newRowValue = clone(newRow);
        const newValue = [newRowValue];
        onChange(convertToEvent(name, newValue));
      }
      return (
        <Grid container item xs={12} spacing={2} p={0}>
          <ErrorText error={error} />
          {value?.map((data, itemIndex) => (
            <Grid container item xs={12} spacing={2} p={0}>
              <Grid container item xs={12} spacing={2} p={0}>
                {rowLabel && (
                  <Grid container item xs={12} p={0}>
                    <MarbleText size="xl" weight="bold">
                      {rowLabel} {itemIndex + 1}
                    </MarbleText>
                  </Grid>
                )}
                {subForms.map((subItem) => {
                  const handleSetChildForm = (e: React.ChangeEvent<any>) => {
                    const updated = value;
                    updated[itemIndex][subItem.field] = e.target.value;
                    onChange(convertToEvent(name, updated));
                  };
                  return (
                    <Grid container item xs={12} p={0} spacing={1}>
                      <FormStep
                        label={subItem.input.label}
                        name={subItem.field}
                        placeholder={subItem.input.placeholder?.toString()}
                        options={subItem.input.options}
                        color={subItem.input.color ?? 'primary'}
                        required={subItem.input.required}
                        type={subItem.input.type}
                        value={data[subItem.field]}
                        exclusive={subItem.input?.exclusive}
                        uploadFields={subItem.input?.uploadFields}
                        processing={processing}
                        handleUpload={handleUpload}
                        handleUpdate={handleUpdate}
                        minDate={subItem.input?.minDate}
                        maxDate={subItem.input?.maxDate}
                        charge={subItem.input?.charge}
                        newRow={subItem.input?.newRow}
                        rowLabel={subItem.input?.rowLabel}
                        subForms={subItem.input?.subForms}
                        percentBase={subItem.input?.percentBase}
                        secured={subItem.input?.secured}
                        maxLength={subItem.input?.maxLength}
                        dontValidateOnChange={
                          subItem.input.dontValidateOnChange
                        }
                        onChange={handleSetChildForm}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item xs={12} textAlign="center">
                <MarbleButton
                  title="Remove"
                  onClick={() => {
                    value.splice(itemIndex, 1);
                    onChange(convertToEvent(name, value));
                  }}
                />
              </Grid>
              {itemIndex !== value.length - 1 && (
                <Grid item xs={12} p={theme.spacing(4)}>
                  <Divider />
                </Grid>
              )}
            </Grid>
          ))}
          <Grid>
            <MarbleButton
              icon={<AddRounded />}
              title="Add Another"
              onClick={() => {
                const newRowValue = clone(newRow);
                const updated = value.concat(newRowValue);
                onChange(convertToEvent(name, updated));
              }}
            />
          </Grid>
        </Grid>
      );
    case 'quote-chooser':
      return (
        <QuoteChooser
          value={value}
          quoteOptions={quoteOptions}
          error={error}
          onChange={(val) => onChange(convertToEvent(name, val))}
        />
      );
    case 'id-check':
      return (
        <Box>
          <MarbleText
            size="l"
            weight="med"
            sx={(th) => ({
              pl: 1,
              pb: 4,
              [th.breakpoints.up('md')]: {
                width: '50%',
              },
            })}
          >
            How ID Verification Works
          </MarbleText>
          <MarbleText
            size="base"
            sx={(th) => ({
              pl: 1,
              pb: 4,
              [th.breakpoints.up('md')]: {
                width: '50%',
              },
            })}
          >
            Marble partners with Stripe to verify a valid government ID and
            facial match. This data is never stored in Marble and used only for
            verification purposes.
          </MarbleText>

          <MarbleButton
            // eslint-disable-next-line no-nested-ternary
            title={
              value ?
                'Complete' :
                me?.idVerificationPending || act.verified ?
                'Refresh' :
                'Verify Identity'
            }
            endIcon={value ? <MarbleIcon name="check" /> : null}
            variant={value ? 'glass' : 'contained'}
            onClick={() => {
              if (me?.idVerificationPending) {
                refreshMe();
              } else {
                act.validate(() => refreshMe());
              }
            }}
            color={value ? 'green' : 'blue'}
            disabled={value}
          />
          {error && <ErrorText error={error} />}
        </Box>
      );
    case 'satisfaction-rating':
      return (
        <MarbleSatisfactionRating
          label={label}
          value={value}
          error={error}
          onChange={(val) => onChange(convertToEvent(name, val))}
        />
      );
    default:
      return <div />;
  }
}

// @ts-ignore
const convertToEvent = (name, val): React.ChangeEvent<any> => ({
  target: {
    name,
    value: val,
  },
});

const clone = (cloneMe) => {
  if (global?.structuredClone) {
    return structuredClone(cloneMe);
  }
  return JSON.parse(JSON.stringify(cloneMe));
};

export const ErrorText = ({ error }: { error: string | undefined }) =>
  error ? (
    <Container
      style={{
        padding: 0,
        marginTop: theme.spacing(2),
        marginLeft: 0,
        marginRight: 0,
      }}
    >
      <MarbleText size="xs" color="red.main">
        {error}
      </MarbleText>
    </Container>
  ) : null;

export const WarningText = ({ warning }: { warning: string | undefined }) =>
  warning ? (
    <Container
      style={{
        padding: 0,
        marginTop: theme.spacing(2),
        marginLeft: 0,
        marginRight: 0,
      }}
    >
      <MarbleText size="xs" color="black">
        {warning}
      </MarbleText>
    </Container>
  ) : null;
