import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Tooltip,
} from '@mui/material';
import React, { useState } from 'react';
import { ChevronDown, ExclamationCircle } from 'react-bootstrap-icons';
import { Tag } from 'src/base';
import { theme } from 'ui-sdk/src/theme';
import { MarbleButton } from '../inputs';
import { MarbleText } from '../texts/Text';

interface EditAccordionProps {
  title: string;
  summary?: string[];
  onSave?: () => void;
  titleStyle?: React.CSSProperties;
  primaryButtonText?: string;
  removeTopDivider?: boolean;
  removeBottomDivider?: boolean;
  secondaryButtonText?: string;
  secondaryButtonAction?: () => void;
  complete?: boolean;
  disabledSave?: boolean;
  warningText?: string;
  pending?: boolean;
  verified?: boolean;
}

export const EditAccordion: React.FC<EditAccordionProps> = ({
  children,
  complete,
  warningText,
  onSave,
  disabledSave,
  primaryButtonText,
  removeTopDivider,
  removeBottomDivider,
  secondaryButtonText,
  secondaryButtonAction,
  summary,
  title,
  titleStyle,
  pending,
  verified,
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      {!removeTopDivider && (
        <Divider variant="middle" style={{ marginLeft: 0, marginRight: 0 }} />
      )}
      <Accordion
        style={{
          backgroundColor: 'transparent',
          marginTop: theme.spacing(3),
          marginBottom: theme.spacing(3),
        }}
        elevation={0}
        expanded={expanded}
        onChange={() => {
          setExpanded(!expanded);
        }}
      >
        <AccordionSummary
          expandIcon={<ChevronDown size={20} />}
          style={{
            border: 0,
            paddingLeft: 0,
            paddingRight: 0,
            alignItems: 'center',
            boxShadow: 'none',
          }}
          sx={{
            '.MuiAccordionSummary-content': {
              alignItems: 'center',
            },
          }}
        >
          <Grid container direction="column">
            <Grid container justifyContent="space-between">
              <Grid item xs={6} sm={9} md={10} pl={2}>
                <MarbleText style={titleStyle}>{title}</MarbleText>
              </Grid>
              <Grid
                item
                xs={5}
                sm={2}
                md={1}
                sx={{
                  marginRight: {
                    sm: 5,
                    md: 10,
                  },
                  paddingLeft: { xs: 0 },
                }}
              >
                {complete === false && !(pending || verified) && (
                  <Tag color="red" type="secondary">
                    Incomplete
                  </Tag>
                )}
                {complete === true && !warningText && (
                  <Tag color="green" type="secondary">
                    Active
                  </Tag>
                )}
                {complete === true && warningText && (
                  <Tooltip title={warningText}>
                    <ExclamationCircle size={25} color="#f1ce61" />
                  </Tooltip>
                )}
                {complete === false && (pending || verified) && (
                  <Tag color="green" type="primary">
                    Active
                  </Tag>
                )}
              </Grid>
            </Grid>
            {summary?.map((s) => (
              <MarbleText
                style={{
                  lineHeight: 1,
                  paddingLeft: theme.spacing(2),
                }}
                size="s"
              >
                {s}
              </MarbleText>
            ))}
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>{children}</AccordionDetails>
        {onSave && (
          <AccordionActions sx={{ paddingTop: theme.spacing(6) }}>
            <MarbleButton
              title={secondaryButtonText ?? 'Cancel'}
              variant="outlined"
              fullWidth={false}
              onClick={
                secondaryButtonAction ??
                (() => {
                  setExpanded(false);
                })
              }
            />
            <MarbleButton
              title={primaryButtonText ?? 'Save'}
              fullWidth={false}
              onClick={() => {
                onSave();
                setExpanded(false);
              }}
              disabled={disabledSave}
            />
          </AccordionActions>
        )}
      </Accordion>
      {!removeBottomDivider && (
        <Divider variant="middle" sx={{ marginLeft: 0, marginRight: 0 }} />
      )}
    </div>
  );
};
