import { Avatar, Badge, Grid, Tab, Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useMemo } from 'react';
import { XCircle } from 'react-bootstrap-icons';
import { C, P } from 'src/base';
import { useWindowDimensions } from 'ui-sdk/src/hooks';
import { useRouter } from 'ui-sdk/src/router';
import { theme } from 'ui-sdk/src/theme';

export interface SidebarItem {
  title: string;
  path: string;
  value: string;
  warn?: boolean;
  number?: number;
}
interface SidebarProps {
  items: SidebarItem[];
  current: string;
  horizontal?: boolean;
  queryParams?: any;
}

const useStyles = (mobile: boolean) => {
  const make = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      flexDirection: mobile ? undefined : 'row',
      // backgroundColor: theme.palette.background.paper,
      display: mobile ? undefined : 'flex',
    },
    badge: {
      padding: '0 10px',
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
      paddingBottom: 30,
      // flex: 1,
    },
    panel: {
      flex: 1,
      paddingLeft: mobile ? theme.spacing(2) : theme.spacing(4),
      paddingRight: mobile ? theme.spacing(2) : 0,
    },
  }));

  return make();
};

export const Sidebar: React.FC<SidebarProps> = ({
  children,
  current,
  items,
}) => {
  console.log('curr', current);
  const router = useRouter();
  const { width } = useWindowDimensions();
  const mobile = useMemo(() => width < 800, [width]);
  const classes = useStyles(mobile);
  return (
    <div className={classes.root}>
      <Tabs
        orientation={mobile ? 'horizontal' : 'vertical'}
        variant={mobile ? 'scrollable' : 'standard'}
        // @ts-ignore
        scrollButtons="auto"
        allowScrollButtonsMobile
        className={classes.tabs}
        style={{
          border: mobile ? 'none' : undefined,
        }}
        value={current}
      >
        {items.map(({ title, path, value, warn }) => (
          // @ts-ignore
          <Tab
            label={
              <Badge
                badgeContent={<XCircle />}
                color="error"
                className={classes.badge}
                invisible={!warn}
              >
                <P>{title}</P>
              </Badge>
            }
            onClick={() => {
              router.push(path);
            }}
            value={value}
          />
        ))}
      </Tabs>
      <div role="tabpanel" className={classes.panel}>
        {children}
      </div>
    </div>
  );
};

export const SidebarNew: React.FC<SidebarProps> = ({
  children,
  current,
  items,
  horizontal = false,
  queryParams,
}) => {
  console.log('curr', current);
  const router = useRouter();
  const { width } = useWindowDimensions();
  const mobile = useMemo(() => width < 800, [width]);
  const classes = useStyles(mobile || horizontal);
  return (
    <div className={classes.root}>
      <Tabs
        orientation={mobile || horizontal ? 'horizontal' : 'vertical'}
        variant={mobile || horizontal ? 'scrollable' : 'standard'}
        // @ts-ignore
        scrollButtons="auto"
        allowScrollButtonsMobile
        indicatorColor={null}
        style={{ paddingBottom: theme.spacing(6) }}
        value={current}
      >
        {items.map(({ title, path, value, number }) => {
          // @ts-ignore
          const selected = value === current;
          return (
            <Tab
              label={
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid
                    item
                    padding={
                      mobile ?
                        `${theme.spacing(2)} ${theme.spacing(
                            3,
                          )} ${theme.spacing(2)} ${theme.spacing(3)}` :
                        theme.spacing(3)
                    }
                  >
                    <C
                      style={{
                        color: selected ?
                          theme.colors.blue.main :
                          theme.colors.black,
                        fontWeight: selected ? 700 : 400,
                        fontSize: '15px',
                        lineHeight: '22.5px',
                      }}
                    >
                      {title}
                    </C>
                  </Grid>
                  {number && (
                    <Grid
                      item
                      pl={0}
                      pt={0}
                      pb={0}
                      paddingRight={
                        mobile ? theme.spacing(2) : theme.spacing(3)
                      }
                    >
                      <Avatar
                        sx={{
                          width: '24px',
                          height: '24px',
                          padding: 0,
                          bgcolor: selected ?
                            theme.colors.blue.main :
                            theme.colors.gray[4],
                          color: selected ?
                            theme.colors.white :
                            theme.colors.black,
                          fontSize: '10px',
                          fontWeight: 700,
                        }}
                      >
                        {number}
                      </Avatar>
                    </Grid>
                  )}
                </Grid>
              }
              sx={{
                '&.Mui-selected': {
                  backgroundColor: theme.colors.blue.glass,
                },
                borderRadius: '5px',
                padding: '0px',
              }}
              onClick={() => {
                if (queryParams) {
                  router.push({
                    pathname: path,
                    query: queryParams,
                  });
                } else {
                  router.push(path);
                }
              }}
              value={value}
            />
          );
        })}
      </Tabs>
      <div role="tabpanel" className={classes.panel}>
        {children}
      </div>
    </div>
  );
};
