import { Address, Property } from 'server-sdk/src/types';

export const UPDATE_LOCK_ORDER_PROPERTY_ID =
  'onboarding/lock_order_property_id';
export const UPDATE_LOCK_ORDER_ADDRESS = 'onboarding/lock_order_addr';
// export const UPDATE_LOCK_ORDER_STRIPE_TOKEN =
//   'onboarding/lock_order_stripe_token';
export const UPDATE_REFERRAL_CODE = 'onboarding/update_referral_code';
export const UPDATE_IS_INBOUND_AFFILIATE = 'onboarding/update_is_inbound_affiliate';
export const UPDATE_PROPERTY = 'onboarding/property';
export const UPDATE_RULES = 'onboarding/rules';
export const UPDATE_STATUS = 'onboarding/status';

interface UpdateLockOrderPropertyId {
  type: typeof UPDATE_LOCK_ORDER_PROPERTY_ID;
  propertyId: number;
}

interface UpdateLockOrderAddress {
  type: typeof UPDATE_LOCK_ORDER_ADDRESS;
  address: Address;
}

// interface UpdateLockOrderStripeToken {
//   type: typeof UPDATE_LOCK_ORDER_STRIPE_TOKEN;
//   token: any;
// }

interface UpdateReferralCode {
  type: typeof UPDATE_REFERRAL_CODE;
  refCode: string;
}

interface UpdateIsInboundAffiliate {
  type: typeof UPDATE_IS_INBOUND_AFFILIATE;
  isInboundAffiliate: boolean;
}

interface UpdateProperty {
  type: typeof UPDATE_PROPERTY;
  property: Property;
}

export type OnboardingAction =
  | UpdateLockOrderPropertyId
  | UpdateLockOrderAddress
  // | UpdateLockOrderStripeToken
  | UpdateReferralCode
  | UpdateProperty
  | UpdateIsInboundAffiliate;

export const updateLockOrderPropId = (
  propId: number,
): UpdateLockOrderPropertyId => ({
  type: UPDATE_LOCK_ORDER_PROPERTY_ID,
  propertyId: propId,
});

export const updateLockOrderAddr = (addr: Address): UpdateLockOrderAddress => ({
  type: UPDATE_LOCK_ORDER_ADDRESS,
  address: addr,
});

// export const updateLockOrderStripeToken = (
//   token: Token,
// ): UpdateLockOrderStripeToken => ({
//   type: UPDATE_LOCK_ORDER_STRIPE_TOKEN,
//   token,
// });

export const updateReferralCode = (refCode: string): UpdateReferralCode => ({
  type: UPDATE_REFERRAL_CODE,
  refCode,
});

export const updateIsInboundAffiliate = (isInboundAffiliate: boolean): UpdateIsInboundAffiliate => ({
  type: UPDATE_IS_INBOUND_AFFILIATE,
  isInboundAffiliate,
});

export const updateProperty = (property: Property): UpdateProperty => ({
  type: UPDATE_PROPERTY,
  property,
});

interface OnboardingState {
  lockOrder?: {
    propertyId?: number;
    shippingAddr?: Address;
    // stripeToken?: Token;
  };
  property?: Property;
  refCode?: string;
  isInboundAffiliate?: boolean;
}

const initialState: OnboardingState = {};

const reducer = (
  state = initialState,
  action: OnboardingAction,
): OnboardingState => {
  switch (action.type) {
    case UPDATE_LOCK_ORDER_PROPERTY_ID:
      return {
        ...state,
        lockOrder: {
          ...state.lockOrder,
          propertyId: action.propertyId,
        },
      };
    case UPDATE_LOCK_ORDER_ADDRESS:
      return {
        ...state,
        lockOrder: {
          ...state.lockOrder,
          shippingAddr: action.address,
        },
      };
    // case UPDATE_LOCK_ORDER_STRIPE_TOKEN:
    //   return {
    //     ...state,
    //     lockOrder: {
    //       ...state.lockOrder,
    //       stripeToken: action.token,
    //     },
    //   };
    case UPDATE_REFERRAL_CODE:
      return {
        ...state,
        refCode: action.refCode,
      };
    case UPDATE_PROPERTY:
      return {
        ...state,
        property: action.property,
      };
    case UPDATE_IS_INBOUND_AFFILIATE:
      return {
        ...state,
        isInboundAffiliate: action.isInboundAffiliate,
      };
    default:
      return state;
  }
};

export { reducer };
export type { OnboardingState };
