import {
  Address,
  Application,
  DocumentFiles,
  LateFeesData,
  LeaseInfo,
  LeaseSigningType,
  User,
} from "server-sdk/src/types";
import { useApiDispatch, useApiStore } from "./global";
import { cents, usd, useMultiPageForm } from "./useForm";
import {
  validateArray,
  validateBoolean,
  validateNumber,
  validateObject,
  validateString,
} from "../util/validate";
import { useEffect, useState } from "react";
import { api } from "server-sdk/src/api";
import { FlowAction, FlowDefinition } from "server-sdk/src/types/workflow";
import { States } from "../constants";
import { usePortfolioRefresher } from "./property";
import { CONTROL_LEASING_FEE } from "server-sdk/src/constants";
import { DateTime } from "luxon";

const buildSigningChoices = (t: LeaseSigningType | undefined) => {
  switch (t) {
    case LeaseSigningType.Individual:
      return "individual";
    case LeaseSigningType.Business:
      return "business";
    default:
  }
  return undefined;
};

const convertSigningChoice = (t: string | undefined) => {
  switch (t) {
    case "individual":
      return LeaseSigningType.Individual;
    case "business":
      return LeaseSigningType.Business;
    default:
      return undefined;
  }
};
type LeaseSigningChoiceVal = "individual" | "business";

export const buildLateFees = (value: LateFeesData | undefined, base: number | undefined, convertUsd: boolean) => {
  if (value === null || value === undefined) {
    return !!base
      ? {
          choice_conditional: {
            choice: undefined,
            one_time_collection: {
              type_conditional: { type: undefined, value: undefined },
            },
            recurring_collection: {
              initial_fee: undefined,
              recurring_fee: undefined,
            },
          },
          days: undefined,
        }
      : {
          choice_conditional: {
            choice: "one_time",
            one_time_collection: {
              type_conditional: {
                type: "absolute",
                value: base ? convertUsd ? usd(base * 0.05) : base * 0.05 : 100,
              },
            },
            recurring_collection: {
              initial_fee: undefined,
              recurring_fee: undefined,
            },
          },
          days: 2,
        };
  }
  const choice =
    value?.one_time?.absolute !== null || value?.one_time?.percentage !== null
      ? "one_time"
      : value?.recurring?.initial_fee !== null &&
        value?.recurring?.recurring_fee !== null
      ? "recurring"
      : undefined;
  if (choice == "one_time") {
    const type = value.one_time?.absolute ? "absolute" : "percentage";
    const amount = !!type
      ? type === "absolute"
        ? convertUsd ? usd(value.one_time?.absolute) : value.one_time?.absolute
        : convertUsd ? usd(value.one_time?.percentage) : value.one_time?.percentage
      : undefined;
    return {
      choice_conditional: {
        choice,
        one_time_collection: { type_conditional: { type, value: amount } },
        recurring_collection: {
          initial_fee: undefined,
          recurring_fee: undefined,
        },
      },
      days: value.days,
    };
  } else if (choice == "recurring") {
    return {
      choice_conditional: {
        choice,
        one_time_collection: {
          type_conditional: { type: undefined, value: undefined },
        },
        recurring_collection: {
          initial_fee: convertUsd ? usd(value?.recurring?.initial_fee) : value?.recurring?.initial_fee,
          recurring_fee: convertUsd ? usd(value?.recurring?.recurring_fee) : value?.recurring?.recurring_fee,
        },
      },
      days: value.days,
    };
  } else {
    return !base
      ? {
          choice_conditional: {
            choice: undefined,
            one_time_collection: {
              type_conditional: { type: undefined, value: undefined },
            },
            recurring_collection: {
              initial_fee: undefined,
              recurring_fee: undefined,
            },
          },
          days: undefined,
        }
      : {
          choice_conditional: {
            choice: "one_time",
            one_time_collection: {
              type_conditional: { type: "absolute", value: convertUsd ? usd(base * 0.05) : base * 0.05 },
            },
            recurring_collection: {
              initial_fee: undefined,
              recurring_fee: undefined,
            },
          },
          days: 2,
        };
  }
};

export const convertLateFees = (value: { choice_conditional: any; days: any; }): LateFeesData | undefined => {
  if (!value) {
    return undefined;
  }
  let late_fees: LateFeesData = {
    one_time: { absolute: undefined, percentage: undefined },
    recurring: { initial_fee: undefined, recurring_fee: undefined },
    days: value.days,
  };
  if (value.choice_conditional.choice === "one_time") {
    const amount = cents(
      value.choice_conditional.one_time_collection.type_conditional.value
    );
    late_fees.one_time =
      value.choice_conditional.one_time_collection.type_conditional.type ===
      "absolute"
        ? { absolute: amount, percentage: undefined }
        : { absolute: undefined, percentage: amount };
  } else if (value.choice_conditional.choice === "recurring") {
    const initial_fee = cents(
      value.choice_conditional.recurring_collection.initial_fee
    );
    const recurring_fee = cents(
      value.choice_conditional.recurring_collection.recurring_fee
    );
    late_fees.recurring = { initial_fee, recurring_fee };
  }

  return late_fees;
};

export const lateFeesStep = (initialLateFee : any, listingPrice : number | undefined) => ({
  initial: {
    days: initialLateFee.days,
    choice: initialLateFee.choice_conditional.choice,
    one_time_type:
      initialLateFee.choice_conditional.one_time_collection.type_conditional
        .type,
    one_time_value:
      initialLateFee.choice_conditional.one_time_collection.type_conditional
        .value,
    recurring_initial_fee:
      initialLateFee.choice_conditional.recurring_collection.initial_fee,
    recurring_recurring_fee:
      initialLateFee.choice_conditional.recurring_collection.recurring_fee,
  },
  validationSchema: validateObject({
    days: validateNumber()
      .required("A charge day is required")
      .min(1)
      .max(31),
    choice: validateString().required("A late fee type is required"),
    one_time_type: validateString().when("choice", {
      is: "one_time",
      then: validateString().test(
        "oneTimeTypeTest",
        "One time type is required",
        (value) => {
          return !!value;
        }
      ),
    }),
    one_time_value: validateNumber().when("choice", {
      is: "one_time",
      then: validateNumber()
        .typeError("One time price is required")
        .min(0)
        .test("oneTimeTypeTest", "One time price is required", (value) => {
          return !!value || value === 0;
        }),
    }),
    recurring_initial_fee: validateNumber().when("choice", {
      is: "recurring",
      then: validateNumber()
        .typeError("Initial fee is required")
        .min(0)
        .test(
          "recurringInitialTest",
          "Initial fee is required",
          (value) => {
            return !!value || value === 0;
          }
        ),
    }),
    recurring_recurring_fee: validateNumber().when("choice", {
      is: "recurring",
      then: validateNumber()
        .typeError("Recurring fee is required")
        .test(
          "recurringInitialTest",
          "Recurring fee is required",
          (value) => {
            return !!value || value === 0;
          }
        ),
    }),
  }),
  steps: (values) => {
    const finalSteps: any[] = [
      {
        name: "days",
        label:
          "How many days after do you want to start charging for late payments?",
        placeholder:
          "When do you want to start charging for late payments?",
        required: true,
        type: "number",
      },
      {
        name: "choice",
        label: "One time fee or recurring fees until paid?",
        placeholder: "One time fee or recurring fees until paid?",
        required: true,
        type: "radio",
        options: [
          { label: "One Time Fee", value: "one_time" },
          { label: "Recurring Fees", value: "recurring" },
        ],
      },
    ];
    if (values?.late_fees?.choice === "one_time") {
      finalSteps.push({
        name: "one_time_type",
        label: "Percentage of rent or absolute price?",
        placeholder: "Percentage of rent or absolute price?",
        required: true,
        type: "radio",
        options: [
          { label: "Percentage", value: "percentage" },
          { label: "Absolute", value: "absolute" },
        ],
      });
      if (values?.late_fees?.one_time_type === "absolute") {
        finalSteps.push({
          name: "one_time_value",
          label: "One Time Absolute Price",
          placeholder: "One Time Absolute Price",
          required: true,
          type: "usd",
        });
      } else {
        finalSteps.push({
          name: "one_time_value",
          label: "One Time Percentage",
          placeholder: "One Time Percentage",
          required: true,
          type: "percentage",
          percentBase: listingPrice,
        });
      }
    } else {
      finalSteps.push({
        name: "recurring_initial_fee",
        label: "Initial Fee",
        placeholder: "Recurring Initial Fee",
        required: true,
        type: "usd",
      });
      finalSteps.push({
        name: "recurring_recurring_fee",
        label: "Recurring Fee",
        placeholder: "Recurring Fee",
        required: true,
        type: "usd",
      });
    }
    return finalSteps;
  },
})

export const useLeaseReviewForm = (
  application: Application,
  user: User | undefined,
  flowActivityId: number,
  onFinish: (action: any) => void,
  goToUrl: (url: string) => void
) => {
  const apiDispatch = useApiDispatch();
  let applicationLeaseInfo = application?.leaseInfo;
  const initialLateFee = buildLateFees(
    application?.property?.late_fees,
    application?.property?.listing_price,
    true
  );
  const [me, refreshMe] = useApiStore(api => api.users.v2.me);
  useEffect(() => {
    refreshMe();
  }, []);

  const updateValues = (updated: LeaseInfo) => {
    applicationLeaseInfo = updated;
    if (applicationLeaseInfo?.signing_type === LeaseSigningType.Individual) {
      // @ts-ignore
      pages.individual_signers.form.values.addresses =
        applicationLeaseInfo && applicationLeaseInfo?.addresses
          ? applicationLeaseInfo?.addresses?.map((lia) => {
              return {
                lease_info_address_id: lia.id,
                individual_legal_name: lia.individual_legal_name,
                individual_email: lia.individual_email,
                individual_address: {
                  addr1: lia.addr1,
                  addr2: lia.addr2,
                  city: lia.city,
                  state: lia.state,
                  zip: lia.zip,
                },
              };
            })
          : [];
      if (pages?.individual_signers?.form?.values?.addresses?.length === 0) {
        // prefill with data if none
        pages?.individual_signers?.form?.values?.addresses.push({
          lease_info_address_id: undefined,
          individual_legal_name:
            applicationLeaseInfo?.individual_legal_name ??
            `${user?.firstName} ${user?.lastName}`,
          individual_email: user?.email,
          // @ts-ignore
          individual_address:
            applicationLeaseInfo &&
            applicationLeaseInfo?.signing_type === LeaseSigningType.Individual
              ? {
                  addr1: applicationLeaseInfo?.addr1,
                  addr2: applicationLeaseInfo?.addr2,
                  state: applicationLeaseInfo?.state,
                  city: applicationLeaseInfo?.city,
                  zip: applicationLeaseInfo?.zip,
                }
              : undefined,
        });
      }
    }
  };

  const today = new Date();
  const minDate = new Date();
  minDate.setDate(today.getDate() - 1);
  today.setUTCHours(0, 0, 0, 0);

  const move_in_dates : number[] = application.applicants?.map((user) => user.move_in_date ?? 0).filter((date) => !!date && date > new Date().getTime()) ?? []
  const dateMillis = move_in_dates.length > 0 ? Math.min(...move_in_dates) : undefined;
  const earliestMoveInDate = dateMillis ? new Date(dateMillis) : undefined

  const [leaseDocuments, setLeaseDocuments] = useState<DocumentFiles[]>([]);

  useEffect(() => {
    apiDispatch(async (call, api) => {
      const res = await call(api.application.leaseReview.getDocuments);

      if(!application?.leaseInfo){
        applicationLeaseInfo = await call(api.application.leaseReview.create, {
          id: application?.id,
        });
      }

      if (res) {
        setLeaseDocuments(res);
        pages.lease_documents.form.values.lease_documents = res;
        return [true];
      }

      return [false, "Something went wrong loading files"];
    });
  }, []);

  const pages = useMultiPageForm({
    signer_type: {
      initial: {
        signing_choice: applicationLeaseInfo
          ? buildSigningChoices(applicationLeaseInfo.signing_type)
          : buildSigningChoices(LeaseSigningType.Individual),
      },
      validationSchema: validateObject({
        signing_choice: validateString().required("Signing type is required"),
      }),
      label: "Signer",
      description: "What entity will you be signing with?",
      steps: (values) => [
        {
          name: "signing_choice",
          label: "Signing Choice",
          placeholder: "Signing Choice",
          required: true,
          type: "choice",
          exclusive: true,
          options: [
            {
              label: "Individual",
              caption: "Will you be signing as an individual?",
              value: "individual",
            },
            {
              label: "Business",
              caption: "Will you be signing as a business entity?",
              value: "business",
            },
          ],
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const signing_type = convertSigningChoice(
            values.signer_type.signing_choice
          );
          let newLeaseInfo = applicationLeaseInfo;
          if (!applicationLeaseInfo) {
            newLeaseInfo = await call(api.application.leaseReview.create, {
              id: application?.id,
            });
            applicationLeaseInfo = newLeaseInfo
          }
          const updated = await call(api.application.leaseReview.update, {
            id: newLeaseInfo?.id,
            signing_type: signing_type,
          });

          if (updated) {
            updateValues(updated);

            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
    individual_signers: {
      initial: {
        addresses: applicationLeaseInfo
          ? applicationLeaseInfo?.addresses?.map((lia) => {
              return {
                lease_info_address_id: lia.id,
                individual_legal_name: lia.individual_legal_name,
                individual_email: lia.individual_email,
                individual_address: {
                  addr1: lia.addr1,
                  addr2: lia.addr2,
                  city: lia.city,
                  state: lia.state,
                  zip: lia.zip,
                },
              };
            })
          : [],
      },
      validationSchema: validateObject({
        addresses: validateArray().test(
          "addressesCheck",
          "Fill in all fields to continue",
          async (value) => {
            if (!value || value.length === 0) {
              return true;
            }
            for (const address of value) {
              if (
                !address.individual_legal_name ||
                !address.individual_email ||
                !address.individual_address.addr1 ||
                !address.individual_address.city ||
                !address.individual_address.state ||
                !address.individual_address.zip
              ) {
                return false;
              }
            }
            return true;
          }
        ),
      }),
      label: "Individual",
      description: "Fill out all landlord individuals who will sign this lease",
      disabled: (values) => values.signer_type.signing_choice !== "individual",
      steps: (values) => [
        {
          name: "addresses",
          label: "Individual Addresses",
          placeholder: "Individual Addresses",
          required: true,
          type: "dynamic-collection",
          uploadFields: {
            id: application?.id,
            field: "individual",
            base: api.forms.lease.landlordReview,
          },
          newRow: { individual_legal_name: "", individual_address: {} },
          subForms: [
            {
              field: "individual_legal_name",
              input: {
                type: "text",
                label: "Legal Name",
                placeholder: "Legal Name",
                required: true,
              },
            },
            {
              field: "individual_email",
              input: {
                type: "text",
                label: "Email",
                placeholder: "Email",
                required: true,
              },
            },
            {
              field: "individual_address",
              input: {
                label: "Address",
                required: true,
                type: "address-auto-complete",
              },
            },
          ],
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const updated = await call(api.application.leaseReview.update, {
            id: applicationLeaseInfo?.id,
            addresses: values.individual_signers.addresses,
          });

          if (updated) {
            updateValues(updated);

            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
    business: {
      initial: {
        individual_legal_name: applicationLeaseInfo?.individual_legal_name,
        business_name: applicationLeaseInfo?.business_name,
        individual_title: applicationLeaseInfo?.individual_title,
        business_address: {
          addr1: applicationLeaseInfo?.addr1,
          addr2: applicationLeaseInfo?.addr2,
          city: applicationLeaseInfo?.city,
          state: applicationLeaseInfo?.state,
          zip: applicationLeaseInfo?.zip,
        },
      },
      validationSchema: validateObject({
        individual_legal_name: validateString().required(
          "Legal Name is required"
        ),
        business_name: validateString().required("Business Name is required"),
        individual_title: validateString().required(
          "Business Title is required"
        ),
        business_address: validateObject()
          .test(
            "checkAddr1",
            "Enter a valid address",
            // @ts-ignore
            (value) => (value?.addr1?.length ?? 0) >= 1
          )
          .test(
            "checkCity",
            "Enter a valid city",
            // @ts-ignore
            (value) => (value?.city?.length ?? 0) >= 1
          )
          .test("checkState", "Enter a valid state", (value) =>
            States.includes(value?.state)
          )
          .test("checkZip", "Enter valid 5 digit zip code", (value) => {
            const regex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
            return regex.test(value?.zip);
          })
          .required("An adress is required"),
      }),
      label: "Business",
      description: "Fill out your business details",
      disabled: (values) => values.signer_type.signing_choice !== "business",
      steps: (values) => [
        {
          name: "individual_legal_name",
          label: "Legal Name",
          placeholder: "Legal Name",
          required: true,
          type: "text",
        },
        {
          name: "business_name",
          label: "Business Name",
          placeholder: "Business Name",
          required: true,
          type: "text",
        },
        {
          name: "individual_title",
          label: "Business Title",
          placeholder: "Business Title",
          required: true,
          type: "text",
        },
        {
          name: "business_address",
          label: "Business Address",
          placeholder: "Business Address",
          required: true,
          type: "address-auto-complete",
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const updated = await call(api.application.leaseReview.update, {
            id: applicationLeaseInfo?.id,
            ...values.business,
            addr1: values.business.business_address.addr1,
            addr2: values.business.business_address.addr2,
            city: values.business.business_address.city,
            state: values.business.business_address.state,
            zip: values.business.business_address.zip,
          });

          if (updated) {
            updateValues(updated);

            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
    rent_terms: {
      initial: {
        rent_due_day: applicationLeaseInfo?.rent_due_day,
        start_lease: applicationLeaseInfo?.start_lease ?? dateMillis,
        end_lease: applicationLeaseInfo?.end_lease,
      },
      validationSchema: validateObject({
        rent_due_day: validateNumber()
          .transform((value) => (isNaN(value) ? 0 : value))
          .min(1, "Rent due day must be within the month")
          .max(31, "Rent due day must be within the month")
          .required("A rent due day is required."),
        start_lease: validateNumber()
          .min(minDate.getTime(), "Start lease must start after today")
          .required("A start lease date is required."),
        end_lease: validateNumber().test({
          name: 'min',
          exclusive: false,
          params: { },
          message: "Lease must be a minimum of 6 months",
          test: function (value) {
              return DateTime.fromMillis(this.parent.start_lease).plus({months: 6}).minus({days: 1}).toMillis() <= (value ?? 0)
          },
        }).required("A end lease date is required."),
      }),
      label: "Rent Details",
      description: "Fill out the rental details for this lease",
      steps: (values) => {
        let steps: any[] = [
          {
            name: "rent_due_day",
            label: "Rent Due Day (The day of the month when rent is due.)",
            placeholder: "Rent Due Day",
            required: true,
            type: "number",
          },
        ];
        if (dateMillis && dateMillis > new Date().getTime()) {
          steps.push({
            name: "placeholder",
            label: `Applicant's desired start lease: ${earliestMoveInDate?.toLocaleDateString()}`,
            required: false,
            type: "note",
          });
        }
        steps = steps.concat([
          {
            name: "start_lease",
            label: "Start Lease",
            placeholder: "Start Lease",
            required: true,
            type: "date",
            minDate: today.getTime(),
          },
          {
            name: "end_lease",
            label: "End Lease",
            placeholder: "End Lease",
            required: true,
            type: "date",
            minDate: values?.rent_terms?.start_lease,
          },
        ]);
        return steps;
      },
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const updated = await call(api.application.leaseReview.update, {
            id: applicationLeaseInfo?.id,
            ...values.rent_terms,
          });

          if (updated) {
            updateValues(updated);

            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
    is_furnished: {
      initial: {
        is_furnished: applicationLeaseInfo?.is_furnished,
      },
      validationSchema: validateObject({
        is_furnished: validateBoolean(),
      }),
      label: "Furnished",
      description: "Is your property furnished?",
      steps: (values) => [
        {
          name: "is_furnished",
          label: "Property Furnished",
          placeholder: "Property Furnished",
          required: true,
          type: "yes-no",
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const updated = await call(api.application.leaseReview.update, {
            id: applicationLeaseInfo?.id,
            ...values.is_furnished,
          });

          if (updated) {
            updateValues(updated);

            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },

    needs_lead_paint_warning: {
      initial: {
        needs_lead_paint_warning:
          applicationLeaseInfo?.needs_lead_paint_warning,
      },
      validationSchema: validateObject({
        needs_lead_paint_warning: validateBoolean(),
      }),
      label: "Paint Warning",
      description: "Is the property built before 1978?",
      steps: (values) => [
        {
          name: "needs_lead_paint_warning",
          label: "Built Before 1978",
          placeholder: "Built Before 1978",
          required: true,
          type: "yes-no",
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const updated = await call(api.application.leaseReview.update, {
            id: applicationLeaseInfo?.id,
            ...values.needs_lead_paint_warning,
          });

          if (updated) {
            updateValues(updated);

            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },

    additional_clauses: {
      initial: {
        has_additional_clauses: applicationLeaseInfo?.has_additional_clauses,
        additional_clauses: applicationLeaseInfo?.additional_clauses
          ? // @ts-ignore
            applicationLeaseInfo?.additional_clauses["clauses"]
          : [],
      },
      validationSchema: validateObject({
        has_additional_clauses: validateBoolean().required(),
        additional_clauses: validateArray().when("has_additional_clauses", {
          is: true,
          then: validateArray().test(
            "additionalClausesCheck",
            "Fill in all fields to continue",
            async (value) => {
              if (!value) {
                return true;
              }
              for (const clause of value) {
                if (
                  !clause.title ||
                  clause.title.length == 0 ||
                  !clause.body ||
                  clause.body.length == 0
                ) {
                  return false;
                }
              }
              return true;
            }
          ),
        }),
      }),
      label: "Additional Clauses",
      description:
        "Are there any additional clauses you would want in the lease?",
      steps: (values) => {
        const finalSteps: any = [
          {
            name: "has_additional_clauses",
            label: "Add Additional Clauses?",
            placeholder: "Add Additional Clauses?",
            required: true,
            type: "yes-no",
          },
        ];
        if (values?.additional_clauses?.has_additional_clauses) {
          finalSteps.push({
            name: "additional_clauses",
            label: "Additional Clauses",
            placeholder: "Additional Clauses",
            required: true,
            type: "dynamic-collection",
            newRow: { title: "", body: "" },
            subForms: [
              {
                field: "title",
                input: {
                  type: "text",
                  label: "Clause Title",
                  placeholder: "Clause Title",
                  required: true,
                },
              },
              {
                field: "body",
                input: {
                  type: "paragraph",
                  label: "Clause Description",
                  placeholder: "Clause Description",
                  required: true,
                },
              },
            ],
          });
        }
        return finalSteps;
      },
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const updated = await call(api.application.leaseReview.update, {
            id: applicationLeaseInfo?.id,
            has_additional_clauses:
              values?.additional_clauses?.has_additional_clauses,
            additional_clauses: values?.additional_clauses
              ?.has_additional_clauses
              ? {
                  clauses: values?.additional_clauses?.additional_clauses,
                }
              : undefined,
          });

          if (updated) {
            updateValues(updated);

            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
    lease_documents: {
      initial: {
        lease_documents: leaseDocuments,
      },
      validationSchema: validateObject({
        lease_documents: validateArray(),
      }),
      label: "Documents",
      description:
        "Please upload any extra documents that will get appended to the end of your lease (i.e. HOA community rules/regulations)",
      steps: (values) => [
        {
          name: "lease_documents",
          label: "Add Lease Documents",
          required: true,
          type: "upload-documents",
          uploadFields: {
            allowedFileTypes: ["jpg", "jpeg", "png", "pdf"],
            id: application?.id,
            field: "lease_documents",
            base: api.forms.lease.landlordReview,
          },
        },
      ],
      submit: ({ values, done }) => {
        done();
      },
    },

    // State Questions
    AZ: {
      initial: {
        allow_pass_through_tax: applicationLeaseInfo?.allow_pass_through_tax,
      },
      validationSchema: validateObject({
        allow_pass_through_tax: validateBoolean(),
      }),
      label: "State Details",
      description:
        "Fill out all information required by your property's state.",
      disabled: (values) => application?.property?.address?.state !== "AZ",
      steps: (values) => [
        {
          name: "allow_pass_through_tax",
          label: "Do you want to allow pass-through tax?",
          placeholder: "Do you want to allow pass-through tax?",
          required: true,
          type: "yes-no",
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const updated = await call(api.application.leaseReview.update, {
            id: applicationLeaseInfo?.id,
            ...values.AZ,
          });

          if (updated) {
            updateValues(updated);

            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },

    FL: {
      initial: {
        is_property_higher_3_floors:
          applicationLeaseInfo?.is_property_higher_3_floors,
      },
      validationSchema: validateObject({
        is_property_higher_3_floors: validateBoolean(),
      }),
      label: "State Details",
      description:
        "Fill out all information required by your property's state.",
      disabled: (values) => application?.property?.address?.state !== "FL",
      steps: (values) => [
        {
          name: "is_property_higher_3_floors",
          label: "Is your property higher than 3 floors?",
          placeholder: "Is your property higher than 3 floors?",
          required: true,
          type: "yes-no",
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const updated = await call(api.application.leaseReview.update, {
            id: applicationLeaseInfo?.id,
            ...values.FL,
          });

          if (updated) {
            updateValues(updated);

            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },

    GA: {
      initial: {
        has_property_flooded_3_times:
          applicationLeaseInfo?.has_property_flooded_3_times,
      },
      validationSchema: validateObject({
        has_property_flooded_3_times: validateBoolean(),
      }),
      label: "State Details",
      description:
        "Fill out all information required by your property's state.",
      disabled: (values) => application?.property?.address?.state !== "GA",
      steps: (values) => [
        {
          name: "has_property_flooded_3_times",
          label:
            "Has the property been flooded at least 3 times within the past 5 years?",
          placeholder:
            "Has the property been flooded at least 3 times within the past 5 years?",
          required: true,
          type: "yes-no",
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const updated = await call(api.application.leaseReview.update, {
            id: applicationLeaseInfo?.id,
            ...values.GA,
          });

          if (updated) {
            updateValues(updated);

            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },

    IL: {
      initial: {
        is_property_higher_3_floors:
          applicationLeaseInfo?.is_property_higher_3_floors,
        was_radon_found: applicationLeaseInfo?.was_radon_found,
      },
      validationSchema: validateObject({
        is_property_higher_3_floors: validateBoolean(),
        was_radon_found: validateBoolean().nullable(),
      }),
      label: "State Details",
      description:
        "Fill out all information required by your property's state.",
      disabled: (values) => application?.property?.address?.state !== "IL",
      steps: (values) => {
        const finalSteps: any = [
          {
            name: "is_property_higher_3_floors",
            label: "Is the property located on the 3rd floor or higher?",
            placeholder: "Is the property located on the 3rd floor or higher?",
            required: true,
            type: "yes-no",
          },
        ];
        if (values?.IL?.is_property_higher_3_floors) {
          finalSteps.push({
            name: "was_radon_found",
            label: "Was radon found on the property?",
            placeholder: "Was radon found on the property?",
            required: true,
            type: "yes-no",
          });
        }
        return finalSteps;
      },
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const updated = await call(api.application.leaseReview.update, {
            id: applicationLeaseInfo?.id,
            ...values.IL,
          });

          if (updated) {
            updateValues(updated);

            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },

    ME: {
      initial: {
        does_property_have_bed_bugs:
          applicationLeaseInfo?.does_property_have_bed_bugs,
      },
      validationSchema: validateObject({
        does_property_have_bed_bugs: validateBoolean(),
      }),
      label: "State Details",
      description:
        "Fill out all information required by your property's state.",
      disabled: (values) => application?.property?.address?.state !== "ME",
      steps: (values) => [
        {
          name: "does_property_have_bed_bugs",
          label: "Bed Bugs",
          placeholder: "Bed Bugs",
          required: true,
          type: "yes-no",
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const updated = await call(api.application.leaseReview.update, {
            id: applicationLeaseInfo?.id,
            ...values.ME,
          });

          if (updated) {
            updateValues(updated);

            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },

    NJ: {
      initial: {
        does_property_have_more_than_2_units:
          applicationLeaseInfo?.does_property_have_more_than_2_units,
        is_property_flood_zone: applicationLeaseInfo?.is_property_flood_zone,
      },
      validationSchema: validateObject({
        does_property_have_more_than_2_units: validateBoolean(),
        is_property_flood_zone: validateBoolean().nullable(),
      }),
      label: "State Details",
      description:
        "Fill out all information required by your property's state.",
      disabled: (values) => application?.property?.address?.state !== "NJ",
      steps: (values) => {
        const finalSteps: any = [
          {
            name: "does_property_have_more_than_2_units",
            label:
              "Is the property located in a building with more than 2 units?",
            placeholder:
              "Is the property located in a building with more than 2 units?",
            required: true,
            type: "yes-no",
          },
        ];
        if (values?.NJ?.does_property_have_more_than_2_units) {
          finalSteps.push({
            name: "is_property_flood_zone",
            label: "Is your property located in a Flood Zone?",
            placeholder: "Is your property located in a Flood Zone?",
            required: true,
            type: "yes-no",
          });
        }
        return finalSteps;
      },
      resources: (values) => {
        return values?.NJ?.does_property_have_more_than_2_units
          ? [
              {
                name: "is_property_flood_zone",
                title: "Is your property located in a Flood Zone?",
                description: "Visit this page to check your property's status",
                actionTitle: "Flood Zone Look Up Tool",
                action: () => {
                  goToUrl(
                    "http://www.region2coastal.com/view-flood-maps-data/what-is-my-bfe-address-lookup-tool/"
                  );
                },
              },
            ]
          : [];
      },
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const updated = await call(api.application.leaseReview.update, {
            id: applicationLeaseInfo?.id,
            ...values.NJ,
          });

          if (updated) {
            updateValues(updated);

            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },

    NY: {
      initial: {
        has_bed_bugs_within_last_year:
          applicationLeaseInfo?.has_bed_bugs_within_last_year,
        bed_bugs_floor: applicationLeaseInfo?.bed_bugs_floor,
        bed_bugs_eradicated: applicationLeaseInfo?.bed_bugs_eradicated,
      },
      validationSchema: validateObject({
        has_bed_bugs_within_last_year: validateBoolean(),
        bed_bugs_floor: validateNumber().transform((value) =>
          isNaN(value) ? 0 : value
        ).nullable(),
        bed_bugs_eradicated: validateBoolean().nullable(),
      }),
      label: "State Details",
      description:
        "Fill out all information required by your property's state.",
      disabled: (values) => application?.property?.address?.state !== "NY",
      steps: (values) => {
        const finalSteps: any = [
          {
            name: "has_bed_bugs_within_last_year",
            label:
              "Is there a history of a bed bug infestation within the past year?",
            placeholder:
              "Is there a history of a bed bug infestation within the past year?",
            required: true,
            type: "yes-no",
          },
        ];
        if (values?.NY?.has_bed_bugs_within_last_year) {
          finalSteps.push(
            {
              name: "bed_bugs_floor",
              label: "What floor where the bed bugs found?",
              placeholder: "What floor where the bed bugs found?",
              required: true,
              type: "number",
            },

            {
              name: "bed_bugs_eradicated",
              label: "Were they eradicated from the property?",
              placeholder: "Were they eradicated from the property?",
              required: true,
              type: "yes-no",
            }
          );
        }
        return finalSteps;
      },
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const updated = await call(api.application.leaseReview.update, {
            id: applicationLeaseInfo?.id,
            ...values.NY,
          });

          if (updated) {
            updateValues(updated);

            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },

    OK: {
      initial: {
        has_property_flooded_1_time:
          applicationLeaseInfo?.has_property_flooded_1_time,
      },
      validationSchema: validateObject({
        has_property_flooded_1_time: validateBoolean(),
      }),
      label: "State Details",
      description:
        "Fill out all information required by your property's state.",
      disabled: (values) => application?.property?.address?.state !== "OK",
      steps: (values) => [
        {
          name: "has_property_flooded_1_time",
          label: "Has the property flooded at lease once in the past 5 years?",
          placeholder:
            "Has the property flooded at lease once in the past 5 years?",
          required: true,
          type: "yes-no",
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const updated = await call(api.application.leaseReview.update, {
            id: applicationLeaseInfo?.id,
            ...values.OK,
          });

          if (updated) {
            updateValues(updated);

            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },

    OR: {
      initial: {
        is_property_in_100_year_floodplain:
          applicationLeaseInfo?.is_property_in_100_year_floodplain,
        is_property_more_than_4_units:
          applicationLeaseInfo?.is_property_more_than_4_units,
        is_property_in_urban_growth_boundary:
          applicationLeaseInfo?.is_property_in_urban_growth_boundary,
      },
      validationSchema: validateObject({
        is_property_in_100_year_floodplain: validateBoolean(),
        is_property_more_than_4_units: validateBoolean(),
        is_property_in_urban_growth_boundary: validateBoolean(),
      }),
      label: "State Details",
      description:
        "Fill out all information required by your property's state.",
      disabled: (values) =>
        application?.property?.address?.state !== "OR" &&
        application?.property?.address?.city?.toLowerCase() !== "portland",
      steps: (values) => [
        {
          name: "is_property_in_100_year_floodplain",
          label: "Is the property located in the 100 year flood plain?",
          placeholder: "Is the property located in the 100 year flood plain?",
          required: true,
          type: "yes-no",
        },
        {
          name: "is_property_more_than_4_units",
          label: "Is the property more than 4 units?",
          placeholder: "Is the property more than 4 units?",
          required: true,
          type: "yes-no",
        },
        {
          name: "is_property_in_urban_growth_boundary",
          label: "Is the property located in the Urban Growth Boundary",
          placeholder: "Is the property located in the Urban Growth Boundary",
          required: true,
          type: "yes-no",
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const updated = await call(api.application.leaseReview.update, {
            id: applicationLeaseInfo?.id,
            ...values.OR,
          });

          if (updated) {
            updateValues(updated);

            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },

    SD: {
      initial: {
        has_property_produced_meth:
          applicationLeaseInfo?.is_property_higher_3_floors,
      },
      validationSchema: validateObject({
        has_property_produced_meth: validateBoolean(),
      }),
      label: "State Details",
      description:
        "Fill out all information required by your property's state.",
      disabled: (values) => application?.property?.address?.state !== "SD",
      steps: (values) => [
        {
          name: "has_property_produced_meth",
          label: "Was this property used to produce methamphetamines?",
          placeholder: "Was this property used to produce methamphetamines?",
          required: true,
          type: "yes-no",
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const updated = await call(api.application.leaseReview.update, {
            id: applicationLeaseInfo?.id,
            ...values.SD,
          });

          if (updated) {
            updateValues(updated);

            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },

    VA: {
      initial: {
        does_property_have_mold: applicationLeaseInfo?.does_property_have_mold,
        has_defective_drywall: applicationLeaseInfo?.has_defective_drywall,
        is_adjacent_air_base: applicationLeaseInfo?.is_adjacent_air_base,
      },
      validationSchema: validateObject({
        does_property_have_mold: validateBoolean(),
        has_defective_drywall: validateBoolean(),
        is_adjacent_air_base: validateBoolean(),
      }),
      label: "State Details",
      description:
        "Fill out all information required by your property's state.",
      disabled: (values) => application?.property?.address?.state !== "VA",
      steps: (values) => [
        {
          name: "does_property_have_mold",
          label: "Is there an existence of mold currently on the property?",
          placeholder:
            "Is there an existence of mold currently on the property?",
          required: true,
          type: "yes-no",
        },
        {
          name: "has_defective_drywall",
          label: "Is there any defective drywall on the property?",
          placeholder: "Is there any defective drywall on the property?",
          required: true,
          type: "yes-no",
        },
        {
          name: "is_adjacent_air_base",
          label: "Is the property located adjacent to an Air Base?",
          placeholder: "Is the property located adjacent to an Air Base?",
          required: true,
          type: "yes-no",
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const updated = await call(api.application.leaseReview.update, {
            id: applicationLeaseInfo?.id,
            ...values.VA,
          });

          if (updated) {
            updateValues(updated);

            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },

    WA: {
      initial: {
        is_single_family: applicationLeaseInfo?.is_single_family,
      },
      validationSchema: validateObject({
        is_single_family: validateBoolean(),
      }),
      label: "State Details",
      description:
        "Fill out all information required by your property's state.",
      disabled: (values) => application?.property?.address?.state !== "WA",
      steps: (values) => [
        {
          name: "is_single_family",
          label: "Is the property considered a single-family home?",
          placeholder: "Is the property considered a single-family home?",
          required: true,
          type: "yes-no",
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const updated = await call(api.application.leaseReview.update, {
            id: applicationLeaseInfo?.id,
            ...values.WA,
          });

          if (updated) {
            updateValues(updated);

            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },

    late_fees: {
      label: "Late Fees",
      description: "Fill out how you would like late fees to be charged",
      ...lateFeesStep(initialLateFee, usd(application?.property?.listing_price)),
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const convertedLateFee = convertLateFees({
            choice_conditional: {
              choice: values.late_fees.choice,
              one_time_collection: {
                type_conditional: {
                  type: values.late_fees.one_time_type,
                  value: values.late_fees.one_time_value,
                },
              },
              recurring_collection: {
                initial_fee: values.late_fees.recurring_initial_fee,
                recurring_fee: values.late_fees.recurring_recurring_fee,
              },
            },
            days: values.late_fees.days,
          });

          const updated = await call(api.property.update, {
            id: application.property?.id,
            late_fees: convertedLateFee,
          });

          if (updated) {
            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
    leasing_fee_on_movein_consent: {
      initial: {
        yesno: undefined,
      },
      validationSchema: validateObject({
        yesno: validateString()
          .oneOf(
            [
              'yes',
            ],
            'You must approve the leasing fee policy.'
          )
          .required('You must approve the leasing fee policy.'),
      }),
      label: 'Leasing Fee Policy',
      description: `Do you allow Marble to deduct a one-time leasing fee of ${usd(CONTROL_LEASING_FEE)} from the first month\'s rent (after tenant placement)?`,
      disabled: () => me?.experiment === 'd',
      steps: () => [
        {
          name: 'yesno',
          required: true,
          label: 'Approve Leasing Fee',
          type: 'choice',
          exclusive: true,
          options: [
            {
              label: 'I Approve',
              caption: 'Marble will deduct the leasing fee from the first month\'s rent after finding a tenant.',
              value: 'yes',
            },
          ]
        }
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          if (values.leasing_fee_on_movein_consent.yesno === 'yes' && application?.property?.id) {
            const updated = await call(api.property.update, {
              id: application?.property?.id,
              setup_deduct_consent: true,
            });
            if (!updated) {
              return [false, "Something went wrong"];
            }

            done();
            return [true];
          }

          return [false];
        });
      },
    },
    thank_you: {
      initial: {
        thank_you: "",
      },
      validationSchema: validateObject({}),
      label: "Conclusion",
      description:
        "Your lease will now be created! Any other additions and edits will be available on the lease review page.",
      steps: (values) => [],
      submit: ({ values, done }) => {
        console.log("values", values);
        // all finished.
        apiDispatch(async (call, api) => {
          const leaseInfo = await call(api.application.leaseReview.addGuid, {
            id: application?.id,
          });
          if (!leaseInfo) {
            return [false, "Something went wrong"];
          }
          const res = await call(api.flows.complete, {
            id: flowActivityId,
            metadata: {
              applicationId: application.id,
              guid: leaseInfo.landlord_guid,
            },
          });

          if (res) {
            done();
            const action = res.activity as FlowAction<FlowDefinition>;
            onFinish(action);
            return [true];
          }
          return [false, "Something went wrong"];
        });
      },
    },
  });

  return pages;
};

export const useMoveInInstructionsForm = (
  application: Application,
  flowActivityId: number,
  onFinish: () => void
) => {
  const apiDispatch = useApiDispatch();
  let applicationLeaseInfo = application?.leaseInfo;

  const pages = useMultiPageForm({
    move_in_instructions: {
      initial: {
        instructions: applicationLeaseInfo?.move_in_instructions,
      },
      validationSchema: validateObject({
        instructions: validateString().required(
          "Move in instructions are required"
        ),
      }),
      label: "Instructions",
      description:
        "Fill in any move-in instructions you want to relay to the tenant to have a successful onboarding",
      steps: (values) => [
        {
          name: "instructions",
          label: "Move In Instructions",
          placeholder: "Move In Instructions",
          required: true,
          type: "paragraph",
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const updated = await call(api.application.leaseReview.update, {
            id: applicationLeaseInfo?.id,
            move_in_instructions: values?.move_in_instructions?.instructions,
          });

          if (updated) {
            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
    utilities_assignment: {
      initial: {
        water_company: applicationLeaseInfo?.water_company ?? "",
        electric_company: applicationLeaseInfo?.electric_company ?? "",
        gas_company: applicationLeaseInfo?.gas_company ?? "",
        garbage_sewage_company:
          applicationLeaseInfo?.garbage_sewage_company ?? "",
      },
      validationSchema: validateObject({
        water_company: validateString().required(
          "A water utility company is required"
        ),
        electric_company: validateString().required(
          "An electricity utility company is required"
        ),
        gas_company: validateString().required(
          "A gas utility company is required"
        ),
        garbage_sewage_company: validateString().required(
          "A garbage & sewage utility company is required"
        ),
      }),
      label: "Utilities",
      description:
        "Enter in the companies responsible for your property's utilities",
      steps: (values) => [
        {
          name: "water_company",
          label: "Water Utility Company",
          placeholder: "Water Utility Company",
          required: true,
          type: "text",
        },
        {
          name: "electric_company",
          label: "Electricity Utility Company",
          placeholder: "Electricity Utility Company",
          required: true,
          type: "text",
        },
        {
          name: "gas_company",
          label: "Gas Utility Company",
          placeholder: "Gas Utility Company",
          required: true,
          type: "text",
        },
        {
          name: "garbage_sewage_company",
          label: "Garbage & Sewage Utility Company",
          placeholder: "Garbage & Sewage Utility Company",
          required: true,
          type: "text",
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const updated = await call(api.application.leaseReview.update, {
            id: applicationLeaseInfo?.id,
            ...values?.utilities_assignment,
          });
          if (!updated) {
            return [false, "Something went wrong"];
          }
          const res = await call(api.flows.complete, {
            id: flowActivityId,
          });

          if (res) {
            done();
            const action = res.activity as FlowAction<FlowDefinition>;
            onFinish();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
  });

  return pages;
};

interface BankInfo {
  bankAcctInfo: {
    routingNumber: string | undefined;
    accountNumber: string | undefined;
  };
  addr: any;
  ssn: number | undefined;
  dob: any;
}

export const useBankOnboardingForm = (
  flowActivityId: number,
  onFinish: (params: BankInfo) => Promise<boolean>
) => {
  const apiDispatch = useApiDispatch();
  const refresh = usePortfolioRefresher();
  const minDate = new Date();
  const maxDate = new Date();

  const pages = useMultiPageForm({
    user_address: {
      initial: {
        address: {
          addr1: undefined,
          addr2: undefined,
          city: undefined,
          state: undefined,
          zip: undefined,
        },
      },
      validationSchema: validateObject({
        address: validateObject()
          .test(
            "checkAddr1",
            "Enter a valid address",
            // @ts-ignore
            (value) => (value?.addr1?.length ?? 0) >= 1
          )
          .test(
            "checkCity",
            "Enter a valid city",
            // @ts-ignore
            (value) => (value?.city?.length ?? 0) >= 1
          )
          .test("checkState", "Enter a valid state", (value) =>
            States.includes(value?.state)
          )
          .test("checkZip", "Enter valid 5 digit zip code", (value) => {
            const regex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
            return regex.test(value?.zip);
          })
          .required("An adress is required"),
      }),

      label: "Address",
      description: "What is your address?",
      steps: (values) => [
        {
          name: "address",
          label: "Your Address",
          placeholder: "Your Address",
          required: true,
          type: "address-auto-complete",
        },
      ],
      submit: ({ values, done }) => {
        done();
        return;
      },
    },
    bank_info: {
      initial: {
        dob: new Date().getTime(),
        ssn: undefined,
        accountNum: undefined,
        routingNum: undefined,
      },
      validationSchema: validateObject({
        dob: validateNumber().required("A Date of Birth is required"),
        ssn: validateString().length(4, 'Submission is not 4 digits').required("An SSN is required"),
        accountNum: validateString().required("An Account Number is required"),
        routingNum: validateString().required("A Routing Number is required"),
      }),
      label: "Bank Info",
      description: "Fill out your bank information",
      steps: (values) => [
        {
          name: "dob",
          label: "Date of Birth",
          placeholder: "Date of Birth",
          required: true,
          type: "date",
        },
        {
          name: "ssn",
          label: "Last 4 SSN",
          placeholder: "Last 4 SSN",
          required: true,
          type: "text",
          secured: true,
          maxLength: 4,
        },
        {
          name: "accountNum",
          label: "Bank Account Number",
          placeholder: "Bank Account Number",
          required: true,
          type: "text",
        },
        {
          name: "routingNum",
          label: "Bank Routing Number",
          placeholder: "Bank Routing Number",
          required: true,
          type: "text",
          maxLength: 9,
        },
      ],
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const date_of_birth = new Date(values.bank_info.dob);

          try {
            const result = await onFinish({
              bankAcctInfo: {
                accountNumber: values?.bank_info?.accountNum,
                routingNumber: values?.bank_info?.routingNum,
              },
              addr: {
                line1: values?.user_address?.address?.addr1,
                line2: values?.user_address?.address?.addr2,
                city: values?.user_address?.address?.city,
                state: values?.user_address?.address?.state,
                postal_code: values?.user_address?.address.zip,
              },
              ssn: values.bank_info.ssn,
              dob: {
                day: date_of_birth.getDate(),
                month: date_of_birth.getMonth() + 1,
                year: date_of_birth.getFullYear(),
              },
            });

            if (!result) {
              return [false];
            }
            const res = await call(api.flows.complete, {
              id: flowActivityId,
            });
            if (res) {
              done();
              return [true];
            }
            return [false];
          } catch (err) {
            console.warn(err);
            global.Error("Invalid bank details");
            return [false];
          }
        });
      },
    },
  });
  return pages;
};
