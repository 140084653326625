import { WebThunk } from '../store';
import { Inbox, LeasingInbox, LeasingSMS, LeasingTexts } from 'server-sdk/src/types';
import { call } from '../call';
import { api } from 'server-sdk/src/api';

enum InboxActionTypes {
  UpdateChats = 'inbox/update_chats',
  UpdateLeasingChats = 'inbox/update_leasing_chats',
  UpdateLeasingSMS = 'inbox/update_leasing_sms',
  UpdateLeasingTexts = 'inbox/update_leasing_texts',
}

export type InboxType = Inbox | LeasingInbox;

interface InboxState {
  chats: Inbox;
  leasing: LeasingInbox;
  leasingSMS: LeasingSMS;
  leasingTexts: LeasingTexts;
}

export const updateChats = (chats: Inbox) => ({
  type: InboxActionTypes.UpdateChats,
  chats,
});

export const updateLeasingChats = (chats: LeasingInbox) => ({
  type: InboxActionTypes.UpdateLeasingChats,
  chats,
});

export const updateLeasingSMS = (chats: LeasingSMS) => ({
  type: InboxActionTypes.UpdateLeasingSMS,
  chats,
});

export const updateLeasingTexts = (chats: LeasingTexts) => ({
  type: InboxActionTypes.UpdateLeasingTexts,
  chats,
});
export const inboxReducer = (
  state: InboxState = {
    chats: {
      messages: [],
    },
    leasing: {
      messages: [],
    },
    leasingSMS: {
      messages: [],
    },
    leasingTexts: {
      chats: [],
    },
  },
  action: ReturnType<typeof updateChats>,
): InboxState => {
  switch (action.type) {
    case InboxActionTypes.UpdateChats:
      return {
        ...state,
        chats: action.chats,
      };
    case InboxActionTypes.UpdateLeasingChats:
      return {
        ...state,
        leasing: action.chats,
      };
    case InboxActionTypes.UpdateLeasingSMS:
      return {
        ...state,
        leasingSMS: action.chats,
      };
    case InboxActionTypes.UpdateLeasingTexts:
      return {
        ...state,
        leasingTexts: action.chats,
      };
    default:
      return state;
  }
};

export const refreshLeasingSMS =
  (page: number = 1): WebThunk =>
  async (dispatch) => {
    const leasingSMS = await call(api.inbox.v2.sms, {
      page,
    });
    dispatch(updateLeasingSMS(leasingSMS));
  };

export const refreshLeasingTexts =
  (page: number = 1): WebThunk =>
  async (dispatch) => {
    const leasingTexts = await call(api.inbox.v2.texts, {
      page,
    });
    dispatch(updateLeasingTexts(leasingTexts));
  };

export const refreshInbox =
  (page: number = 1): WebThunk =>
  async (dispatch) => {
    const inbox = await call(api.inbox.v2.chats, {
      page,
    });
    // console.log(inbox);
    // inbox.messages = inbox.messages.slice(0, 100)
    dispatch(updateChats(inbox));
  };

export const refreshLeasingInbox =
  (page: number = 1): WebThunk =>
  async (dispatch) => {
    const inbox = await call(api.inbox.v2.leasingInbox, {
      page,
    });
    dispatch(updateLeasingChats(inbox));
  };
