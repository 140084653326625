import {
  Box,
  ButtonBase,
  Card as MuiCard,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { MarbleIcon } from 'src/base/outputs/icons';
import { Gray } from 'src/Constants';
import { theme } from 'ui-sdk/src/theme';
import { Tag, TagProps } from '../texts';
import { MarbleText } from '../texts/Text';

const PREFIX = 'Card';

const classes = {
  root: `${PREFIX}-root`,
  media: `${PREFIX}-media`,
};

const StyledMuiCard = styled(MuiCard)({
  [`& .${classes.root}`]: {
    maxWidth: 345,
  },
  [`& .${classes.media}`]: {
    height: 200,
  },
});

interface CardProps {
  style?: React.CSSProperties;
  onClick?: () => void;
}

export const Card: React.FC<CardProps> = ({ children, onClick, style }) => (
  // <Grow
  //   in
  //   timeout={750}
  // >
  <Paper
    style={{
      borderRadius: 5,
      border: '1px solid',
      borderColor: Gray,
      ...style,
    }}
    elevation={0}
    onClick={onClick}
  >
    <div>{children}</div>
  </Paper>
  // </Grow>
);

interface MediaCardProps {
  image: string;
}

export const MediaCard: React.FC<CardProps & MediaCardProps> = ({
  image,
  onClick,
  children,
}) => (
  <StyledMuiCard
    onClick={onClick}
    elevation={0}
    style={{
      border: 'none',
    }}
  >
    <CardMedia
      className={classes.media}
      image={image}
      style={{
        borderRadius: 5,
        /* stylelint-disable */
        border: `1px solid ${Gray.toString()}`,
        /* stylelint-enable */
      }}
    />
    <div
      style={{
        paddingTop: theme.spacing(1),
      }}
    >
      {children}
    </div>
  </StyledMuiCard>
);

interface CardActionLayoutProps {
  style?: React.CSSProperties;
  isMe?: boolean;
  title: string;
  description: string;
  tagText: string;
  tagProps: TagProps;
  additionalTag?: any;
}

const cardShadow = { boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.15)' };

export const CardActionLayout: React.FC<CardActionLayoutProps> = ({
  isMe,
  title,
  description,
  tagText,
  tagProps,
  additionalTag,
}) => (
  <Box
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: 0,
      height: '100%',
    }}
  >
    <CardContent style={{ paddingBottom: 0 }}>
      <Grid
        container
        sx={{
          pb: 2,
        }}
      >
        {tagText && <Tag {...tagProps}>{tagText}</Tag>}
        <Grid item pl={1} pr={1}>
          {additionalTag}
        </Grid>
      </Grid>
      <MarbleText size="l" weight="bold" color={isMe ? 'white' : 'black'}>
        {title}
      </MarbleText>
      <MarbleText size="s" weight="regular" color={isMe ? 'white' : 'gray.1'}>
        {description}
      </MarbleText>
    </CardContent>
    {isMe && (
      <CardContent
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          paddingBottom: theme.spacing(2),
          paddingRight: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <MarbleText
            color="gray.5"
            size="s"
            sx={{
              pr: 1,
            }}
          >
            START
          </MarbleText>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <MarbleIcon name="chevron-right" color="white" />
          </Box>
        </Box>
      </CardContent>
    )}
  </Box>
);

interface CardRowLayoutProps {
  style?: React.CSSProperties;
  prefix?: React.ReactElement;
  title?: string;
  metadata?: string;
  suffix?: React.ReactElement;
}

const rowCardStyles = makeStyles(() => ({
  root: {
    cursor: 'pointer',
  },
  cardHeaderAction: {
    margin: 'auto',
  },
}));

export const CardRowLayout: React.FC<CardRowLayoutProps> = ({
  title,
  suffix,
  metadata,
}) => {
  const rowClasses = rowCardStyles();
  const [expanded, setExpanded] = React.useState(false);
  return (
    <ButtonBase
      onClick={() => {
        setExpanded(!expanded);
      }}
      sx={{
        width: '100%',
        justifyContent: 'space-between',
      }}
    >
      <CardHeader
        // avatar={prefix}
        title={
          <MarbleText
            size="s"
            weight="regular"
            color="black"
            align="left"
            justifyContent="space-between"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              // WebkitLineClamp: expanded ? 'unset' : 1,
              // lineClamp: expanded ? 'unset' : 1,
            }}
          >
            {title}
          </MarbleText>
        }
        subheader={
          <MarbleText
            size="xs"
            color="gray.1"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
              lineClamp: 1,
            }}
          >
            {metadata}
          </MarbleText>
        }
        action={suffix}
        classes={{
          root: rowClasses.root,
          action: rowClasses.cardHeaderAction,
        }}
        sx={{
          width: '100%',
          justifyContent: 'space-between',
        }}
      />
    </ButtonBase>
  );
};

interface ElevatedCardProps {
  style?: React.CSSProperties;
  children: React.ReactElement | React.ReactElement[];
  backgroundColor?: string;
}

export const ElevatedCard: React.FC<ElevatedCardProps> = ({
  style,
  children,
  backgroundColor = theme.colors.white,
}) => (
  <MuiCard
    variant="elevation"
    sx={{
      borderRadius: theme.spacing(1),
      backgroundColor,
      ...cardShadow,
      ...style,
    }}
  >
    {children}
  </MuiCard>
);
