import { FormControl, FormControlLabel, RadioGroup } from '@mui/material';
import React from 'react';
import { InputListProps, InputProps } from 'src/base/inputs/props';
import MarbleRadio from './MarbleRadio';

interface RadioProps {
  color?: 'primary' | 'secondary' | 'default';
}

export const RadioList: React.FC<RadioProps & InputProps & InputListProps> = ({
  name,
  onBlur,
  onChange,
  options,
  value,
  color,
}) => (
  <FormControl component="fieldset">
    <RadioGroup
      aria-label={name}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    >
      {options.map((option) => (
        <FormControlLabel
          value={option.value}
          control={<MarbleRadio color={color} />}
          label={option.label}
          checked={value?.toString() === option.value?.toString()}
        />
      ))}
    </RadioGroup>
  </FormControl>
);
