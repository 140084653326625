import {
  createTheme,
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from '@mui/material';
import React from 'react';
import { DarkGray, MarbleBlue } from 'src/base/Constants';
import { theme as marbleTheme } from 'ui-sdk/src/theme';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const theme = createTheme({
  spacing: 5,
  shape: {
    borderRadius: 5,
  },
  palette: {
    primary: {
      main: MarbleBlue,
    },
    info: {
      main: DarkGray,
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    fontFamily: 'Inter, Arial, sans-serif, Lora',
    h1: {
      fontSize: '250%',
      fontWeight: 500,
    },
    h2: {
      fontWeight: 500,
      fontSize: '200%',
    },
    h3: {
      fontWeight: 500,
      fontSize: '175%',
    },
    body1: {
      fontSize: '110%',
      fontWeight: 400,
    },
    caption: {
      fontSize: '100%',
      color: '#222222',
      fontWeight: 400,
    },
  },
  // @ts-ignore
  components: {
    MuiGrid: {
      styleOverrides: {
        item: {
          paddingLeft: 15,
          paddingRight: 15,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        disableElevation: true,
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        text: {
          fontSize: '50%',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          // weird hack to get rid of top border on accordion
          '&:before': {
            height: 0,
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        hover: {
          cursor: 'pointer',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: marbleTheme.colors.green.glass,
          color: marbleTheme.colors.green.main,
        },
        standardError: {
          backgroundColor: marbleTheme.colors.red.glass,
          color: marbleTheme.colors.red.main,
        },
      },
    },
  },
});

export const { palette } = theme;

export const MarbleThemeProvider: React.FC = ({ children }) => (
  <StyledEngineProvider>
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </StyledEngineProvider>
);
