import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { call } from '../call';
import { api } from 'server-sdk/src/api';
import { Lock, Shipment } from 'server-sdk/src/types';
import { validateNumber, validateObject, validateString } from '../util/validate';
import { useGlobal, useForm } from '.';
import {
  refreshLocks,
  refreshPortfolio,
} from '../ducks/portfolio';

export const useLockOrderForm = (shipment: Shipment) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(refreshPortfolio());
  }, []);
  const { success, error } = useGlobal();

  const quantity = useForm({
    initial: {
      quantity: shipment.quantity,
      item: shipment.item,
    },
    validationSchema: validateObject({
      item: validateNumber().required(),
    }),
    submit: () => {
      address.validate();
    },
  });

  const address = useForm({
    initial: {
      ...shipment.address,
    },
    validationSchema: validateObject({
      addr1: validateString()
        .required('Street address is required.')
        .min(1, 'Enter a valid address'),
      addr2: validateString(),
      city: validateString()
        .required('City is required.')
        .min(1, 'Enter a valid city'),
      state: validateString()
        .required('State is required')
        .min(2, 'Enter valid state'),
      zip: validateString()
        .required('Zip code is required.')
        .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Enter valid 5 digit zip code'),
    }),
    submit: (values) => {
      dispatch(async () => {
        const s = await call(api.shipment.start, {
          ...shipment,
          quantity: quantity.values.quantity,
          item: quantity.values.item,
          address: values,
        });
        if (s) {
          setFinalShipment(s);
        } else {
          error('Failed to get shipment data');
        }
      });
    },
  });

  const [finalShipment, setFinalShipment] = useState<Shipment>(shipment);

  const confirmShipment = (token: string, idempotencyKey: string) => {
    dispatch(async () => {
      await call(api.shipment.confirm, {
        ...finalShipment,
        charge: {
          ...finalShipment.charge,
          idempotencyKey,
          token,
        },
      });
      success('Lock order has been submitted!');
      dispatch(refreshPortfolio());
    });
  };

  return {
    address,
    quantity,
    confirmShipment,
    finalShipment,
  };
};

export const useIglooLock = () => {
  const dispatch = useDispatch();
  const global = useGlobal();

  const [pin, setPin] = useState(undefined);

  const getPermanentPin = (lockId: number) => {
    dispatch(async () => {
      try {
        const p = await call(api.lock.pin.get, {
          lockId,
        });

        setPin(p.pin);
      } catch (e) {
        console.warn(e);
        global.error();
      }
    });
  };

  return {
    pin,
    getPermanentPin,
  };
};

export const useLockSetup = () => {
  const dispatch = useDispatch();
  const global = useGlobal();

  const setup = (lock: Lock) => {
    dispatch(async () => {
      try {
        const c = await call(api.lock.create, lock);
        const p = await call(api.lock.install, {
          lock: {
            id: c.id,
          },
          property: {
            id: lock.propertyId,
          },
        });
        if (!c || !p) {
          global.error();
          return;
        }
        await call(api.flows.tryComplete, {
          propertyId: lock.propertyId,
          actionType: 'setup-lock',
          flowType: 'listing-setup'
        });

        dispatch(refreshLocks());
      } catch (e) {
        global.error();
      }
    });
  };

  const remove = async (lockId: number) => {
    dispatch(async () => {
      try {
        const dl = await call(api.lock.delete, {
          id: lockId,
        });
        if (!dl) {
          global.error();
          return;
        }
        dispatch(refreshLocks());
      } catch (e) {
        global.error();
      }
    });
  };

  return {
    setup,
    remove,
  };
};
