import { ButtonBase } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { ErrorText } from 'src/base/inputs';
import { MarbleText } from 'src/base/texts/Text';
import { theme } from 'ui-sdk/src/theme';

interface MarbleSatisfactionRatingProps {
  value: number;
  onChange: any;
  label?: string;
  error?: string;
}

const MarbleSatisfactionRating: React.FC<MarbleSatisfactionRatingProps> = ({
  label,
  value,
  error,
  onChange,
}) => {
  const MAX_RATING = 5;

  return (
    <Grid container>
      {label && (
        <MarbleText weight="med" size="s">
          {label}
        </MarbleText>
      )}
      <Grid
        item
        container
        style={{
          flexDirection: 'row',
          marginTop: theme.spacing(2),
        }}
      >
        {[...Array(MAX_RATING)].map((_, index) => (
          <Grid item style={{ flexDirection: 'column' }}>
            <ButtonBase
              onClick={() => onChange(index + 1)}
              style={{ width: '100%' }}
            >
              <Grid
                item
                sx={{
                  border: `2px solid ${theme.colors.gray[4]}`,
                  borderRadius: '5px',
                  marginRight: theme.spacing(1),
                  marginLeft: theme.spacing(1),
                  paddingRight: theme.spacing(4),
                  paddingLeft: theme.spacing(4),
                  paddingTop: theme.spacing(2),
                  paddingBottom: theme.spacing(2),
                  ...(index + 1 === value && {
                    backgroundColor: theme.colors.blue.main,
                    borderColor: theme.colors.blue.main,
                  }),
                }}
              >
                <MarbleText
                  size="s"
                  color={index + 1 === value ? 'white' : 'black'}
                >
                  {index + 1}
                </MarbleText>
              </Grid>
            </ButtonBase>
            {index === 0 && (
              <Grid item style={{ marginTop: theme.spacing(1) }}>
                <MarbleText size="xxs" color="gray.1">
                  Not Satisfied
                </MarbleText>
              </Grid>
            )}
            {index === MAX_RATING - 1 && (
              <Grid item style={{ marginTop: theme.spacing(1) }}>
                <MarbleText size="xxs" color="gray.1">
                  Very Satisfied
                </MarbleText>
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
      <ErrorText error={error} />
    </Grid>
  );
};

export default MarbleSatisfactionRating;
