/* eslint-disable react/require-default-props */
import { Box, Button, Typography } from '@mui/material';
import Image from 'next/image';
import React from 'react';

export const Logo: React.FC<LogoProps> = ({
  size = 30,
  variant = 'default',
  onlyIcon = false,
  onClick,
}) => (
  <Button disableRipple disableElevation onClick={onClick}>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <Image width={size} height={size} src={`/img/logo_svg/${variant}.svg`} />
      {!onlyIcon && (
        <Box
          sx={{
            paddingLeft: 2,
            color: variant === 'outline-white' ? 'white' : undefined,
            fontSize: size / 1.4,
          }}
        >
          <Typography variant="body1">Marble</Typography>
        </Box>
      )}
    </Box>
  </Button>
);

interface LogoProps {
  size?: number;
  variant?:
    | 'default'
    | 'outline-black'
    | 'outline-blue'
    | 'outline-white'
    | 'transparent';
  onlyIcon?: boolean;
  onClick?: () => void;
}
