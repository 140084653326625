import { THEME_MULTIPLIER } from "./constants";

let themeOpts: MarbleThemeOptions = {
  multiplier: THEME_MULTIPLIER,
  usePx: false,
};

type MarbleThemeOptions = {
  multiplier?: number;
  usePx?: boolean;
};

export const initTheme = (options: MarbleThemeOptions) => {
  themeOpts = {
    ...themeOpts,
    ...options,
  };
}

const createColorSet = <T extends { [key: string]: string }>(colors: T) => colors;

const grayColors = createColorSet({
  1: '#666666',
  2: '#999999',
  3: '#CCCCCC',
  4: '#E5E5E5',
  5: '#F7F7F7',
});

const blueColors = createColorSet({
  main: '#3171ef',
  hover: '#3258BC',
  glass: '#ECF0FD',
});

const greenColors = createColorSet({
  main: '#00873E',
  hover: '#006C32',
  glass: '#E5F3EC',
});

const redColors = createColorSet({
  main: '#D32437',
  hover: '#A91D2C',
  glass: '#FBE9EB',
});

const black = '#000000';
const white = '#FFFFFF'
const iris = '#5D5FEF';
export type BlackColors = typeof black;
export type WhiteColors = typeof white;
export type GrayColors = typeof grayColors[keyof typeof grayColors];
export type BlueColors = typeof blueColors[keyof typeof blueColors];
export type GreenColors = typeof greenColors[keyof typeof greenColors];
export type RedColors = typeof redColors[keyof typeof redColors];

export type ColorName =
  | 'black'
  | 'white'
  | 'iris'
  | 'gray.1'
  | 'gray.2'
  | 'gray.3'
  | 'gray.4'
  | 'gray.5'
  | 'blue.main'
  | 'blue.hover'
  | 'blue.glass'
  | 'green.main'
  | 'green.hover'
  | 'green.glass'
  | 'red.main'
  | 'red.hover'
  | 'red.glass'

export function getColor(key: ColorName): string {
  const path = ['colors'].concat(key.split('.'));
  let value: any = theme;
  for (const p of path) {
    value = value[p];
  }
  return value;
};

export const theme = {
  colors: {
    black,
    white,
    iris,
    gray: grayColors,
    blue: blueColors,
    green: greenColors,
    red: redColors,
  },
  spacing: (scale: number) => {
    if (themeOpts.usePx) {
      // @ts-ignore
      return `${scale * themeOpts.multiplier}px`;
    }
    // @ts-ignore
    return scale * themeOpts.multiplier;
  },
  shadows: {
    sm: {
      x: 0,
      y: 5,
      b: 20,
      s: 0,
      color: '#000000',
      opacity: 0.15,
    },
    md: {
      x: 0,
      y: 10,
      b: 20,
      s: 0,
      color: '#000000',
      opacity: 0.15,
    },
    lg: {
      x: 0,
      y: 15,
      b: 25,
      s: 0,
      color: '#000000',
      opacity: 0.15,
    },
  },
  radius: {
    base: 5,
    large: 10,
  },
};

export type Shadow = 'sm' | 'md' | 'lg'

export type Theme = typeof theme;
export type Color = keyof typeof theme.colors;
export type ColorWeight = keyof typeof theme.colors.blue;

export type MarbleInputProps = {
  focus?: boolean;
  error?: string;
  required?: boolean;
  label?: 'top' | 'left' | 'right'
  placeholder?: string;
  value?: string;
}

export type TextSize =
  | 'h1'
  | 'h2'
  | 'xl'
  | 'l'
  | 'base'
  | 's'
  | 'xs'
  | 'xxs'

export type TextWeight =
  | 'bold'
  | 'med'
  | 'regular'

export type TextColor = ColorName;

export interface TextProps {
  size?: TextSize;
  weight?: TextWeight;
  color?: TextColor;
  opacity?: number;
}
