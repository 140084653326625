import { useEffect, useState } from 'react';

export const useQuery = (key: string) => {
  const [value, setValue] = useState('');
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setValue(params.get(key)!);
  }, []);

  return value;
};
