import React, { useEffect, useState } from 'react';
import { CostBreakdown } from 'src/base';
import { Dialog, Grid, List, ListItem, ListItemText } from 'src/deps/mui';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ListItemIcon,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  MARBLE_SUBSCRIPTION_PRICE_ANNUAL,
  MARBLE_SUBSCRIPTION_PRICE,
} from 'src/Constants';
import { MarbleText } from 'src/base/texts/Text';
import { Check, ExpandMore } from '@mui/icons-material';
import { CardInput } from 'src/components/app/stripe';
import { useApiDispatch, useApiStore } from 'ui-sdk/src/hooks';
import { track } from 'src/deps/mixpanel';
import { MarbleButton } from '../inputs';

interface PayWallProps {
  paid: boolean;
  id: number;
  onFinish?: () => void;
}

export const PayWall: React.FC<PayWallProps> = ({
  children,
  paid,
  id,
  onFinish,
}) => {
  const [modal, setModal] = useState(false);

  return (
    <>
      <div
        style={{
          cursor: !paid ? 'pointer' : undefined,
        }}
        onClick={() => {
          if (!paid) {
            setModal(true);
          }
        }}
      >
        <div
          style={{
            pointerEvents: !paid ? 'none' : undefined,
          }}
        >
          {children}
        </div>
      </div>
      <PayWallModal
        modal={modal}
        setModal={setModal}
        id={id}
        onFinish={onFinish}
      />
    </>
  );
};

interface PayWallModalProps {
  modal;
  setModal;
  id;
  onFinish?: () => void;
}

export const PayWallModal: React.FC<PayWallModalProps> = ({
  modal,
  setModal,
  id,
  onFinish,
}) => {
  // @ts-ignore
  const isMobile = useMediaQuery((th) => th.breakpoints.down('sm'));

  const [cycle] = useState<'monthly' | 'annual'>('monthly');

  const [open, setOpen] = useState(false);
  const [token, setToken] = useState(undefined);

  const dispatch = useApiDispatch();
  const [defaultPaymentMethod, refreshDefaultPaymentMethod] = useApiStore(
    (api) => api.payment.methods.default,
  );

  useEffect(() => {
    refreshDefaultPaymentMethod({});
    track('Paywall Displayed');
  }, []);

  const handleClick = () => {
    dispatch(async (call, api) => {
      if (!defaultPaymentMethod && !token) {
        return [false, 'Payment method not valid.'];
      }

      const p = await call(api.property.subscribe, {
        propertyId: id,
        creationToken: token?.id,
      });

      if (!p) {
        return [false, 'Something went wrong'];
      }

      if (onFinish) {
        onFinish();
      }
      setModal(false);
      return [true];
    });
  };

  return (
    <Dialog
      onClose={() => setModal(false)}
      open={modal}
      fullWidth
      maxWidth="lg"
      sx={{
        minWidth: '100%',
        p: 0,
      }}
    >
      <Grid
        container
        sx={(theme) => ({
          flexDirection: 'row',
          p: 8,
          [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            p: 2,
          },
        })}
      >
        <Grid item xs={12} sm={6} p={0}>
          <Grid
            container
            sx={(theme) => ({
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              [theme.breakpoints.down('sm')]: {
                height: '100%',
                pb: 6,
              },
            })}
          >
            <Grid
              item
              sx={(theme) => ({
                [theme.breakpoints.down('sm')]: {
                  pb: 6,
                  pt: 2,
                },
              })}
            >
              <MarbleText size="xl" weight="bold" pb={1}>
                Activate Subscription
              </MarbleText>
              <MarbleText weight="med">
                One final step to enable Premium Management on your property.
              </MarbleText>
            </Grid>
            <Grid item>
              <MarbleText
                size={isMobile ? 'xl' : 'h2'}
                weight="bold"
                color="blue.main"
              >
                $
                {cycle === 'annual' ?
                  MARBLE_SUBSCRIPTION_PRICE_ANNUAL / 100 :
                  MARBLE_SUBSCRIPTION_PRICE / 100}{' '}
                / month per unit
              </MarbleText>
              {/* <MarbleText
                    size="l"
                    weight="med"
                    color="gray.2"
                    sx={{
                      pb: 4,
                    }}
                  >
                    + $
                    {cycle === 'annual' ?
                      SETUP_FEE_ANNUAL / 100 :
                      SETUP_FEE / 100}{' '}
                    leasing fee
                  </MarbleText> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={(theme) => ({
            pl: 10,
            [theme.breakpoints.down('sm')]: {
              pl: 2,
            },
          })}
        >
          <Accordion
            expanded={open || !isMobile}
            onChange={() => {
              setOpen(!open);
            }}
            elevation={0}
          >
            <AccordionSummary
              sx={(theme) => ({
                [theme.breakpoints.up('sm')]: {
                  display: 'none',
                },
              })}
              expandIcon={<ExpandMore />}
            >
              <MarbleText
                align="center"
                color="gray.1"
                sx={{
                  width: '100%',
                }}
              >
                Features
              </MarbleText>
            </AccordionSummary>
            <AccordionDetails>
              <List sx={{}}>
                {[
                  'Self-guided Tours',
                  'Concierge Leasing Team',
                  'Lease Generation & E-Signatures',
                  '24/7 Maintenance Dispatch',
                  'Utility Invoicing',
                  'Rent Payments + Late Fee Management',
                  'Financial Reports',
                ].map((line) => (
                  <ListItem sx={{ marginBottom: -2, marginTop: -2 }}>
                    <ListItemIcon sx={{ marginRight: -3, ml: -2 }}>
                      <Check color="success" />
                    </ListItemIcon>
                    <ListItemText key={line}>
                      <Typography variant="subtitle1">{line}</Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid xs={12}>
          <Box
            sx={{
              pt: 6,
              pl: 3,
              pr: 3,
            }}
          >
            <Box
              sx={{
                pb: 2,
              }}
            >
              <MarbleText weight="bold" size="l">
                Checkout Summary
              </MarbleText>
            </Box>
            <Box
              sx={{
                pb: 2,
              }}
            >
              <MarbleText>
                {!defaultPaymentMethod ?
                  '14 Day Free Trial. Cancel anytime.' :
                  'The new charges below will be applied to your current subscription.'}
              </MarbleText>
            </Box>
            <CostBreakdown
              breakdown={[
                // {
                //   item: `Setup fee (1x)`,
                //   price: cycle === 'annual' ? SETUP_FEE_ANNUAL : SETUP_FEE,
                // },
                {
                  item: `Subscription fee (${
                    cycle === 'annual' ? 'annual' : 'monthly'
                  })`,
                  price: defaultPaymentMethod ? MARBLE_SUBSCRIPTION_PRICE : 0,
                },
              ]}
              total={defaultPaymentMethod ? MARBLE_SUBSCRIPTION_PRICE : 0}
            />
            {!defaultPaymentMethod && (
              <MarbleText
                size="s"
                color="gray.1"
                sx={{
                  pt: 3,
                }}
              >
                For security purposes, you may notice a temporary authorization
                of $9.99 on your card. This authorization is temporary and will
                be released after 7 days.
              </MarbleText>
            )}
          </Box>

          <CardInput setToken={setToken} tryDefault paddingTop={15} />

          <MarbleButton
            title="Activate Marble Premium"
            fullWidth
            onClick={handleClick}
            sx={{
              mt: 4,
            }}
          />
          <MarbleButton
            title="Cancel"
            color="red"
            variant="nobg"
            fullWidth
            onClick={() => {
              setModal(false);
            }}
            sx={{
              mt: 2,
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};
