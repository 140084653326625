import React, { useEffect, useState } from 'react';
import 'react-image-lightbox/style.css';
import { PropertyPhoto } from 'server-sdk/types';
import reactImageSize from 'react-image-size';
import { Button, Dialog, Grid } from '@mui/material';
import Image from 'next/image';
import { PhotoGalleryRow } from './PhotoGalleryRow';

interface PhotoGallaryPreviewProp {
  photos: PropertyPhoto[];
}

export const GenerateImage = (src, setModalOpen) => (
  <Image
    src={src}
    layout="fill"
    objectFit="cover"
    style={{
      cursor: 'pointer',
    }}
    onClick={() => setModalOpen(true)}
    placeholder="empty"
    priority
  />
);

export const PhotoGalleryPreview = ({ photos }: PhotoGallaryPreviewProp) => {
  const [galleryPhotos, setGalleryPhotos] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const getGalleryPhotos = async () => {
      const photosWithDimentions = await Promise.all(
        photos.map(async (p) => {
          const { width, height } = await reactImageSize(p.src);
          return {
            id: p.id,
            src: p.src,
            height,
            width,
          };
        }),
      );
      setGalleryPhotos(photosWithDimentions);
    };
    getGalleryPhotos();
  }, [photos]);

  return (
    <>
      <Grid container sx={{ overflow: 'hidden', position: 'relative' }}>
        {photos?.length >= 3 ? (
          <Grid container>
            <Grid container item pr={1} xs={12} md={8} sx={{ height: '100%' }}>
              <Grid item xs={12} sx={{ height: '100%', position: 'relative' }}>
                {GenerateImage(photos[0]?.src, setModalOpen)}
              </Grid>
            </Grid>
            <Grid container item p={0} rowGap={1} xs={0} md={4}>
              <Grid
                item
                xs={12}
                sx={{ width: 'auto', position: 'relative', height: '50%' }}
              >
                {GenerateImage(photos[1]?.src, setModalOpen)}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ width: 'auto', position: 'relative', height: '50%' }}
              >
                {GenerateImage(photos[2]?.src, setModalOpen)}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          photos?.length >= 1 && (
            <Grid container>
              <Grid item xs={12}>
                {GenerateImage(photos[0]?.src, setModalOpen)}
              </Grid>
            </Grid>
          )
        )}
        {photos?.length > 0 && (
          <Button
            style={{
              backgroundColor: 'white',
              border: 'black 1px solid',
              padding: '4px 12px',
              borderRadius: 8,
              color: 'black',
              fontSize: 15,
              textTransform: 'none',
              bottom: '5%',
              right: '5%',
              position: 'absolute',
              backgroundSize: 'contain',
              zIndex: 1,
            }}
            onClick={() => setModalOpen(true)}
          >
            <div style={{ marginRight: 5 }}>
              <GridIcon />
            </div>
            Show all photos
          </Button>
        )}
      </Grid>
      <Dialog
        fullWidth
        maxWidth="lg"
        style={{ zIndex: 10 }}
        onClose={() => {
          setModalOpen(false);
        }}
        open={modalOpen}
      >
        <PhotoGalleryRow photos={galleryPhotos} />
      </Dialog>
    </>
  );
};

export const GridIcon = () => (
  <svg
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    role="presentation"
    focusable="false"
    style={{ display: 'block', height: 16, width: 16, fill: 'currentcolor' }}
  >
    <path
      d="m3 11.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm-10-5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm-10-5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3z"
      fillRule="evenodd"
    />
  </svg>
);
