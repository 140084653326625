import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import { Grow } from 'src/base/animations';
import { Progress } from 'src/base/feedback/Progress';
import { H3 } from 'src/base/texts/H3';
import { P } from 'src/base/texts/P';
import { StepperProps } from 'src/base/nav/props';
import { Button } from 'src/base/inputs/Button';

export const HorizontalStepper: React.FC<StepperProps> = ({
  steps,
  noProgress,
}) => {
  const [index, setIndex] = useState(0);
  const prev = () => {
    if (index > 0) {
      const prevStep = steps[index - 1];
      if (!prevStep.skip) {
        setIndex(index - 1);
      } else {
        const skippedIndex = index - 2;
        if (skippedIndex >= 0) {
          setIndex(skippedIndex);
        }
      }
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
  };
  const next = () => {
    const { submit } = steps[index];
    if (submit) {
      submit();
    }
    if (index < steps.length - 1) {
      const nextStep = steps[index + 1];
      if (!nextStep.skip) {
        setIndex(index + 1);
      } else {
        const skippedIndex = index + 2;
        if (skippedIndex <= steps.length - 1) {
          setIndex(skippedIndex);
        }
      }
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
  };
  return (
    <div>
      {steps.map(({ label }, i) => (
        <div>
          {index === i && (
            <div>
              <H3>{label}</H3>
            </div>
          )}
        </div>
      ))}
      {!noProgress && (
        <Progress
          style={{
            paddingBottom: 10,
          }}
          current={index}
          max={steps.length}
        />
      )}
      {steps.map(({ content, description }, i) => (
        <div>
          <Grow on={index === i}>
            <P>{description}</P>
            <div
              style={{
                paddingTop: 10,
              }}
            >
              {content}
            </div>
          </Grow>
        </div>
      ))}
      {index === 0 && (
        <Grid
          style={{
            paddingTop: 30,
          }}
          container
          // justify="flex-end"
        >
          <Button
            fullWidth={false}
            title="Begin"
            onClick={next}
            disabled={steps[index].disabled}
            color="primary"
          />
        </Grid>
      )}
      {index > 0 && (
        <Grid
          container
          // justify="space-between"
          style={{
            paddingTop: 30,
          }}
        >
          <Grid item>
            <Button fullWidth={false} title="Back" onClick={prev} />
          </Grid>
          <Grid item>
            <Button
              fullWidth={false}
              title={index < steps.length - 1 ? 'Next' : 'Submit'}
              onClick={next}
              disabled={steps[index].disabled}
              color="primary"
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};
