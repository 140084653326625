import React from 'react';
import { InputProps } from 'src/base/inputs/props';
import { Num } from 'src/components/ServerForm2';
import { MarbleTextInput, MarbleTextInputProps } from './MarbleTextInput';

interface MarbleNumberInputProps {
  numberType?: 'usd' | 'percentage';
  decimals?: number;
}

export const MarbleNumberInput: React.FC<
  MarbleNumberInputProps & MarbleTextInputProps & InputProps
> = ({
  autoFocus,
  error,
  focused,
  inputRef,
  rows,
  multiline,
  name,
  onBlur,
  onChange,
  placeholder,
  required,
  type,
  value,
  inputId,
  inputProps,
  removeLabel,
  numberType,
  decimals,
}) => (
  <MarbleTextInput
    autoFocus={autoFocus}
    error={error}
    focused={focused}
    inputRef={inputRef}
    mask="num"
    rows={rows}
    multiline={multiline}
    name={name}
    onBlur={onBlur}
    onChange={onChange}
    placeholder={placeholder}
    required={required}
    type={type}
    value={value}
    inputId={inputId}
    inputProps={{
      ...inputProps,
      inputMode: 'decimal',
      pattern: '[0-9,.]*',
      ...(numberType === 'percentage' && {
        suffix: '%',
        fixedDecimalScale: true,
        allowNegative: false,
        decimalScale: decimals ?? 0,
      }),
      ...(numberType === 'usd' && {
        prefix: '$',
        thousandsGroupStyle: 'thousand',
        fixedDecimalScale: true,
        allowNegative: false,
        decimalScale: decimals ?? 0,
      }),
    }}
    removeLabel={removeLabel}
    inputComponent={Num}
  />
);
