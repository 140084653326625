import { useMemo } from 'react';
import { ABVariant, AB_COOKIE } from 'server-sdk/src/types';
import {
  setExperimentSaveFn,
  setExperimentGetFn,
} from 'ui-sdk/src/util/experiment';

export function initExperiment() {
  setExperimentGetFn(() => {
    try {
      return getCookie(AB_COOKIE) as ABVariant;
    } catch (e) {
      console.error(e);
    }
    return undefined;
  });

  setExperimentSaveFn((expGroup: string) => {
    try {
      setCookie(AB_COOKIE, expGroup);
    } catch (e) {
      console.error(e);
    }
  });
}

export function getCookie(name) {
  if (typeof document === 'undefined') return undefined;
  if (!document?.cookie) return undefined;

  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

function setCookie(name: string, value: string, days?: number) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
}

export const useCookie = (name: string) =>
  useMemo(() => getCookie(name), [name]);
