import { Property } from "./property";
import { User } from "./user";

export enum ChargeType {
  Application,
  Rent,
  Shipment,
  PrepaidListings,
  PrepaidLeasingAssistant,
  PropertySetupFee,
};

export interface Charge {
  id?: number,
  date?: number
  amount?: number
  breakdown?: SubCharge[]
  token?: string
  idempotencyKey?: string
  type?: ChargeType
  stripe_status?: string
}

export interface SubCharge {
  name?: string
  value?: number
};

export interface RentPayment {
  sender?: User
  charge?: Charge
  property?: Property
}

export interface MonthlyCashTotal {
  month: string;
  payments: RentPayment[];
}

export interface CheckoutSession {
  priceId?: string
  sessionUrl?: string
  sessionId?: string
}

export interface PaymentIntentRequest {
  currency: string;
  amount: number;
}

export interface PaymentIntentResponse {
  key: string;
}

export interface StripePayout {
  id: string;
  amount: number;
  creationTime: number;
  statementDescriptor: string;
  arrivalDate: number;
  payoutCharges: StripePayoutCharge[];
}

export interface StripePayoutCharge {
  amount: number;
  creationTime: number;
  renterName: string;
  property: Property;
}

export interface OwnerBankInfo {
  balance: number;
  payouts: StripePayout[];
}

export enum RentAdjustmentType {
  ETC = 1,
  RENT = 2,
  DEPOSIT = 3,
  ACH_BOUNCE = 4,
}

export interface RentAdjustment {
  id: number;
  renter_user_id?: number;
  unregistered_renter_id?: number;
  amount: number; // can be pos or neg to indicate bill or credit
  reason: string;
  paid: boolean;
  creation_time: number;
  type: RentAdjustmentType; // 2 is base rent, 1 is etc rn
  due_date: number;
}

export interface PaymentMethod {
  id?: number;
  brand?: string;
  last4?: string;
  creationTime?: number;
  lastUsedTime?: number;
  type?: string; // native_pay or card
}

export interface DefaultPaymentMethod {
  paymentMethod?: PaymentMethod;
}

export interface CreateBillingPortal {
  link?: string;
  isMobile?: boolean;
}