import { useState } from "react";
import { useDispatch } from "react-redux";
import { ChatType } from "server-sdk/src/types";
import { FormBase, FormBase2 } from "server-sdk/src/api";
import marbleApi, { call } from "../call";

type CompressFn = (file: File) => Promise<Blob>;

export const useFileUpload = (compress: CompressFn) => {
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);

  const handleUpload = async (
    base: FormBase,
    id: number,
    field: string,
    e: any,
    cb: (files: any[]) => void,
    guid?: string
  ) => {
    const blobs: Blob[] = [];
    if (!e.target) {
      for (const f of e) {
        blobs.push(f.data);
      }
    }
    const files: Blob[] = e.target ? Array.from(e.target.files) : blobs;
    console.log(files)
    const res = await fileUpload(
      base.upload.add.path,
      id,
      files,
      cb,
      compress,
      setProcessing,
      field,
      guid
    );
    return res;
  };

  const handleUpdate = (
    base: FormBase,
    id: number,
    field: string,
    files: any[],
    cb: (res: string[]) => void,
    guid?: string
  ) => {
    setProcessing(true);
    dispatch(async () => {
      const updated = await call(
        base.upload.update,
        {
          id,
          field,
          files,
        },
        {
          headers: {
            guid,
          },
        }
      );

      console.log("photo updated", updated);
      setProcessing(false);
      cb(updated?.files);
    });
  };

  const handleDelete = (
    base: FormBase2,
    id: number,
    field: string,
    files: any[],
    cb: (res: string[]) => void,
    guid?: string
  ) => {
    setProcessing(true);
    dispatch(async () => {
      const updated = await call(
        base.upload.delete,
        {
          id,
          field,
          files,
        },
        {
          headers: {
            guid,
          },
        }
      );

      console.log("photo updated", updated);
      setProcessing(false);
      cb(updated?.files);
    });
  };

  return {
    processing,
    handleUpload,
    handleUpdate,
    handleDelete,
  };
};

export const usePhotoChat = (
  chatId: number,
  chatType: ChatType,
  compress: CompressFn
) => {
  const [uploading, setUploading] = useState(false);
  const [count, setCount] = useState(0);
  const onChange = async (e) => {
    const files: Blob[] = Array.from(e.target.files);
    setUploading(true);
    setCount(files.length);
    console.log(files);

    await Promise.all(
      Array.from(files.entries()).map(async ([idx, file]) => {
        // @ts-ignore
        let url;
        let blob;
        let fileType;
        let name;
        if (file.type.startsWith("image")) {
          const compressedFile = await compress(<File>file);
          url = URL.createObjectURL(compressedFile);
          blob = await fetch(url).then((r) => r.blob());
          fileType = "image";
        } else if (file.type.startsWith("video")) {
          url = URL.createObjectURL(file);
          blob = await fetch(url).then((r) => r.blob());
          fileType = "video";
        } else if (file.type === "application/pdf") {
          url = URL.createObjectURL(file);
          blob = await fetch(url).then((r) => r.blob());
          fileType = "pdf";
          // @ts-ignore
          name = file.name;
        }
        const formData = new FormData();
        formData.append("media", blob);
        formData.append("sortKey", `${idx}`);
        console.log(`fd: ${JSON.stringify(formData.get("media"))}`);
        await marbleApi.post(
          name ? `/inbox/${chatType}/${chatId}/${fileType}/upload?name=${name}` : `/inbox/${chatType}/${chatId}/${fileType}/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data; charset=utf-8;",
            },
            timeout: 20000,
          }
        );
      })
    );

    setUploading(false);
  };

  return {
    uploading,
    count,
    onChange,
  };
};

export const useFlowUpload = (compress: CompressFn) => {
  const [processing, setProcessing] = useState(false);

  const handleUpload = async (
    commentId: number,
    files: any,
    cb: (files: any[]) => void
  ) => {
    const res = await fileUpload(
      `/flows/upload`,
      commentId,
      files,
      cb,
      compress,
      setProcessing
    );
    return res;
  };

  return {
    handleUpload,
    processing,
  };
};

const fileUpload = async (
  apiRoute: string,
  id: number,
  files: Blob[],
  cb: (files: any[]) => void,
  compress: CompressFn,
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>,
  field?: string,
  guid?: string
) => {
  setProcessing(true);
  const failedUploads: string[] = [];
  const compressedFiles = await Promise.all(
    files
      .map(async (file) => {
        try {
          let blob;
          if (file.type === "application/pdf") {
            const url = URL.createObjectURL(file);
            blob = await fetch(url).then((r) => r.blob());
          } else {
            const compressedFile = await compress(<File>file);
            const url = URL.createObjectURL(compressedFile);
            blob = await fetch(url).then((r) => r.blob());
          }
          return {
            blob,
            file: file as File,
          };
        } catch (e) {
          console.error("Could not compress file:", (file as File).name);
          failedUploads.push((file as File).name);
          return undefined;
        }
      })
      .filter((compressedFile) => compressedFile !== undefined)
  );

  const uploads: any[] = [];
  async function handleConcurrency(files: any) {
    for (const response of files) {
      if (response !== undefined) {
        const blob = response.blob;
        const file = response.file;
        const formData = new FormData();
        formData.append("id", `${id}`);
        if (field) {
          formData.append("field", field);
        }
        formData.append("name", file.name);
        formData.append("type", blob.type);
        formData.append("media", blob);

        try {
          const res = await marbleApi.post(apiRoute, formData, {
            headers: {
              "Content-Type": "multipart/form-data; charset=utf-8;",
              guid,
            },
            timeout: 60000,
          });
          console.log(res)
          if (res.status !== 200) {
            failedUploads.push(file.name);
            return null;
          }
          console.log("finished upload");
          uploads.push(res.data.file);
        } catch (e) {
          failedUploads.push(file.name);
          console.error("Failed to upload ", file.name);
          return null;
        }
      } else {
        return null;
      }
    }
  }

  const concurrencyLimit = 2;
  const uploadWorkers = new Array(concurrencyLimit)
    .fill(compressedFiles.values())
    .map(handleConcurrency);

  await Promise.allSettled(uploadWorkers);

  let ok;
  if (uploads) {
    const flattenedUploads = [].concat(...uploads);
    ok = flattenedUploads.filter((u) => u !== null);
    console.log("ok", ok);
    if (failedUploads.length > 0) {
      console.log("failed", failedUploads.join(" "));
    }
    cb(ok);
  } else {
    console.error("Could not complete uploads");
    console.log("failed", failedUploads.join(" "));
  }
  setProcessing(false);
  return {
    success: ok,
    failed: failedUploads,
  };
};
