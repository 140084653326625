import React from 'react';

interface VideoProps {
  src: string;
}

export const Video = ({ src }: VideoProps) => (
  <video
    loop
    controls
    style={{
      width: '100%',
    }}
  >
    <source src={src} type="video/mp4" />
  </video>
);
