import { InputLabel } from '@mui/material';
import { InputBaseComponentProps } from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';
import React from 'react';
import NumberFormat from 'react-number-format';
import { InputProps } from 'src/base/inputs/props';
import { theme as marbleTheme, theme } from 'ui-sdk/src/theme';

export interface MarbleTextInputProps {
  autoFocus?: boolean;
  required?: boolean;
  type?: string;
  mask?: 'phone' | 'otp' | 'usd' | 'num' | 'percent';
  focused?: boolean;
  rows?: number;
  multiline?: boolean;
  inputRef?: React.Ref<any>;
  inputId?: string;
  inputProps?: any;
  removeLabel?: boolean;
  textFieldProps?: any;
  inputComponent?: any;
  params?: any;
  secured?: boolean;
  maxLength?: number;
}

export const Num: React.FC<InputBaseComponentProps> = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    // @ts-ignore
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        console.log('changing values', values);
        onChange({
          target: {
            // @ts-ignore
            name: props.name,
            value: parseFloat(values.value),
          },
        });
      }}
      value={props.value ?? ''}
      style={{
        outline: 'none',
        border: 0,
      }}
    />
  );
};

const PhoneMask: React.FC<InputBaseComponentProps> = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    // @ts-ignore
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            // @ts-ignore
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="+1 (###) ###-####"
      style={{
        outline: 'none',
        border: 0,
      }}
    />
  );
};

const CodeMask: React.FC<InputBaseComponentProps> = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    // @ts-ignore
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            // @ts-ignore
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="###-###"
      style={{
        outline: 'none',
        border: 0,
      }}
    />
  );
};

const UsdMask: React.FC<InputBaseComponentProps> = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    // @ts-ignore
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            // @ts-ignore
            name: props.name,
            value: values.value,
          },
        });
      }}
      prefix="$"
      thousandsGroupStyle="thousand"
      fixedDecimalScale
      allowNegative={false}
      decimalScale={2}
      style={{
        outline: 'none',
        border: 0,
      }}
    />
  );
};

const PercentMask: React.FC<InputBaseComponentProps> = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    // @ts-ignore
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            // @ts-ignore
            name: props.name,
            value: values.value,
          },
        });
      }}
      suffix="%"
      fixedDecimalScale
      allowNegative={false}
      numberType="percentage"
      decimalScale={2}
      style={{
        outline: 'none',
        border: 0,
      }}
    />
  );
};

export const MarbleTextInput: React.FC<MarbleTextInputProps & InputProps> = ({
  autoFocus,
  error,
  focused,
  inputRef,
  mask,
  rows,
  multiline,
  name,
  onBlur,
  onChange,
  placeholder,
  required,
  type,
  value,
  inputId,
  inputProps,
  removeLabel,
  inputComponent,
  params,
  secured,
  maxLength,
}) => (
  <>
    {!removeLabel && (
      <InputLabel
        // classes={{ root: classes.label, asterisk: classes.labelAsterisk }}
        required={required}
        sx={{
          '& .MuiFormLabel-asterisk': {
            color: theme.colors.red.main,
          },
          fontSize: '15px',
          marginBottom: theme.spacing(1),
          fontWeight: 700,
          color: marbleTheme.colors.black,
          lineHeight: '150%',
        }}
      >
        {placeholder}
      </InputLabel>
    )}
    <TextField
      fullWidth
      id={inputId}
      inputRef={inputRef}
      autoFocus={autoFocus}
      error={Boolean(error)}
      focused={focused}
      helperText={error}
      multiline={multiline}
      name={name}
      placeholder={placeholder}
      onBlur={onBlur}
      onChange={onChange}
      required={required}
      rows={rows}
      type={secured ? 'password' : type}
      value={value}
      sx={{
        '& .MuiOutlinedInput-root': {
          fontSize: '17.5px',
          fontWeight: '500',
          lineHeight: '150%',
          fontStyle: 'normal',
          padding: '0px',
          color: marbleTheme.colors.gray[1],
          background: marbleTheme.colors.gray[5],
          '&.Mui-error fieldset': {
            border: `2px solid rgba(211, 36, 55, .5)`, // red with 50% opacity
          },
          '&.Mui-focused': {
            padding: '0px',
          },
          '&.Mui-focused fieldset': {
            border: `2px solid rgba(49, 113, 239, .5)`, // blue with 50% opacity
          },
          '&.Mui-focused .MuiOutlinedInput-input': {
            backgroundColor: marbleTheme.colors.white,
          },
          '&.MuiOutlinedInput-root input, &.MuiOutlinedInput-root textarea': {
            padding: `${marbleTheme.spacing(2)} ${marbleTheme.spacing(3)}`,
          },
          '&.MuiOutlinedInput-root fieldset': {
            padding: 0,
          },
        },
        '& .MuiOutlinedInput-root fieldset': {
          borderWidth: '0px',
        },
        '& .MuiFormHelperText-root': {
          marginLeft: 0,
          marginRight: 0,
          fontSize: '12.5px',
        },
        '& .MuiFormHelperText-root .Mui-error': {
          color: marbleTheme.colors.red.main,
        },
      }}
      InputLabelProps={{ shrink: value !== undefined && value !== null }}
      {...(mask === 'phone' && {
        InputProps: {
          // ...inputProps,
          inputProps: {
            ...inputProps,
            maxLength: maxLength ?? undefined,
          },
          inputComponent: PhoneMask,
        },
      })}
      {...(mask === 'otp' && {
        InputProps: {
          // ...inputProps,
          inputProps: {
            ...inputProps,
            maxLength: maxLength ?? undefined,
          },
          inputComponent: CodeMask,
        },
      })}
      {...(mask === 'usd' && {
        InputProps: {
          // ...inputProps,
          inputProps: {
            ...inputProps,
            maxLength: maxLength ?? undefined,
          },
          inputComponent: UsdMask,
        },
      })}
      {...(mask === 'percent' && {
        InputProps: {
          // ...inputProps,
          inputProps: {
            ...inputProps,
            maxLength: maxLength ?? undefined,
          },
          inputComponent: PercentMask,
        },
      })}
      {...(mask === 'num' && {
        InputProps: {
          // ...inputProps,
          inputProps: {
            ...inputProps,
            maxLength: maxLength ?? undefined,
          },
          inputComponent: Num,
        },
      })}
      {...(!mask && {
        InputProps: {
          ...inputProps,
          maxLength: maxLength ?? undefined,
          inputComponent,
        },
      })}
      {...params}
    />
  </>
);
