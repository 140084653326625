import { useEffect, useState } from "react";
import { useApiDispatch } from "./global";
import { DocumentFiles, UtilityAssignment } from "server-sdk/src/types";
import { useMultiPageForm } from "./useForm";
import { validateArray, validateBoolean, validateNumber, validateObject, validateString } from "../util/validate";
import { api } from "server-sdk/src/api";

export const useMoveInChecklistForm = (onFinish: () => void, goToUrl: (url: string) => void
) => {
  const apiDispatch = useApiDispatch();

  const [rentalAgreement, setRentalAgreement] = useState<any | undefined>(
    undefined
  );
  const [documents, setDocuments] = useState<DocumentFiles[]>([]);
  const [walkthroughDocuments, setWalkthroughDocuments] = useState<DocumentFiles[]>([]);

  useEffect(() => {
    apiDispatch(async (call, api) => {
      const res = await call(api.property.getRenterRentalAgreement);
      if (!res) {
        onFinish();
        return [false, "No rental agreement found for user"];
      }
      setRentalAgreement(res);

      if(res.feedback_form_response || res.movein_event_id){
        onFinish();
        return [true, "Already completed move in checklist form"]
      }

      const docs = await call(api.renter.getRenterInsuranceDocs);

      if (!docs) {
        return [false, "Something went wrong"];
      }
      setDocuments(docs);
      pages.renter_insurance_upload.form.values.documents = docs;

      const walkthroughDocs = await call(api.renter.getWalkthroughDocs);
      if (!walkthroughDocs) {
        return [false, "Something went wrong"];
      }
      setWalkthroughDocuments(walkthroughDocs);
      pages.walkthrough_form.form.values.walkthroughDocuments = walkthroughDocs;


      return [true];
    });
  }, []);


  const pages = useMultiPageForm({
    lease_HOA_confirmation: {
      initial: {
        confirm: undefined,
      },
      validationSchema: validateObject({
        confirm: validateBoolean().typeError("Confirmation is required").required("Confirmation is required")
      }),
      label: "Lease & HOA Confirmation",
      description: `Please confirm you've received a copy of the lease and HOA from docusign after signing the lease. If you have not, please reach out to us.`,
      steps: (values) => [
        {
          name: "confirm",
          label: "Confirmation",
          placeholder: "Confirmation",
          required: true,
          type: "choice",
          exclusive: true,
          options: [
            { label: "I've received the lease and HOA from docusign", value: "true" },
          ],
        },
      ],
      submit: ({ values, done }) => {
        done();
      },
    },
    gas_confirmation: {
      initial: {
        confirm: undefined,
      },
      validationSchema: validateObject({
        confirm: validateBoolean().typeError("Confirmation is required").required("Confirmation is required")
      }),
      label: "Gas Utilities confirmation",
      description: `Please confirm you've transferred gas services to your name.  If you have not, please reach out to us.`,
      disabled: (values) => rentalAgreement?.property?.gas_utilities != UtilityAssignment.Renter,
      steps: (values) => [
        {
          name: "confirm",
          label: "Confirmation",
          placeholder: "Confirmation",
          required: true,
          type: "choice",
          exclusive: true,
          options: [
            { label: "I have transferred gas services, effective no later than my lease start date, into my name or made the necessary changes", value: "true" },
          ],
        },
      ],
      submit: ({ values, done }) => {
        done();
      },
    },
    water_confirmation: {
      initial: {
        confirm: undefined,
      },
      validationSchema: validateObject({
        confirm: validateBoolean().typeError("Confirmation is required").required("Confirmation is required")
      }),
      label: "Water Utilities confirmation",
      description: `Please confirm you've transferred water services to your name. If you have not, please reach out to us.`,
      disabled: (values) => rentalAgreement?.property?.water_utilities != UtilityAssignment.Renter,
      steps: (values) => [
        {
          name: "confirm",
          label: "Confirmation",
          placeholder: "Confirmation",
          required: true,
          type: "choice",
          exclusive: true,
          options: [
            { label: "I have transferred water services, effective no later than my lease start date, into my name or made the necessary changes", value: "true" },
          ],
        },
      ],
      submit: ({ values, done }) => {
        done();
      },
    },
    electric_confirmation: {
      initial: {
        confirm: undefined,
      },
      validationSchema: validateObject({
        confirm: validateBoolean().typeError("Confirmation is required").required("Confirmation is required")
      }),
      label: "Electric Utilities confirmation",
      description: `Please confirm you've transferred electric services to your name. If you have not, please reach out to us.`,
      disabled: (values) => rentalAgreement?.property?.electric_utilities != UtilityAssignment.Renter,
      steps: (values) => [
        {
          name: "confirm",
          label: "Confirmation",
          placeholder: "Confirmation",
          required: true,
          type: "choice",
          exclusive: true,
          options: [
            { label: "I have transferred electric services, effective no later than my lease start date, into my name or made the necessary changes", value: "true" },
          ],
        },
      ],
      submit: ({ values, done }) => {
        done();
      },
    },
    garbage_sewage_confirmation: {
      initial: {
        confirm: undefined,
      },
      validationSchema: validateObject({
        confirm: validateBoolean().typeError("Confirmation is required").required("Confirmation is required")
      }),
      label: "Garbage & Sewage Utilities confirmation",
      description: `Please confirm you've transferred garbage and sewage services to your name. If you have not, please reach out to us.`,
      disabled: (values) => rentalAgreement?.property?.garbage_sewage_utilities != UtilityAssignment.Renter,
      steps: (values) => [
        {
          name: "confirm",
          label: "Confirmation",
          placeholder: "Confirmation",
          required: true,
          type: "choice",
          exclusive: true,
          options: [
            { label: "I have transferred garbage & sewage services, effective no later than my lease start date, into my name or made the necessary changes", value: "true" },
          ],
        },
      ],
      submit: ({ values, done }) => {
        done();
      },
    },
    renter_insurance_upload: {
      initial: {
        documents: documents,
        placeholder: undefined
      },
      validationSchema: validateObject({
        documents: validateArray()
          .min(1, "At least 1 document upload is required.")
          .required("Renter Insurance is required"),
      }),
      label: "Renter Insurance",
      description: `Please upload your renter's insurance below.`,
      steps: (values) => [
        {
          name: "placeholder",
          label: `Double-check your lease to double check whether it is required or not. You may need to reach out to your insurance provider.`,
          required: false,
          type: "note",
        },
        {
          name: "placeholder",
          label: `*If you have already sent it over via email, we ask that you please upload again.`,
          required: false,
          type: "note",
        },
        {
          name: "documents",
          label: "Add Renter Insurance Documents",
          required: true,
          type: "upload-documents",
          uploadFields: {
            allowedFileTypes: ["jpg", "jpeg", "png", "pdf"],
            id: rentalAgreement?.id,
            field: "renter_insurance",
            base: api.forms.moveInUpload,
          },
        },
      ],
      resources: (values) => {
        return [
              {
                name: "documents",
                title: "Marble offers renter's insurance as well, it is the nationwide standard coverage and rate.",
                description: "",
                actionTitle: "Follow this link to purchase",
                action: () => {
                  goToUrl(
                    "https://marble.sureapp.com/renters-insurance"
                  );
                },
              },
            ]
      },
      submit: ({ values, done }) => {
        done();
      },
    },
    walkthrough_form: {
      initial: {
        walkthroughDocuments: walkthroughDocuments,
        placeholder: undefined
      },
      validationSchema: validateObject({
        walkthroughDocuments: validateArray()
          .min(1, "At least 1 document upload is required.")
          .required("Walkthrough Form is required"),
      }),
      label: "Walkthrough Document",
      description: `Please upload a completed & signed copy of your walkthrough. The walkthrough checklist is attached to your lease.`,
      steps: (values) => [
        {
          name: "placeholder",
          label: `Take care to be thorough and mark any pre-existing damages as to avoid future penalties. Add pictures of any damages.`,
          required: false,
          type: "note",
        },
        {
          name: "placeholder",
          label: `*If you have already sent it over via email, we ask that you please upload again.`,
          required: false,
          type: "note",
        },
        {
          name: "walkthroughDocuments",
          label: "Walkthrough Documents",
          required: true,
          type: "upload-documents",
          uploadFields: {
            allowedFileTypes: ["jpg", "jpeg", "png", "pdf"],
            id: rentalAgreement?.id,
            field: "walkthrough",
            base: api.forms.moveInUpload,
          },
        },
      ],
      submit: ({ values, done }) => {
        done();
      },
    },
    rate_satisfaction: {
      initial: {
        rating: -1,
      },
      label:
        "Rating",
      description:
        "How did your renter experience go?",
      validationSchema: validateObject({
        rating: validateNumber().min(0, "Please enter a value"),
      }),
      steps: (values) => [
        {
          name: "rating",
          label: "",
          placeholder: "Rating",
          required: false,
          type: "satisfaction-rating",
        },
      ],
      submit: ({ values, done }) => {
        console.log("values", values);
        done();
        return [true];
      },
    },
    feedback: {
      initial: {
        feedback: "",
      },
      label: "Feedback",
      description: "Additional move in feedback. Please write down any other notes you'd like to record.",

      validationSchema: validateObject({
        feedback: validateString(),
      }),
      steps: (values) => [
        {
          name: "feedback",
          label: "",
          placeholder: "Feedback",
          required: false,
          type: "paragraph",
        },
      ],
      submit: ({ values, done }) => {
        console.log("values", values);
        done();
        return [true];
      },
    },
    thank_you_for_your_time: {
      initial: {
        thank_you_for_your_time: "",
      },
      label: "Thank you for your time",
      isEnd: true,
      description:
        "Thank you for your time. Please click Submit to finish the form",
      steps: (values) => [
        {
          // @ts-ignore
          name: "thank_you_for_your_time",
          label: "",
          // @ts-ignore
          type: "",
        },
      ],
      submit: ({ values, done }) => {
        // all finished.
        apiDispatch(async (call, api) => {
          const res = await call(api.renter.submitMoveInFeedback, {
            id: rentalAgreement.id,
            feedback_form_response: {
              rating: values.rate_satisfaction.rating,
              feedback: values.feedback.feedback,
            },
          });

          if (res) {
            done();
            onFinish();
            return [true];
          }
          return [false, "Something went wrong"];
        });

      },
    },
  });
  return pages;
};