import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Head from 'next/head';
import React from 'react';
import Navigation from 'src/base/nav/Navigation';
import CopyrightFooter from 'src/components/layout/footer/CopyrightFooter';
import EtcHead from 'src/components/layout/head/EtcHead';
import TrackingScript from 'src/components/layout/head/TrackingScript';
import { Snack } from 'src/components/layout/Snack';
import { NextPageWithLayout } from 'src/utils/types';

const stripe = loadStripe(process.env.STRIPE_API_KEY);

const Layout = ({ children }) => (
  <>
    <Head>
      <title>Renter - Marble</title>
    </Head>
    <EtcHead />
    <Navigation
      homePath="/renter/"
      tabs={[
        {
          title: 'Home',
          path: '/renter/',
        },
        {
          title: 'Discover',
          path: '/discover/',
          openWithNewTab: true,
        },
      ]}
      profile={{
        title: 'Profile',
        path: '/renter/profile/',
      }}
    />
    <TrackingScript />
    <Snack />
    <Elements stripe={stripe}>{children}</Elements>
    <CopyrightFooter />
  </>
);

export default function withRenterLayout(page: NextPageWithLayout) {
  page.getLayout = function getLayout(pageElement: React.ReactElement) {
    return <Layout>{pageElement}</Layout>;
  };

  return page;
}
