import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  ChangeIdentity,
  ListingStatus,
  Reservation,
  User,
} from 'server-sdk/src/types';
import { call } from '../call';
import { api } from 'server-sdk/src/api';
import { DAYS, MONTHS } from '../constants';
import { useForm, useReduxState, useGlobal, useAuth } from '.';
import {
  updateBooking,
  updateRentals,
  updateReservations,
} from '../ducks/discover';
import { validateObject, validateString } from '../util/validate';
import { login } from '../thunks/identity';
import { useRouter } from '../router';

export const useDiscover = (
  discoverProps: {
    selectedId?: number;
  } = {},
) => {
  const { selectedId } = discoverProps;
  const dispatch = useDispatch();

  const { rentals, booking } = useReduxState((state) => state.discover);

  const global = useGlobal();
  const auth = useAuth();

  useEffect(() => {
    refresh();
  }, []);

  const [schedule, setSchedule] =
    useState<Map<string, Reservation[]>>(undefined);
  const [direct, setDirect] = useState<User>(undefined);
  const [waitlist, setWaitlist] = useState<boolean>(false);

  const rental = useMemo(
    () => rentals.find((p) => p.id === selectedId),
    [rentals, selectedId],
  );

  useEffect(() => {
    if (selectedId) {
      dispatch(async () => {
        const availability = await call(api.reservation.property.availability, {
          property: {
            id: selectedId,
          },
          reservations: [],
        });
        if (!availability) {
          return [false, 'Something went wrong, contact support for help'];
        }
        if (availability?.property.listing_status === ListingStatus.Waitlist) {
          setWaitlist(true);
        } else if (availability?.user) {
          setDirect(availability.user);
        } else {
          const scheduleMap: Map<string, Reservation[]> = new Map();
          for (const rez of availability?.reservations ?? []) {
            const date = new Date(rez.startTime);
            const day = `${DAYS[date.getDay()]}, ${MONTHS[date.getMonth()]
              } ${date.getDate()}`;
            if (!scheduleMap.get(day)) {
              scheduleMap.set(day, []);
            }
            scheduleMap.get(day).push(rez);
          }
          setSchedule(scheduleMap);
        }
      });
    }
  }, [rentals, selectedId]);

  const refresh = () => {
    dispatch(async () => {
      try {
        const data = await call(api.discover.all);
        dispatch(updateRentals(data.homes));
      } catch (e) {
        // console.error(e);
      }
    });
  };

  const book = (booking: Reservation) => {
    const result = dispatch(async () => {
      try {
        const res = await call(api.reservation.property.reserve, booking);
        if (res) {
          dispatch(updateBooking(res));
          if(res.userId && auth.authenticated){
            const reservations = await call(api.reservation.schedule);
            dispatch(updateReservations(reservations));
          }
          return true;
        }
        global.error(
          'Something went wrong while scheduling, please try again later',
        );
        return false;
      } catch (e) {
        global.error(
          'Something went wrong while scheduling, please try again later',
        );
        return false;
      }
    });
    return result;
  };

  const deleteBook = (booking: Reservation) => {
    dispatch(async () => {
      try {
        const res = await call(
          api.reservation.property.deleteReservation,
          booking,
        );
        if (res) {
          const reservations = await call(api.reservation.schedule);
          dispatch(updateReservations(reservations));
        } else {
          global.error(
            'Something went wrong while deleting the tour reservation, please try again later',
          );
        }
      } catch (e) {
        global.error(
          'Something went wrong while deleting the tour reservation, please try again later',
        );
      }
    });
  };
  const get = useCallback(
    (propertyId: number) => rentals.find((p) => p.id === propertyId),
    [rentals],
  );

  return {
    get,
    rentals,
    selected: {
      rental,
      schedule,
      waitlist,
      direct,
    },
    deleteBook,
    book,
    booking,
  };
};

export const useTourConfirmationForm = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const user = useReduxState((state) => state.identity.user);
  const { booking } = useReduxState((state) => state.discover);
  const { book } = useDiscover({
    selectedId: booking?.propertyId,
  });
  const [hasConfirmedOtp, setHasConfirmedOtp] = useState(false);

  const confirmationForm = useForm<ChangeIdentity>({
    initial: {
      otp: '',
    },
    validationSchema: validateObject({
      otp: validateString().required(),
    }),
    submit: (identity) => {
      setHasConfirmedOtp(false);
      dispatch(confirmBook(identity));
    },
  });

  const confirmBook = (identity) => {
    dispatch(async () => {
      dispatch(login(identity.email, identity.phone, identity.otp));
      setHasConfirmedOtp(true);
    });
  };
  useEffect(() => {
    if (user && hasConfirmedOtp) {
      book(booking);
      router.push('/renter');
    }
  }, [user]);
  return confirmationForm;
};

export const useCities = (cityName) => {
  const dispatch = useDispatch();

  const [rentals, rentalsHandler] = useState([]);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    dispatch(async () => {
      try {
        const data = await call(api.discover.cities, { city: cityName });
        dispatch(rentalsHandler(data.properties));
      } catch (e) {
        console.error(e);
      }
    });
  };

  return {
    rentals,
  };
};
