import { Application } from "./application";

export interface Invite {
  id?: number;
  creation_time?: number;
  email?: string;
  application_id?: number;
  status?: InviteStatus | string;
  application?: Application;
  property_id?: number;
}

export enum InviteStatus {
  Sent,
  Accepted,
  Declined
};

export interface PropertyInvite {
  email?: string;
  phone?: string;
  inviteId?: number;
  propertyId?: number;
  propertyName?: string;
  reservationId?: number;
  startDate?: number;
  endDate?: number;
}