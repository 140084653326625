import axios, { AxiosResponse } from "axios";
import store from "./store";
import { updateToken } from "./ducks/identity";
import { ApiCall } from "server-sdk/src/api";
import { AB_HEADER } from "server-sdk/src/types";
import { getExperimentGroup, setExperimentGroup } from "./util/experiment";
import { API_BASE_URL } from "./constants";

const marbleApi = axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000,
});

let _getLoginToken: () => string;
let _logout: () => void;

export const setupLoginLogout = (get: () => string | undefined, logout: () => void) => {
  _getLoginToken = get;
  _logout = logout;
}

marbleApi.interceptors.request.use(
  (config) => {
    const token = _getLoginToken();

    if (token) {
      config.headers.Authorization = token;
      try {
        config.headers[AB_HEADER] = getExperimentGroup();
      } catch (e) {
      }
    }
    return config;
  },
  () => {
    _logout();
  },
);

// do nothing if successful
marbleApi.interceptors.response.use(
  (response) => {
    if (response.status === 403) {
      _logout();
    }

    try {
      const curr = getExperimentGroup();
      const persistedExperiment = response?.headers[AB_HEADER];
      // console.log('headers', response?.headers);
      if (persistedExperiment && persistedExperiment !== curr) {
        // console.log('experiment mismatch', persistedExperiment, curr);
        setExperimentGroup(persistedExperiment);
      }
    } catch (e) {
      console.error('int res', e);
    }

    return response;
  },
  (error) => {
    // eslint-disable-next-line no-underscore-dangle
    if (error.config && !error.config.__isRetryRequest) {
      // retires all borked requests once
      // eslint-disable-next-line no-underscore-dangle
      error.config.__isRetryRequest = true;
      return axios(error.config);
    }
    return Promise.reject(error);
  },
);

let previousCall;
export const callWithCancel = async <
  State,
  Params extends keyof State,
  ParamType extends Pick<State, Params>,
>(
  ac: ApiCall<State, Params, ParamType>,
  body?: ParamType,
  options?: {},
): Promise<State> => {
  let res: AxiosResponse<State>;
  try {
    if (previousCall) {
      previousCall.cancel('Cancelling previous request');
    }
    previousCall = axios.CancelToken.source();

    res = await marbleApi.post(ac.path, body, {
      ...options,
      cancelToken: previousCall.token,
      timeout: 30000,
    });
  } catch (e) {
    console.error(`${ac.path}: ${e}`);
  }

  previousCall = undefined;
  if (res && res.status === 200) {
    return res.data;
  }

  return undefined;
};

export const call = async <
  State,
  Params extends keyof State,
  ParamType extends Pick<State, Params>,
>(
  ac: ApiCall<State, Params, ParamType>,
  body?: ParamType,
  options?: {},
): Promise<State> => {
  let res: AxiosResponse<State>;
  try {
    res = await marbleApi.post(ac.path, body, {
      ...options,
      timeout: 30000,
    });
  } catch (e) {
    console.error(`${ac.path}: ${e}`);
  }

  if (res && res.status === 200) {
    return res.data;
  }

  return undefined;
};

export default marbleApi;
