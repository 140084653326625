import { DocSchema } from "./types/schemas";
import { DocTemplate, GetTemplates } from "./types/templates";
import {
  ActivityLogRequest,
  ActivityLogResponse,
  DeleteLockRequest,
  JobShard,
  LeasingSMS,
  LeasingTexts,
  LoginInvitation,
  PairingDataRequest,
  PairingDataResponse,
  PaymentIntentRequest,
  PaymentIntentResponse,
  PropertyInvite,
  Shipment,
  SubscribeProperty,
  TextChat,
  TextMessage,
  TextSMSConversation,
  TextSuggestionsRequest,
  TimezoneRequest,
  TimezoneResponse,
  UnsubscribeProperty,
  UserAddress,
  EnvelopeData,
  LeaseDocumentRequest,
  Envelope,
  DocLeaseTemplateSchema,
  VoiceToken,
  UpdateShipment,
  DocumentFiles,
  SubmitRenterFeedbackRequest,
  SubmitRejectCompletedTrip,
  SubmitReviewCompletedTrip,
  UnsubscribeTransactional,
  GetAllMaintenanceReviewData,
  InvestHome,
  DirectOfferListing,
  SendDirectOfferMessage,
  GetDirectOfferInquiry,
  AddDirectOfferListing,
  JoinEmailList,
} from "./types";
import { Application, ApplicationUser, LandlordReference, LeaseInfo, ValidateLeaseGuid } from "./types/application";
import { Chat, Inbox, LeasingInbox, Message, TextSMSMessage, EmailMessage, EmailChat } from "./types/chat";
import { CityProperties, DiscoverResult, ExternalListingInquiry } from "./types/discover";
import { IdVerification, IdVerificationResults } from "./types/idVerification";
import { AnswerLandlordQuestionsResponse, Answers, AnswerUserQuestionsResponse, ApplicationPaymentInfo, AuthenticateResponse, AuthenticationInfo, GetLandlordQuestionsResponse, GetSharedReportGroupInfo, GetSharedReportInfo, GroupReportResponse, PaymentProcessed, ReportPrice, ReportResponse, UserInfo } from "./types/experian";
import {
  IglooLockExistRequest,
  IglooSaveSettingsRequest,
  Lock,
  LockCalibration,
  LockPin,
  LockSetup,
  MakerAuthToken
} from "./types/lock";
import { ChooseMaintenanceQuote, GetFormattedMaintenanceResponse, GetMaintenanceQuotes, GetMaintenanceRequest, LandlordMaintenanceLink, OwnerOpsTaskLink, RenterMaintenanceLink, SubmitHomeWarrantyConfirmation, SubmitLandlordMaintenanceRequestApproval, SubmitMaintenanceRequest, SubmitOwnVendor, GetMaintenanceReviewData, SubmitOwnerFeedbackRequest } from "./types/maintenance";
import { RefNameLookup } from "./types/onboarding";
import { CreateBillingPortal, DefaultPaymentMethod, MonthlyCashTotal, OwnerBankInfo, RentAdjustment, RentPayment } from "./types/payment";
import { Demo, LeasingData, LeasingReport, Property, PropertyRentEstimate, SubscribeTransactional, UnregisteredRenter, UpdateCard } from "./types/property";
import { AgreementAcceptance, RentalAgreement, WaitlistReservation } from "./types/renter";
import { PropertyUserReservation, Reservation } from "./types/reservation";
import { AddAvailabilites, Availability, BookRenterAvailability, GetMyAvailabilities, GetRenterAvailabilities, OutsideTour } from "./types/schedule";
import { BluetoothMessage, Tour, ValidateTourParams } from "./types/tour";
import { ChangeIdentity, DismissTooltip, Login, User } from "./types/user";
import { RentEstimate, RentEstimateRequest, RentEstimateSettings } from "./types/rentEstimate";
import { SuggestedRentals } from "./types/suggestedRentals";
import { LeaseCategory } from "./types/nancy";
import { ReferralInfo } from "./types/referrals";
import { Form, FormUploadAdd, FormUploadDelete, FormUploadUpdate } from "./types/forms";
import { LeaseAddendumInfo, LeasingActivities, SubmitMoveOutFormRequest } from "./types/leasing";
import { FreeWaitlistLookup } from "./types/freeWaitlist";
import { FlowComment, GetFlow, GetFlowActivity, GetFlowList, StartFlow, TryCompleteFlowActivity, UpdateFlowActivity } from "./types/workflow";
import { CreateCardWaitlist } from "./types/card";

export interface ApiBase {
  hackyBaseCheck: true
  path?: string
}

type None = never;

export interface ApiCall<State, Params extends keyof State, RequestType extends Pick<State, Params>> extends ApiBase {
}

export interface ApiCallDefault<State> extends ApiBase {
}

export type ClientCall = <State, Params extends keyof State, ParamType extends Pick<State, Params>>(ac: ApiCall<State, Params, ParamType>, body?: ParamType, options?: {}) => Promise<State>

const actionLazyType = <State>(path: string): ApiCall<State, keyof State, Pick<State, keyof State>> => {
  return {
    ...action<State, keyof State>(),
    path,
  };
};

export const actionNoParam = <
  State
>(): ApiCall<State, keyof State, Pick<State, keyof State>> => {
  return {
    ...action<State, keyof State>(),
  };
};

export const action = <
  State,
  Params extends keyof State,
>(): ApiCall<State, Params, Pick<State, Params>> => {
  return {
    hackyBaseCheck: true,
  };
};

export const form = () => {
  return {
    get: action<Form, 'id' | 'field'>(),
    update: action<Form, 'id' | 'value' | 'field'>(),
    upload: {
      add: action<FormUploadAdd, 'id' | 'field'>(),
      update: action<FormUploadUpdate, 'id' | 'field' | 'files'>(),
    },
  };
};

export const form2 = () => {
  return {
    get: action<Form, 'id' | 'field' | 'context' | 'cache'>(),
    submit: action<Form, 'id' | 'field' |'context' | 'cache' | 'value'>(),
    upload: {
      add: action<FormUploadAdd, 'id' | 'field'>(),
      delete: action<FormUploadDelete, 'id' | 'field' | 'files' | 'cache'>(),
      update: action<FormUploadUpdate, 'id' | 'field' |'context' | 'cache' |  'files'>(),
    },
  };
};

export const formUpload = () => {
  return {
    upload: {
      add: action<FormUploadAdd, 'id' | 'field'>(),
      update: action<FormUploadUpdate, 'id' | 'field' | 'files'>(),
    }
  }
}

export type FormBase = ReturnType<typeof form>;
export type FormBase2 = ReturnType<typeof form2>;
export type FormUploadBase = ReturnType<typeof formUpload>;

const isBase = (o: any): o is ApiBase => {
  return o['hackyBaseCheck'];
}

const addPaths = (o: any, path: string) => {
  if (!o) return;
  if (isBase(o)) {
    if (!o.path) {
      o.path = path.slice(0, path.length - 1);
    }
    return;
  }

  for (const [key, val] of Object.entries(o)) {
    addPaths(val, path + key + '/');
  }
}

const _api = {
  application: {
    apply: actionLazyType<Application>('application/apply'),
    applyWithExperian: actionLazyType<Application>('application/applyWithExperian'),
    invite: {
      accept: actionLazyType<Application>('application/invite/accept'),
      decline: actionLazyType<Application>('application/invite/decline'),
      remove: actionLazyType<Application>('application/invite/remove'),
    },
    list: {
      renter: actionLazyType<Application[]>('application/list/renter'),
      invites: actionLazyType<Application[]>('application/invites'),
      owner: actionLazyType<Application[]>('application/list/owner'),
    },
    approve: actionLazyType<Application>('application/approve'),
    getIncomeDocuments: actionLazyType<ApplicationUser>('application/getIncomeDocuments'),
    reject: actionLazyType<Application>('application/reject'),
    upload: actionLazyType<ApplicationUser>('application/upload/kdkeindjfhhujsenbhjskudfybe'),
    uploadIncomeDocuments: actionLazyType<ApplicationUser>('application/uploadIncomeDocuments'),
    getDetails: actionLazyType<ApplicationUser>('application/getDetails'),
    getReference: actionLazyType<LandlordReference>('application/getReference'),
    submitReference: actionLazyType<LandlordReference>('application/submitReference'),
    leaseReview: {
      create: action<Application, 'id'>(),
      update: actionLazyType<LeaseInfo>('application/leaseReview/update'),
      getDocuments: actionLazyType<DocumentFiles[]>('application/leaseReview/getDocuments'),
      validateGuid: actionLazyType<ValidateLeaseGuid>('application/leaseReview/validateGuid'),
      addGuid: actionLazyType<LeaseInfo>('application/leaseReview/addGuid'),
    }
  },
  discover: {
    all: actionLazyType<DiscoverResult>('discover/all'),
    home: actionLazyType<Property>('discover/home'),
    cities: action<CityProperties, 'city'>(),
    getExternalListing: action<{listingId?: string, property?: Property, inquiry?: ExternalListingInquiry}, 'listingId'>(),
    submitExternalListingInquiry: action<{inquiry?: ExternalListingInquiry}, 'inquiry'>(),
  },
  experian: {
    answerLandlordQuestions: action<{answers?: Answers, response?: AnswerLandlordQuestionsResponse}, 'answers'>(),
    answerUserQuestions: action<{answers?: Answers, response?: AnswerUserQuestionsResponse}, 'answers'>(),    
    authenticateUser: action<{userInfo?: AuthenticationInfo, response?: AuthenticateResponse}, 'userInfo'>(),
    processApplicationPayment: action<{paymentInfo?: ApplicationPaymentInfo, processed?: PaymentProcessed}, 'paymentInfo'>(),
    getUserInfo: actionNoParam<UserInfo>(),
    getLandlordInfo: actionNoParam<UserInfo>(),
    getApplicationInfo: action<{applicationId?: number, property?: Property }, 'applicationId'>(),
    getLandlordName: actionNoParam<{propertyId?: number, name?: string, guid?: string}>(),
    getLandlordQuestions: action<{landlordInfo?: AuthenticationInfo, response?: GetLandlordQuestionsResponse}, 'landlordInfo'>(),
    getPrice: action<ReportPrice, 'propertyId'>(),
    getRenterReport: action<ReportResponse, 'guid' | 'applicationUser'>(),
    getSharedReport: action<{sharedReportInfo?: GetSharedReportInfo, response?: ReportResponse}, 'sharedReportInfo'>(),
    getSharedGroupReport: action<{sharedReportGroupInfo?: GetSharedReportGroupInfo, response?: GroupReportResponse}, 'sharedReportGroupInfo'>(),
    getSampleReport: actionNoParam<{response?: ReportResponse}>(),
    getSampleGroupReport: actionNoParam<{response?: GroupReportResponse}>(),
    shareReport: action<{payload?: { propertyId: number, guid: string }}, 'payload'>(),
    stopShareReport: action<{payload?: { shareReportId: number, guid: string }}, 'payload'>(),
    updateLandlordAddress: action<{payload?: {userAddress?: UserAddress, guid?: string}}, 'payload'>(),
    fallBackToSingleKeyApplication: action<{payload?: { propertyId: number, guid?: string}}, 'payload'>(),
  },
  docusign: {
    sendEnvelope: action<EnvelopeData, 'type' | 'lease_addendum_info_id' | 'application_id' | 'email' | 'doc'>(),
    getEnvelope: action<EnvelopeData, 'id'>(),
    redoEnvelope: action<EnvelopeData, 'application_id'>()
  },
  leasing: {
    getApplicationsWithTimelines: action<LeasingActivities, 'property_id'>(),
    getInquiries: action<LeasingActivities, 'property_id'>(),
    getTours: action<LeasingActivities, 'property_id'>(),
    getSummary: action<LeasingActivities, 'property_id'>(),
    markSeen: action<LeasingActivities, 'property_id' | 'leasing_activities'>()
  },
  lock: {
    all: actionLazyType<Lock[]>('lock/all'),
    calibrate: action<LockCalibration, 'lockId'>(),
    create: actionLazyType<Lock>('lock/create'),
    delete: actionLazyType<Lock>('lock/delete'),
    setup: {
      start: actionLazyType<Lock>('lock/setup/start'),
      message: actionLazyType<LockSetup>('lock/setup/message'),
    },
    install: actionLazyType<{ lock: Lock, property: Property }>('lock/install'),
    list: actionLazyType<Lock[]>('lock/list'),
    keys: {
      private: actionLazyType<Lock>('lock/keys/private'),
    },
    message: {
      encrypt: actionLazyType<{ lock: Lock, message: BluetoothMessage }>('lock/message/encrypt'),
      decrypt: actionLazyType<{ lock: Lock, message: BluetoothMessage }>('tour/message/decrypt'),
    },
    token: {
      create: actionLazyType<MakerAuthToken>('lock/token/create'),
    },
    pin: {
      get: action<LockPin, 'lockId'>(),
    },
    igloo: {
      getTimezone: action<{
        timezoneRequest: TimezoneRequest;
        timezoneResponse: TimezoneResponse;
      }, 'timezoneRequest'>(),
      submitPairingData: action<{
        pairingDataRequest: PairingDataRequest;
        pairingDataResponse: PairingDataResponse;
      }, 'pairingDataRequest'>(),
      deleteLock: action<DeleteLockRequest, 'lockId' | 'lockName'>(),
      submitActivityLogs: action<{
        activityLogRequest: ActivityLogRequest;
        activityLogResponse: ActivityLogResponse;
      }, 'activityLogRequest'>(),
      exists: action<IglooLockExistRequest, 'id'>(),
      saveSettings: action<IglooSaveSettingsRequest, 'id' | 'lockSettings'>()
    },
  },
  property: {
    create: actionLazyType<Property & {flowActivityId?: number} >('property/create'),
    update: actionLazyType<Property>('property/update'),
    list: actionLazyType<Property[]>('property/list'),
    demo: actionLazyType<Demo>('property/demo'),
    inquiry: actionLazyType<{ user: User, property?: Property }>('property/inquiry/dfnekdsfji3938fh'),
    updatePhotoOrder: actionLazyType<Property>('property/update_photo_order'),
    subscribeTransactional: action<SubscribeTransactional, 'propertyId'>(),
    unsubscribeTransactional: action<UnsubscribeTransactional, 'propertyId'>(),
    subscribe: action<SubscribeProperty, 'propertyId' | 'creationToken'>(),
    unsubscribe: action<UnsubscribeProperty, 'propertyId'>(),
    updateCardAndSubscription: action<SubscribeProperty, 'propertyId' | 'creationToken'>(),
    updateCardDontSubscribe: action<UpdateCard, 'creationToken'>(),
    rentEstimate: actionLazyType<PropertyRentEstimate>('property/rent_estimate'),
    leasing: action<LeasingData, 'propertyId'>(),
    getPropertyInvites: actionLazyType<PropertyInvite[]>('property/getPropertyInvites'),
    leasingReport: action<LeasingReport, 'property_id'>(),
    createUnregisteredRenter: action<UnregisteredRenter, 'property_id'>(),
    updateUnregisteredRenter: actionLazyType<UnregisteredRenter>('property/updateUnregisteredRenter'),
    getPropertyRentalAgreement: action<RentalAgreement, 'property_id'>(),
    getRenterRentalAgreement: actionNoParam<RentalAgreement>()
  },
  reservation: {
    property: {
      availability: actionLazyType<{
        property?: Property,
        reservations?: Reservation[],
        user?: User,
      }>('reservation/property/availability'),
      deleteReservation: action<Reservation, 'id' | 'propertyId' | 'phone'>(),
      reserve: actionLazyType<Reservation>('reservation/property/reserve'),
      waitlist: actionLazyType<WaitlistReservation>('reservation/property/waitlist'),
    },
    invite: {
      isReserved: action<Reservation, 'email' | 'phone'>(),
    },
    schedule: actionLazyType<Reservation[]>('reservation/schedule'),
    remind: actionLazyType<JobShard>('reservation/remind/dfecnwoienvlkxlbviecjxkcienwpqdpfjg'),
    engage: actionLazyType<JobShard>('reservation/engage/kdkjifjaslkdfleijeslifhhfjd'),
    get: action<PropertyUserReservation, 'id' >(),
    update: action<PropertyUserReservation, 'id' | 'userId' >(),
    verify: action<Reservation, 'firstName' | 'lastName' | 'email' | 'phone' >(),
    findUser: action<User, 'phone' | 'email'>()
  },
  schedule: {
    mine: {
      get: actionNoParam<GetMyAvailabilities>(),
      add: actionNoParam<AddAvailabilites>(),
      update: action<Availability, 'id' | 'startTime' | 'endTime'>(),
      delete: action<Availability, 'id'>(),
    },
    with: {
      renter: {
        get: action<GetRenterAvailabilities, 'renterId'>(),
        book: action<BookRenterAvailability, 'start' | 'end' | 'renterId' | 'schedulerFirstName' | 'schedulerLastName' | 'schedulerEmail'>(),
        details: action<OutsideTour, 'tourId'>(),
      }
    }
  },
  tour: {
    active: actionLazyType<Tour>('tour/active'),
    start: actionLazyType<Tour>('tour/start'),
    end: actionLazyType<Tour>('tour/end'),
    encrypt: actionLazyType<{ tour: Tour, message: BluetoothMessage }>('tour/encrypt'),
    decrypt: actionLazyType<{ tour: Tour, message: BluetoothMessage }>('tour/decrypt'),
    lock: {
      read: actionLazyType<{ tour: Tour, lock?: Lock, pin?: LockPin }>('tour/lock/read'),
      update: actionLazyType<{ tour: Tour, lock: Lock }>('tour/lock/update'),
      getId: actionLazyType<{ tour?: Tour, lock?: Lock, reservation?: PropertyUserReservation }>('tour/lock/getId'),
    },
    list: {
      owner: actionLazyType<Tour[]>('tour/list/owner'),
    },
    validateTour: action<ValidateTourParams, 'tourId' | 'userId' | 'propertyId'>()
  },
  users: {
    auth: {
      login: actionLazyType<User>('users/auth/login'),
      otp: actionLazyType<Login>('users/auth/otp'),
      confirmationOtp: actionLazyType<ChangeIdentity>('users/auth/confirmation_otp'),
      startChangeIdentity: actionLazyType<ChangeIdentity>('users/auth/start_change_identity'),
      finishChangeIdentity: actionLazyType<ChangeIdentity>('users/auth/finish_change_identity'),
      validate: actionLazyType<Login>('users/auth/validate'),
      validateInvitation: action<LoginInvitation, 'email' | 'phone' | 'invitationId'>()
    },
    tooltip: {
      dismiss: action<DismissTooltip, 'tooltip'>(),
    },
    v2: {
      me: action<User, None>(),
      create: actionLazyType<User>('users/v2/create'),
    }
  },
  shipment: {
    start: actionLazyType<Shipment>('shipment/start'),
    findOrCreate: action<Shipment, 'property_id'>(),
    update: action<UpdateShipment, 'shipment'>(),
    cancel: actionLazyType<Shipment>('shipment/cancel'),
    confirm: actionLazyType<Shipment>('shipment/confirm'),
    confirmManual: actionLazyType<Shipment>('shipment/confirmManual'),
    list: actionLazyType<Shipment[]>('shipment/list'),
  },
  identity: {
    verification: {
      persist: actionLazyType<IdVerification>('/identity/verification/persist'),
      fetch: actionNoParam<IdVerificationResults>(),
    },
    stripe: {
      fetch: actionNoParam<IdVerification>(),
      verify: action<IdVerification, 'propertyId'>(),
      verifyMobile: actionNoParam<IdVerification>()
    }
  },
  onboarding: {
    referrerNameLookup: actionLazyType<RefNameLookup>('onboarding/referrer_name_lookup')
  },
  payment: {
    rent: {
      received: actionLazyType<RentPayment[]>('payment/rent/received'),
      markPaid: action<RentAdjustment, 'id' | 'renter_user_id' | 'amount'>()
    },
    createPaymentIntent: action<{
      paymentIntentRequest: PaymentIntentRequest;
      paymentIntentResponse: PaymentIntentResponse;
    }, 'paymentIntentRequest'>(),
    cash_flow: actionLazyType<MonthlyCashTotal[]>('payment/cash_flow'),
    ownerBankInfo: actionNoParam<OwnerBankInfo>(),
    methods: {
      default: actionLazyType<DefaultPaymentMethod>('payment/methods/default'),
    },
    billing: {
      portal: action<CreateBillingPortal, 'isMobile'>(),
    },
  },
  renter: {
    accept: actionLazyType<AgreementAcceptance>('renter/accept'),
    key: actionLazyType<{ property?: Property, lock?: Lock }>('renter/key'),
    createMaintenanceLink: actionNoParam<RenterMaintenanceLink>(),
    getRenterInsuranceDocs: actionLazyType<DocumentFiles[]>('renter/getRenterInsuranceDocs'),
    getWalkthroughDocs: actionLazyType<DocumentFiles[]>('renter/getRenterInsuranceDocs'),
    submitMoveInFeedback: action<RentalAgreement, "id" | "feedback_form_response">()
  },
  landlord: {
    createMaintenanceLink: action<LandlordMaintenanceLink, "propertyId">(),
    createOpsTaskLink: action<OwnerOpsTaskLink, "propertyId">(),
  },
  inbox: {
    v2: {
      sms: action<LeasingSMS, 'page'>(),
      texts: action<LeasingTexts, 'page'>(),
      textMessages: action<TextChat, 'id'>(),
      emailMessages: action<EmailChat, 'id'>(),
      chats: actionLazyType<Inbox>('inbox/v2/chats'),
      leasingInbox: actionLazyType<LeasingInbox>('inbox/v2/leasingmc'),
      sendMessage: actionLazyType<Message>('inbox/v2/send_message'),
      sendTextMessage: actionLazyType<Message>('inbox/v2/send_text_message'),
      sendEmailMessage: action<EmailMessage, 'text' | 'id'>(),
      markSeen: actionLazyType<Chat>('inbox/v2/mark_seen'),
      messages: action<Chat, 'id'>(),
      smsTexts: action<TextSMSConversation, 'with'>(),
      startChat: actionNoParam<Chat>(),
    },
    sms: {
      markSeen: action<TextSMSConversation, 'with'>(),
      send: action<TextSMSMessage, 'text' | 'to'>()
    },
    text: {
      getSuggestions: action<TextSuggestionsRequest, 'clientMessages'>(),
      sendText: action<TextMessage, 'phone' | 'conversationId' | 'message'>()
    },
    renewal: {
      fetch: actionNoParam<Inbox>(),
    },
    voice: {
      getToken: action<VoiceToken, 'identity'>()
    }
  },
  maintenance: {
    submitMaintenanceRequest: action<SubmitMaintenanceRequest, 'propertyId' | 'formResponse'>(),
    submitLandlordMaintenanceApproval: action<SubmitLandlordMaintenanceRequestApproval, 'maintenanceRequestId' | 'flowActivityId' | 'formResponse'>(),
    submitHomeWarrantyConfirmation: action<SubmitHomeWarrantyConfirmation, 'maintenanceRequestId' | 'flowActivityId' | 'formResponse'>(),
    getMaintenanceRequestData: action<GetFormattedMaintenanceResponse, | 'maintenanceId'>(),
    getMaintenanceRequest: action<GetMaintenanceRequest, | 'maintenanceId'>(),
    getMaintenanceQuotes: action<GetMaintenanceQuotes, | 'maintenanceId'>(),
    chooseMaintenanceQuote: action<ChooseMaintenanceQuote, | 'maintenanceId' | 'flowActivityId' | 'maintenanceQuoteId'>(),
    submitOwnVendor: action<SubmitOwnVendor, | 'maintenanceId' | 'flowActivityId' | 'formResponse'>(),
    getMaintenanceReviewData: action<GetMaintenanceReviewData, | 'maintenanceId' | 'maintenanceTripId'>(),
    getAllMaintenanceReviewData: action<GetAllMaintenanceReviewData, | 'maintenanceId'>(),
    submitReviewCompletedTrip: action<SubmitReviewCompletedTrip, | 'maintenanceId' | 'maintenanceTripId' | 'reviewCompletedTripActionId' | 'approvalStatus'>(),
    submitRejectCompletedTrip: action<SubmitRejectCompletedTrip, | 'maintenanceId' | 'maintenanceTripId' | 'reviewCompletedTripActionId' | 'rejectFeedbackResponse' | 'approvalStatus'>(),
    submitOwnerFeedback: action<SubmitOwnerFeedbackRequest, | 'maintenanceId' | 'ownerFeedbackActionId' | 'ownerFeedbackResponse'>(),
    submitRenterFeedback: action<SubmitRenterFeedbackRequest, | 'maintenanceId' | 'renterFeedbackResponse'>(),
    upload: {
      add: action<FormUploadAdd, 'id' | 'field'>(),
    },
  },
  webhooks: {
    latchel: actionLazyType<any>('webhooks/latchel'),
    zillow: actionLazyType<any>('webhooks/zillow'),
  },
  feeds: {
    zillow: actionLazyType<any>('feeds/zillow'),
  },
  jobs: {
    rentAdjustmentCreation: actionLazyType<JobShard>('jobs/rentAdjustmentCreation/asdkifhjiasdf'),
  },
  forms: {
    onboarding: {
      owner: form(),
      renter: form(),
      lock: form(),
      updateDetails: form(),
    },
    lease: {
      landlordReview: form()
    },
    share: {
      renter: form(),
      landlord: form()
    },
    freeTier: {
      listing: form(),
      leasing: form(),
    },
    v2: {
      onboarding: {
        renter: form2(),
      }
    },
    property: {
      details: form(),
      pricing: form()
    },
    moveInUpload: formUpload(),
  },
  doc: {
    schema: {
      get: action<DocSchema, 'type' | 'templateState'>(),
      data: action<DocSchema, 'type' | 'data' | 'application_id'>(),
      save: action<DocLeaseTemplateSchema, 'doc' | 'application_id' | 'guid'>()
    },
    template: {
      get: action<DocTemplate, 'schema_type' | 'id'>(),
      getStateTemplate: action<DocTemplate, 'schema_type' | 'state' | 'schema'>(),
      getTemplateByType: action<DocTemplate, 'schema_type'>(),
      getData: action<DocTemplate, 'schema' | 'schema_type' | 'id' | 'guid'>(),
      list: action<GetTemplates, 'schema_type'>(),
      save: action<DocTemplate, 'content' | 'id' | 'name' | 'schema' | 'state'| 'documents'>(),
      create: action<DocTemplate, 'schema_type'>(),
      saveDraft: action<DocTemplate, 'content' | 'id' | 'envelopeType'>(),
    },
    leaseFiles: {
      list: action<LeaseDocumentRequest, 'docType'>(),
    },
    envelope: {
      get: action<Envelope, 'application_id'>()
    }
  },
  rentEstimate: {
    get: action<RentEstimate, 'addr_slug'>(),
    settings: {
      get: action<RentEstimateSettings, 'addr_slug'>(),
      save: action<RentEstimateSettings, 'addr_slug' | 'is_following' | 'offers_enabled' | 'offers_target_price' | 'alerts_enabled' | 'alerts_threshold'>(),
    },
    request: action<RentEstimateRequest, 'address' | 'bedrooms' | 'bathrooms'>(),
    getAuthed: action<RentEstimate, 'addr_slug'>(),
  },
  suggestedRentals: {
    find: actionNoParam<SuggestedRentals>()
  },
  nancy: {
    demo: action<LeaseCategory, 'questions'>(),
  },
  referrals: {
    info: actionNoParam<ReferralInfo>(),
  },
  freeWaitlist: {
    lookup: action<FreeWaitlistLookup, 'email' | 'inboundRefCode'>(),
  },
  card: {
    landlordWaitlist: {
      join: action<CreateCardWaitlist, 'email'>(),
    },
    renterWaitlist: {
      lookup: action<FreeWaitlistLookup, 'email' | 'inboundRefCode'>(),
      join: action<CreateCardWaitlist, 'email' | 'inboundRefCode'>(),
    }
  },
  flows: {
    list: actionNoParam<GetFlowList>(),
    get: action<GetFlow, 'id'>(),
    getActivity: action<GetFlowActivity, 'activity'>(),
    test: action<any, 'blah'>(),
    complete: action<UpdateFlowActivity, 'id' | 'metadata'>(),
    completeLeaseGen: action<UpdateFlowActivity, 'id' | 'metadata' | 'guid'>(),
    updateMetadata: action<UpdateFlowActivity, 'id' | 'metadata'>(),
    tryComplete: action<TryCompleteFlowActivity, 'propertyId' | 'actionType' | 'flowType'>(),
    uploadComment: action<FlowComment, 'flow_id' | 'content'>(),
    start: action<StartFlow, 'params'>(),
    updateLastSeen: action<GetFlow, 'id'>(),
    initializationComment: action<GetFlow, 'id'>(),
  },
  leasing_addendum: {
    createInfo: action<LeaseAddendumInfo, 'rental_agreement_id' | 'addendum_type' | 'change_fields' | 'reason'>(),
    updateInfo: actionLazyType<LeaseAddendumInfo>('leasing_addendum/updateInfo'),
    getInfo: action<LeaseAddendumInfo, 'id'>(),
    getInfoByGuid: action<LeaseAddendumInfo, 'id' | 'guid'>(),
    completeAddendumReview: action<LeaseAddendumInfo, 'id' | 'template' | 'guid' | 'flowActivityId'>(),
  },
  moveOut: {
    submitMoveOutForm: action<SubmitMoveOutFormRequest, 'flowActivityId' | 'formResponse' >(),
  },
  invest: {
    data: actionNoParam<InvestHome>(),
    getListing: action<DirectOfferListing, 'addr_slug'>(),
    getInquiry: action<GetDirectOfferInquiry, 'addr_slug'>(),
    sendInquiry: action<SendDirectOfferMessage, 'addr_slug' | 'message'>(),
    addListing: action<AddDirectOfferListing, 'address' | 'bedrooms' | 'bathrooms'>(),
    joinEmailList: action<JoinEmailList, 'email'>(),
  }
};

addPaths(_api, '');

export const api = _api;
