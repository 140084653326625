
const LIVE_URL = process.env.VERCEL_ENV === 'preview' && process.env.VERCEL_GIT_COMMIT_REF ? `https://${process.env.VERCEL_GIT_COMMIT_REF.replace('/', '-')}-api.mrb-dev.rentmarble.com` : 'https://api.rentmarble.com';

export const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? LIVE_URL : 'http://localhost:3000';

export const FRIDAY_UNO = 19900;
export const IGLOO_KEYBOX_PRICE = 17900;
export const IGLOO_DEADBOLT_PRICE = 19900;
export const HANDYMAN_PRICE = 15000;

export const APPLICATION_FEE = 4000;
export const NY_APPLICATION_FEE = 2000;
export const STATE_NY = 'NY';
export const THEME_MULTIPLIER = 5;

export const States = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NC',
  'ND',
  'NH',
  'NJ',
  'NM',
  'NV',
  STATE_NY,
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const NotApplicable = 'N/A';

export const States_With_NA = [NotApplicable].concat(States);

export const PetType_Cat = 'Cat';
export const PetType_SmallDog = 'Small Dog (less than 30 pounds)';
export const PetType_LargeDog = 'Large Dog (more than 30 pounds)';
export const PetType_Other = 'Other';

export const PetTypes = [
  PetType_Cat,
  PetType_SmallDog,
  PetType_LargeDog,
  'Bird',
  PetType_Other
];

export const ValidateEmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const CDN_URL = 'https://i.marblestatic.com';

export const Marble_Web_Url =  process.env.NODE_ENV === 'production'
? 'https://wwww.rentmarble.com' : 'http://localhost:8000';

const currDate = new Date();
export const demoCashFlowData = [
  {
    month: new Date(
      currDate.getFullYear(),
      currDate.getMonth() - 7,
    ).toLocaleString('en-us', {
      month: 'short',
      year: 'numeric',
    }),
    payments: [
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 7, 12),
          type: 1,
          amount: 125000,
        },
      },
    ],
  },
  {
    month: new Date(
      currDate.getFullYear(),
      currDate.getMonth() - 6,
    ).toLocaleString('en-us', {
      month: 'short',
      year: 'numeric',
    }),
    payments: [
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 6, 20),
          type: 2,
          amount: -12000,
        },
      },
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 6, 12),
          type: 1,
          amount: 125000,
        },
      },
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 6, 5),
          type: 2,
          amount: -25000,
        },
      },
    ],
  },
  {
    month: new Date(
      currDate.getFullYear(),
      currDate.getMonth() - 5,
    ).toLocaleString('en-us', {
      month: 'short',
      year: 'numeric',
    }),
    payments: [
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 5, 12),
          type: 1,
          amount: 125000,
        },
      },
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 5, 5),
          type: 2,
          amount: -25000,
        },
      },
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 5, 5),
          type: 2,
          amount: -25000,
        },
      },
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 5, 5),
          type: 2,
          amount: -25000,
        },
      },
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 5, 5),
          type: 2,
          amount: -25000,
        },
      },
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 5, 5),
          type: 2,
          amount: -25000,
        },
      },
    ],
  },
  {
    month: new Date(
      currDate.getFullYear(),
      currDate.getMonth() - 4,
    ).toLocaleString('en-us', {
      month: 'short',
      year: 'numeric',
    }),
    payments: [
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 4, 13),
          type: 1,
          amount: 70000,
        },
      },
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 4, 9),
          type: 2,
          amount: -20000,
        },
      },
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 4, 5),
          type: 2,
          amount: -25000,
        },
      },
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 4, 4),
          type: 2,
          amount: -25000,
        },
      },
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 4, 1),
          type: 2,
          amount: -15000,
        },
      },
    ],
  },
  {
    month: new Date(
      currDate.getFullYear(),
      currDate.getMonth() - 3,
    ).toLocaleString('en-us', {
      month: 'short',
      year: 'numeric',
    }),
    payments: [
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 3, 13),
          type: 1,
          amount: 125000,
        },
      },
    ],
  },
  {
    month: new Date(
      currDate.getFullYear(),
      currDate.getMonth() - 2,
    ).toLocaleString('en-us', {
      month: 'short',
      year: 'numeric',
    }),
    payments: [
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 2, 15),
          type: 1,
          amount: 125000,
        },
      },
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 2, 14),
          type: 1,
          amount: 3500,
        },
      },
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 2, 13),
          type: 1,
          amount: 7500,
        },
      },
    ],
  },
  {
    month: new Date(
      currDate.getFullYear(),
      currDate.getMonth() - 1,
    ).toLocaleString('en-us', {
      month: 'short',
      year: 'numeric',
    }),
    payments: [
      {
        charge: {
          date: new Date(currDate.getFullYear(), currDate.getMonth() - 1, 2),
          type: 1,
          amount: 125000,
        },
      },
    ],
  },
  {
    month: currDate.toLocaleString('en-us', {
      month: 'short',
      year: 'numeric',
    }),
    payments: [],
  },
];

export const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
