import { useApiDispatch } from "./global";
import {
  validateArray,
  validateBoolean,
  validateNumber,
  validateObject,
  validateString,
} from "../util/validate";
import { cents, usd, useMultiPageForm } from "./useForm";
import {
  LeaseAddendumChanges,
  LeaseAddendumInfo,
  LeaseAddendumType,
  RentalAgreement,
  UtilityAssignment,
} from "server-sdk/src/types";
import { useEffect, useState } from "react";
import { buildLateFees, convertLateFees, lateFeesStep } from "./moveInForms";
import { utilitiesList } from "./listingSetupForms";
import { DateTime } from "luxon";

export const useTenantLeaseAddendumForm = (onFinish: () => void) => {
  const apiDispatch = useApiDispatch();

  const [rentalAgreement, setRentalAgreement] = useState<
    any | undefined
  >(undefined);

  const [leaseAddendumInfo, setLeaseAddendumInfo] = useState<
    LeaseAddendumInfo | undefined
  >(undefined);

  let endLease = new Date(rentalAgreement?.end_lease);
  let leaseMinimum = new Date(rentalAgreement?.end_lease);
  leaseMinimum.setMonth(endLease.getMonth() - 6);

  let pets: { pet: any; user: number }[] = [];
  const startOfToday = DateTime.now().startOf("day");

  const setValues = (ra: any) => {
    endLease = new Date(ra.end_lease);
    leaseMinimum.setMonth(endLease.getMonth() - 6);

    const unflattened: any[] =
      ra?.application_users?.map((appUser) =>
        appUser.pets?.pets?.map((pet) => ({ pet: pet, user: appUser?.user_id }))
      ) ?? [];
    pets = unflattened.flat().filter((pet) => pet !== undefined);

    pages.lease_start.form.values.start_lease = ra.start_lease
    pages.early_termination.form.values.end_lease = ra.end_lease


  };

  useEffect(() => {
    apiDispatch(async (call, api) => {
      const res = await call(api.property.getRenterRentalAgreement);
      console.log(res);
      if (res) {
        setRentalAgreement(res);
        setValues(res);
        return [true];
      }

      return [false, "Something went wrong"];
    });
  }, []);

  const startLeasingAddendumFlow = (
    addendum_type: LeaseAddendumType | undefined,
    reason: string | undefined,
    change_fields: LeaseAddendumChanges,
    done: () => void
  ) => {
    apiDispatch(async (call, api) => {
      const res = await call(api.leasing_addendum.createInfo, {
        rental_agreement_id: rentalAgreement?.id,
        addendum_type,
        change_fields,
        reason
      });

      if (!res) {
        return [false, "Something went wrong"];
      }

      setLeaseAddendumInfo(res);

      // create flow
      call(api.flows.start, {
        params: {
          type: "leasing-addendum",
          args: {
            propertyId: rentalAgreement?.property_id ?? 0,
            leaseAddendumInfoId: parseInt(res.id ? res.id.toString() : "0"),
          },
        },
      });

      onFinish();
      done();
      return [true, "Submitted addendum request!"];
    });
  };


  const pages = useMultiPageForm({
    options: {
      initial: {
        choice: undefined,
      },
      label: "Lease Addendum",
      description: "What addendum is being added?",
      validationSchema: validateObject({
        choice: validateString()
          .typeError("Please select an option")
          .oneOf([
            LeaseAddendumType.EarlyTermination,
            LeaseAddendumType.AddPet,
            LeaseAddendumType.RemovePet,
            LeaseAddendumType.AddRoommate,
            LeaseAddendumType.RemoveRoommate,
            LeaseAddendumType.PropertyChanges,
            LeaseAddendumType.LeaseStart,
          ])
          .required("Please select an option"),
      }),
      steps: (values) => {
        let options = [];
        if(rentalAgreement?.start_lease > new Date().getTime()){
          options.push({
            value: LeaseAddendumType.LeaseStart,
            label: "Change Lease Start Date",
          })
        }
        options = options.concat([
          {
            value: LeaseAddendumType.EarlyTermination,
            label: "Early Termination",
          },
          {
            value: LeaseAddendumType.AddPet,
            label: "Add a New Pet",
          },
        ])

        if (pets.length > 0) {
          options.push({
            value: LeaseAddendumType.RemovePet,
            label: "Remove a Pet",
          });
        }
        options.push({
          value: LeaseAddendumType.AddRoommate,
          label: "Add a Roommate",
        });
        if ((rentalAgreement?.roommates?.length ?? 0) > 1) {
          options.push({
            value: LeaseAddendumType.RemoveRoommate,
            label: "Remove a Roommate",
          });
        }
        options.push({
          value: LeaseAddendumType.PropertyChanges,
          label: "Property Changes",
        });
        return [
        {
          name: "choice",
          label: "Request Type",
          placeholder: "Choice",
          required: true,
          type: "choice",
          exclusive: true,
          options: options
        },
      ]},
      submit: ({ values, done }) => {
        console.log("values", values);
        done();
      },
    },
    addendum_reason: {
      initial: {
        reason: leaseAddendumInfo?.reason,
      },
      label: "Reason",
      description: "Please provide a reason for the change you want to make.",
      validationSchema: validateObject({
        reason: validateString().required("Reason is required"),
      }),
      disabled: (values) => {
        return (
          !values.options.choice
        );
      },
      steps: (values) => [
        {
          name: "reason",
          label: "Reason for change",
          placeholder: "Reason for change",
          required: true,
          type: "paragraph",
        },
      ],
      submit: ({ values, done }) => {
        done();
      },
    },
    lease_start: {
      initial: {
        start_lease: leaseAddendumInfo?.change_fields?.start_lease,
      },
      validationSchema: validateObject({
        start_lease: validateNumber()
          .min(
            startOfToday.minus({ day: 1 }).toMillis(),
            "Start lease must start after today"
          )
          .max(
            leaseMinimum.getTime(),
            "Rent terms must be a minimum of 6 months"
          )
          .required("A start lease date is required."),
      }),
      label: "Lease Start",
      description: "When will you be moving in?",
      disabled: (values) => {
        return (
          !values.options.choice ||
          values.options.choice !== LeaseAddendumType.LeaseStart
        );
      },
      steps: (values) => [
        {
          name: "start_lease",
          label: "Move In Date",
          placeholder: "Move In Date",
          required: true,
          type: "date",
          minDate: startOfToday.toMillis(),
        },
      ],
      submit: ({ values, done }) => {
        startLeasingAddendumFlow(
          values?.options?.choice,
          values?.addendum_reason.reason,
          {
            ...values.lease_start,
          },
          done
        );
      },
    },
    early_termination: {
      initial: {
        end_lease: leaseAddendumInfo?.change_fields?.end_lease,
      },
      label: "Lease End",
      description: "When will you be moving out?",
      validationSchema: validateObject({
        end_lease: validateNumber()
          .min(
            startOfToday.minus({ day: 1 }).toMillis(),
            "End lease must be after today"
          )
          .max(
            endLease.getTime(),
            "End lease must be before the current end lease"
          )
          .required("A end lease date is required."),
      }),
      disabled: (values) => {
        return (
          !values.options.choice ||
          values.options.choice !== LeaseAddendumType.EarlyTermination
        );
      },
      steps: (values) => [
        {
          name: "end_lease",
          label: "Lease End Date",
          placeholder: "End Date",
          required: true,
          type: "date",
          minDate: startOfToday.toMillis(),
          maxDate: endLease.getTime(),
        },
      ],
      submit: ({ values, done }) => {
        startLeasingAddendumFlow(
          values?.options?.choice,
          values?.addendum_reason.reason,
          {
            ...values.early_termination,
          },
          done
        );
      },
    },
    add_pet: {
      initial: {
        pet_type: leaseAddendumInfo?.change_fields?.pet_type,
        pet_name: leaseAddendumInfo?.change_fields?.pet_name,
        pet_breed: leaseAddendumInfo?.change_fields?.pet_breed,
        pet_weight: leaseAddendumInfo?.change_fields?.pet_weight,
        pet_age: leaseAddendumInfo?.change_fields?.pet_age,
        pet_neutered: leaseAddendumInfo?.change_fields?.pet_neutered,
        pet_vaccines: leaseAddendumInfo?.change_fields?.pet_vaccines,
        pet_license: leaseAddendumInfo?.change_fields?.pet_license,
      },
      label: "Add Pet",
      description: "Specify the pet you will be adding",
      validationSchema: validateObject({
        pet_type: validateString()
          .typeError("Type of pet is required")
          .oneOf([
            "Small Dog (less than 30 pounds)",
            "Large Dog (more than 30 pounds)",
            "Cat",
            "Other",
          ])
          .required("Type of pet is required"),
        pet_name: validateString()
          .typeError("Pet Name is required")
          .min(2, "Pet Name must be longer than 2 characters")
          .required("Pet Name is required"),
        pet_breed: validateString()
          .typeError("Pet Breed is required")
          .min(1, "Pet Breed must be longer than 1 character")
          .required("Pet Breed is required"),
        pet_weight: validateNumber().typeError("Pet Weight is required").required("Pet Weight is required"),
        pet_age: validateNumber().typeError("Pet Age is required").required("Pet Age is required"),
        pet_neutered: validateBoolean().typeError("Neutered status is required").required("Neutered status is required"),
        pet_vaccines: validateString(),
        pet_license: validateNumber(),
      }),
      disabled: (values) => {
        return (
          !values?.options?.choice ||
          values?.options?.choice !== LeaseAddendumType.AddPet
        );
      },
      steps: (values) => [
        {
          name: "pet_type",
          label: "Pet Type",
          placeholder: "Type",
          required: true,
          type: "dropdown",
          options: [
            {
              value: "Small Dog (less than 30 pounds)",
              label: "Small Dog (less than 30 pounds)",
            },
            {
              value: "Large Dog (more than 30 pounds)",
              label: "Large Dog (more than 30 pounds)",
            },
            {
              value: "Cat",
              label: "Cat",
            },
            {
              value: "Other",
              label: "Other",
            },
          ],
        },
        {
          name: "pet_name",
          label: "Pet Name",
          placeholder: "Name",
          required: true,
          type: "text",
        },
        {
          name: "pet_breed",
          label: "Pet Breed",
          placeholder: "Breed",
          required: true,
          type: "text",
        },
        {
          name: "pet_weight",
          label: "Pet Weight (lb)",
          placeholder: "Weight (lb)",
          required: true,
          type: "number",
        },
        {
          name: "pet_age",
          label: "Pet Age (yr)",
          placeholder: "Age (yr)",
          required: true,
          type: "number",
        },
        {
          name: "pet_neutered",
          label: "Neutered Or Spade",
          placeholder: "Neutered Or Spade",
          required: true,
          type: "yes-no",
        },
        {
          name: "pet_vaccines",
          label: "List of Vaccines (if applicable)",
          placeholder: "List of Vaccines (if applicable)",
          required: false,
          type: "paragraph",
        },
        {
          name: "pet_license",
          label: "Valid Animal License (if applicable)",
          placeholder: "Valid Animal License (if applicable)",
          required: false,
          type: "number",
        },
      ],
      submit: ({ values, done }) => {
        startLeasingAddendumFlow(
          values?.options?.choice,
          values?.addendum_reason.reason,
          {
            ...values.add_pet,
          },
          done
        );
      },
    },
    remove_pet: {
      initial: {
        pet_type: leaseAddendumInfo?.change_fields?.pet_type,
        pet_name: leaseAddendumInfo?.change_fields?.pet_name,
        pet_breed: leaseAddendumInfo?.change_fields?.pet_breed,
        pet_weight: leaseAddendumInfo?.change_fields?.pet_weight,
        pet_age: leaseAddendumInfo?.change_fields?.pet_age,
        pet_neutered: leaseAddendumInfo?.change_fields?.pet_neutered,
        pet_vaccines: leaseAddendumInfo?.change_fields?.pet_vaccines,
        pet_license: leaseAddendumInfo?.change_fields?.pet_license,
        effective_date: undefined,
      },
      validationSchema: validateObject({
        pet_type: validateString()
          .typeError("Type of pet is required")
          .oneOf([
            "Small Dog (less than 30 pounds)",
            "Large Dog (more than 30 pounds)",
            "Cat",
            "Other",
          ])
          .required("Type of pet is required"),
        pet_name: validateString()
          .typeError("Pet Name is required")
          .min(2, "Pet Name must be longer than 2 characters")
          .required("Pet Name is required"),
        pet_breed: validateString()
          .typeError("Pet Breed is required")
          .min(1, "Pet Breed must be longer than 1 character")
          .required("Pet Breed is required"),
        pet_weight: validateNumber().typeError("Pet Weight is required").required("Pet Weight is required"),
        pet_age: validateNumber().typeError("Pet Age is required").required("Pet Age is required"),
        pet_neutered: validateBoolean().typeError("Neutered status is required").required("Neutered status is required"),
        pet_vaccines: validateString(),
        pet_license: validateNumber(),
        effective_date: validateNumber()
          .typeError("Effective date is required")
          .min(
            startOfToday.minus({ day: 1 }).toMillis(),
            "Change must begin no earlier than today"
          )
          .required("Effective date is required"),
      }),
      label: "Remove Pet",
      description: "Specify the pet you will be removing",
      disabled: (values) => {
        return (
          !values.options.choice ||
          values.options.choice !== LeaseAddendumType.RemovePet
        );
      },
      steps: (values) => [
        {
          name: "pet_type",
          label: "Pet Type",
          placeholder: "Type",
          required: true,
          type: "dropdown",
          options: [
            {
              value: "Small Dog (less than 30 pounds)",
              label: "Small Dog (less than 30 pounds)",
            },
            {
              value: "Large Dog (more than 30 pounds)",
              label: "Large Dog (more than 30 pounds)",
            },
            {
              value: "Cat",
              label: "Cat",
            },
            {
              value: "Other",
              label: "Other",
            },
          ],
        },
        {
          name: "pet_name",
          label: "Pet Name",
          placeholder: "Name",
          required: true,
          type: "text",
        },
        {
          name: "pet_breed",
          label: "Pet Breed",
          placeholder: "Breed",
          required: true,
          type: "text",
        },
        {
          name: "pet_weight",
          label: "Pet Weight (lb)",
          placeholder: "Weight (lb)",
          required: true,
          type: "number",
        },
        {
          name: "pet_age",
          label: "Pet Age (yr)",
          placeholder: "Age (yr)",
          required: true,
          type: "number",
        },
        {
          name: "pet_neutered",
          label: "Neutered Or Spade",
          placeholder: "Neutered Or Spade",
          required: true,
          type: "yes-no",
        },
        {
          name: "pet_vaccines",
          label: "List of Vaccines (if applicable)",
          placeholder: "List of Vaccines (if applicable)",
          required: false,
          type: "paragraph",
        },
        {
          name: "pet_license",
          label: "Valid Animal License (if applicable)",
          placeholder: "Valid Animal License (if applicable)",
          required: false,
          type: "number",
        },
        {
          name: "effective_date",
          label: "Effective Date",
          placeholder: "Effective Date",
          required: true,
          type: "date",
          minDate: startOfToday.toMillis(),
        },
      ],
      submit: ({ values, done }) => {
        startLeasingAddendumFlow(
          values?.options?.choice,
          values?.addendum_reason.reason,
          {
            ...values.remove_pet,
          },
          done
        );
      },
    },
    add_roommate: {
      initial: {
        roommate_relationship:
          leaseAddendumInfo?.change_fields?.rooomate_relationship,
        roommate_first_name: leaseAddendumInfo?.change_fields?.roommate_first_name,
        roommate_last_name: leaseAddendumInfo?.change_fields?.roommate_last_name,
        roommate_email: leaseAddendumInfo?.change_fields?.roommate_email,
        roommate_phone: leaseAddendumInfo?.change_fields?.roommate_phone,
        roommate_move: leaseAddendumInfo?.change_fields?.roommate_move,
      },
      label: "Add Roommate",
      description: "Specify the roommate you will be adding",
      validationSchema: validateObject({
        roommate_relationship: validateString().typeError("Please enter the relationship")
          .oneOf(["spouse", "parent_guardian", "sibling", "friend"])
          .required("Please enter the relationship"),
        roommate_first_name: validateString()
          .typeError("First name is required")
          .required("First name is required")
          .max(30, "Maximum length is 30 characters"),
        roommate_last_name: validateString()
          .typeError("Last name is required")
          .required("Last name is required")
          .max(30, "Maximum length is 30 characters"),
        roommate_email: validateString()
          .typeError("Please enter an email")
          .required("Please enter an email")
          .email("Invalid email address"),
        roommate_phone: validateString()
          .typeError("Please enter a phone number")
          .required("Please enter a phone number")
          .min(10)
          .max(16),
        roommate_move: validateNumber()
          .required("Please enter a move in date")
          .min(
            startOfToday.minus({ day: 1 }).toMillis(),
            "Move in must begin no earlier than today"
          )
          .required("A move in date is required"),
      }),
      disabled: (values) => {
        return (
          !values.options.choice ||
          values.options.choice !== LeaseAddendumType.AddRoommate
        );
      },
      steps: (values) => [
        {
          name: "roommate_relationship",
          label: "Relationship",
          placeholder: "Relationship",
          required: true,
          type: "dropdown",
          options: [
            {
              value: "spouse",
              label: "Spouse",
            },
            {
              value: "parent_guardian",
              label: "Parent/Guardian",
            },
            {
              value: "sibling",
              label: "Sibling",
            },
            {
              value: "friend",
              label: "Friend",
            },
          ],
        },
        {
          name: "roommate_first_name",
          label: "Roommate First Name",
          placeholder: "First Name",
          required: true,
          type: "text",
        },
        {
          name: "roommate_last_name",
          label: "Roommate Last Name",
          placeholder: "Last Name",
          required: true,
          type: "text",
        },
        {
          name: "roommate_email",
          label: "Roommate Email",
          placeholder: "Email",
          required: true,
          type: "email",
        },
        {
          name: "roommate_phone",
          label: "Roommate Phone",
          placeholder: "Phone",
          required: true,
          type: "phone",
        },
        {
          name: "roommate_move",
          label: "Roommate's Move-in Date",
          placeholder: "Move-in Date",
          required: true,
          type: "date",
          minDate: startOfToday.toMillis(),
        },
      ],
      submit: ({ values, done }) => {
        startLeasingAddendumFlow(
          values?.options?.choice,
          values?.addendum_reason.reason,
          {
            roommate_first_name: values.add_roommate.roommate_first_name?.trim(),
            roommate_last_name: values.add_roommate.roommate_last_name?.trim(),
            roommate_email: values.add_roommate.roommate_email?.trim().toLowerCase(),
            roommate_phone: values.add_roommate.roommate_phone?.trim(),
            roommate_move: values.add_roommate.roommate_move, 
          },
          done
        );
      },
    },
    remove_roommate: {
      initial: {
        remove_roommate_id:
          leaseAddendumInfo?.change_fields?.remove_roommate_id,
        roommate_move: leaseAddendumInfo?.change_fields?.roommate_move,
      },
      label: "Remove Roommate",
      description: "Specify the roommate you will be removing",
      validationSchema: validateObject({
        remove_roommate_id: validateNumber()
          .typeError("Please select a roommate")
          .required("Please select a roommate"),
        roommate_move: validateNumber()
          .typeError("A move out date is required")
          .min(
            startOfToday.minus({ day: 1 }).toMillis(),
            "Move in must begin no earlier than today"
          )
          .required("A move out date is required"),
      }),
      disabled: (values) => {
        return (
          !values.options.choice ||
          values.options.choice !== LeaseAddendumType.RemoveRoommate
        );
      },
      steps: (values) => {
        return [
          {
            name: "remove_roommate_id",
            label: "Roommate Choice",
            placeholder: "Roommate",
            required: true,
            exclusive: true,
            type: "choice",
            options: rentalAgreement?.roommates?.map((roommate) => {
              return {
                label: `${roommate.renter_user.first_name} ${roommate.renter_user.last_name}`,
                value: roommate.renter_user_id,
              };
            }),
          },
          {
            name: "roommate_move",
            label: "Roommate's Move-out Date",
            placeholder: "Move-out Date",
            required: true,
            type: "date",
            minDate: startOfToday.toMillis(),
          },
        ];
      },
      submit: ({ values, done }) => {
        const roommate = rentalAgreement?.roommates.find((roommate) => roommate.renter_user_id === values?.remove_roommate?.remove_roommate_id);

        startLeasingAddendumFlow(
          values?.options?.choice,
          values?.addendum_reason.reason,
          {
            ...values.remove_roommate,
            roommate_name: `${roommate.user.first_name} ${roommate.user.last_name}`
          },
          done
        );
      },
    },
    property_changes_location: {
      initial: {
        property_change_location:
          leaseAddendumInfo?.change_fields?.property_change_location,
      },
      label: "Change Location",
      description: "Where are the changes you'd like to request?",
      validationSchema: validateObject({
        property_change_location: validateString()
          .typeError("Location is required to continue")
          .oneOf(["inside", "outside"])
          .required("Location is required to continue"),
      }),
      disabled: (values) => {
        return (
          !values.options.choice ||
          values.options.choice !== LeaseAddendumType.PropertyChanges
        );
      },
      steps: (values) => [
        {
          name: "property_change_location",
          label: "Location",
          placeholder: "Location",
          required: true,
          type: "choice",
          exclusive: true,
          options: [
            {
              value: "inside",
              label: "Inside",
              caption: "Anything that is within the walls & doors of the home.",
            },
            {
              value: "outside",
              label: "Outside",
              caption:
                "Anything that is outside of the walls & doors of the home.",
            },
          ],
        },
      ],
      submit: ({ values, done }) => {
        done()
      },
    },
    property_changes_type: {
      initial: {
        property_change_type:
          leaseAddendumInfo?.change_fields?.property_change_type,
      },
      label: "Change Type",
      description: "What type of changes would you like to request?",
      validationSchema: validateObject({
        property_change_type: validateString()
          .typeError("Type of change is required to continue")
          .oneOf(['addition', 'removal', 'repair'])
          .required("Type of change is required to continue"),
      }),
      disabled: (values) => {
        return (
          !values.options.choice ||
          values.options.choice !== LeaseAddendumType.PropertyChanges
        );
      },
      steps: (values) => [
        {
          name: "property_change_type",
          label: "Type",
          placeholder: "Type",
          required: true,
          type: "choice",
          exclusive: true,
          options: [
            {
              value: "addition",
              label: "Addition",
              caption: "I'd like to add something to the home.",
            },
            {
              value: "removal",
              label: "Removal",
              caption:
                "I'd like to remove and/or throw away some component of the home.",
            },
            {
              value: "repair",
              label: "Repair or Modify",
              caption:
                "I'd like to repair or modify some component of the home.",
            },
          ],
        },
      ],
      submit: ({ values, done }) => {
        done()
      },
    },
    property_changes_description: {
      initial: {
        property_change_description:
          leaseAddendumInfo?.change_fields?.property_change_description,
      },
      label: "Description",
      description: "Please provide a description representing the changes you'd like to make:",
      validationSchema: validateObject({
        property_change_description: validateString().required("Description is required"),
      }),
      disabled: (values) => {
        return (
          !values.options.choice ||
          values.options.choice !== LeaseAddendumType.PropertyChanges
        );
      },
      steps: (values) => [
        {
          name: "property_change_description",
          label: "Description",
          placeholder: "Description",
          required: true,
          type: "paragraph",
        },
      ],
      submit: ({ values, done }) => {
        startLeasingAddendumFlow(
          values?.options?.choice,
          values?.addendum_reason.reason,
          {
            property_change_location: values?.property_changes_location?.property_change_location,
            property_change_type: values?.property_changes_type?.property_change_type,
            property_change_description: values?.property_changes_description?.property_change_description,
            additional_specifics: ["property_changes"]
          },
          done
        );
      },
    },
  });
  return pages;
};

export const useOwnerLeaseAddendumForm = (
  propertyId: number,
  onFinish: () => void
) => {
  const apiDispatch = useApiDispatch();

  const [rentalAgreement, setRentalAgreement] = useState<
    RentalAgreement | undefined
  >(undefined);

  const [leaseAddendumInfo, setLeaseAddendumInfo] = useState<
    LeaseAddendumInfo | undefined
  >(undefined);
  const unflattened: any[] =
  rentalAgreement?.application_users?.map((appUser) =>
    // @ts-ignore
    appUser.pets?.pets?.map((pet) => ({ pet: pet, user: appUser?.user_id }))
  ) ?? [];
  let pets = unflattened.flat().filter((pet) => pet !== undefined);
  const startOfToday = DateTime.now().startOf("day");

  let initialLateFee = buildLateFees(
    rentalAgreement?.property?.late_fees,
    rentalAgreement?.property?.listing_price,
    true
  );

  const setValues = (ra: any) => {
    const unflattened: any[] =
      ra?.application_users?.map((appUser) =>
        appUser.pets?.pets?.map((pet) => ({ pet: pet, user: appUser?.user_id }))
      ) ?? [];
    pets = unflattened.flat().filter((pet) => pet !== undefined);
    initialLateFee = buildLateFees(
      ra?.property?.late_fees,
      ra?.property?.listing_price,
      true
    );
    pages.late_fees.form.values.days = initialLateFee.days;
    pages.late_fees.form.values.choice =
      initialLateFee.choice_conditional.choice;
    pages.late_fees.form.values.one_time_type =
      initialLateFee.choice_conditional.one_time_collection.type_conditional.type;
    pages.late_fees.form.values.one_time_value =
      initialLateFee.choice_conditional.one_time_collection.type_conditional.value;
    pages.late_fees.form.values.recurring_initial_fee =
      initialLateFee.choice_conditional.recurring_collection.initial_fee;
    pages.late_fees.form.values.recurring_recurring_fee =
      initialLateFee.choice_conditional.recurring_collection.recurring_fee;
    pages.new_fees_parking_fees.form.values.new_parking_fee = ra?.property?.parking_fee;
    pages.new_fees_pet_fees.form.values.new_cat_deposit = ra?.property?.cat_deposit;
    pages.new_fees_pet_fees.form.values.new_cat_fee = ra?.property?.cat_fee;
    pages.new_fees_pet_fees.form.values.new_dog_deposit = ra?.property?.dog_deposit;
    pages.new_fees_pet_fees.form.values.new_dog_fee = ra?.property?.dog_fee;
    pages.rent_update_price.form.values.new_rent = usd(ra?.base_rent_amount);
    pages.rent_update_price.form.values.new_rent_due_day =
      ra?.rent_due_day;
    pages.utilities.form.values.electric_utilities =
      ra?.property?.electric_utilities;
    pages.utilities.form.values.gas_utilities = ra?.property?.gas_utilities;
    pages.utilities.form.values.water_utilities = ra?.property?.water_utilities;
    pages.utilities.form.values.garbage_sewage_utilities =
      ra?.property?.garbage_sewage_utilities;
  };

  useEffect(() => {
    apiDispatch(async (call, api) => {
      const res = await call(api.property.getPropertyRentalAgreement, {
        property_id: propertyId,
      });
      console.log(res);
      if (res) {
        setRentalAgreement(res);
        setValues(res);
        return [true];
      }

      return [false, "Something went wrong"];
    });
  }, [propertyId]);

  const startLeasingAddendumFlow = (
    addendum_type: LeaseAddendumType | undefined,
    change_fields: LeaseAddendumChanges,
    done: () => void
  ) => {
    apiDispatch(async (call, api) => {
      const res = await call(api.leasing_addendum.createInfo, {
        rental_agreement_id: rentalAgreement?.id,
        addendum_type,
        change_fields,
        reason: "Owner request"
      });

      if (!res) {
        return [false, "Something went wrong"];
      }

      setLeaseAddendumInfo(res);

      // create flow
      call(api.flows.start, {
        params: {
          type: "leasing-addendum",
          args: {
            propertyId: rentalAgreement?.property_id ?? 0,
            leaseAddendumInfoId: parseInt(res.id ? res.id.toString() : "0"),
          },
        },
      });

      onFinish();
      done();
      return [true];
    });
  };

  const pages = useMultiPageForm({
    options: {
      initial: {
        choice: undefined,
      },
      label: "Lease Addendum",
      description: "What addendum is being added?",
      validationSchema: validateObject({
        choice: validateString()
          .typeError("Please select an option")
          .oneOf([
            LeaseAddendumType.RentUpdate,
            LeaseAddendumType.TransferAssignments,
            LeaseAddendumType.FeesChange,
            LeaseAddendumType.PropertyChanges,
          ])
          .required("Please select an option"),
      }),
      steps: (values) => [
        {
          name: "choice",
          label: "Request Type",
          placeholder: "Choice",
          required: true,
          type: "choice",
          exclusive: true,
          options: [
            {
              value: LeaseAddendumType.RentUpdate,
              label: "Change Rent Price",
            },
            {
              value: LeaseAddendumType.TransferAssignments,
              label: "Transfer Utility Assignment",
            },
            {
              value: LeaseAddendumType.FeesChange,
              label: "Change extra fees for lease (Parking & Pet fees)",
            },
            {
              value: LeaseAddendumType.PropertyChanges,
              label: "Update Property Info",
            },
          ],
        },
      ],
      submit: ({ values, done }) => {
        console.log("values", values);
        done();
      },
    },
    rent_update_price: {
      initial: {
        new_rent: rentalAgreement?.base_rent_amount,
        new_rent_due_day: rentalAgreement?.rent_due_day,
        effective_date: undefined,
      },
      label: "Change Rent Price",
      description: "What will be the updated rent price?",

      validationSchema: validateObject({
        new_rent: validateNumber().typeError("New rent price is required").required("New rent price is required"),
        new_rent_due_day: validateNumber().typeError("Rent due day is required").min(1).max(31).required("Rent due day is required"),
        effective_date: validateNumber()
          .typeError("Effective date is required")
          .min(
            startOfToday.minus({ day: 1 }).toMillis(),
            "Change must begin no earlier than today"
          )
          .required("Effective date is required"),
      }),
      disabled: (values) => {
        return (
          !values.options.choice ||
          values.options.choice !== LeaseAddendumType.RentUpdate
        );
      },
      steps: (values) => [
        {
          name: "new_rent",
          label: "Rent Price",
          placeholder: "Rent Price",
          required: true,
          exclusive: true,
          type: "usd",
        },
        {
          name: "new_rent_due_day",
          label: "Rent Due Day",
          placeholder: "Rent Due Day",
          required: true,
          exclusive: true,
          type: "number",
        },
        {
          name: "effective_date",
          label: "Effective Date",
          placeholder: "Effective Date",
          required: true,
          type: "date",
          minDate: startOfToday.toMillis(),
        },
      ],
      submit: ({ values, done }) => {
        done();
      },
    },
    rent_update_additional_specifics: {
      initial: {
        additional_specifics: [],
      },
      label: "Reason",
      description: "What are the reason(s) for the rent update?",

      validationSchema: validateObject({
        additional_specifics: validateArray(),
      }),
      disabled: (values) => {
        return (
          !values.options.choice ||
          values.options.choice !== LeaseAddendumType.RentUpdate
        );
      },
      steps: (values) => {
        const tenantUtilities = [];
        const utilities = [
          "gas_utilities",
          "water_utilities",
          "electric_utilities",
          "garbage_sewage_utilities",
        ];
        for (const utility of utilities) {
          if (
            (rentalAgreement?.property as any)?.[utility] ===
            UtilityAssignment.Renter
          ) {
            const formattedUtility = utility
              .substring(0, utility.indexOf("_utilities"))
              .replace("_", "/");
            tenantUtilities.push(formattedUtility);
          }
        }

        const options = [
          {
            value:
              "The standard allowed annual rent increase and adjustment for cost of living.",
            label:
              "The standard allowed annual rent increase and adjustment for cost of living.",
          },
          {
            value:
              "The required payment of tenant responsible property repairs.",
            label:
              "The required payment of tenant responsible property repairs.",
          },
          {
            value:
              "The reimbursement for tenant completed property repairs or improvements",
            label:
              "The reimbursement for tenant completed property repairs or improvements",
          },
        ];
        if (tenantUtilities.length > 0) {
          options.push({
            value: `The required payment for tenant use of ${tenantUtilities.toString()} utilities.`,
            label: `The required payment for tenant use of ${tenantUtilities.toString()} utilities.`,
          });
        }
        return [
          {
            name: "additional_specifics",
            label: "Specifics",
            placeholder: "Specifics",
            required: true,
            type: "choice",
            exclusive: false,
            options: options,
          },
        ];
      },
      submit: ({ values, done }) => {
        startLeasingAddendumFlow(
          values?.options?.choice,
          {
            ...values.rent_update_price,
            new_rent: cents(values.rent_update_price.new_rent),
            ...values.rent_update_additional_specifics,
          },
          done
        );
      },
    },
    utilities: {
      label: "Utilities",
      description: "Assign your utility fees",
      initial: {
        water_utilities: rentalAgreement?.property?.water_utilities,
        electric_utilities: rentalAgreement?.property?.electric_utilities,
        gas_utilities: rentalAgreement?.property?.gas_utilities,
        garbage_sewage_utilities:
          rentalAgreement?.property?.garbage_sewage_utilities,
        effective_date: undefined,
      },
      validationSchema: validateObject({
        water_utilities: validateNumber()
          .typeError("Water Utilities are required")
          .oneOf(utilitiesList)
          .required("Water Utilities are required"),
        electric_utilities: validateNumber()
          .typeError("Electric Utilities are required")
          .oneOf(utilitiesList)
          .required("Electric Utilities are required"),
        gas_utilities: validateNumber()
          .typeError("Gas Utilities are required")
          .oneOf(utilitiesList)
          .required("Gas Utilities are required"),
        garbage_sewage_utilities: validateNumber()
          .typeError("Garbage & Sewage Utilities are required")
          .oneOf(utilitiesList)
          .required("Garbage & Sewage Utilities are required"),
        effective_date: validateNumber()
          .typeError("Effective date is required")
          .min(
            startOfToday.minus({ day: 1 }).toMillis(),
            "Change must begin no earlier than today"
          )
          .required("Effective date is required"),
      }),
      disabled: (values) => {
        return (
          !values.options.choice ||
          values.options.choice !== LeaseAddendumType.TransferAssignments
        );
      },
      steps: (values) => {
        const utilitiesChoices = [
          {
            label: "Tenant pays",
            value: UtilityAssignment.Renter,
          },
          {
            label: "Landlord pays",
            value: UtilityAssignment.Owner,
          },
          {
            label: "N/A",
            value: UtilityAssignment.NA,
          },
        ];
        return [
          {
            name: "water_utilities",
            label: "Water Utilities",
            placeholder: "Water Utilities",
            required: true,
            type: "dropdown",
            options: utilitiesChoices,
          },
          {
            name: "electric_utilities",
            label: "Electric Utilities",
            placeholder: "Electric Utilities",
            required: true,
            type: "dropdown",
            options: utilitiesChoices,
          },
          {
            name: "gas_utilities",
            label: "Gas Utilities",
            placeholder: "Gas Utilities",
            required: true,
            type: "dropdown",
            options: utilitiesChoices,
          },
          {
            name: "garbage_sewage_utilities",
            label: "Garbage & Sewage Utilities",
            placeholder: "Garbage & Sewage Utilities",
            required: true,
            type: "dropdown",
            options: utilitiesChoices,
          },
          {
            name: "effective_date",
            label: "Effective Date",
            placeholder: "Effective Date",
            required: true,
            type: "date",
            minDate: startOfToday.toMillis(),
          },
        ];
      },
      submit: ({ values, done }) => {
        done();
      },
    },
    utilities_transfer_responsibility: {
      initial: {
        transfer_responsibility_answer: false,
        utility_company: "",
        utility_contact_user: undefined,
        utility_contact: "",
      },
      label: "Transfer Responsibility",
      description:
        "Is the tenant required to contact the utility company or companies to transfer resposibility to their name?",
      validationSchema: validateObject({
        transfer_responsibility_answer: validateBoolean().required(),
        utility_company: validateString().when("answer", {
          is: true,
          then: validateString().required("A utility company is required"),
        }),
        utility_contact_user: validateString().when("answer", {
          is: true,
          then: validateString().required(
            "Please select who will be contacting the utility company"
          ),
        }),
        utility_contact: validateString().when("answer", {
          is: true,
          then: validateString().required(
            "Provide the contact information for the utility company(s)"
          ),
        }),
      }),
      disabled: (values) => {
        return (
          !values.options.choice ||
          values.options.choice !== LeaseAddendumType.TransferAssignments
        );
      },
      steps: (values) => {
        let finalSteps: any[] = [
          {
            name: "transfer_responsibility_answer",
            label: "Answer",
            placeholder: "Answer",
            required: true,
            type: "yes-no",
          },
        ];
        if(values?.utilities_transfer_responsibility?.transfer_responsibility_answer){
          finalSteps = finalSteps.concat([
            {
              name: "utility_company",
              label: "Utility Company Name(s)",
              placeholder: "Utility Company Name(s)",
              required: true,
              type: "text",
            },
            {
              name: "utility_contact_user",
              label: "Who will contact the utility company?",
              placeholder: "Who will contact the utility company?",
              required: true,
              exclusive: true,
              type: "choice",
              options: [
                {
                  value: "TENANT(s)",
                  label: "Tenant(s)",
                },
                {
                  value: "LANDLORD(s)",
                  label: "Landlord(s)",
                },
              ],
            },
            {
              name: "utility_contact",
              label:
                "How will they contact the utility company? (phone OR website)",
              placeholder:
                "How will they contact the utility company? (phone OR website)",
              required: true,
              type: "text",
            },
          ]);
        }
        return finalSteps;
      },
      submit: ({ values, done }) => {
        done();
      },
    },
    utilities_reimburse_tenant: {
      initial: {
        reimburse_tenant_answer: true,
      },
      label: "Reimburse Tenants",
      description: `Do you want to reimburse the tenants for the now landlord utilties for the period from start of the lease to the effective date?`,
      validationSchema: validateObject({
        reimburse_tenant_answer: validateBoolean().required("An answer is required to continue"),
      }),
      disabled: (values) => {
        const new_owner_utilities = [];
        const utilities = [
          "gas_utilities",
          "water_utilities",
          "electric_utilities",
          "garbage_sewage_utilities",
        ];
        for (const utility of utilities) {
          if (
            (rentalAgreement?.property as any)?.[utility] ===
              UtilityAssignment.Renter &&
            values?.utilities?.[utility] === UtilityAssignment.Owner
          ) {
            new_owner_utilities.push(
              utility.substring(0, utility.indexOf("_utilities"))
            );
          }
        }
        return (
          !values.options.choice ||
          values.options.choice !== LeaseAddendumType.TransferAssignments ||
          new_owner_utilities.length === 0
        );
      },
      steps: (values) => {
        return [
          {
            name: "reimburse_tenant_answer",
            label: "Answer",
            placeholder: "Answer",
            required: true,
            type: "yes-no",
          },
        ];
      },
      submit: ({ values, done }) => {
        const additional_clauses = [];
        if (values.utilities_transfer_responsibility.transfer_responsibility_answer) {
          additional_clauses.push("utility_company");
          additional_clauses.push("utility_contact");
        } 
        if (values.utilities_reimburse_tenant.reimburse_tenant_answer) {
          additional_clauses.push(
            'utility_reimburse_tenant'
          );
        }
        startLeasingAddendumFlow(
          values?.options?.choice,
          {
            ...values.utilities,
            additional_specifics: additional_clauses,
            ...values.utilities_transfer_responsibility,
            ...values.utilities_reimburse_tenant
          },
          done
        );
      },
    },
    new_fees_choice: {
      initial: {
        new_fees_choice: [],
      },
      label: "Change Fees",
      description: "What will be changed about the property?",

      validationSchema: validateObject({
        new_fees_choice: validateArray().typeError("Select an option").min(1, "Select an option").required("Select an option"),
      }),
      disabled: (values) => {
        return (
          !values.options.choice ||
          values.options.choice !== LeaseAddendumType.FeesChange
        );
      },
      steps: (values) => {
        const options = [
          {
            label: "Late Fees",
            value: "Late Fees",
          },
        ];
        if ((rentalAgreement?.property?.parking_count ?? 0) > 0) {
          options.push({
            label: "Parking Fees",
            value: "Parking Fees",
          });
        }
        if (pets.length > 0) {
          options.push({
            label: "Pet Fees",
            value: "Pet Fees",
          });
        }

        return [
          {
            name: "new_fees_choice",
            label: "New Fees Choice (Select all that applies)",
            placeholder: "New Fees Choice (Select all that applies)",
            required: true,
            exclusive: false,
            type: "choice",
            options: options,
          },
        ];
      },
      submit: ({ values, done }) => {
        done();
      },
    },
    late_fees: {
      disabled: (values) => {
        return (
          !values.options.choice ||
          values.options.choice !== LeaseAddendumType.FeesChange ||
          !values.new_fees_choice?.new_fees_choice?.includes("Late Fees")
        );
      },
      label: "Late Fees",
      description: "Fill out how you would like late fees to be charged",
      ...lateFeesStep(initialLateFee, usd(rentalAgreement?.base_rent_amount)),
      submit: ({ values, done }) => {
        done();
      },
    },
    new_fees_parking_fees: {
      label: "Parking Pricing",
      description: "Monthly Parking Fee (per spot)",
      initial: {
        new_parking_fee: rentalAgreement?.property?.parking_fee,
      },
      validationSchema: validateObject({
        new_parking_fee: validateNumber().required("A new parking fee is required"),
      }),
      disabled: (values) => {
        return (
          !values.options.choice ||
          values.options.choice !== LeaseAddendumType.FeesChange ||
          !values.new_fees_choice?.new_fees_choice?.includes("Parking Fees")
        );
      },
      steps: (values) => [
        {
          name: "new_parking_fee",
          label: "Parking Fee",
          placeholder: "Fee",
          required: true,
          type: "usd",
        },
      ],
      submit: ({ values, done }) => {
        done()
      },
    },
    new_fees_pet_fees: {
      label: "Pet Fees",
      description: "Pet Deposit and Fees (per pet)",
      disabled: (values) => {
        return (
          !values.options.choice ||
          values.options.choice !== LeaseAddendumType.FeesChange ||
          !values.new_fees_choice?.new_fees_choice?.includes("Pet Fees")
        );
      },
      initial: {
        new_cat_deposit: rentalAgreement?.property?.cat_deposit,
        new_cat_fee: rentalAgreement?.property?.cat_fee,
        new_dog_deposit: rentalAgreement?.property?.dog_deposit,
        new_dog_fee: rentalAgreement?.property?.dog_fee,
      },
      validationSchema: validateObject({
        new_cat_deposit: validateNumber().test("checkCatDeposit", "A cat deposit is required", (value) => {
          return (!rentalAgreement?.property?.allow_cats || !!value || value === 0)
        }),
        new_cat_fee: validateNumber().test("checkCatFee", "A cat fee is required", (value) => {
          return (!rentalAgreement?.property?.allow_cats || !!value || value === 0)
        }),
        new_dog_deposit: validateNumber().test("checkDogDeposit", "A dog deposit is required", (value) => {
          return (!rentalAgreement?.property?.allow_dogs || !!value || value === 0)
        }),
        new_dog_fee: validateNumber().test("checkDogFee", "A dog fee is required", (value) => {
          return (!rentalAgreement?.property?.allow_dogs || !!value || value === 0)
        }),
      }),
      steps: (values) => {
        const finalSteps: any[] = [];
        if (rentalAgreement?.property?.allow_cats) {
          finalSteps.push({
            name: "new_cat_deposit",
            label: "Cat Deposit",
            placeholder: "Cat Deposit",
            required: true,
            type: "usd",
          });
          finalSteps.push({
            name: "new_cat_fee",
            label: "Cat Fee",
            placeholder: "Cat Fee",
            required: true,
            type: "usd",
          });
        }
        if (rentalAgreement?.property?.allow_dogs) {
          finalSteps.push({
            name: "new_dog_deposit",
            label: "Dog Deposit",
            placeholder: "Dog Deposit",
            required: true,
            type: "usd",
          });
          finalSteps.push({
            name: "new_dog_fee",
            label: "Dog Fee",
            placeholder: "Dog Fee",
            required: true,
            type: "usd",
          });
        }
        return finalSteps;
      },
      submit: ({ values, done }) => {
        done()
      },
    },
    new_fees_effective_date: {
      label: "Effective Date",
      description: "When will the changes be effective?",
      disabled: (values) => {
        return (
          !values.options.choice ||
          values.options.choice !== LeaseAddendumType.FeesChange
        );
      },
      initial: {
        effective_date: undefined
      },
      validationSchema: validateObject({
        effective_date: validateNumber()
          .typeError("Effective date is required")
          .min(
            startOfToday.minus({ day: 1 }).toMillis(),
            "Change must begin no earlier than today"
          )
          .required("Effective date is required"),
      }),
      steps: (values) => {
        return [{
          name: "effective_date",
          label: "Effective Date",
          placeholder: "Effective Date",
          required: true,
          type: "date",
          minDate: startOfToday.toMillis(),
        },];
      },
      submit: ({ values, done }) => {
        const convertedLateFee = values.new_fees_choice?.new_fees_choice?.includes("Late Fees") ? convertLateFees({
          choice_conditional: {
            choice: values.late_fees.choice,
            one_time_collection: {
              type_conditional: {
                type: values.late_fees.one_time_type,
                value: values.late_fees.one_time_value,
              },
            },
            recurring_collection: {
              initial_fee: values.late_fees.recurring_initial_fee,
              recurring_fee: values.late_fees.recurring_recurring_fee,
            },
          },
          days: values.late_fees.days,
        }) : undefined;
        startLeasingAddendumFlow(
          values?.options?.choice,
          {
            new_late_fees: convertedLateFee,
            new_parking_fee: values.new_fees_choice.new_fees_choice.includes(
              "Parking Fees"
            )
              ? cents(values.new_fees_parking_fees.new_parking_fee)
              : undefined,
            ...(values.new_fees_choice?.new_fees_choice?.includes("Pet Fees") ? {
              new_cat_deposit: cents(values.new_fees_pet_fees.new_cat_deposit),
              new_cat_fee: cents(values.new_fees_pet_fees.new_cat_fee),
              new_dog_deposit: cents(values.new_fees_pet_fees.new_dog_deposit),
              new_dog_fee: cents(values.new_fees_pet_fees.new_dog_fee),
            } : {}),
            ...values.new_fees_effective_date
          },
          done
        );
      },
    },
    property_changes_location: {
      initial: {
        property_change_location:
          leaseAddendumInfo?.change_fields?.property_change_location,
      },
      label: "Change Location",
      description: "Where are the changes you'd like to request?",
      validationSchema: validateObject({
        property_change_location: validateString()
          .typeError("Location is required to continue")
          .oneOf(["inside", "outside"])
          .required("Location is required to continue"),
      }),
      disabled: (values) => {
        return (
          !values.options.choice ||
          values.options.choice !== LeaseAddendumType.PropertyChanges
        );
      },
      steps: (values) => [
        {
          name: "property_change_location",
          label: "Location",
          placeholder: "Location",
          required: true,
          exclusive: true,
          type: "choice",
          options: [
            {
              value: "inside",
              label: "Inside",
              caption: "Anything that is within the walls & doors of the home.",
            },
            {
              value: "outside",
              label: "Outside",
              caption:
                "Anything that is outside of the walls & doors of the home.",
            },
          ],
        },
      ],
      submit: ({ values, done }) => {
        done()
      },
    },
    property_changes_type: {
      initial: {
        property_change_type:
          leaseAddendumInfo?.change_fields?.property_change_type,
      },
      label: "Change Type",
      description: "What type of changes would you like to request?",
      validationSchema: validateObject({
        property_change_type: validateString()
          .typeError("Type of change is required to continue")
          .oneOf(['addition', 'removal', 'repair'])
          .required("Type of change is required to continue"),
      }),
      disabled: (values) => {
        return (
          !values.options.choice ||
          values.options.choice !== LeaseAddendumType.PropertyChanges
        );
      },
      steps: (values) => [
        {
          name: "property_change_type",
          label: "Type",
          placeholder: "Type",
          required: true,
          type: "choice",
          exclusive: true,
          options: [
            {
              value: "addition",
              label: "Addition",
              caption: "I'd like to add something to the home.",
            },
            {
              value: "removal",
              label: "Removal",
              caption:
                "I'd like to remove and/or throw away some component of the home.",
            },
            {
              value: "repair",
              label: "Repair or Modify",
              caption:
                "I'd like to repair or modify some component of the home.",
            },
          ],
        },
      ],
      submit: ({ values, done }) => {
        done()
      },
    },
    property_changes_description: {
      initial: {
        property_change_description:
          leaseAddendumInfo?.change_fields?.property_change_description,
      },
      label: "Description",
      description: "Please provide a description representing the changes you'd like to make:",
      validationSchema: validateObject({
        property_change_description: validateString().required("Description is required"),
      }),
      disabled: (values) => {
        return (
          !values.options.choice ||
          values.options.choice !== LeaseAddendumType.PropertyChanges
        );
      },
      steps: (values) => [
        {
          name: "property_change_description",
          label: "Description",
          placeholder: "Description",
          required: true,
          type: "paragraph",
        },
      ],
      submit: ({ values, done }) => {
        startLeasingAddendumFlow(
          values?.options?.choice,
          {
            property_change_location: values?.property_changes_location?.property_change_location,
            property_change_type: values?.property_changes_type?.property_change_type,
            property_change_description: values?.property_changes_description?.property_change_description,
            additional_specifics: ["property_changes"]
          },
          done
        );
      },
    },
  });

  return pages;
};

export const useOwnerAddendumReviewForm = (
  leaseAddendumInfo: LeaseAddendumInfo,
  flowActivityId: number,
  onFinish: (declined: boolean) => void,
  goToUrl: (url: string) => void
) => {
  const apiDispatch = useApiDispatch();

  const acceptLeasingAddendumFlow = (
    change_fields: LeaseAddendumChanges,
    done: () => void
  ) => {
    apiDispatch(async (call, api) => {
      const res = await call(api.leasing_addendum.updateInfo, {
        id: leaseAddendumInfo.id,
        change_fields,
      });

      if (!res) {
        return [false, "Something went wrong"];
      }

      // complete flow
      call(api.flows.complete, {
        id: flowActivityId,
      });

      onFinish(false);
      done();
      return [true];
    });
  };

  const declineLeasingAddendumFlow = (done: () => void) => {
    apiDispatch(async (call, api) => {
      const res = await call(api.flows.complete, {
        id: flowActivityId,
        metadata: {
          declined: true,
        },
      });
      if (!res) {
        return [false, "Failed to complete action, please try again"];
      }

      onFinish(true);
      done();
      return [true];
    });
  };

  const pages = useMultiPageForm({
    accept_addendum: {
      initial: {
        choice: true,
      },
      label: "Accept",
      description: "Do you accept this addendum request?",
      validationSchema: validateObject({
        choice: validateBoolean()
          .typeError("You need to select an option to continue")
          .required("You need to select an option to continue"),
      }),
      steps: (values) => {
        const options = [
          {
            value: true,
            label: "I accept",
            caption:
              "You will be asked to fill out a few more steps required for the document signing.",
          },
        ];

        if (
          leaseAddendumInfo.addendum_type !== LeaseAddendumType.EarlyTermination
        ) {
          options.push({
            value: false,
            label: "I decline",
            caption:
              "This will close the request and you will not be able to undo this action.",
          });
        }
        return [
          {
            name: "choice",
            label: "Review",
            placeholder: "Review",
            required: true,
            type: "choice",
            exclusive: true,
            options: options,
          },
        ];
      },
      submit: ({ values, done }) => {
        console.log("values", values);
        if (leaseAddendumInfo.addendum_type !==
          LeaseAddendumType.EarlyTermination && !values.accept_addendum.choice) {
          declineLeasingAddendumFlow(done);
        } else if (
          leaseAddendumInfo.addendum_type !==
            LeaseAddendumType.EarlyTermination &&
          leaseAddendumInfo?.addendum_type !== LeaseAddendumType.AddPet
        ) {
          acceptLeasingAddendumFlow({}, done);
        } else {
          done();
        }
      },
    },
    early_termination_penalty: {
      initial: {
        early_termination_penalty_choice: undefined,
        early_termination_penalty: undefined,
      },
      label: "Termination Penalty",
      description:
        "Will there be a penalty charged for this early termination?",

      validationSchema: validateObject({
        early_termination_penalty_choice: validateBoolean().typeError("Please select an option").required("Please select an option"),
        early_termination_penalty: validateNumber(),
      }),
      disabled: (values) => {
        return (
          leaseAddendumInfo?.addendum_type !==
            LeaseAddendumType.EarlyTermination || !values.accept_addendum.choice
        );
      },
      steps: (values) => {
        const finalSteps: any[] = [
          {
            name: "early_termination_penalty_choice",
            label: "Penalty Choice",
            placeholder: "Penalty Choice",
            required: true,
            type: "yes-no",
          },
        ];
        if (
          values?.early_termination_penalty?.early_termination_penalty_choice
        ) {
          finalSteps.push({
            name: "early_termination_penalty",
            label: "Termination Penalty",
            placeholder: "Termination Penalty",
            required: true,
            type: "usd",
          });
        }
        return finalSteps;
      },
      submit: ({ values, done }) => {
        apiDispatch(async (call, api) => {
          const res = await call(api.leasing_addendum.updateInfo, {
            id: leaseAddendumInfo.id,
            change_fields: {
              termination_penalty: values.early_termination_penalty.early_termination_penalty ? cents(values.early_termination_penalty.early_termination_penalty) : undefined,
            },
          });

          if (res) {
            done();
            return [true];
          }

          return [false, "Something went wrong"];
        });
      },
    },
    early_termination_time_limit: {
      initial: {
        answer: true,
      },
      label: "Payment Deadline",
      description:
        "Do you want the termination penalty to be paid prior within 48 hours of signing the agreement?",
      validationSchema: validateObject({
        answer: validateBoolean().typeError("Please select an option").required("Please select an option"),
      }),
      disabled: (values) => {
        return (
          leaseAddendumInfo?.addendum_type !==
            LeaseAddendumType.EarlyTermination ||
          !values?.early_termination_penalty
            ?.early_termination_penalty_choice ||
          !values.accept_addendum.choice
        );
      },
      steps: (values) => {
        return [
          {
            name: "answer",
            label: "Answer",
            placeholder: "Answer",
            required: true,
            type: "yes-no",
          },
        ];
      },
      submit: ({ values, done }) => {
        done();
      },
    },
    early_termination_payment_plan: {
      initial: {
        choice: "",
      },
      label: "Additional Specifics",
      description:
        "Will the tenant be required to continue paying the monthly rent for a period of time?",

      validationSchema: validateObject({
        choice: validateString().typeError("Please select an option").required("Please select an option")
      }),
      disabled: (values) => {
        return (
          leaseAddendumInfo?.addendum_type !==
            LeaseAddendumType.EarlyTermination ||
          !values.accept_addendum.choice
        );
      },
      steps: (values) => {
        const options = [
          {
            value: `early_termination_penalty_till_date`,
            label: `Required to pay the monthly rent until the effective termination date.`,
          },
          {
            value: `early_termination_penalty_till_tenant`,
            label: `Required to pay the monthly rent until a new tenant is found.`,
          },
          {
            value: `early_termination_penalty_till_end`,
            label: `Required to pay the remaining balance up until the end of the existing agreement`,
          },
        ];

        return [
          {
            name: "choice",
            label: "Specifics",
            placeholder: "Specifics",
            required: true,
            type: "choice",
            exclusive: true,
            options: options,
          },
        ];
      },
      submit: ({ values, done }) => {
        const additional_clauses = [];
        if (
          values.early_termination_time_limit.answer &&
          values?.early_termination_penalty?.early_termination_penalty_choice
        ) {
          additional_clauses.push("early_termination_penalty_due");
        }
        additional_clauses.push(values.early_termination_payment_plan.choice);

        acceptLeasingAddendumFlow(
          {
            additional_specifics: additional_clauses,
          },
          done
        );
      },
    },
    add_pet_one_time_fee: {
      initial: {
        answer: true,
        pet_refundable_fee: undefined,
      },
      label: "One-Time Fee",
      description:
        "Will the tenant(s) be required to pay a one time fee for the addition of a pet?",
      validationSchema: validateObject({
        answer: validateBoolean().typeError("Please select an option").required("Please select an option"),
        pet_refundable_fee: validateBoolean().when("answer", {
          is: true,
          then: validateBoolean().typeError("Please select an option").required("Please select an option"),
        }),
      }),
      disabled: (values) => {
        return (
          leaseAddendumInfo?.addendum_type !== LeaseAddendumType.AddPet ||
          !values.accept_addendum.choice
        );
      },
      steps: (values) => {
        const finalSteps: any[] = [
          {
            name: "answer",
            label: "Answer",
            placeholder: "Answer",
            required: true,
            type: "yes-no",
          },
        ];
        if (values?.add_pet_one_time_fee?.answer) {
          finalSteps.push({
            name: "pet_refundable_fee",
            label: "Is the one-time pet fee refundable?",
            placeholder: "Is the one-time pet fee refundable?",
            type: "yes-no",
          });
        }
        return finalSteps;
      },
      submit: ({ values, done }) => {
        done();
      },
    },
    add_pet_recurring_fee: {
      initial: {
        answer: true,
      },
      label: "Recurring Fee",
      description:
        "Will the tenant(s) be required to pay a recurring fee for the addition of a pet until the end of the agreement?",
      validationSchema: validateObject({
        answer: validateBoolean().typeError("Please select an option").required("Please select an option"),
      }),
      disabled: (values) => {
        return (
          leaseAddendumInfo?.addendum_type !== LeaseAddendumType.AddPet ||
          !values.accept_addendum.choice
        );
      },
      steps: (values) => {
        const finalSteps: any[] = [
          {
            name: "answer",
            label: "Answer",
            placeholder: "Answer",
            required: true,
            type: "yes-no",
          },
        ];
        return finalSteps;
      },
      submit: ({ values, done }) => {
        const additional_clauses = [];
        if (values?.add_pet_one_time_fee?.answer) {
          additional_clauses.push("add_pet_one_time");
        }
        if (values?.add_pet_recurring_fee?.answer) {
          additional_clauses.push("add_pet_recurring");
        }
        acceptLeasingAddendumFlow(
          {
            additional_specifics: additional_clauses,
            pet_refundable_fee: values.add_pet_one_time_fee.pet_refundable_fee ?? undefined
          },
          done
        );
      },
    },
  });

  return pages;
};
