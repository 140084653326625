export interface DocumentFiles {
  id?: number;
  key?: string;
  name?: string;
  file_type?: DocumentFileType;
  user_id?: number;
}

export enum DocumentFileType {
  Lease = 0,
  RenterInsurance = 1,
  Walkthrough = 2
}

export interface LeaseDocumentRequest {
  docType?: string;
  leaseDocuments?: StateLeaseDocument[];
}

export interface StateLeaseDocument {
  name?: string;
  s3key?: string;
  url?: string;
}
