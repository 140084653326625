import { Typography, TypographyProps } from '@mui/material';
import React from 'react';
import { CssStyleProps } from 'src/base/props/CssStyleProps';
import { TextProps as OldTextProps } from 'src/base/texts/props';
import { getColor, TextProps } from 'ui-sdk/src/theme';

export const Text: React.FC<OldTextProps & CssStyleProps> = ({
  align,
  children,
  className,
  style,
  variant,
}) => (
  <Typography
    align={align}
    variant={variant}
    gutterBottom
    style={style}
    className={className}
  >
    {children}
  </Typography>
);

export const MarbleText: React.FC<TextProps & TypographyProps> = ({
  children,
  size = 'base',
  weight = 'regular',
  color = 'black',
  ...otherProps
}) => {
  const sizeMap = {
    h1: {
      fontSize: 50,
      lineHeight: '130%',
    },
    h2: {
      fontSize: 35,
      lineHeight: '140%',
    },
    xl: {
      fontSize: 25,
      lineHeight: '140%',
    },
    l: {
      fontSize: 20,
      lineHeight: '140%',
    },
    base: {
      fontSize: 17.5,
      lineHeight: '150%',
    },
    s: {
      fontSize: 15,
      lineHeight: '150%',
    },
    xs: {
      fontSize: 12.5,
      lineHeight: '150%',
    },
    xxs: {
      fontSize: 10,
      lineHeight: '150%',
    },
  };

  const weightMap = {
    bold: 'bold',
    med: 'medium',
    regular: 'regular',
  };

  return (
    <Typography
      {...{
        ...otherProps,
        gutterBottom: false,
        fontWeight: weightMap[weight],
        fontSize: sizeMap[size].fontSize,
        lineHeight: sizeMap[size].lineHeight,
        color: getColor(color),
      }}
    >
      {children}
    </Typography>
  );
};
