import { Property, User, OwnerBankInfo, ReferralInfo } from 'server-sdk/src/types';
import { call } from '../call';
import { api } from 'server-sdk/src/api';
import { WebThunk } from '../store';

export const UPDATE_USER = 'identity/update_user';
export const UPDATE_TOKEN = 'identity/update_token';
export const UPDATE_REFERRAL_INFO = 'identity/update_referral_info';
export const UPDATE_OWNER_BANK_INFO = 'identity/update_owner_bank_info';

interface UpdateUserAction {
  type: typeof UPDATE_USER;
  user: User;
}

interface UpdateTokenAction {
  type: typeof UPDATE_TOKEN;
  token: string;
}

interface UpdateReferralInfoAction {
  type: typeof UPDATE_REFERRAL_INFO;
  referralInfo: ReferralInfo;
}

interface UpdateOwnerBankInfoAction {
  type: typeof UPDATE_OWNER_BANK_INFO;
  ownerBankInfo: OwnerBankInfo;
}

export type IdentityAction =
  | UpdateUserAction
  | UpdateTokenAction
  | UpdateReferralInfoAction
  | UpdateOwnerBankInfoAction;

export const updateUser = (user: User): UpdateUserAction => ({
  type: UPDATE_USER,
  user,
});

export const updateToken = (token: string): UpdateTokenAction => ({
  type: UPDATE_TOKEN,
  token,
});

export const updateOwnerBankInfo = (
  ownerBankInfo: OwnerBankInfo,
): UpdateOwnerBankInfoAction => ({
  type: UPDATE_OWNER_BANK_INFO,
  ownerBankInfo,
});

export const updateReferralInfo = (
  referralInfo: ReferralInfo,
): UpdateReferralInfoAction => ({
  type: UPDATE_REFERRAL_INFO,
  referralInfo,
});

interface IdentityState {
  user?: User;
  token?: string;
  properties: Property[];
  referralInfo?: ReferralInfo;
  ownerBankInfo?: OwnerBankInfo;
}

const initialState: IdentityState = {
  properties: [],
};

const reducer = (
  state = initialState,
  action: IdentityAction,
): IdentityState => {
  switch (action.type) {
    case UPDATE_USER:
      return {
        ...state,
        user: action.user,
      };
    case UPDATE_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case UPDATE_REFERRAL_INFO:
      return {
        ...state,
        referralInfo: action.referralInfo,
      };
    case UPDATE_OWNER_BANK_INFO:
      return {
        ...state,
        ownerBankInfo: action.ownerBankInfo,
      };
    default:
      return state;
  }
};

export const refreshReferralInfo = (): WebThunk => async (dispatch) => {
  const referralInfo = await call(api.referrals.info);
  console.log(referralInfo);
  dispatch(updateReferralInfo(referralInfo));
};

export { reducer };

export type { IdentityState };
