import marbleApi, { call } from '../call';
import { api } from 'server-sdk/src/api';
import { User, UserType } from 'server-sdk/src/types';
import { alias, identify, track, sendConversion } from '../util/track';
import { updateSnack } from '../ducks/global';
import { updateOwnerBankInfo, updateToken, updateUser } from '../ducks/identity';
import { updateProperties, updateTours } from '../ducks/portfolio';
import { WebThunk } from '../store';

export const signUp =
  (newUser: User): WebThunk =>
  async (dispatch) => {
    if (newUser.type === UserType.Owner) {
      console.log('logging conversion');
      sendConversion();
    }
    const checkUser = await call(api.users.auth.validate, {
      phone: newUser.phone,
    });
    let user = newUser;
    if (!checkUser.exists) {
      user = await call(api.users.v2.create, newUser);
    }
    if (!user) {
      const errorMessage = 'Unable to create user.';
      dispatch(updateSnack('error', errorMessage));
      return false;
    }
    dispatch(sendOtp(user.email, user.phone));
    track('Create Account');
    alias(user.id);
    return true;
  };

export const sendOtp =
  (email?: string, phone?: string): WebThunk =>
  async () => {
    await call(api.users.auth.otp, {
      phone,
      email,
      isWebApp: true,
    });
  };

export const resendConfirmationOtp =
  (userId: number, email?: string, phone?: string): WebThunk =>
  async () => {
    await call(api.users.auth.confirmationOtp, {
      phone,
      email,
      currentUser: {
        id: userId,
      },
      isWebApp: true,
    });
  };

export const changeEmail =
  (email: string, userId: number, otp: string): WebThunk =>
  async (dispatch) => {
    try {
      const changeIdentity = await call(api.users.auth.finishChangeIdentity, {
        email,
        currentUser: {
          id: userId,
          password: otp,
        },
      });
      const user = changeIdentity.currentUser;
      if (user) {
        console.log(user);
        dispatch(updateUser(user));
        identify(user.id);
      }
    } catch (e) {
      console.error(e);
    }
  };

export const changePhoneNumber =
  (phone: string, userId: number, otp: string): WebThunk =>
  async (dispatch) => {
    try {
      const changeIdentity = await call(api.users.auth.finishChangeIdentity, {
        phone,
        currentUser: {
          id: userId,
          password: otp,
        },
      });
      const user = changeIdentity.currentUser;
      if (user) {
        console.log(user);
        dispatch(updateUser(user));
        identify(user.id);
      }
    } catch (e) {
      console.error(e);
    }
  };

export const getOwnerBankInfo = (): WebThunk => async (dispatch) => {
  try {
    const bankInfo = await call(api.payment.ownerBankInfo);
    if (bankInfo) {
      dispatch(updateOwnerBankInfo(bankInfo));
    }
  } catch (e) {
    console.error(e);
  }
};

export const login =
  (email: string, phone: string, otp: string): WebThunk =>
  async (dispatch) => {
    const user = await call(api.users.auth.login, {
      email,
      phone,
      password: otp,
    });
    if (user) {
      console.log(user);
      dispatch(updateUser(user));
      dispatch(updateToken(user.authToken));
      track('Login');
      identify(user.id);
    }
  };

export const logout = (): WebThunk => async (dispatch) => {
  await marbleApi.post('/users/logout');
  dispatch(updateUser(undefined));
  dispatch(updateToken(undefined));
  dispatch(updateProperties([]));
  dispatch(updateTours([]));
  console.log('loggingout');
};
