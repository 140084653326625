import { useCallback, useEffect, useMemo, useState } from "react";
import { ToolTipType } from "server-sdk/src/types";
import { useApiStore } from "./global";

export const useOnboardingTooltip = (
  type: ToolTipType,
) => {
  const [me, fetchMe] = useApiStore(api => api.users.v2.me);
  const [dismissed, dismiss] = useApiStore(api => api.users.tooltip.dismiss);
  const [manual, setManual] = useState(false);

  const types: ToolTipType[] = ['start-workflow'];

  const open = useMemo(() => {
    const notDismissed = types.filter(t => !(me?.dismissed_tooltips && me.dismissed_tooltips[t]));
    if (!notDismissed?.length) {
      return false;
    }
    const first = notDismissed[0];
    console.log('first', first, type);
    return manual && first === type;
  }, [dismissed, me, manual]);

  useEffect(() => {
    if (!me) {
      fetchMe({});
    }
    if (!dismissed) {

    }
  }, [manual, dismissed]);

  useEffect(() => {
    if (me) {
      setManual(true);
    }
  }, [me]);

  const handleDismiss = useCallback(() => {
    setManual(false);
    dismiss({ tooltip: type });
  }, []);

  return {
    open,
    handleDismiss,
  };
}