import React from 'react';
import { Alert } from '@mui/material';
import { C } from 'src/base';

export enum AlertCardType {
  Info,
  Warning,
  Error,
}

interface AlertCardProps {
  title: string;
  alertType: AlertCardType;
}

/**
 * Component that visualizes an alert message to the user
 * @param title the title that the alert will use
 * @param alertType the AlertCardType that the alert will have
 * @returns
 */
export const AlertCard = ({ title, alertType }: AlertCardProps) => {
  const getVariant = () => {
    switch (alertType) {
      case AlertCardType.Warning:
        return 'warning';
      case AlertCardType.Error:
        return 'error';
      default:
        return 'info';
    }
  };

  return (
    <Alert
      severity={getVariant()}
      sx={{
        marginTop: '10px',
        marginBottom: '10px',
      }}
    >
      <C style={{ marginLeft: 5 }}>{title}</C>
    </Alert>
  );
};
