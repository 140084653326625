export interface FridayUno extends MakerMetadataBase {
  type: LockType.FridayUno
  id: string
  setupCode?: string
  syncTime?: number
  calibrationTime?: number
}

export interface FridayDos extends MakerMetadataBase {
  type: LockType.FridayDos

  // DEPRECATED
  id: string
}

export interface IglooKeybox3 extends MakerMetadataBase {
  type: LockType.IglooKeybox3
  key?: string;
  masterPin?: string;
  id: string
}

export interface ManualLock extends MakerMetadataBase {
  type: LockType.ManualLock
  masterCode: string
  guestCodeList: string[]
}

export interface YaleAssure2 extends MakerMetadataBase {
  type: LockType.YaleAssure2
  id: string
}

export type MakerMetadata =
  | FridayUno
  | FridayDos
  | ManualLock
  | IglooKeybox3
  | YaleAssure2;

export interface MakerMetadataBase {
  type: LockType
}

export enum LockType {
  FridayUno = 'friday/uno',
  FridayDos = 'friday/dos',
  IglooKeybox3 = 'igloo/keybox-3',
  IglooDeadbolt2E = 'igloo/deadbolt-2e',
  ManualLock = 'manual',
  YaleAssure2 = 'yale/assure-2',
}

export interface LockSettings {
  lockDirection: string;
}

export interface Lock {
  id?: number
  landlordId?: number
  propertyId?: number
  bluetoothId?: string
  manufacturerId?: string
  metadata?: MakerMetadata
  lockSettings?: LockSettings
  keys?: Keys;
  needsDirectionCalibration?: boolean;
}

export interface Keys {
  lockPublicKey?: number[] 
  clientPublicKey?: number[]
}

export interface MakerAuthToken {
  type?: LockType
  token?: string
}

export interface LockSetup {
  lock?: Lock
  messages?: LockMessage[]
  finished?: boolean
}

export interface LockMessage {
  message?: string
  responses?: string[]
}

export interface LockPin {
  lockId?: number
  pin?: string
}

export interface LockCalibration {
  lockId: number;
}

export interface DaylightSavings {
  year?: number;
  start_date?: number;
  end_date?: number;
  offset?: number;
}

export interface TimezoneRequest {
  timezoneId?: string;
}

export interface TimezoneResponse {
  gmtOffset?: number;
  ranges?: DaylightSavings[];
}

export interface PairingDataRequest {
  payload?: string;
  timezone?: string;
}

export interface PairingDataResponse {
  bluetoothAdminKey?: string;
  masterPin?: string;
}

export interface DeleteLockRequest {
  lockId: number;
  lockName: string;
}

export interface ActivityLogRequest {
  lockName: string;
  payload: string;
}

export interface ActivityLogResponse {
  logType: number;
  entryDate: number;
  keyId: number;
  operationId: number;
}

export interface IglooLockResponse {
  id?: string;
  type?: number;
  masterPin?: string;
  bluetoothAdminKey?: string;
}

export interface IglooLockExistRequest {
  id?: string;
  isSetup?: boolean;
  isSynced?: boolean;
}

export interface IglooSaveSettingsRequest {
  id?: string;
  lockSettings?: LockSettings
}