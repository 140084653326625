import { call } from '../call';
import { api } from 'server-sdk/src/api';
import { Property, PropertyPhoto } from 'server-sdk/src/types';
import { track } from '../util/track';
import { updateSnack } from '../ducks/global';
import {
  getMyProperties,
  refreshPortfolio,
  updateProperties,
} from '../ducks/portfolio';
import { WebThunk } from '../store';

export const createProperty =
  (newProperty: Property & {flowActivity?: number}): WebThunk =>
  async (dispatch) => {
    const property = await call(api.property.create, newProperty);
    if (!property) {
      const errorMessage =
        'Unable to create property. This property has already been created';
      dispatch(updateSnack('error', errorMessage));
      console.log(errorMessage);
    } else {
      await call(api.property.update, {
        ...newProperty,
        id: property.id,
      });
      dispatch(getMyProperties());
      track('Add Property');
      updateSnack('success', 'Property has been created successfully');
    }
  };

export const updateProperty =
  (property: Property): WebThunk =>
  async (dispatch) => {
    await call(api.property.update, property);
    dispatch(refreshPortfolio());
  };

export const updatePropertyPhotosTyped =
  (propertyId: number, photos: PropertyPhoto[]): WebThunk =>
  async (dispatch, getState) => {
    const { properties } = getState().portfolio;
    const photosSortKeyed = photos.map((p, idx) => ({
      id: p?.id,
      src: p?.src,
      sortKey: idx,
    }));
    const newProps = properties.map((p) => {
      if (p.id === propertyId) {
        return {
          ...p,
          photosTyped: photosSortKeyed,
        };
      }
      return p;
    });
    dispatch(updateProperties(newProps));
    await call(api.property.updatePhotoOrder, {
      id: propertyId,
      photosTyped: photosSortKeyed,
    });
  };

export const savePropertyUpdate = (property: Property): WebThunk => {
  return async (dispatch) => {
    try {
      const update = await call(api.property.update, property);
      console.log(update);
    } catch (e) {
      console.error(e);
      // dispatch(
      //   updateError('Something went wrong while updating your property'),
      // );
    }
  };
};
