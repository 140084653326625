import { User } from "./user";
import { Address } from "./property";

export interface Reservation {
  id?: number;
  userId?: number;
  leadId?: number;
  propertyId?: number;
  userReservationId?: number;
  address?: Address
  startTime?: number;
  endTime?: number;
  available?: boolean;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  reservationError?: string;
}

export interface ShipmentReservation extends Reservation {
  shipmentId: number;
}

export interface PropertyUserReservation {
  id?: number;
  userName?: string;
  startTime?: number;
  propertyId?: number
  userId?: number;
  leadId?: number;
  contactVerified?: boolean;
  idVerified?: boolean;
  lead?: TenantLead;
  user?: User;
  reservation?: {
      creation_time? : number;
      id?: number;
      property_id?: number;
      start_time: number;
      end_time?: number;
    };
}

export interface Schedule {
  date?: number;
  hours?: number[];
}

export interface TenantLead {
  id?: number;
  firstName?: string;
  lastName?: string;
  name?: string;
  email?: string;
  phone?: string;
  source?: string;
}

export enum TenantLeadSource {
  Twilio = 'Twilio',
  Zillow = 'Zillow',
  Rentlinx = 'Rentlinx',
  Lambda = 'Lambda',
  Touring = 'Touring'
}
