import { Link } from '@mui/material';
import React from 'react';
import { MarbleText } from 'src/base/texts/Text';
import { theme } from 'ui-sdk/src/theme';

export default () => (
  <footer
    style={{
      marginTop: 60,
      paddingTop: 40,
      paddingBottom: 40,
      backgroundColor: theme.colors.black,
    }}
  >
    <div className="marble-disclaimer">
      <MarbleText size="xs" color="white" weight="med">
        Marble Living, Inc. is a technology company that helps you manage your
        long-term rental properties. Communications between you and Marble are
        protected by our Privacy Policy. Marble also provides access to
        self-help services at your specific direction. We cannot make any
        decisions on behalf of any property owners. Your access to the website
        is subject to our{' '}
        <Link color={theme.colors.white} href="/legal/privacy.html">
          Privacy Policy
        </Link>{' '}
        and{' '}
        <Link color={theme.colors.white} href="/legal/tos.html">
          Terms and conditions
        </Link>
        .
      </MarbleText>
      <MarbleText
        size="xs"
        color="white"
        sx={{
          pt: 2,
        }}
      >
        © 2023 Marble Living, Inc.
      </MarbleText>
    </div>
  </footer>
);
