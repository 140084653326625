import {
  applyMiddleware,
  createStore as reduxCreateStore,
  Action,
} from 'redux';
import { combineReducers } from '@reduxjs/toolkit';
import discover from './ducks/discover';
import { inboxReducer } from './ducks/inbox';
import global from './ducks/global';
import thunk, { ThunkAction } from 'redux-thunk';
import application from './ducks/application';
import { portfolioReducer } from './ducks/portfolio';
import { reducer as onboarding } from './ducks/onboarding';
import { reducer as identity } from './ducks/identity';

const reducer = combineReducers({
  identity,
  onboarding,
  portfolio: portfolioReducer,
  discover,
  inbox: inboxReducer,
  global,
  application,
});

type RootState = ReturnType<typeof reducer>;

const createStore = (store) =>
  reduxCreateStore(reducer, store, applyMiddleware(thunk));

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (e) {
    // Ignore write errors;
  }
};

const persistedState = loadState();

const store = createStore(
  persistedState,
  // Others reducers...
);
store.subscribe(() => {
  const state = store.getState();
  saveState(state);
});

export type WebThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
export type { RootState };
