import { useReduxState } from '.';

export const useTour = (propertyId: number) => {
  const portfolio = useReduxState((state) => state.portfolio);
  const tours = portfolio.tours
    ?.filter((t) => t.propertyId === propertyId)
    .sort((a, b) => b.startTime - a.startTime);

  return {
    tours,
  };
};
