import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import { usd } from 'server-sdk/src/util/currency';
import { MarbleText } from '../texts/Text';

interface CostBreakdownProps {
  breakdown: {
    item: string;
    price: number;
  }[];
  total: number;
}

export const CostBreakdown = ({ breakdown, total }: CostBreakdownProps) => (
  <TableContainer
    sx={{
      pb: 0,
      mb: 0,
    }}
  >
    <Table
      sx={{
        pb: 0,
        mb: 0,
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell>
            <MarbleText>Item</MarbleText>
          </TableCell>
          <TableCell align="right">
            <MarbleText>Price</MarbleText>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {breakdown?.map((r) => (
          <TableRow key={r.item}>
            <TableCell>
              <MarbleText>{r.item}</MarbleText>
            </TableCell>
            <TableCell align="right">
              <MarbleText>{usd(r.price / 100)}</MarbleText>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell>
            <MarbleText weight="bold">Total</MarbleText>
          </TableCell>
          <TableCell align="right">
            <MarbleText weight="bold">{usd(total / 100)}</MarbleText>
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  </TableContainer>
);
