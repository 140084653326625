import { call } from '../call';
import { api } from 'server-sdk/src/api';
import { WebThunk } from '../store';
import {
  Application,
  Demo,
  Lock,
  Property,
  RentPayment,
  MonthlyCashTotal,
  Shipment,
  Tour,
} from 'server-sdk/src/types';
import { refreshReferralInfo } from './identity';

enum PortfolioActionTypes {
  UpdateProperties = 'portfolio/update_properties',
  UpdateRentPayments = 'portfolio/update_rent_payments',
  UpdateCashFlow = 'portfolio/update_cash_flow',
  UpdateTours = 'portfolio/update_tours',
  UpdateDemo = 'portfolio/update_demo',
  UpdateLocks = 'portfolio/update_locks',
  UpdateShipments = 'portfolio/update_shipments',
  UpdateApplications = 'portfolio/update_applications',
  UpdatePropertyPhotos = 'portfolio/update_property_photos',
  UpdatePortfolioLoading = 'portfolio/update_portfolio_loading',
}

interface PortfolioState {
  demo?: Demo;
  properties: Property[];
  tours: Tour[];
  payments: RentPayment[];
  cash_flow: MonthlyCashTotal[];
  locks: Lock[];
  shipments: Shipment[];
  applications: Application[];
  isLoading: boolean;
}

export const updateDemo = (
  demo: Demo,
): {
  type: typeof PortfolioActionTypes.UpdateDemo;
  demo: Demo;
} => ({
  type: PortfolioActionTypes.UpdateDemo,
  demo,
});

export const updateProperties = (
  properties: Property[],
): {
  type: typeof PortfolioActionTypes.UpdateProperties;
  properties: Property[];
} => ({
  type: PortfolioActionTypes.UpdateProperties,
  properties,
});

export const updateTours = (
  tours: Tour[],
): {
  type: typeof PortfolioActionTypes.UpdateTours;
  tours: Tour[];
} => ({
  type: PortfolioActionTypes.UpdateTours,
  tours,
});

export const updateRentPayments = (
  payments: RentPayment[],
): {
  type: typeof PortfolioActionTypes.UpdateRentPayments;
  payments: RentPayment[];
} => ({
  type: PortfolioActionTypes.UpdateRentPayments,
  payments,
});

export const updateCashFlow = (
  cashFlow: MonthlyCashTotal[],
): {
  type: typeof PortfolioActionTypes.UpdateCashFlow;
  cashFlow: MonthlyCashTotal[];
} => ({
  type: PortfolioActionTypes.UpdateCashFlow,
  cashFlow,
});

export const updateLocks = (
  locks: Lock[],
): {
  type: typeof PortfolioActionTypes.UpdateLocks;
  locks: Lock[];
} => ({
  type: PortfolioActionTypes.UpdateLocks,
  locks,
});

export const updateShipments = (
  shipments: Shipment[],
): {
  type: typeof PortfolioActionTypes.UpdateShipments;
  shipments: Shipment[];
} => ({
  type: PortfolioActionTypes.UpdateShipments,
  shipments,
});

export const updateApplications = (
  applications: Application[],
): {
  type: typeof PortfolioActionTypes.UpdateApplications;
  applications: Application[];
} => ({
  type: PortfolioActionTypes.UpdateApplications,
  applications,
});

const updateIsLoading = (isLoading: boolean): {
  type: typeof PortfolioActionTypes.UpdatePortfolioLoading;
  isLoading: boolean;
} => {
  return {
    type: PortfolioActionTypes.UpdatePortfolioLoading,
    isLoading,
  };
};

export const portfolioReducer = (
  state: PortfolioState = {
    properties: [],
    tours: [],
    payments: [],
    cash_flow: [],
    locks: [],
    shipments: [],
    applications: [],
    isLoading: false,
  },
  action:
    | ReturnType<typeof updateProperties>
    | ReturnType<typeof updateTours>
    | ReturnType<typeof updateDemo>
    | ReturnType<typeof updateRentPayments>
    | ReturnType<typeof updateCashFlow>
    | ReturnType<typeof updateLocks>
    | ReturnType<typeof updateShipments>
    | ReturnType<typeof updateApplications>
    | ReturnType<typeof updateIsLoading>,
): PortfolioState => {
  switch (action.type) {
    case PortfolioActionTypes.UpdateDemo:
      return {
        ...state,
        demo: action.demo,
      };
    case PortfolioActionTypes.UpdateTours:
      return {
        ...state,
        tours: action.tours,
      };
    case PortfolioActionTypes.UpdateProperties:
      return {
        ...state,
        properties: action.properties,
      };
    case PortfolioActionTypes.UpdateRentPayments:
      return {
        ...state,
        payments: action.payments,
      };
    case PortfolioActionTypes.UpdateCashFlow:
      return {
        ...state,
        cash_flow: action.cashFlow,
      };
    case PortfolioActionTypes.UpdateLocks:
      return {
        ...state,
        locks: action.locks,
      };
    case PortfolioActionTypes.UpdateShipments:
      return {
        ...state,
        shipments: action.shipments,
      };
    case PortfolioActionTypes.UpdateApplications:
      return {
        ...state,
        applications: action.applications,
      };
    case PortfolioActionTypes.UpdatePortfolioLoading:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
};

export const getDemo = (): WebThunk => async (dispatch) => {
  const demo = await call(api.property.demo, {});
  // dispatch(updateDemo(demo));
  dispatch(updateProperties(demo.properties));
  dispatch(updateTours(demo.tours));
  dispatch(updateRentPayments(demo.payments));
};

export const getMyProperties = (): WebThunk => async (dispatch) => {
  const properties = await call(api.property.list, []);
  console.log(properties);
  dispatch(updateProperties(properties));
};

export const getTours = (): WebThunk => async (dispatch) => {
  const tours = await call(api.tour.list.owner, []);
  if (tours) {
    dispatch(updateTours(tours));
  } else {
    dispatch(updateTours([]));
  }
};

export const refreshRentHistory = (): WebThunk => async (dispatch) => {
  const payments = await call(api.payment.rent.received, []);
  if (payments) {
    dispatch(updateRentPayments(payments));
  } else {
    dispatch(updateRentPayments([]));
  }
};

export const refreshCashFlow = (): WebThunk => async (dispatch) => {
  const cashFlow = await call(api.payment.cash_flow, []);
  if (cashFlow) {
    dispatch(updateCashFlow(cashFlow));
  } else {
    dispatch(updateCashFlow([]));
  }
};

export const refreshLocks = (): WebThunk => async (dispatch) => {
  const locks = await call(api.lock.list, []);
  if (locks) {
    dispatch(updateLocks(locks));
  } else {
    dispatch(updateLocks([]));
  }
};

export const refreshShipments = (): WebThunk => async (dispatch) => {
  const shipments = await call(api.shipment.list, []);
  if (shipments) {
    dispatch(updateShipments(shipments));
  } else {
    dispatch(updateShipments([]));
  }
};

export const refreshApplications = (): WebThunk => async (dispatch) => {
  const applications = await call(api.application.list.owner, [], {
    timeout: 30000,
  });
  dispatch(updateApplications(applications ?? []));
};

export const refreshPortfolio = (): WebThunk => async (dispatch, getState) => {
  const { token } = getState().identity;
  if (token) {
    dispatch(getMyProperties());
    dispatch(getTours());
    dispatch(refreshRentHistory());
    dispatch(refreshLocks());
    dispatch(refreshShipments());
    dispatch(refreshApplications());
    dispatch(refreshCashFlow());
    dispatch(refreshReferralInfo());
  } else {
    dispatch(getDemo());
  }
};

export const setLoading = (isLoading: boolean) => {
  return async (dispatch) => {
    dispatch(updateIsLoading(isLoading));
  };
};

export const setProperty = (property: Property) => {
  return async (dispatch) => {
    dispatch(updateProperties([property]));
  };
};
