import { Box, Dialog, DialogActions, Grid } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { Calendar, luxonLocalizer, Views } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css'; // import the CSS
import { MarbleButton } from 'src/base';
import { addTimeZoneOffsetForServer } from 'ui-sdk/src/util/date';
import { WarningText } from '.';
import { MarbleText } from '../texts/Text';
import { ErrorText } from './Form';

const CustomToolbar = (props) => {
  const goToBack = () => {
    props.date.setDate(props.date.getDate() - 7);
    props.onNavigate('prev');
  };

  const goToNext = () => {
    props.date.setDate(props.date.getDate() + 7);
    props.onNavigate('next');
  };

  const weekAgoDateTime = DateTime.fromJSDate(props.date).minus({ weeks: 1 });
  const isBackDisabled = props.minDate.startOf('week') > weekAgoDateTime;
  return (
    <Grid
      container
      justifyContent="space-around"
      alignItems="center"
      flexDirection="row"
      pl={0}
      mb={2}
    >
      <Grid item container xs={6} pl={0}>
        <Grid mr={1}>
          <MarbleButton
            size="small"
            title="Back"
            disabled={isBackDisabled}
            onClick={goToBack}
          />
        </Grid>
        <MarbleButton size="small" title="Next" onClick={goToNext} />
      </Grid>
      <Grid xs={6}>
        <MarbleText>{props.label}</MarbleText>
      </Grid>
    </Grid>
  );
};

interface DateSchedulerProps {
  value: any[];
  onChange: any;
  minDate?: any;
  maxDate?: any;
  error?: string;
}

export const DateTimeScheduler: React.FC<DateSchedulerProps> = ({
  value,
  onChange,
  minDate,
  error,
}) => {
  const [myEvents, setEvents] = useState([]);
  const [curIndex, setCurIndex] = useState(0);

  const [selectedEvent, setSelectedEvent] = useState(undefined);
  const [warning, setWarning] = useState(undefined);

  const [openModal, setOpenModal] = useState(undefined);
  const handleNew = ({ start, end }) => {
    setEvents((prev) => [
      ...prev,
      { id: `slot-${curIndex}`, start, end, title: `Slot ${curIndex + 1}` },
    ]);
    onChange([
      ...value,
      {
        id: `slot-${curIndex}`,
        startDate: addTimeZoneOffsetForServer(new Date(start)).getTime(),
        endDate: addTimeZoneOffsetForServer(new Date(end)).getTime(),
      },
    ]);
    checkWarning(new Date(start));
    setCurIndex(curIndex + 1);
  };

  const moveEvent = ({
    event,
    start,
    end,
    isAllDay: droppedOnAllDaySlot = false,
  }) => {
    const { allDay } = event;
    if (!allDay && droppedOnAllDaySlot) {
      event.allDay = true;
    }

    setEvents((prev) =>
      prev.map((p) => {
        if (p.id === event.id) {
          return { ...p, start, end, allDay };
        }
        return p;
      }),
    );
    onChange(
      value.map((v) => {
        if (v.id === event.id) {
          return {
            ...v,
            startDate: addTimeZoneOffsetForServer(new Date(start)).getTime(),
            endDate: addTimeZoneOffsetForServer(new Date(end)).getTime(),
          };
        }
        return v;
      }),
    );
    checkWarning(new Date(start));
  };

  const resizeEvent = ({ event, start, end }) => {
    setEvents((prev) =>
      prev.map((p) => {
        if (p.id === event.id) {
          return { ...p, start, end };
        }
        return p;
      }),
    );
    onChange(
      value.map((v) => {
        if (v.id === event.id) {
          return {
            ...v,
            startDate: addTimeZoneOffsetForServer(new Date(start)).getTime(),
            endDate: addTimeZoneOffsetForServer(new Date(end)).getTime(),
          };
        }
        return v;
      }),
    );
    checkWarning(new Date(start));
  };

  const checkWarning = (newStartDate) => {
    const newStartDateTime = DateTime.fromJSDate(newStartDate);
    const oneWeekFromNow = DateTime.now().plus({ week: 1 });
    if (newStartDateTime < oneWeekFromNow) {
      setWarning('It may take a few days to schedule and find a vendor');
    }
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setOpenModal(true);
  };

  const deleteEvent = () => {
    setEvents((prev) => prev.filter((p) => p.id !== selectedEvent.id));
    onChange(value.filter((v) => v.id !== selectedEvent.id));
    setOpenModal(false);
  };
  const { defaultDate, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(minDate),
      scrollToTime: new Date(1970, 1, 1, 6),
    }),
    [],
  );

  const localizer = luxonLocalizer(DateTime, { firstDayOfWeek: 7 });

  // .rbc-time-header-gutter: {
  //   min-width: 121px !important;
  //   border: none !important;
  //   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15) !important;
  // },
  // .rbc-day-bg: { min-width: 120px !important; }
  return (
    <Box>
      <style>
        {`
        .rbc-timeslot-group {
          min-height: 100px;
        }
        .rbc-time-header-gutter {
          min-width: 82px !important;
          width: 82px !important;
          max-width: 82px !important;
        }
        .rbc-time-gutter {
          min-width: 82px !important;
          width: 82px !important;
          max-width: 82px !important; 
        }
      `}
      </style>
      <WarningText warning={warning} />

      <ErrorText error={error} />
      <DndCalendar
        defaultDate={defaultDate}
        defaultView={Views.WEEK}
        view={Views.WEEK}
        events={myEvents}
        localizer={localizer}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleNew}
        selectable
        resizable
        step={180}
        onEventDrop={moveEvent}
        popup
        onEventResize={resizeEvent}
        views={['week']}
        scrollToTime={scrollToTime}
        components={{
          // data as events array and change is custom method passed into component(for perform any functionality on parent state)
          // eslint-disable-next-line react/no-unstable-nested-components
          toolbar: (props) => <CustomToolbar {...props} minDate={minDate} />,
        }}
      />
      <Dialog
        onClose={() => {
          setOpenModal(false);
        }}
        open={openModal}
      >
        <Box p={2}>
          <MarbleText weight="bold">Slot 1</MarbleText>
          <MarbleText>
            {DateTime.fromJSDate(selectedEvent?.start).toFormat(
              'MM/dd/yyyy HH:mm a',
            )}{' '}
            -{' '}
            {DateTime.fromJSDate(selectedEvent?.end).toFormat(
              'MM/dd/yyyy HH:mm a',
            )}
          </MarbleText>

          <DialogActions>
            <MarbleButton
              title="Delete"
              color="red"
              onClick={() => {
                deleteEvent();
              }}
            />
            <MarbleButton
              title="Cancel"
              variant="outlined"
              onClick={() => {
                setOpenModal(false);
              }}
            />
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

const DndCalendar = withDragAndDrop(Calendar);
