import { UrlObject } from "url";

let _hook: RouterHook;

type Url = UrlObject | string;

export type CrossPlatformRouter = {
  push: (url: Url, as?: Url, opts?: TransitionOptions) => Promise<boolean>;
  back: () => void;
  query: {
    [key: string]: string | string[] | undefined;
  };
  isReady: boolean;
  pathname: string;
  replace: (url: Url, as?: Url, options?: TransitionOptions) => Promise<boolean>;
}

type TransitionOptions = {
}

type RouterHook = () => CrossPlatformRouter;

export const setupRouter = (hook: RouterHook) => {
  _hook = hook;
}

export const useRouter: RouterHook = () => {
  return _hook && _hook();
}
