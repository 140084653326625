import React from 'react';
import Navigation from 'src/base/nav/Navigation';
import CopyrightFooter from 'src/components/layout/footer/CopyrightFooter';
import AdsHead from 'src/components/layout/head/AdsHead';
import EtcHead from 'src/components/layout/head/EtcHead';
import TitleDescriptionHead from 'src/components/layout/head/TitleDescriptionHead';
import TrackingScript from 'src/components/layout/head/TrackingScript';
import { Snack } from 'src/components/layout/Snack';
import { useAuth } from 'ui-sdk/src/hooks/identity';
import { NextPageWithLayout } from 'src/utils/types';

const Layout = ({ children }) => {
  const { authenticated, isOwner } = useAuth();

  return (
    <>
      <AdsHead />
      <EtcHead />
      <TitleDescriptionHead
        title="Marble - Virtual Rental Management"
        description="Marble helps owners with the most stressful parts of renting out their home. We take care of tenant placement and 24/7 maintenance, all for a $65/month flat fee."
      />
      <Navigation
        banner
        homePath="/"
        tabs={[
          {
            path: '/how-it-works',
            title: 'How It Works',
          },
          {
            path: '/pricing',
            title: 'Pricing',
          },
          {
            path: '/about',
            title: 'About',
          },
          {
            path: '/blog',
            title: 'Blog',
          },
        ]}
        actions={[
          {
            path: authenticated ? (isOwner ? '/owner/' : '/renter') : '/start',
            title: authenticated ? 'My Account' : 'Try for Free',
            isPrimary: true,
          },
          {
            path: '/login',
            title: 'Login',
            isPrimary: false,
            isHidden: authenticated,
          },
        ]}
      />
      <TrackingScript />
      {children}
      <Snack />
      <CopyrightFooter />
    </>
  );
};

export default function withMarketingLayout<Props = {}>(
  page: NextPageWithLayout<Props>,
) {
  page.getLayout = function getLayout(pageElement: React.ReactElement) {
    return <Layout>{pageElement}</Layout>;
  };
  return page;
}
