import React from 'react';
import {
  Check,
  ChevronRight,
  Plus,
  QuestionCircleFill,
  Bookmark,
  BookmarkFill,
} from 'react-bootstrap-icons';
import { ColorName, getColor } from 'ui-sdk/src/theme';

type MarbleIconProps = {
  name:
    | 'plus'
    | 'check'
    | 'chevron-right'
    | 'question'
    | 'bookmark'
    | 'bookmark-fill';
  color?: ColorName;
};

export const MarbleIcon: React.FC<MarbleIconProps> = ({ name, color }) => {
  const realColor = color ? getColor(color) : undefined;
  switch (name) {
    case 'plus':
      return <Plus color={realColor} size={30} />;
    case 'check':
      return <Check color={realColor} size={30} />;
    case 'chevron-right':
      return <ChevronRight color={realColor} size={18} />;
    case 'question':
      return <QuestionCircleFill color={realColor} size={20} />;
    case 'bookmark':
      return <Bookmark color={realColor} size={20} />;
    case 'bookmark-fill':
      return <BookmarkFill color={realColor} size={20} />;
    default:
  }
};
