import {
  alpha,
  Button as MaterialButton,
  CircularProgress,
  darken,
  Grid,
  SxProps,
  Theme,
  Menu,
  MenuItem,
} from '@mui/material';
import React, { useMemo } from 'react';
import { CssStyleProps } from 'src/base/props/CssStyleProps';
import {
  theme as marbleTheme,
  Color,
  TextSize,
  TextWeight,
} from 'ui-sdk/src/theme';
import { theme } from 'src/styles/theme';
import { track } from 'ui-sdk/src/util/track';
import { MarbleText } from '../texts/Text';

interface ButtonProps {
  title: any;
  onClick?: (e) => void;
  color?: 'primary' | 'secondary' | 'default' | 'cancel' | 'success';
  disabled?: boolean;
  fullWidth?: boolean;
  type?: 'submit' | 'button';
  size?: 'small' | 'medium' | 'large';
  href?: string;
  target?: string;
  loading?: boolean;
}

export const Button: React.FC<ButtonProps & CssStyleProps> = ({
  fullWidth,
  title,
  onClick,
  color = 'default',
  disabled,
  href,
  target,
  size,
  style,
  type = 'button',
  className,
  loading,
}) => {
  let trueColor;
  let variant;
  switch (color) {
    case 'primary':
      trueColor = 'primary';
      variant = 'contained';
      break;
    case 'secondary':
      trueColor = 'primary';
      variant = 'outlined';
      break;
    case 'default':
      trueColor = 'info';
      variant = 'text';
      break;
    case 'cancel':
      trueColor = 'error';
      variant = 'outlined';
      break;
    default:
      break;
  }
  return (
    <MaterialButton
      variant={variant}
      onClick={onClick}
      color={trueColor}
      disabled={disabled}
      fullWidth={fullWidth}
      type={type}
      size={size}
      className={className}
      style={{
        ...{
          textTransform: 'none',
          fontSize: theme.typography.body1.fontSize,
          fontWeight: theme.typography.body1.fontWeight,
        },
        ...style,
        ...(color === 'cancel' &&
          !disabled && {
            color: trueColor,
            borderColor: trueColor,
          }),
        ...(color === 'success' &&
          !disabled && {
            color: 'white',
            backgroundColor: '#42b72a',
            borderColor: '#42b72a',
            outlineColor: '#42b72a',
          }),
      }}
      href={href}
      target={target}
    >
      {loading ?? false ? (
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            paddingLeft: marbleTheme.spacing(3),
            paddingRight: marbleTheme.spacing(3),
          }}
        >
          <CircularProgress size={30} color="inherit" />
        </div>
      ) : (
        title
      )}
    </MaterialButton>
  );
};

interface NewButtonProps {
  id?: string;
  title?: any;
  onClick?: (e) => void;
  menu?: {
    title: string;
    onClick: () => void;
  }[];
  color?: Color;
  disabled?: boolean;
  fullWidth?: boolean;
  type?: 'submit' | 'button';
  size?: 'small' | 'medium' | 'large';
  href?: string;
  target?: string;
  loading?: boolean;
  variant?: 'contained' | 'glass' | 'outlined' | 'nobg';
  icon?: any;
  endIcon?: any;
  sm?: any;
  sx?: SxProps<Theme>;
  textSize?: TextSize;
  textWeight?: TextWeight;
}

export const MarbleButton: React.FC<NewButtonProps & CssStyleProps> = ({
  fullWidth,
  title,
  id,
  onClick,
  menu,
  color = 'blue',
  disabled,
  href,
  target,
  size,
  type = 'button',
  className,
  loading,
  variant = 'contained',
  icon,
  endIcon,
  sm,
  sx,
  textSize = 'base',
  textWeight = 'regular',
}) => {
  const mainColor = useMemo(() => {
    switch (color) {
      case 'green':
        return marbleTheme.colors.green.main;
      case 'red':
        return marbleTheme.colors.red.main;
      case 'white':
        return marbleTheme.colors.white;
      case 'black':
        return marbleTheme.colors.black;
      case 'gray':
        return marbleTheme.colors.gray[4];
      default:
        return marbleTheme.colors.blue.main;
    }
  }, [color]);

  const glassColor = useMemo(() => {
    switch (color) {
      case 'green':
        return marbleTheme.colors.green.glass;
      case 'red':
        return marbleTheme.colors.red.glass;
      case 'white':
        return '#FFFFFF26';
      case 'black':
        return '#E5E5E5';
      default:
        return marbleTheme.colors.blue.glass;
    }
  }, [color]);

  const hoverColor = useMemo(() => {
    switch (color) {
      case 'green':
        return marbleTheme.colors.green.hover;
      case 'red':
        return marbleTheme.colors.red.hover;
      case 'white':
        return '#E5E5E5';
      case 'black':
        return '#000000';
      default:
        return marbleTheme.colors.blue.hover;
    }
  }, [color]);

  // map from ColorName to marbleTheme.colors value
  const textColor = {
    green: variant === 'contained' ? 'white' : 'green.main',
    red: variant === 'contained' ? 'white' : 'red.main',
    white: variant === 'contained' ? 'black' : 'white',
    black: variant === 'contained' ? 'white' : 'black',
    blue: variant === 'contained' ? 'white' : 'blue.main',
  }[color];

  const buttonStyles = {
    color: undefined,
    backgroundColor: undefined,
    borderColor: undefined,
    outlineColor: undefined,
  };

  switch (variant) {
    case 'contained':
      buttonStyles.color =
        color === 'white' || color === 'gray' ?
          marbleTheme.colors.black :
          marbleTheme.colors.white;
      buttonStyles.backgroundColor = mainColor;
      buttonStyles.borderColor = mainColor;
      buttonStyles.outlineColor = mainColor;
      break;
    case 'glass':
      buttonStyles.color = mainColor;
      buttonStyles.backgroundColor = glassColor;
      buttonStyles.borderColor = color === 'white' ? 'transparent' : glassColor;
      buttonStyles.outlineColor =
        color === 'white' ? 'transparent' : glassColor;
      break;
    case 'outlined':
      buttonStyles.color = mainColor;
      buttonStyles.backgroundColor =
        color === 'white' ? marbleTheme.colors.black : marbleTheme.colors.white;
      buttonStyles.borderColor = alpha(mainColor, 0.5);
      buttonStyles.outlineColor = alpha(mainColor, 0.5);
      break;
    case 'nobg': {
      const c = color === 'white' ? 'transparent' : marbleTheme.colors.white;
      buttonStyles.color = color === 'white' ? 'transparent' : mainColor;
      buttonStyles.backgroundColor = 'transparent';
      buttonStyles.borderColor = 'transparent';
      buttonStyles.outlineColor = color === 'white' ? 'transparent' : c;
      break;
    }
    default:
      break;
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (menu) {
      setAnchorEl(event.currentTarget);
    } else if (onClick) {
      onClick(event);
    }
    try {
      if (title) {
        track('Button Click', {
          button: {
            title,
          },
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu
        id={`${id}-basic-menu`}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `${id}-basic-button`,
        }}
        sx={{
          marginTop: theme.spacing(2),
        }}
      >
        {menu?.map(({ title: menuItemTitle, onClick: menuItemOnClick }) => (
          <MenuItem onClick={menuItemOnClick}>
            <MarbleText>{menuItemTitle}</MarbleText>
          </MenuItem>
        ))}
      </Menu>
      <MaterialButton
        id={`${id}-basic-button`}
        aria-controls={open ? `${id}-basic-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disabled={disabled}
        fullWidth={fullWidth}
        type={type}
        size={size}
        className={className}
        disableElevation
        sx={(thm) => ({
          ...sx,
          ...buttonStyles,
          textTransform: 'none',
          borderStyle: 'solid',
          borderWidth: 2,
          borderRadius: 1,
          padding:
            (icon || endIcon) && !title ?
              `${marbleTheme.spacing(2)} ${marbleTheme.spacing(
                  2,
                )} ${marbleTheme.spacing(2)} ${marbleTheme.spacing(2)}` :
              `${marbleTheme.spacing(2)} ${marbleTheme.spacing(
                  3,
                )} ${marbleTheme.spacing(2)} ${marbleTheme.spacing(3)}`,
          minWidth: '45px',
          ':hover': {
            backgroundColor:
              variant === 'contained' ?
                hoverColor :
                variant === 'glass' ?
                darken(buttonStyles.backgroundColor, 0.1) :
                variant === 'nobg' ?
                color === 'white' ?
                  alpha(marbleTheme.colors.black, 0.1) :
                  darken(marbleTheme.colors.white, 0.1) :
                glassColor,
            borderColor:
              buttonStyles.borderColor === 'transparent' ?
                buttonStyles.borderColor :
                darken(buttonStyles.borderColor, 0.1),
          },
          ':disabled': {
            backgroundColor:
              buttonStyles.backgroundColor === 'transparent' ?
                buttonStyles.backgroundColor :
                alpha(buttonStyles.backgroundColor, 0.5),
            color:
              buttonStyles.color === 'transparent' ?
                buttonStyles.color :
                alpha(buttonStyles.color, 0.5),
            borderColor:
              variant === 'outlined' ?
                alpha(buttonStyles.borderColor, 0.5) :
                'transparent',
            borderStyle: 'solid',
          },
          [thm.breakpoints.down('sm')]: {
            ...sm,
          },
        })}
        href={href}
        target={target}
      >
        {loading ?? false ? (
          <div
            style={{
              justifyContent: 'center',
              display: 'flex',
              paddingLeft: marbleTheme.spacing(3),
              paddingRight: marbleTheme.spacing(3),
            }}
          >
            <CircularProgress size={30} color="inherit" />
          </div>
        ) : icon && title ? (
          <Grid
            container
            p={0}
            sx={{ flexWrap: 'nowrap', justifyContent: 'center' }}
          >
            <Grid
              item
              p={0}
              pr={marbleTheme.spacing(1)}
              sx={{ alignItems: 'center', display: 'flex', flexWrap: 'nowrap' }}
            >
              {icon}
            </Grid>
            <Grid
              item
              p={0}
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                flexWrap: 'nowrap',
              }}
            >
              <MarbleText size={textSize} weight={textWeight} color={textColor}>
                {title}
              </MarbleText>
            </Grid>
          </Grid>
        ) : endIcon && title ? (
          <Grid
            container
            p={0}
            sx={{ flexWrap: 'nowrap', justifyContent: 'center' }}
          >
            <Grid
              item
              p={0}
              pr={marbleTheme.spacing(1)}
              sx={{ alignItems: 'center', display: 'flex', flexWrap: 'nowrap' }}
            >
              <MarbleText size={textSize} weight={textWeight} color={textColor}>
                {title}
              </MarbleText>
            </Grid>
            <Grid
              item
              p={0}
              sx={{ alignItems: 'center', display: 'flex', flexWrap: 'nowrap' }}
            >
              {endIcon}
            </Grid>
          </Grid>
        ) : icon || endIcon ? (
          <Grid item p={0} sx={{ alignItems: 'center', display: 'flex' }}>
            {icon || endIcon}
          </Grid>
        ) : (
          <MarbleText size={textSize} weight={textWeight} color={textColor}>
            {title}
          </MarbleText>
        )}
      </MaterialButton>
    </>
  );
};
