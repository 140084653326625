import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { call, callWithCancel } from '../call';
import { api } from 'server-sdk/src/api';
import {
  ChatType,
  TextChat,
  TextMessage,
  TextSMSConversation,
  TextSMSMessage,
} from 'server-sdk/src/types';
import { useReduxState } from './useReduxState';
import { InboxType } from '../ducks/inbox';
import { WebThunk } from '../store';
import {
  refreshInbox,
  refreshLeasingInbox,
  refreshLeasingSMS,
  refreshLeasingTexts,
} from '../ducks/inbox';
import { track } from '../util/track';
import { start } from 'repl';

export const markChatSeen =
  (chatId: number, chatType?: ChatType): WebThunk =>
  async (dispatch) => {
    await call(api.inbox.v2.markSeen, {
      id: chatId,
      chatType,
    });
    dispatch(refreshInbox());
  };

export const startChat = (): WebThunk => async (dispatch) => {
  await call(api.inbox.v2.startChat, {});
  dispatch(refreshInbox());
}

export const sendMessage =
  (message: string, chatId: number): WebThunk =>
  async (dispatch) => {
    await call(api.inbox.v2.sendMessage, {
      chatId,
      text: message,
    });
    track('Message Sent', { chatId });
    dispatch(refreshInbox());
  };

export const sendTextMessage =
  (message: string, chatId: number): WebThunk =>
  async (dispatch) => {
    await call(api.inbox.v2.sendTextMessage, {
      chatId,
      text: message,
    });
    track('Message Sent', { chatId });
    dispatch(refreshInbox());
  };

export const sendEmailMessage =
  (message: string, chatId: number): WebThunk =>
  async (dispatch) => {
    await call(api.inbox.v2.sendEmailMessage, {
      id: chatId,
      text: message,
    });
    dispatch(refreshInbox());
  };

export const sendSMSText =
  (text: TextSMSMessage): WebThunk =>
  async (dispatch) => {
    await call(api.inbox.sms.send, text);
    dispatch(refreshLeasingSMS());
  };

export const sendText =
  (textMessage: TextMessage): WebThunk =>
  async (dispatch) => {
    await call(api.inbox.text.sendText, textMessage);
    dispatch(refreshLeasingTexts());
  };

export const markSMSSeen =
  (phoneNumber: string): WebThunk =>
  async (dispatch) => {
    await call(api.inbox.sms.markSeen, {
      with: phoneNumber,
    });
    dispatch(refreshLeasingSMS());
  };

export const useTextMessages = (chatId: number) => {
  const dispatch = useDispatch();
  const user = useReduxState((state) => state.identity.user);
  const [textChat, setTextChat] = useState<TextChat>(undefined);
  const latestSentTextTime = textChat ? textChat.modificationTime : undefined;
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
  const [lastCustomerMessage, setLastCustomerMessage] =
    useState<string>(undefined);
  const [replySuggestions, setReplySuggestions] = useState([]);

  const loadMessages = () => {
    dispatch(async () => {
      if (chatId) {
        setIsLoading(true);
        const c = await callWithCancel(api.inbox.v2.textMessages, {
          id: chatId,
        });

        if (c) {
          c.messages = c.messages.sort(
            (a, b) => b.creationTime - a.creationTime,
          );
          setTextChat(c);
          if ((c.messages?.length ?? 0) > 0) {
            const curLastMessage = c.messages[0];
            if (curLastMessage?.message !== lastCustomerMessage) {
              if (
                curLastMessage?.phone?.phone !== c.leasingPropertyPhone?.phone
              ) {
                setLastCustomerMessage(curLastMessage?.message);
              } else {
                setReplySuggestions([]);
              }
            }
          }
        }
        setIsLoading(false);
      } else {
        console.log('skip chat call');
      }
    });
  };

  const getReplySuggestions = () => {
    dispatch(async () => {
      setIsLoadingSuggestions(true);
      const allLastMessages = getLastMessages();
      const suggestions = await call(api.inbox.text.getSuggestions, {
        clientMessages: allLastMessages,
      });
      setReplySuggestions(suggestions.replySuggestions);
      setIsLoadingSuggestions(false);
    });
  };

  const getLastMessages = () => {
    let index = 0;
    const retVal = [];
    if (textChat && (textChat.messages?.length ?? 0) > 0) {
      let curLastMessage = textChat.messages[0];
      const customerPhone = curLastMessage.phone;
      while (curLastMessage?.phone?.phone === customerPhone?.phone) {
        retVal.push(curLastMessage.message);
        index += 1;
        if (index > textChat.messages.length - 1) {
          break;
        }
        curLastMessage = textChat.messages[index];
      }
    }
    return retVal;
  };

  const send = (text: TextMessage) => {
    dispatch(sendText(text));
  };

  useEffect(() => {
    loadMessages();

    const interval = setInterval(() => {
      loadMessages();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [user, chatId]);

  useEffect(() => {
    // if the chat changes, reset the suggestions and last message
    setReplySuggestions([]);
    setLastCustomerMessage('');
  }, [chatId]);

  useMemo(() => {
    if (lastCustomerMessage) {
      getReplySuggestions();
    }
  }, [lastCustomerMessage]);

  return {
    isLoading,
    textChat,
    latestSentTextTime,
    isLoadingSuggestions,
    replySuggestions,
    send,
  };
};

export const useSMSText = (phoneNumber: string) => {
  const dispatch = useDispatch();
  const user = useReduxState((state) => state.identity.user);
  const [textConversation, setTextConversation] =
    useState<TextSMSConversation>(undefined);
  const latestSentTextTime = textConversation?.messages[0]?.creationTime;
  const [isLoading, setIsLoading] = useState(false);

  const loadMessages = () => {
    dispatch(async () => {
      if (phoneNumber) {
        setIsLoading(true);
        const c = await callWithCancel(api.inbox.v2.smsTexts, {
          with: phoneNumber,
        });

        if (c) {
          c.messages = c.messages.sort(
            (a, b) => b.creationTime - a.creationTime,
          );
          setTextConversation(c);
        }
        setIsLoading(false);
      } else {
        console.log('skip chat call');
      }
    });
  };
  const send = (text: TextSMSMessage) => {
    dispatch(sendSMSText(text));
  };

  useEffect(() => {
    loadMessages();

    const interval = setInterval(() => {
      loadMessages();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [user, phoneNumber]);

  return {
    isLoading,
    textConversation,
    latestSentTextTime,
    send,
  };
};

export const useChat = (
  chatId: number,
  textChat?: boolean,
  email?: boolean,
) => {
  const dispatch = useDispatch();
  const user = useReduxState((state) => state.identity.user);

  const [chat, setChat] = useState<any>(undefined);

  const latestSentTextTime =
    chat?.messages?.length > 0 ? chat.messages[0]?.creationTime : 0;

  const [isLoading, setIsLoading] = useState(false);
  const loadMessages = () => {
    dispatch(async () => {
      if (chatId && !isLoading) {
        console.log('get chats');
        setIsLoading(true);

        let c;
        if (textChat) {
          c = await call(api.inbox.v2.textMessages, {
            id: chatId,
          });
        } else if (email) {
          c = await call(api.inbox.v2.emailMessages, {
            id: chatId,
          });
        } else {
          c = await call(api.inbox.v2.messages, {
            id: chatId,
          });
        }

        setIsLoading(false);

        if (c) {
          setChat(c);
        }
      } else {
        console.log('skip chat call');
      }
    });
  };

  const send = (message: string) => {
    if (chat && message) {
      dispatch(sendMessage(message, chat.id));
    }
  };

  const sendText = (message: string) => {
    if (chat && message) {
      dispatch(sendTextMessage(message, chat.id));
    }
  };

  const sendEmail = (message: string) => {
    if (chat && message) {
      dispatch(sendEmailMessage(message, chat.id));
    }
  };

  useEffect(() => {
    loadMessages();

    const interval = setInterval(() => {
      loadMessages();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [user, chatId]);

  return {
    chat,
    latestSentTextTime,
    send,
    sendText,
    sendEmail,
    startChat
  };
};

const inboxView = (
  inbox: InboxType,
  refreshInboxDispatch: (page: number) => WebThunk,
) => {
  const dispatch = useDispatch();
  const user = useReduxState((state) => state.identity.user);
  const [activeChat, setActiveChat] = useState<undefined | number>(undefined);

  const inboxChats = inbox;

  const [page, setPage] = useState(1);
  const [next, setNext] = useState(0);
  const incrementPage = () => {
    const curr = inboxChats.messages?.length ?? 0;
    if (curr > next) {
      setNext(curr + 1);
      setPage(page + 1);
    }
  };

  const inboxChatsOrdered = useMemo(
    () =>
      inboxChats && inboxChats.messages ?
        inboxChats.messages.sort(
            (a, b) =>
              (b.messages[0]?.creationTime ?? 0) -
              (a.messages[0]?.creationTime ?? 0),
          ) :
        [],
    [inboxChats],
  );

  const startNewChat = () => {
    dispatch(startChat());
  }

  const refresh = (page: number) => {
    dispatch(refreshInboxDispatch(page));

    // default active is most recent read chat
    if (inboxChats !== undefined && inboxChats !== null) {
      for (const chat of inboxChats.messages) {
        const read = chat.lastSeen > chat.messages[0]?.creationTime;
        if (read) {
          if (!activeChat) {
            setActiveChat(chat.id);
            break;
          }
        }
      }
    }
  };

  useEffect(() => {
    refresh(page);

    const interval = setInterval(() => {
      dispatch(refreshInboxDispatch(page));
    }, 5000);
    return () => clearInterval(interval);
  }, [user, page]);

  return {
    chats: inboxChatsOrdered,
    incrementPage,
    startNewChat,
  };
};

export const useInboxChat = () => {
  const inbox = useReduxState((state) => state.inbox.chats);
  return inboxView(inbox, refreshInbox);
};

export const useLeasingInbox = () => {
  const inbox = useReduxState((state) => state.inbox.leasing);
  return inboxView(inbox, refreshLeasingInbox);
};

export const useLeasingSMS = () => {
  const inbox = useReduxState((state) => state.inbox.leasingSMS);
  return inboxView(inbox, refreshLeasingSMS);
};

export const useInboxTexts = (chatId: number) => {
  const dispatch = useDispatch();
  const user = useReduxState((state) => state.identity.user);

  const inbox = useReduxState((state) => state.inbox.leasingTexts);

  const texts = inbox?.chats?.sort(
    (a, b) => b.modificationTime - a.modificationTime,
  );
  const active = inbox?.chats?.find((t) => t.id === chatId);
  const latestSentTextTime = active?.modificationTime;

  const [page, setPage] = useState(1);
  const [next, setNext] = useState(0);
  const incrementPage = () => {
    const curr = inbox.chats?.length ?? 0;
    if (curr > next) {
      setNext(curr + 1);
      setPage(page + 1);
    }
  };

  const refresh = () => {
    dispatch(refreshLeasingTexts(page));
  };

  useEffect(() => {
    refresh();

    const interval = setInterval(() => {
      dispatch(refreshLeasingTexts(page));
    }, 5000);
    return () => clearInterval(interval);
  }, [user, page]);

  const send = (text: TextMessage) => {
    dispatch(sendText(text));
  };

  return {
    texts,
    active,
    latestSentTextTime,
    send,
    incrementPage,
  };
};

export const useInboxSMS = () => {
  const dispatch = useDispatch();
  const user = useReduxState((state) => state.identity.user);
  const [activeText, setActiveText] = useState<undefined | string>(undefined);

  const inbox = useReduxState((state) => state.inbox.leasingSMS);

  const texts = inbox?.messages.sort(
    (a, b) => b.messages[0].creationTime - a.messages[0].creationTime,
  );
  const active = inbox?.messages.find((t) => t.with === activeText);
  const latestSentTextTime = active?.messages[0].creationTime;

  const [page, setPage] = useState(1);
  const [next, setNext] = useState(0);
  const incrementPage = () => {
    const curr = inbox.messages?.length ?? 0;
    if (curr > next) {
      setNext(curr + 1);
      setPage(page + 1);
    }
  };

  const refresh = () => {
    dispatch(refreshLeasingSMS());
  };

  useEffect(() => {
    refresh();

    const interval = setInterval(() => {
      dispatch(refreshLeasingSMS());
    }, 5000);
    return () => clearInterval(interval);
  }, [user]);

  const send = (text: TextSMSMessage) => {
    dispatch(sendSMSText(text));
  };

  return {
    texts,
    setActiveText,
    active,
    latestSentTextTime,
    send,
    incrementPage,
  };
};
