import * as MUIIcons from '@mui/icons-material';
import { Box, Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import { CssStyleProps } from 'src/base/props/CssStyleProps';
import { theme } from 'ui-sdk/src/theme';
import { P } from '../texts/P';

interface MultiSelectProps {
  val?: any;
  onChange?: (e) => void;
  exclusive?: boolean;
  choices?: Choice[];
  type?: 'default' | 'check' | 'icon';
  layout?: 'list' | 'grid' | 'column';
}

interface Choice {
  icon?: string;
  label?: string;
  caption?: string;
  value?: any;
}

export const MultiSelect: React.FC<MultiSelectProps & CssStyleProps> = ({
  onChange,
  val,
  choices,
  exclusive,
  type,
  layout,
}) => (
  // const Icon = MUIIcons.HelpOutlineOutlined;
  <ToggleButtonGroup
    orientation="vertical"
    value={val}
    onChange={(e, v) => {
      onChange(v);
    }}
    sx={[
      layout === 'column' && {
        display: 'grid',
        gridTemplateColumns: {
          xs: 'auto',
          md: 'auto auto',
        },
        gridGap: theme.spacing(4),
        padding: theme.spacing(2),
      },
      layout === 'grid' && {
        display: 'grid',
        gridTemplateColumns: {
          xs: 'auto',
          md: 'auto auto',
          lg: 'auto auto auto auto',
        },
        gridGap: theme.spacing(10),
        padding: theme.spacing(2),
      },
    ]}
    exclusive={exclusive}
    style={{ width: '100%' }}
  >
    {choices.map((c) => {
      const selected = exclusive ? val === c.value : val?.includes(c.value);
      const toggleButtonStyle = {
        marginTop: theme.spacing(3),
        border: `2px solid`,
        borderColor: selected ?
          `${theme.colors.blue.main}80` :
          theme.colors.gray[4],
        borderRadius: theme.spacing(1),
        padding: theme.spacing(3),
        // width: '100%',
      };
      const toggleButtonSX = {
        '&.Mui-selected': {
          backgroundColor: theme.colors.white,
        },
        '&.MuiToggleButton-root:hover ': {
          backgroundColor: theme.colors.blue.glass,
        },
      };
      const Icon = c.icon ? MUIIcons[c.icon] : null;
      const BoxIcon = MUIIcons.CheckBoxOutlineBlankRounded;
      const CheckIcon = MUIIcons.CheckBoxRounded;
      // LIST LAYOUT
      if (layout === 'list' || layout === 'column') {
        return (
          <ToggleButton
            value={c.value}
            style={toggleButtonStyle}
            sx={toggleButtonSX}
          >
            <Box width="100%">
              <Grid container alignItems="center">
                {type === 'icon' && c.icon && (
                  <Grid xs={3} item pb={0} pt={0} pl={0} pr={theme.spacing(3)}>
                    <Icon
                      fontSize="large"
                      style={{
                        color: selected ?
                          theme.colors.blue.main :
                          `${theme.colors.gray[4]}`,
                      }}
                    />
                  </Grid>
                )}
                {type === 'check' && (
                  <Grid xs={3} item pb={0} pt={0} pl={0} pr={theme.spacing(3)}>
                    {selected ? (
                      <CheckIcon
                        fontSize="large"
                        style={{ color: theme.colors.blue.main }}
                      />
                    ) : (
                      <BoxIcon
                        fontSize="large"
                        style={{ color: `${theme.colors.gray[4]}` }}
                      />
                    )}
                  </Grid>
                )}
                <Grid item xs={9} p={0}>
                  <P
                    align="left"
                    style={{
                      fontSize: '15px',
                      color: `${theme.colors.black}`,
                    }}
                  >
                    {c.label}
                  </P>
                  <P
                    align="left"
                    style={{
                      fontSize: '10px',
                      color: `${theme.colors.gray[1]}`,
                    }}
                  >
                    {c.caption}
                  </P>
                </Grid>
              </Grid>
            </Box>
          </ToggleButton>
        );
      }
      // GRID LAYOUT
      return (
        <ToggleButton
          value={c.value}
          selected={selected}
          style={toggleButtonStyle}
          sx={toggleButtonSX}
        >
          <Grid container item justifyContent="center">
            <Box
              p={
                type === 'icon' || type === 'check' ?
                  `${theme.spacing(6)} ${theme.spacing(4)} ${theme.spacing(
                      6,
                    )} ${theme.spacing(4)}` :
                  `44.5px ${theme.spacing(4)} 44.5px ${theme.spacing(4)}`
              }
            >
              {type === 'icon' && c.icon && (
                <Icon
                  fontSize="large"
                  style={{
                    color: selected ?
                      theme.colors.blue.main :
                      `${theme.colors.gray[4]}`,
                  }}
                />
              )}
              {type === 'check' && selected ? (
                <CheckIcon
                  fontSize="large"
                  style={{ color: theme.colors.blue.main }}
                />
              ) : (
                type === 'check' &&
                !selected && (
                  <BoxIcon
                    fontSize="large"
                    style={{ color: `${theme.colors.gray[4]}` }}
                  />
                )
              )}
              <P
                align="center"
                style={{
                  fontSize: '15px',
                  color: `${theme.colors.black}`,
                }}
              >
                {c.label}
              </P>
              <P
                align="center"
                style={{
                  fontSize: '10px',
                  color: `${theme.colors.gray[1]}`,
                }}
              >
                {c.caption}
              </P>
            </Box>
          </Grid>
        </ToggleButton>
      );
    })}
  </ToggleButtonGroup>
);
