import { useDispatch } from 'react-redux';
import { useGlobal } from '.';

export const useRenter = () => {
  const dispatch = useDispatch();
  const global = useGlobal();

  const refreshRenter = () => {
    dispatch(async () => {
      try {
      } catch (e) {
        global.error('Failed to');
      }
    });
  };
};
