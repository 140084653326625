import { call } from '../call';
import { api } from 'server-sdk/src/api';
import { WebThunk } from '../store';
import { Application, Availability, Property, Reservation } from 'server-sdk/src/types';

enum ActionType {
  UpdateAvailabilities = 'discover/update_availabilities',
  UpdateBooking = 'discover/update_booking',
  UpdateRentals = 'discover/update_rentals',
  UpdateReservations = 'discover/update_reservations',
  UpdateApplications = 'discover/update_applications',
  UpdateInvitations = 'discover/update_invitations',
}

interface DiscoverState {
  rentals: Property[];
  reservations: Reservation[];
  booking?: Reservation;
  applications: Application[];
  availabilities: Availability[];
  invitations: Application[];
}

export const updateAvailabilities = (
  availabilities: Availability[],
): {
  type: ActionType.UpdateAvailabilities;
  availabilities: Availability[];
} => ({
  type: ActionType.UpdateAvailabilities,
  availabilities,
});

export const updateBooking = (
  booking: Reservation,
): {
  type: ActionType.UpdateBooking;
  booking: Reservation;
} => ({
  type: ActionType.UpdateBooking,
  booking,
});

export const updateRentals = (
  rentals: Property[],
): {
  type: typeof ActionType.UpdateRentals;
  rentals: Property[];
} => ({
  type: ActionType.UpdateRentals,
  rentals,
});

export const updateReservations = (
  reservations: Reservation[],
): {
  type: typeof ActionType.UpdateReservations;
  reservations: Reservation[];
} => ({
  type: ActionType.UpdateReservations,
  reservations,
});

export const updateApplications = (
  applications: Application[],
): {
  type: typeof ActionType.UpdateApplications;
  applications: Application[];
} => ({
  type: ActionType.UpdateApplications,
  applications,
});

export const updateInvitations = (
  invitations: Application[],
): {
  type: typeof ActionType.UpdateInvitations;
  invitations: Application[];
} => ({
  type: ActionType.UpdateInvitations,
  invitations,
});

export default (
  state: DiscoverState = {
    rentals: [],
    reservations: [],
    availabilities: [],
    applications: [],
    invitations: [],
  },
  action:
    | ReturnType<typeof updateAvailabilities>
    | ReturnType<typeof updateRentals>
    | ReturnType<typeof updateReservations>
    | ReturnType<typeof updateBooking>
    | ReturnType<typeof updateApplications>
    | ReturnType<typeof updateInvitations>,
) => {
  switch (action.type) {
    case ActionType.UpdateAvailabilities:
      return {
        ...state,
        availabilities: action.availabilities,
      };
    case ActionType.UpdateBooking:
      return {
        ...state,
        booking: action.booking,
      };
    case ActionType.UpdateRentals:
      return {
        ...state,
        rentals: action.rentals,
      };
    case ActionType.UpdateReservations:
      return {
        ...state,
        reservations: action.reservations,
      };
    case ActionType.UpdateApplications:
      return {
        ...state,
        applications: action.applications,
      };
    case ActionType.UpdateInvitations:
      return {
        ...state,
        invitations: action.invitations,
      };
    default:
      return state;
  }
};

export const refreshDiscover = (): WebThunk => async (dispatch) => {
  try {
    const data = await call(api.discover.all);
    dispatch(updateRentals(data.homes));
    const reservations = await call(api.reservation.schedule);
    dispatch(updateReservations(reservations));
    const applications = await call(api.application.list.renter);
    dispatch(updateApplications(applications));
    const invitations = await call(api.application.list.invites);
    dispatch(updateInvitations(invitations));
  } catch (e) {
    console.error(e);
  }
};
