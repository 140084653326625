import { Grid } from '@mui/material';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent, Token } from '@stripe/stripe-js';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';
import { C, MarbleButton } from 'src/base';
import { MarbleText } from 'src/base/texts/Text';
import { useApiStore } from 'ui-sdk/src/hooks';
import { theme } from 'ui-sdk/src/theme';

const StripeCardOptions = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Inter, Arial, sans-serif, Lora',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      border: '1px solid',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: theme.colors.red.main,
      iconColor: theme.colors.red.main,
    },
  },
};

interface CardInputProps {
  setToken: (t: Token) => void;
  paddingTop?: number;
  tryDefault?: boolean;
}

export const CardInput = ({
  setToken,
  paddingTop,
  tryDefault,
}: CardInputProps) => {
  const [error, setError] = useState(undefined);
  const [hasBlurred, setHasBlurred] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const [defaultPaymentMethod, refreshDefaultPaymentMethod] = useApiStore(
    (api) => api.payment.methods.default,
  );
  const [session, startBillingSession] = useApiStore(
    (api) => api.payment.billing.portal,
  );
  const router = useRouter();

  useMemo(() => {
    if (session?.link) {
      router.push(session.link);
    }
  }, [session]);

  useEffect(() => {
    refreshDefaultPaymentMethod({});
  }, []);

  if (stripe) {
    console.log(process.env.STRIPE_API_KEY);
    console.log('got stripe');
  }
  if (elements) {
    console.log('got elements');
  }

  const handleBlur = async () => {
    setHasBlurred(true);
  };

  const handleChange = async (event: StripeCardElementChangeEvent) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
      const c = elements?.getElement(CardElement);
      console.log(c);
      if (c) {
        try {
          const result = await stripe.createToken(c);
          if (result) {
            setToken(result.token);
          }
        } catch (e) {
          console.log(e);
          console.log('failed to get token');
          setToken(undefined);
        }
      }
    }
  };

  const padTop = paddingTop ?? theme.spacing(16);

  return (
    <div
      style={{
        paddingTop: padTop,
      }}
    >
      <style>
        {`
          .StripeElement {
            box-sizing: border-box;
            height: 40px;
            padding: 10px 12px;
            width: 100%;
            border: 1px solid #bababa;
            border-radius: 4px;
            background-color: white;
            box-shadow: 0 1px 3px 0 #e6ebf1;
            -webkit-transition: box-shadow 150ms ease;
            transition: box-shadow 150ms ease;
          }

          .StripeElement--focus {
            box-shadow: 0 1px 3px 0 #cfd7df;
          }

          .StripeElement--invalid {
            border-color: ${theme.colors.red.main}};
          }

          .StripeElement--webkit-autofill {
            background-color: #fefde5 !important;
          }
        `}
      </style>
      {tryDefault && defaultPaymentMethod && (
        <Grid
          container
          sx={(theme) => ({
            pt: 4,
            [theme.breakpoints.down('sm')]: {
              pt: 0,
            },
          })}
          direction="row-reverse"
        >
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              alignItems: 'end',
              justifyContent: 'flex-end',
              display: 'flex',
            }}
            direction="column"
          >
            <MarbleText weight="med" align="right">
              {defaultPaymentMethod?.paymentMethod?.brand} ending in{' '}
              {defaultPaymentMethod?.paymentMethod?.last4}
            </MarbleText>
            <MarbleButton
              variant="nobg"
              color="blue"
              title="Edit Card"
              textSize="s"
              textWeight="med"
              onClick={() => {
                startBillingSession({
                  isMobile: false,
                });
              }}
              sx={{
                alignSelf: 'flex-end',
              }}
            />
          </Grid>
        </Grid>
      )}
      {(!tryDefault || !defaultPaymentMethod) && (
        <>
          <div
            style={{
              padding: theme.spacing(2),
              backgroundColor: 'white',
              borderRadius: 5,
              marginBottom: 5,
              // border: '1px solid',
              // borderColor: Gray
            }}
          >
            <CardElement
              id="card-element"
              options={StripeCardOptions}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <Grid container justifyContent="space-between">
            <Grid item>
              <C
                style={{
                  paddingLeft: theme.spacing(2),
                  paddingTop: theme.spacing(2),
                }}
              >
                Powered by{' '}
                <a
                  href="https://www.stripe.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Stripe
                </a>
              </C>
            </Grid>
            <Grid item>
              <MarbleText color="red.main" size="s">
                {hasBlurred && error}
              </MarbleText>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};
