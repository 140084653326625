import { track } from 'ui-sdk/src/util/track';

// this is from gtag.js -- linter may complain
export function sendConversion() {
  // @ts-ignore
  gtag('event', 'conversion', {
    send_to: 'AW-874863650/zbovCJu03toBEKK4laED',
  });
  try {
    track('Owner Account Creation');
  } catch (e) {
    console.error('Failed to track owner account creation to segment');
  }
}

export function sendOwnerPropertyAddedConversion() {
  // @ts-ignore
  gtag('event', 'conversion', {
    send_to: 'AW-874863650/9xeYCJq26YEYEKK4laED',
  });
}

export function sendCreateSubscriptionConversion() {
  // @ts-ignore
  gtag('event', 'conversion', {
    send_to: 'AW-874863650/WxcUCIfztowYEKK4laED',
  });
}

export function sendFreeWaitlistConversion() {
  // @ts-ignore
  gtag('event', 'conversion', {
    send_to: 'AW-874863650/m2CiCMzjr6AYEKK4laED',
  });
}
