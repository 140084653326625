const enabled = () =>
  process.env.NODE_ENV === 'production' &&
  typeof window !== 'undefined' &&
  // @ts-ignore
  window.analytics;

type OwnerFunnel =
  | 'Visit'
  | 'Sign Up'
  | 'Click Tier'
  | 'Owner Account Creation'
  | 'Create Account'
  | 'Login'
  | 'Add Property'
  | 'ID Verification Started'
  | 'ID Verification Complete'
  | 'ID Verification Cancelled'
  | 'ID Verification Errored'
  | 'Message Sent'
  | 'Referral Link Copied'
  | 'Call Booked'
  | 'Rental Services Shop'
  | 'Free Waitlist Regisered'
  | 'Free Waitlist Referral Link Copied'
  | 'Mortgage Credit Card Waitlist (Front End)'
  | 'Paywall Displayed'
  | 'Rent Estimate Settings Update'
  | 'Rent Estimate Auth Wall'
  | 'Rent Estimate Offers Help Clicked'
  | 'Rent Estimate Comparable Clicked'
  | 'Invest Page Share Click'
  | 'Invest Page Email Track';

type RenterFunnel =
  | 'Update Availability'
  | 'Add Availability'
  | 'Remove Availability'
  | 'Preview Availability Link'
  | 'Copy Availability Link'
  | 'Copy Renter Referral Link'
  | 'Renter Availability View'
  | 'Renter Availability Booking'
  | 'Suggested Rentals Clicked'
  | 'Rent Payment Rewards Waitlist (Front End)'
  | 'Rent Payment Rewards Waitlist Share Copy (Front End)';

export const track = (event: OwnerFunnel | RenterFunnel, props?: any) => {
  if (enabled()) {
    // @ts-ignore
    window.analytics.track(event, props);
  }
};

export const alias = (userId: number) => {
  if (enabled()) {
    // @ts-ignore
    analytics.identify(userId.toString());
  }
};

export const identify = (userId: number) => {
  if (enabled()) {
    // @ts-ignore
    analytics.identify(userId.toString());
  }
};
